import { Component, OnDestroy, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { NavigationService } from '@services/support/navigation.service';
import { map, startWith, Subscription, switchMap } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { DetailPageService } from '@services/support/detail-page.service';
import { ModalService } from '@services/support/modal.service';
import { LoaderService } from '@services/support/loader.service';
import { AccountService } from '@services/account.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@services/support/utility.service';

export interface PeriodicElementNew {
  name: string;
  value: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy {

  @ViewChild("licenseTemplate", { static: true }) private licenseTemplate: TemplateRef<any>;
  @Output() sideLinkClicked = new EventEmitter<void>();

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['name', 'value'];

  sidebarOpen: boolean = false;
  sidebarSub: Subscription = null;

  licenseInfo: any = {
    license: null
  };
  licenseInfoSub: Subscription = null;

  workflowAvailable: boolean = false;
  ticketAvailable: boolean = false;
  reportChart: boolean = false;

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private navigationService: NavigationService,
    private detailPageService: DetailPageService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private multilanguageService: MultilanguageService,
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) {
    this.sidebarSub = this.navigationService.sidebarState.subscribe(state => {
      this.sidebarOpen = state;
    });

    this.licenseInfoSub = this.accountService.accountData.pipe(
      switchMap(accountData => this.multilanguageService.onLangChange.pipe(
        // onLangChange will not triggered until lang change,
        // we need to trigger first time to fill license related data
        startWith(this.multilanguageService.currentLang),
        map(() => accountData)
      ))
    )
    .subscribe(accountData => {
      this.workflowAvailable = accountData.license.workflow_account;
      this.ticketAvailable = accountData.add_ons.ticket;
      this.reportChart = accountData.features.adminreportcharts;
      this.mapLicence(accountData);
    });
  }

  mapLicence(accountData) {
    this.licenseInfo.license = {
      start: this.datePipe.transform(new Date(accountData.license.remote_start + UtilityService.timezoneOffset), 'mediumDate'),
      end: this.datePipe.transform(new Date(accountData.license.remote_end + UtilityService.timezoneOffset), 'mediumDate'),
      limit: accountData.license.limit === 0 ? this.translateService.instant('APP.MAIN.SIDEBAR.LICENSE_MODAL.UNLIMITED') : accountData.license.limit,
      limit2: accountData.license.limit2 === 0 ? this.translateService.instant('APP.MAIN.SIDEBAR.LICENSE_MODAL.UNLIMITED') : accountData.license.limit2,
      expert_concurrent_limit: accountData.license.expert_concurrent_limit === 0 ? this.translateService.instant('APP.MAIN.SIDEBAR.LICENSE_MODAL.UNLIMITED') : accountData.license.expert_concurrent_limit,
      allow_archiving: accountData.features.archiving ? this.translateService.instant('APP.MAIN.SIDEBAR.LICENSE_MODAL.ARCHIVE_AVAILABLE') : this.translateService.instant('APP.MAIN.SIDEBAR.LICENSE_MODAL.ARCHIVE_UNAVAILABLE'),
      trainingLicenses: accountData.training_licenses ? Object.keys(accountData.training_licenses).map(l => accountData.training_licenses[l]) : null
    }
    this.dataSource = new MatTableDataSource(Object.entries(this.licenseInfo.license));
  }

  toggleSidebar() {
    this.navigationService.toggleSidebar();
  }

  onSidebarLinkClicked() {
    this.sideLinkClicked.emit();
    // Toggle sidebar if width is smaller than 768px
    if (window.innerWidth < 768) {
      this.navigationService.toggleSidebar();
    }
    this.detailPageService.removeComponent();
    this.detailPageService.removeComponent2();
  }

  onLicenseClicked() {
    this.onSidebarLinkClicked();

    this.loaderService.show();
    this.loaderService.hide();
    const modalId = this.modalService.show({
      template: this.licenseTemplate,
      context: {
        dataModel: this.licenseInfo,
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }

  openWorkflowEditor(workflowUrl: string) {
    this.loaderService.show();
    this.authService.switchToWorkflow(workflowUrl)
    .finally(() => this.loaderService.hide());
  }

  ngOnDestroy() {
    if (this.sidebarSub) { this.sidebarSub.unsubscribe() }
    if (this.licenseInfoSub) { this.licenseInfoSub.unsubscribe() }
  }
}
