<div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
        <span class="card-header-title" *ngIf="type === TYPE_LAST_SESSIONS">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.LAST_SESSIONS' | translate }}</span>
        <span class="card-header-title" *ngIf="type === TYPE_ROOM_SESSIONS">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.ROOM_SESSIONS' | translate:{room:roomName} }}</span>
    </div>
    <div class="card-body px-0 py-1">
        <div class="text-center my-2" *ngIf="!sessions">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.LOADING' | translate }}</div>
        <div class="text-center my-2" *ngIf="sessions && sessions.length === 0">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.NO_SESSION_MESSAGE' | translate }}</div>
        <div class="t-container container-fluid" *ngIf="sessions && sessions.length !== 0">
            <div class="t-head row sm-hide pl-3">
                <div class="t-cell col-1">#</div>
                <div class="t-cell col-2">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.DATE' | translate }} ({{ timezone }})</div>
                <div class="t-cell col-3">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.ROOM_NAME' | translate }}</div>
                <div class="t-cell col-2">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.PARTICIPANTS' | translate }}</div>
                <div class="t-cell col-2">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.STATUS' | translate }}</div>
                <div class="t-cell col-2">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.DURATION' | translate }}</div>
            </div>

            <div class="t-row row pl-3" *ngFor="let session of sessions; index as i;" (click)="onSessionDetails(session)">
                <div class="t-cell col-1 sm-hide">{{ i+1 }}</div>
                <div class="t-cell sm-show col-6">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.DATE' | translate }} ({{ timezone }})</div>
                <div class="t-cell col-6 col-lg-2">{{ session.create_time | utcCalc | async }}</div>
                <div class="t-cell sm-show col-6">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.ROOM_NAME' | translate }}</div>
                <div class="t-cell col-6 col-lg-3">{{ session.room_id | roomName | async }}</div>
                <div class="t-cell sm-show col-6">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.PARTICIPANTS' | translate }}</div>
                <div class="t-cell col-6 col-lg-2">
                    <div *ngFor="let name of session.users | getIds | filterGuests:session.guest_users | userNames | async" class="mt-1">{{ name }}</div>
                    <div *ngFor="let guest of session.guest_users | objectToArray" class="mt-1">{{ guest.name }} <span class="text-info">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.GUEST' | translate }}</span></div>
                </div>
                <div class="t-cell sm-show col-6"></div>
                <div class="t-cell col-6 col-lg-2">
                    <span class="badge" [ngClass]="{'badge-success': !session.end_time, 'badge-secondary': session.end_time}">{{ (session.end_time ? 'APP.MAIN.SESSIONS.SESSION_LIST.ENDED' : 'APP.MAIN.SESSIONS.SESSION_LIST.ON_GOING') | translate }}</span>
                </div>
                <div class="t-cell sm-show col-6">{{ 'APP.MAIN.SESSIONS.SESSION_LIST.DURATION' | translate }}</div>
                <div class="t-cell col-6 col-lg-2">{{ session | sessionDuration }}</div>
            </div>
        </div>
    </div>
</div>
