<div class="storage-container">
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.STORAGE.TITLE' | translate }}</span>
      <ng-container *ngIf="!webdavEnabled">
        <button [class.disabled]="!allowAzureIntegration" *ngIf="allowArchiving && azureInfo && azureInfo.storage_type.shared" class="btn btn-sm btn-info" (click)="onCheckAzure(true)"
          [dynamicTooltip]="'APP.MAIN.STORAGE.ARCHIVE_STORAGE.PRIVATE_STORAGE_UNAVAILABLE'" [tooltipDisabled]="allowAzureIntegration" [tooltipDelay]="200">{{ 'APP.MAIN.STORAGE.CONNECT_AZURE' | translate }}</button>
        <button [class.disabled]="!allowAzureIntegration" *ngIf="allowArchiving && azureInfo && !azureInfo.storage_type.shared" class="btn btn-sm btn-danger" (click)="onCheckAzure(false)"
          [dynamicTooltip]="'APP.MAIN.STORAGE.ARCHIVE_STORAGE.PRIVATE_STORAGE_UNAVAILABLE'" [tooltipDisabled]="allowAzureIntegration" [tooltipDelay]="200">{{ 'APP.MAIN.STORAGE.DISCONNECT_AZURE' | translate }}</button>
      </ng-container>
    </div>
    <div class="card-body p-3 px-lg-5 py-lg-4">
      <div *ngIf="azureInfo">
        <ng-container *ngIf="!webdavEnabled">
          <div class="shared-storage border border-light rounded d-flex flex-row" [class.bg-light]="azureInfo.storage_type.shared">
            <div class="d-flex align-items-center justify-content-center px-3 px-md-4">
              <div class="custom-control centered-checkbox custom-checkbox mr-1 mr-md-3" [class.disabled]="true">
                <input type="checkbox" class="custom-control-input" id="shared-checkbox" [disabled]="true" [(ngModel)]="azureInfo.storage_type.shared">
                <label class="custom-control-label" for="shared-checkbox"></label>
              </div>
              <img style="width: 32px; height: 32px; object-fit: contain;" [src]="('design' | env).squareLogo">
            </div>
            <div class="container-fluid py-4">
              <progressbar class="progress mb-3" [value]="sharedAzureProgress" [max]="100"></progressbar>
              <div *ngIf="azureInfo.shared_storage.dedicated > 0">
                <div [innerHTML]="'APP.MAIN.STORAGE.SHARED_DEDICATED_SIZE' | translate:azureInfo.shared_storage"></div>
                <div class="mt-1">{{ 'APP.MAIN.STORAGE.SHARED_DEDICATED_DELETE' | translate }}</div>
              </div>
              <div *ngIf="azureInfo.shared_storage.expire > 0">
                <div [innerHTML]="'APP.MAIN.STORAGE.SHARED_EXPIRE_TIME' | translate:azureInfo.shared_storage"></div>
                <div class="mt-1">{{ 'APP.MAIN.STORAGE.SHARED_EXPIRE_DELETE' | translate }}</div>
              </div>
              <div *ngIf="azureInfo.shared_storage.dedicated === 0 && azureInfo.shared_storage.expire === 0">
                <div [innerHTML]="'APP.MAIN.STORAGE.SHARED_UNLIMITED' | translate"></div>
              </div>
            </div>
          </div>
          <div class="private-storage border border-light rounded d-flex flex-row mt-2" [class.bg-light]="!azureInfo.storage_type.shared">
            <div class="d-flex align-items-center justify-content-center px-3 px-md-4">
              <div class="custom-control centered-checkbox custom-checkbox mr-1 mr-md-3" [class.disabled]="true">
                <input type="checkbox" class="custom-control-input" id="private-checkbox" [disabled]="true" [(ngModel)]="!azureInfo.storage_type.shared">
                <label class="custom-control-label" for="private-checkbox"></label>
              </div>
              <img style="width: 32px; height: 32px; object-fit: contain;" src="assets/img/azure_blue.png">
            </div>
            <div class="container-fluid py-4">
              <ng-container *ngIf="azureInfo.storage_type.shared">
                <div>{{ 'APP.MAIN.STORAGE.PRIVATE_STORAGE_MESSAGE' | translate:('design' | env) }}</div>
                <div class="mt-1 mb-3">{{ 'APP.MAIN.STORAGE.PRIVATE_STORAGE_EXPLANATION' | translate }}</div>
                <div class="font-weight-bold text-danger">{{ 'APP.MAIN.STORAGE.PRIVATE_NO_AZURE' | translate }}</div>
              </ng-container>
              <ng-container *ngIf="!azureInfo.storage_type.shared">
                <div>
                  <div class="font-weight-bold text-success mb-3">{{ 'APP.MAIN.STORAGE.PRIVATE_AZURE' | translate }}</div>
                  <div [innerHTML]="'APP.MAIN.STORAGE.PRIVATE_AZURE_NAME' | translate:azureInfo.storage_type"></div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="allowArchiving && !allowAzureIntegration" class="azure-integration-disabled-overlay" [dynamicTooltip]="'APP.MAIN.STORAGE.ARCHIVE_STORAGE.PRIVATE_STORAGE_UNAVAILABLE'" [tooltipDisabled]="allowAzureIntegration" [tooltipDelay]="200"></div>
          </div>
        </ng-container>

        <div class="webdav-storage border border-light bg-light rounded d-flex flex-row mt-2" *ngIf="webdavEnabled">
          <div class="d-flex align-items-center justify-content-center px-3 px-md-4">
            <div class="custom-control centered-checkbox custom-checkbox mr-1 mr-md-3 disabled">
              <input type="checkbox" class="custom-control-input" id="private-checkbox" [disabled]="true" [(ngModel)]="webdavEnabled">
              <label class="custom-control-label" for="private-checkbox"></label>
            </div>
            <div class="text-info font-weight-bold" style="font-size: 12px;"><span style="color: #4a688f">WEB</span><span style="color: #e6646b">DAV</span></div>
          </div>
          <div class="container-fluid py-4">{{ 'APP.MAIN.STORAGE.WEBDAV_MESSAGE' | translate }}</div>
        </div>

      </div>
      <div *ngIf="!azureInfo" class="text-center">{{ 'APP.MAIN.STORAGE.AZURE_INFO_LOADING' | translate }}</div>
      <div *ngIf="!allowArchiving" class="disabled-overlay" [dynamicTooltip]="'APP.MAIN.STORAGE.ARCHIVE_STORAGE.ARCHIVE_UNAVAILABLE'" [tooltipDisabled]="allowArchiving" [tooltipDelay]="200"></div>
    </div>
  </div><!-- card -->
</div>

<ng-template #checkAzureTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ (dataModel.connect ? 'APP.MAIN.STORAGE.CHECK_AZURE_TEMPLATE.CONNECT_AZURE_CLOUD_STORAGE' : 'APP.MAIN.STORAGE.CHECK_AZURE_TEMPLATE.DISCONNECT_AZURE_CLOUD_STORAGE') | translate }}</div>
        <div class="card-body">
          <div class="m-body pt-4 px-5">
            <div class="font-weight-bold text-danger mb-3">{{ 'APP.MAIN.STORAGE.CHECK_AZURE_TEMPLATE.CAUTION' | translate }}</div>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.STORAGE.CHECK_AZURE_TEMPLATE.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.continue()">{{ (dataModel.connect ? 'APP.MAIN.STORAGE.CHECK_AZURE_TEMPLATE.CONNECT_ANYWAY' : 'APP.MAIN.STORAGE.CHECK_AZURE_TEMPLATE.DISCONNECT_ANYWAY') | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #connectAzureTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CONNECT_AZURE_CLOUD_STORAGE' | translate }}</div>
        <div class="card-body">
          <div class="m-body pt-4 px-5">
            <form #azureForm="ngForm" autocomplete="off" (ngSubmit)="callbacks.connect(azureForm)">

              <div class="form-group">
                <label for="accountName">{{ 'APP.MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.ACCOUNT_NAME' | translate }}<span class="required-field">*</span></label>
                <input #accountName="ngModel" class="form-control" type="text" name="accountName" placeholder="{{ 'APP.MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.ACCOUNT_NAME' | translate }}" 
                    [ngClass]="{'is-invalid':accountName.errors && accountName.touched}" [(ngModel)]="dataModel.accountName" required>
                <small class="invalid-feedback mx-2" [hidden]="!accountName.errors?.required">{{ 'APP.MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.ACCOUNT_NAME_IS_REQUIRED' | translate }}</small>
              </div>

              <div class="form-group">
                <label for="containerName">{{ 'APP.MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CONTAINER_NAME' | translate }}<span class="required-field">*</span></label>
                <input #containerName="ngModel" class="form-control" type="text" name="containerName" placeholder="{{ 'APP.MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CONTAINER_NAME' | translate }}" 
                    [ngClass]="{'is-invalid':containerName.errors && containerName.touched}" [(ngModel)]="dataModel.containerName" required>
                <small class="invalid-feedback mx-2" [hidden]="!containerName.errors?.required">{{ 'APP.MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CONTAINER_NAME_IS_REQUIRED' | translate }}</small>
              </div>

              <div class="form-group">
                <label for="accountKey">{{ 'APP.MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.ACCOUNT_KEY' | translate }}<span class="required-field">*</span></label>
                <input #accountKey="ngModel" class="form-control" type="text" name="accountKey" placeholder="{{ 'APP.MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.ACCOUNT_KEY' | translate }}" 
                    [ngClass]="{'is-invalid':accountKey.errors && accountKey.touched}" [(ngModel)]="dataModel.accountKey" required>
                <small class="invalid-feedback mx-2" [hidden]="!accountKey.errors?.required">{{ 'APP.MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.ACCOUNT_KEY_IS_REQUIRED' | translate }}</small>
              </div>
            </form>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CANCEL' | translate }}</a>
            <a class="text-success" (click)="callbacks.connect(azureForm)" [class.disabled]="!azureForm.valid">{{ 'APP.MAIN.STORAGE.CONNECT_AZURE_TEMPLATE.CONNECT' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #disconnectAzureTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.STORAGE.DISCONNECT_AZURE_MODAL.TITLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <p class="text-secondary">{{ 'APP.MAIN.STORAGE.DISCONNECT_AZURE_MODAL.MESSAGE' | translate }}</p>
            <p class="text-danger font-weight-bold mt-2">{{ 'APP.MAIN.STORAGE.DISCONNECT_AZURE_MODAL.CAUTION' | translate:('design' | env) }}</p>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.STORAGE.DISCONNECT_AZURE_MODAL.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.disconnect()">{{ 'APP.MAIN.STORAGE.DISCONNECT_AZURE_MODAL.DISCONNECT' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>