import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';

import firebase from "firebase/app";

import { saveAs } from "file-saver";

import { Subject } from 'rxjs';
import { UploadModel } from '@models/UploadModel';
import { UploadFile } from '@models/UploadFile';
import { AuthService } from '@services/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileShareService {

  private storage: firebase.storage.Storage;

  constructor(
    private authService: AuthService,
    private afdb: AngularFireDatabase,
    private afst: AngularFireStorage,
    private http: HttpClient,
  ) {
    this.storage = this.afst.storage;
  }

  async downloadFile(url: string, filename: string) {
    return this.http.get(url, {observe: 'response', responseType: 'blob'}).toPromise().then(response => {
      saveAs(response.body, filename);
      return;
    });
  }

  upload(uploadModel: UploadModel, stationId: string) {
    const storageRef = this.storage.ref(`accounts/${this.authService.currentUser.account_id}/workflow/stations/${stationId}`);
    const uploadedSource = new Subject<UploadFile>();
    const urlPromises: Promise<any>[] = [];

    uploadModel.uploading = true;

    let finished = 0;
    uploadModel.uploadFiles.forEach(upload => {
      upload.key = this.afdb.createPushId();
      upload.uploadTask = storageRef.child(upload.key + '.' + upload.extension).put(upload.file, {customMetadata: { name: upload.file.name }});

      uploadModel.totalBytes += upload.uploadTask.snapshot.totalBytes;
      
      upload.uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: firebase.storage.UploadTaskSnapshot) => {

          let trBytes = 0;
          let ttBytes = 0;
          uploadModel.uploadFiles.forEach(upfile => {
            if (!(upfile.uploadTask.snapshot.state === 'error' || upfile.uploadTask.snapshot.state === 'canceled')) {
              ttBytes += upfile.uploadTask.snapshot.totalBytes;
              trBytes += upfile.uploadTask.snapshot.bytesTransferred;
            }
          });
          uploadModel.totalBytes = ttBytes;
          uploadModel.transferredBytes = trBytes;

          upload.progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
        (error) => {
          finished++;
          if (finished === uploadModel.uploadFiles.length) {
            uploadModel.uploading = false;
            uploadModel.completed = true;
          }
        },
        () => {

          urlPromises.push(
            upload.uploadTask.snapshot.ref.getDownloadURL()
            .then(url => {
              upload.url = url;
              uploadedSource.next(upload);
            })
            .catch(error => {
            })
          );

          finished++;
          if (finished === uploadModel.uploadFiles.length) {
            uploadModel.uploading = false;
            uploadModel.completed = true;

            Promise.all(urlPromises)
            .then(() => {
              uploadedSource.complete();
            })
            .catch(error => {
            });
          }
        }
      );
    });

    return uploadedSource.asObservable();
  }

  convertBase64toFile(content: string, contentType: string, filename: string): File {
    const sliceSize = 512;
    // Method which converts base64 to binary
    const byteCharacters = window.atob(content);
    const byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
 
    // Method which converts byteArrays to blob
    const blob = new Blob(byteArrays, { type: contentType });

    // File constructor for create file
    let f;
    try {
      f = new File([blob], filename, { type: contentType, lastModified: (new Date()).getTime() });
    } catch(error) {
      const lastModifiedDate = new Date();
      Object.defineProperties(blob, {
        name: {
          value: filename
        },
        lastModifiedDate: {
          value: lastModifiedDate
        },
        lastModified: {
          value: lastModifiedDate.getTime()
        },
        toString: {
          value() {
            return '[object File]'
          }
        }
      });
      f = blob;
    }
    return f;
  }

  convertCanvasToJPEGFile(canvas: HTMLCanvasElement, filename: string, compressionRatio: number): Promise<File> {
    return new Promise<File>(resolve => {
      if (canvas.toBlob) {
        canvas.toBlob(blob => {
          resolve(new File([blob], filename, { type: "image/jpeg", lastModified: (new Date()).getTime() }));
        }, "image/jpeg", compressionRatio);
      } else {
        resolve(this.convertBase64toFile(canvas.toDataURL("image/jpeg", compressionRatio).slice('data:image/jpeg;base64,'.length), "image/jpeg", filename));
      }
    });
  }

  saveBase64File(content: string, contentType: string, filename: string) {
    const sliceSize = 512;
    // Method which converts base64 to binary
    const byteCharacters = window.atob(content);
    const byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    saveAs(new Blob(byteArrays, { type: contentType }), filename);
  }

  saveFile(file: File) {
    saveAs(file);
  }

  async getSessionExportPDF(room_id: string, session_id: string) {
    const url = environment.endPoints.getExportPDF;
    const body = {
      room_id: room_id,
      session_id: session_id,
      token: this.authService.currentUser.token
    }

    if (typeof (window as any).showSaveFilePicker === 'function')  {
      const defaultFileName = 'export.pdf';

      const options = {
        types: [
          {
            description: 'PDF Files',
            accept: {
              'application/pdf': ['.pdf'],
            },
          },
        ],
        suggestedName: defaultFileName,
      };

      const handle = await (window as any).showSaveFilePicker(options);
      const fileName = handle.name || defaultFileName;

      const response = await this.http.post(url, body, { observe: 'response', responseType: 'blob' }).toPromise();
      const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });

      const writable = await handle.createWritable();
      await writable.write(blob);
      await writable.close();
    } else {
      const response = await this.http.post(url, body, { observe: 'response', responseType: 'blob' }).toPromise();
      const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
      saveAs(blob, 'export.pdf');
    }
  }
}
