<div class="room-list-container">
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <span class="card-header-title">{{ 'APP.MAIN.ROOMS.ROOM_LIST.TITLE' | translate }}</span>
            <button class="btn btn-outline-success" *ngIf="detailed" (click)="onNewRoom()">{{ 'APP.MAIN.ROOMS.ROOM_LIST.NEW_ROOM' | translate }}</button>
        </div>

        <div *ngIf="detailed" class="filters container-fluid">
            <div class="row">
                <div class="col-sm mb-2 mt-2">
                    <input 
                        class="field form-control py-2" 
                        placeholder="{{ 'APP.MAIN.ROOMS.ROOM_LIST.ROOM_NAME' | translate }}"
                        #name="ngModel" 
                        [(ngModel)]="roomName" 
                        name="roomName" 
                        [ngClass]="{'is-invalid':name.errors && name.touched}"
                        type="text" 
                        (ngModelChange)="onSearchTextChange($event)"
                    >
                </div>
                <div class="col-sm mb-2 mt-2">
                    <ng-select 
                        [items]="userNames" 
                        name="users" 
                        [clearSearchOnAdd]="true" 
                        [closeOnSelect]="false"
                        placeholder="{{ 'APP.MAIN.ROOMS.ROOM_LIST.ALL_USERS' | translate }}" 
                        [multiple]="true" 
                        [(ngModel)]="selectedUserFilter" 
                        (change)="onFilterChanged()"
                        style="font-size: 13px;"
                    >
                    </ng-select>
                </div>
            </div>
        </div>

        <div class="card-body px-0 py-1">
            <div class="text-center my-2" *ngIf="rooms.length === 0">{{ 'APP.MAIN.ROOMS.ROOM_LIST.NO_ROOM_MESSAGE' | translate }}</div>
            <div class="t-container container-fluid" *ngIf="rooms.length !== 0">
                <div class="t-head row sm-hide pl-3 pr-4" *ngIf="detailed" [ngClass]="{'button-pad': detailed}">
                    <div class="t-cell col-1">#</div>
                    <div class="t-cell col-1">{{ 'APP.MAIN.ROOMS.ROOM_LIST.ICON' | translate }}</div>
                    <div class="t-cell" [ngClass]="{'col-5': detailed, 'col-6': !detailed}">{{ 'APP.MAIN.ROOMS.ROOM_LIST.ROOM_NAME' | translate }}</div>
                    <div class="t-cell col-4">{{ 'APP.MAIN.ROOMS.ROOM_LIST.USERS' | translate }}</div>
                    <div class="t-cell col-1" *ngIf="detailed"></div>
                </div>

                <div class="t-row row pl-3 pr-4" *ngFor="let room of rooms; index as i;"
                        (click)="onRoomDetails($event, room)" [ngClass]="{'button-pad': detailed}">
                    <div class="t-cell col-1 sm-hide">{{ i+1 }}</div>
                    <div class="t-cell sm-show col-6">{{ 'APP.MAIN.ROOMS.ROOM_LIST.ICON' | translate }}</div>
                    <div class="t-cell col-6 col-lg-1">
                        <div class="room-icon">
                            <img src="{{ room.room_data.icon }}">
                        </div>
                    </div>
                    <div class="t-cell sm-show col-6">{{ 'APP.MAIN.ROOMS.ROOM_LIST.ROOM_NAME' | translate }}</div>
                    <div class="t-cell col-6" [ngClass]="{'col-lg-5': detailed, 'col-lg-6': !detailed}">
                        <span>{{ room.room_data.name }}</span>
                        <span *ngIf="room.room_data.session.active" class="badge badge-success ml-2">{{ 'APP.MAIN.ROOMS.ROOM_LIST.ACTIVE' | translate }}</span>
                        <span *ngIf="!room.room_data.session.active && !room.room_data.disabled" class="badge badge-secondary ml-2">{{ 'APP.MAIN.ROOMS.ROOM_LIST.PASSIVE' | translate }}</span>
                        <span *ngIf="room.room_data.disabled" class="badge badge-danger ml-2">{{ 'APP.MAIN.ROOMS.ROOM_LIST.DISABLED' | translate }}</span>
                    </div>
                    <div class="t-cell sm-show col-6">{{ 'APP.MAIN.ROOMS.ROOM_LIST.USERS' | translate }}</div>
                    <div class="t-cell col-6 col-lg-4">

                        <ng-container *ngIf="!room.room_data.isExpanded">
                            <div  *ngFor="let user of getRoomUsers(room.room_data.users) | slice:0:4">
                                <i class="mi mi-fiber-manual-record" style="font-size: 9px"
                                    [ngClass]="{ 'text-danger': !user.in_room, 'text-success': user.in_room }"></i>
                                <span class="ml-1">{{ user.name }}</span><span *ngIf="user.guest" class="text-info ml-1">{{ 'APP.MAIN.ROOMS.ROOM_LIST.GUEST' | translate }}</span>
                                </div>
                        </ng-container>
                       
                        <ng-container *ngIf="room.room_data.isExpanded">
                            <div *ngFor="let user of getRoomUsers(room.room_data.users) ; let i = index">
                                <i class="mi mi-fiber-manual-record" style="font-size: 9px"
                                [ngClass]="{ 'text-danger': !user.in_room, 'text-success': user.in_room }"></i>
                            <span class="ml-1">{{ user.name }}</span><span *ngIf="user.guest" class="text-info ml-1">{{ 'APP.MAIN.ROOMS.ROOM_LIST.GUEST' | translate }}</span>
                            </div>
                          </ng-container>

                          <div class="pt-2">
                            <a  (click)="toggleItems($event,room.room_data)" class="text-muted pt-2 show-more"  *ngIf="getRoomUsers(room.room_data.users).length > 4 && !room.room_data.isExpanded">
                                {{ 'APP.MAIN.SHARED.SHOW_MORE' | translate:{count:getRoomUsers(room.room_data.users).length-4} }} <i class="mi mi-keyboard-arrow-down"></i>
                            </a>
                           
                          </div>
                             <div>
                              <a  (click)="toggleItems($event,room.room_data)" class="text-muted pt-2 show-more"  *ngIf="getRoomUsers(room.room_data.users).length > 4 && room.room_data.isExpanded">
                                {{ 'APP.MAIN.SHARED.SHOW_LESS' | translate }}  <i class="mi mi-keyboard-arrow-up"></i>
                              </a>
                             </div>
                    </div>
                    <div class="t-cell col-12 col-lg-1" *ngIf="detailed">
                        <div class="btn-group d-flex">

                            <button type="button" class="btn btn-sm btn-warning" (click)="onRoomDetails($event, room)">
                                <i class="mi mi-remove-red-eye"></i><span class="d-inline d-lg-none">{{ 'APP.MAIN.ROOMS.ROOM_LIST.DETAILS' | translate }}</span>
                            </button>
                            <button type="button" class="btn btn-sm btn-info" (click)="onRoomEdit($event, room)" [class.disabled]="room.room_data.disabled">
                                <i class="mi mi-edit"></i><span class="d-inline d-lg-none">{{ 'APP.MAIN.ROOMS.ROOM_LIST.EDIT' | translate }}</span>
                            </button>
                            <button type="button" class="btn btn-sm" [ngClass]="{'btn-secondary': !room.room_data.disabled, 'btn-primary': room.room_data.disabled}" (click)="onRoomToggle($event, room)">
                                <i class="mi mi-block" [ngStyle]="{ opacity: !room.room_data.disabled ? 0.4 : 1.0 }"></i>
                                <span *ngIf="!room.room_data.disabled" class="d-inline d-lg-none">{{ 'APP.MAIN.ROOMS.ROOM_LIST.DISABLE' | translate }}</span>
                                <span *ngIf="room.room_data.disabled" class="d-inline d-lg-none">{{ 'APP.MAIN.ROOMS.ROOM_LIST.ENABLE' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #toggleTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="card">
            <div *ngIf="dataModel.disabled" class="card-header py-3">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.TITLE_ENABLE' | translate }}</div>
            <div *ngIf="!dataModel.disabled" class="card-header py-3">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.TITLE_DISABLE' | translate }}</div>
            <div class="card-body">
            <div class="m-body padded">
                <div *ngIf="dataModel.disabled" [innerHTML]="'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.MESSAGE_ENABLE' | translate:dataModel"></div>
                <div *ngIf="!dataModel.disabled" [innerHTML]="'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.MESSAGE_DISABLE' | translate:dataModel"></div>
            </div>
            <div class="m-footer">
                <a class="text-info" (click)="callbacks.no()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.CANCEL' | translate }}</a>
                <a *ngIf="dataModel.disabled" class="text-primary" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.ENABLE' | translate }}</a>
                <a *ngIf="!dataModel.disabled" class="text-secondary" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.DISABLE' | translate }}</a>
            </div>
            </div>
        </div>
        </div>
    </div>
</ng-template>