import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minlengthValidator(minLength: string): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors |  null => {
        const value = control.value;
        if (!value) { 
            return { minlengthAccessed: true }; 
        }

        const errors: any = {};
        if (value.length < parseInt(minLength)) { 
            errors['minlengthAccessed'] = true; 
        }

        if (Object.keys(errors).length > 0) {
            return { minlengthAccessed: true };
        } else {
            return null;
        }
    }
}