<div class="steps-container">
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <span class="card-header-title">{{ 'APP.MAIN.STATIONS.STEP_LIST.TITLE' | translate }}</span>
            <button class="btn btn-outline-success" (click)="onNewStep()" [disabled]="noNewStep">{{ 'APP.MAIN.STATIONS.STEP_LIST.NEW_STEP' | translate }}</button>
        </div>
        <div class="card-body p-0">
            <div class="text-center my-2" *ngIf="!steps">{{ 'APP.MAIN.STATIONS.STEP_LIST.LOADING' | translate }}</div>
            <div class="text-center my-2" *ngIf="steps && steps.length === 0">{{ 'APP.MAIN.STATIONS.STEP_LIST.NO_STEP_MESSAGE' | translate }}</div>
            <div cdkDropList #stepList="cdkDropList" [cdkDropListData]="steps" (cdkDropListDropped)="onDrop($event)"
                    class="t-container container-fluid" *ngIf="steps && steps.length !== 0">
                <div class="t-head row sm-hide" *ngIf="false">
                    <div class="t-cell col-1">#</div>
                    <div class="t-cell col-4">{{ 'APP.MAIN.STATIONS.STEP_LIST.NAME' | translate }}</div>
                    <div class="t-cell col-5">{{ 'APP.MAIN.STATIONS.STEP_LIST.DESCRIPTION' | translate }}</div>
                    <!--<div class="t-cell col-2">{{ 'APP.MAIN.STATIONS.STEP_LIST.CYCLE_TIME' | translate }}</div>-->
                    <div class="t-cell col-2"></div>
                </div>
                <div class="t-row t-draggable row bg-white" *ngFor="let step of steps; index as i; count as c;" (click)="onStepDetails(step, i)" cdkDrag>
                    <div class="drag-handle" cdkDragHandle><i class="mi mi-drag-handle"></i></div>
                    <div class="t-cell col-1 sm-hide" *ngIf="false">{{ i+1 }}</div>
                    <div class="t-cell col-6">{{ 'APP.MAIN.STATIONS.STEP_LIST.NAME' | translate }}</div>
                    <div class="t-cell col-6">{{ step.name }}</div>
                    <div class="t-cell col-6">{{ 'APP.MAIN.STATIONS.STEP_LIST.DESCRIPTION' | translate }}</div>
                    <div class="t-cell col-6">{{ step.description }}</div>
                    <!--
                    <div class="t-cell sm-show col-6">{{ 'APP.MAIN.STATIONS.STEP_LIST.CYCLE_TIME' | translate }}</div>
                    <div class="t-cell col-6 col-lg-2">{{ step.cycleTime }} sec</div>
                    -->
                    <div class="t-cell col-12 d-flex flex-row justify-content-around mt-2">
                        <div class="btn-group">
                            <button type="button" class="btn btn-sm btn-outline-secondary" [class.disabled]="i === 0" (click)="onStepUp(step); $event.stopPropagation()">
                                <i class="mi mi-keyboard-arrow-up"></i><span class="d-none">{{ 'APP.MAIN.STATIONS.STATION_LIST.MOVE_UP' | translate }}</span>
                            </button>
                            <button type="button" class="btn btn-sm btn-outline-secondary" [class.disabled]="i === c-1" (click)="onStepDown(step); $event.stopPropagation()">
                                <i class="mi mi-keyboard-arrow-down"></i><span class="d-none">{{ 'APP.MAIN.STATIONS.STATION_LIST.MOVE_DOWN' | translate }}</span>
                            </button>
                        </div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-sm btn-outline-warning" (click)="onStepDetails(step, i); $event.stopPropagation()">
                                <i class="mi mi-remove-red-eye"></i><span class="d-none d-lg-inline">{{ 'APP.MAIN.STATIONS.STATION_LIST.DETAILS' | translate }}</span>
                            </button>
                            <button type="button" class="btn btn-sm btn-outline-info" (click)="onStepEdit(step, i); $event.stopPropagation()">
                                <i class="mi mi-edit"></i><span class="d-none d-lg-inline">{{ 'APP.MAIN.STATIONS.STATION_LIST.EDIT' | translate }}</span>
                            </button>
                            <button type="button" class="btn btn-sm btn-outline-danger" (click)="onStepDelete(step); $event.stopPropagation()">
                                <i class="mi mi-delete"></i><span class="d-none d-lg-inline">{{ 'APP.MAIN.STATIONS.STATION_LIST.DELETE' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #deleteStepTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.DELETE_MODAL.TITLE' | translate }}</div>
                <div class="card-body">
                    <div class="m-body padded" [innerHTML]="'APP.MAIN.STATIONS.STEP_DETAILS.DELETE_MODAL.MESSAGE' | translate:dataModel"></div>
                    <div class="m-footer">
                        <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.DELETE_MODAL.CANCEL' | translate }}</a>
                        <a class="text-danger" (click)="callbacks.delete()">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.DELETE_MODAL.DELETE' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>