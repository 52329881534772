import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, FormGroup } from '@angular/forms';

import { minlengthValidator } from '../validators/minlength.validator';

@Directive({
    selector: '[minlengthValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => MinlengthDirective), multi: true }]
})
export class MinlengthDirective implements Validator {
    @Input('minlengthValidator') minlengthValidator: string = '';

    validate(formGroup: FormGroup): ValidationErrors {
        return minlengthValidator(this.minlengthValidator)(formGroup);
    }
}