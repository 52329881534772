export const environment = {
  production: false,
  version: "v6.0.2",
  design: {
    appName: "VSight",
    wideLogo: false,
    logo: "assets/img/logo.png",
    logoStyle: {height:'48px','margin':'50px 0 50px'},
    wideLegalLogo: false,
    legalLogo: "assets/img/vsight_logo.png",
    squareLogo: "assets/img/square_logo.png",
    showMsLogin: true,
    msLoginSquareLogo: false,
    msLoginText: "Sign in with Microsoft",
    msLoginTextDE: "Mit Microsoft anmelden",
    loginColor: "#00142E",
    linkColor: "#14389B",
    contactMail: "info@vsight.io",
    showPoweredBy: false,
    showArchivePermission: true,
    showAzureAdIntegration: true,
    legalAppName: "Vsight",
    legalName: "app.vsight.io",
    legalMail: "legal@vsight.io",
    showWorkflowEditorLink: true,
    showLabels: true
  },
  translationLanguagesOverride: null,
  firebase: {
    apiKey: "AIzaSyDMmqISyiiWwgugYZ7XdJCSQL65W0unfbk",
    authDomain: "vsight-remote-angular-dev.firebaseapp.com",
    databaseURL: "https://vsight-remote-angular-dev-4d310.europe-west1.firebasedatabase.app/",
    projectId: "vsight-remote-angular-dev",
    storageBucket: "hangaarlab-remote",
    messagingSenderId: "848344207337"
  },
  endPoints: {
    login: "https://admin-dev.vsight.io/api/login",
    switchToAdmin:"https://admin-dev.vsight.io/apiV2/switchToAdmin",
    guides: "https://admin-dev.vsight.io/api/guides",
    updateuser: "https://admin-dev.vsight.io/api/updateuser",
    createuser: "https://admin-dev.vsight.io/api/createuser",
    deleteuser: "https://admin-dev.vsight.io/api/deleteuser",
    toggleuser: "https://admin-dev.vsight.io/api/toggleuser",
    updateroom: "https://admin-dev.vsight.io/api/updateroom",
    createroom: "https://admin-dev.vsight.io/api/createroom",
    deleteroom: "https://admin-dev.vsight.io/api/deleteroom",
    toggleroom: "https://admin-dev.vsight.io/api/toggleroom",
    changepsd: "https://admin-dev.vsight.io/api/changepsd",
    generateremoveqr: "https://admin-dev.vsight.io/api/generateremoveqr",
    changeazure: "https://admin-dev.vsight.io/api/changeazure",
    changearchive: "https://admin-dev.vsight.io/api/changearchive",
    changesettings: "https://admin-dev.vsight.io/api/changesettings",
    twofactorauth: "https://admin-dev.vsight.io/api/twofactorauth",
    admin_sso: "https://admin-dev.vsight.io/api/admin_sso",
    remote_sso_integration: "https://admin-dev.vsight.io/api/remote_sso_integration",
    setserviceemail: "https://admin-dev.vsight.io/api/setserviceemail",
    createticket: "https://admin-dev.vsight.io/api/createticket",
    attachticket: "https://admin-dev.vsight.io/api/attachticket",
    updateticket: "https://admin-dev.vsight.io/api/updateticket",
    deleteticket: "https://admin-dev.vsight.io/api/deleteticket",
    importusers: "https://admin-dev.vsight.io/api/importusers",
    importusersconfirm: "https://admin-dev.vsight.io/api/importusersconfirm",
    exportsession: "https://admin-dev.vsight.io/api/exportsession",
    passwordstrength: "https://admin-dev.vsight.io/api/passwordstrength",
    getExportPDF: "https://admin-dev.vsight.io/api/generateSessionExportPdf"
  },
  azureAdConfig: {
    samlEntityId: "https://dev.vsight.io/${name}/saml",
    samlReplyUrl: "https://dev.vsight.io/${name}/saml/login",
    samlReplyUrl2: "https://admin-dev.vsight.io/${name}/saml/login",
    samlReplyUrl3: "https://dev.ar-remote-assistance.com/${name}/saml/login",
    scimEndpoint: "https://dev.vsight.io/${name}/scim",
    msLoginUrl: "https://dev.ar-remote-assistance.com/${name}/saml/login?RelayState=admin"
  },
  oauthOIDCConfig: {
    initiateLoginUrl: "https://dev.ar-remote-assistance.com/oauth/${name}/login",
    redirectUrl: "https://dev.ar-remote-assistance.com/oauth/${name}/callback"
  }
};