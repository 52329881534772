<div class="ticket-list">
    <div class="card">
        <div class="card-header">
            <div class="filters container-fluid">
                <div class="row">
                    <div class="col-sm-6 col-md-12 col-lg-6 mt-2">
                        <ng-select [items]="rooms" name="rooms" bindLabel="name" bindValue="id" [clearSearchOnAdd] = "true" [closeOnSelect]="false"
                            placeholder="{{ 'APP.MAIN.TICKETS.ALL_ROOMS' | translate }}" [multiple]="true" [(ngModel)]="filterRooms" (change)="onFilterChanged()">
                        </ng-select>
                    </div>
                    <div class="col-sm-6 col-md-12 col-lg-6 mt-2">
                        <ng-select [items]="users" name="users" bindLabel="name" bindValue="id" [clearSearchOnAdd] = "true" [closeOnSelect]="false"
                            placeholder="{{ 'APP.MAIN.TICKETS.ALL_CREATORS' | translate }}" [multiple]="true" [(ngModel)]="filterUsers" (change)="onFilterChanged()">
                        </ng-select>
                    </div>
                    <div class="col-sm-6 col-md-12 col-lg-6 mt-2 datepicker-form-group">
                        <input #filterDates="bsDaterangepicker" name="filterDates" [(ngModel)]="filterStartEnd" class="form-control datepicker-input" bsDaterangepicker
                            placeholder="{{ 'APP.MAIN.TICKETS.CREATED_BETWEEN' | translate }}" [bsConfig]="{ rangeInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }" onkeypress="return false;"
                            (ngModelChange)="onFilterChanged()" style="cursor: default;">
                        <a class="datepicker-clear" (click)="filterStartEnd = null; onFilterChanged();" style="right: 1.5rem;"><i style="color: #777;" class="mi mi-close"></i></a>
                    </div>
                    <div class="col-sm-6 col-md-12 col-lg-6 mt-2">
                        <ng-select [items]="ticketStatuses" name="statuses" bindLabel="name" bindValue="id" [clearSearchOnAdd] = "true" [closeOnSelect]="false"
                            placeholder="{{ 'APP.MAIN.TICKETS.ALL_STATUSES' | translate }}" [multiple]="true" [(ngModel)]="filterStatuses" (change)="onFilterChanged()">
                        </ng-select>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col-12 text-right mt-3">
                        <button class="btn btn-outline-success" (click)="onCreateTicket()">{{ 'APP.MAIN.TICKETS.NEW_TICKET' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body px-0 py-1">
            <div class="bg-white" *ngIf="filteredTickets && filteredTickets.length === 0">
                <div class="ticket-list-empty text-muted py-2"> {{ 'APP.MAIN.TICKETS.NO_TICKETS' | translate }}</div>
            </div>
            <div>
                <table mat-table matSort matTableResponsive [dataSource]="dataSource" >

                  <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell data-column-name="#" *matCellDef="let i = index"> {{ i+1 }} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="title">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Title </th>
                    <td mat-cell data-column-name="Title" *matCellDef="let element"> {{ element.title }} </td>
                  </ng-container>

                  <ng-container matColumnDef="room_id">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Room </th>
                    <td mat-cell data-column-name="Room" *matCellDef="let element"> {{ element.room_id | roomName | async }} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="creator">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Created By </th>
                    <td mat-cell data-column-name="Created By" *matCellDef="let element"> {{ element.creator | userName | async }} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="create_time">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Created On </th>
                    <td mat-cell data-column-name="Created On" *matCellDef="let element"> {{ element.create_time | utcCalc | async }} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="status">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Status </th>
                    <td mat-cell data-column-name="Status" *matCellDef="let element">
                         <div *ngIf="element.status === 'open'" class="badge badge-success">{{ 'APP.MAIN.TICKETS.STATUS_OPEN' | translate }} </div>
                         <div *ngIf="element.status === 'in-progress'" class="badge badge-warning">{{ 'APP.MAIN.TICKETS.STATUS_IN_PROGRESS' | translate }} </div>
                         <div *ngIf="element.status === 'closed'" class="badge badge-secondary">{{ 'APP.MAIN.TICKETS.STATUS_CLOSED' | translate }} </div>
                        </td>
                  </ng-container>
              
                  <tr [hidden]="filteredTickets && filteredTickets.length === 0" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row (click)="onTicketClicked(row);" *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #ticketTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog-wide modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3 d-flex align-items-center justify-content-between">
                    <h5 class="modal-title d-flex align-items-center">{{ (dataModel.mode === 'create' ? 'APP.MAIN.TICKETS.CREATE_TICKET' : 'APP.MAIN.TICKETS.TICKET_DETAILS') | translate }}</h5>
                    <button *ngIf="dataModel.mode === 'detail'" type="button" class="btn btn-info" (click)="callbacks.edit(ticketForm)">{{ 'APP.MAIN.TICKETS.EDIT' | translate }}</button>
                </div>

                <div class="card-body">
                    <div class="m-body" style="height: 400px; overflow: scroll;">
                        <form #ticketForm="ngForm" class="ticket-form p-3" autocomplete="off" (ngSubmit)="dataModel.mode === 'create' ? callbacks.create(ticketForm) : callbacks.save(ticketForm);">
                            <input autocomplete="false" name="hidden" type="text" style="display:none;">
                            <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                    <label for="type">{{ 'APP.MAIN.TICKETS.TICKET_TYPE' | translate }}</label>
                                    <ng-select [items]="ticketTypes" name="type" bindLabel="name" bindValue="id" [multiple]="false" [disabled]="dataModel.mode === 'detail'"
                                        placeholder="{{ 'APP.MAIN.TICKETS.TICKET_TYPE' | translate }}" [(ngModel)]="dataModel.ticket.type" [clearable]="false">
                                    </ng-select>
                                </div>
                            </div>
                            <hr class="mt-0">
                            <div class="form-row">
                                <ng-container *ngIf="dataModel.mode === 'detail'">
                                    <div class="col-12">
                                        <span class="font-weight-bold">{{ 'APP.MAIN.TICKETS.ROOM2' | translate }}</span><span>{{ dataModel.ticket.room_id | roomName | async }}</span>
                                    </div>
                                    <div class="col-12 mt-1">
                                        <span class="font-weight-bold">{{ 'APP.MAIN.TICKETS.CREATED_BY2' | translate }}</span><span>{{ dataModel.ticket.creator | userName | async }}</span>
                                    </div>
                                    <div class="col-12 mt-1">
                                        <span class="font-weight-bold">{{ 'APP.MAIN.TICKETS.CREATED_ON2' | translate }}</span><span>{{ dataModel.ticket.create_time | utcCalc | async }} {{ timezone }}</span>    
                                    </div>
                                </ng-container>
                                <div class="form-group col-12 col-md-6 mb-0" *ngIf="dataModel.mode !== 'detail'">
                                    <label for="room_id">{{ 'APP.MAIN.TICKETS.ROOM3' | translate }}</label>
                                    <ng-select [items]="rooms" name="room_id" bindLabel="name" bindValue="id" [multiple]="false" [disabled]="dataModel.mode === 'detail'"
                                        placeholder="{{ 'APP.MAIN.TICKETS.ROOM3' | translate }}" [(ngModel)]="dataModel.ticket.room_id" [clearable]="false" (change)="callbacks.roomChanged($event)">
                                    </ng-select>
                                </div>
                            </div>
                            <hr>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="title">{{ 'APP.MAIN.TICKETS.TICKET_TITLE2' | translate }}</label>
                                    <input #title="ngModel" class="form-control" type="text" name="title" placeholder="{{ 'APP.MAIN.TICKETS.TICKET_TITLE2' | translate }}" 
                                        [ngClass]="{'is-invalid':title.errors && title.touched}" [disabled]="dataModel.mode === 'detail'"
                                        [(ngModel)]="dataModel.ticket.title" maxlength="100" required>
                                    <small class="invalid-feedback mx-2" [hidden]="!title.errors?.required">{{ 'APP.MAIN.TICKETS.TITLE_REQUIRED' | translate }}</small>
                                </div>
                                <div class="form-group col-12">
                                    <label for="description">{{ 'APP.MAIN.TICKETS.DESCRIPTION' | translate }}</label>
                                    <textarea #description="ngModel" class="form-control" rows="3" placeholder="{{ 'APP.MAIN.TICKETS.DESCRIPTION' | translate }}" [(ngModel)]="dataModel.ticket.description"
                                        name="description" [ngClass]="{'is-invalid':description.errors && description.touched}" maxlength="1000" required [disabled]="dataModel.mode === 'detail'"></textarea>
                                    <small class="invalid-feedback mx-2" [hidden]="!description.errors?.required">{{ 'APP.MAIN.TICKETS.DESCRIPTION_REQUIRED' | translate }}</small>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="status">{{ 'APP.MAIN.TICKETS.STATUS2' | translate }}</label>
                                    <ng-select [items]="ticketStatuses" name="status" bindLabel="name" bindValue="id" [multiple]="false" [disabled]="dataModel.mode === 'detail'"
                                        placeholder="{{ 'APP.MAIN.TICKETS.STATUS2' | translate }}" [(ngModel)]="dataModel.ticket.status" [clearable]="false">
                                    </ng-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="priority">{{ 'APP.MAIN.TICKETS.PRIORITY' | translate }}</label>
                                    <ng-select [items]="ticketPriorities" name="priority" bindLabel="name" bindValue="id" [multiple]="false" [disabled]="dataModel.mode === 'detail'"
                                        placeholder="{{ 'APP.MAIN.TICKETS.PRIORITY' | translate }}" [(ngModel)]="dataModel.ticket.priority" [clearable]="false">
                                    </ng-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="assignee">{{ 'APP.MAIN.TICKETS.ASSIGNEE' | translate }}</label>
                                    <ng-select [items]="selectedTicketRoomUsers" name="assignee" bindLabel="name" bindValue="id" [multiple]="false" [disabled]="dataModel.mode === 'detail'"
                                        placeholder="{{ 'APP.MAIN.TICKETS.ASSIGNEE' | translate }}" [(ngModel)]="dataModel.ticket.assignee" [clearable]="true">
                                    </ng-select>
                                </div>
                                <div class="form-group datepicker-form-group col-12 col-md-6" style="position: relative;">
                                    <label for="followup">{{ 'APP.MAIN.TICKETS.FOLLOW_UP' | translate }}</label>
                                    <input #followup="bsDatepicker" name="followup" [(ngModel)]="dataModel.ticket.followup" class="form-control" bsDatepicker [disabled]="dataModel.mode === 'detail'"
                                        placeholder="{{ 'APP.MAIN.TICKETS.FOLLOW_UP' | translate }}" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }" onkeypress="return false;">
                                    <a *ngIf="dataModel.mode !== 'detail'" class="datepicker-clear" (click)="dataModel.ticket.followup = null;"><i class="mi mi-close"></i></a>
                                </div>
                                <div class="form-group col-12">
                                    <label for="resolution">{{ 'APP.MAIN.TICKETS.RESOLUTION' | translate }}</label>
                                    <textarea #resolution="ngModel" class="form-control" rows="3" placeholder="{{ 'APP.MAIN.TICKETS.RESOLUTION' | translate }}" [(ngModel)]="dataModel.ticket.resolution"
                                        [ngClass]="{'is-invalid':resolution.errors && resolution.touched}" name="resolution" maxlength="1000" [disabled]="dataModel.mode === 'detail'"></textarea>
                                </div>
                            </div>
                        </form>

                        <div class="cumulative-data py-3 mb-3 mx-3 bg-white" *ngIf="dataModel.mode === 'detail' && dataModel.sessionStats">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12 mb-3 cumulative-title text-center">{{ 'APP.MAIN.TICKETS.ATTACHED_SESSIONS' | translate }}</div>
                                    <div class="col-md-6 mt-2"><span class="key">{{ 'APP.MAIN.TICKETS.SESSION_COUNT' | translate }}</span><span class="value">{{ dataModel.sessionStats.count }}</span></div>
                                    <div class="col-md-6 mt-2"><span class="key">{{ 'APP.MAIN.TICKETS.TOTAL_DURATION' | translate }}</span><span class="value">{{ dataModel.sessionStats.duration }}</span></div>
                                    <div class="col-12 my-2">
                                        <div class="key my-2">{{ 'APP.MAIN.TICKETS.PARTICIPANTS2' | translate }}</div>
                                        <div class="row">
                                            <div class="col-md-6 mt-1" *ngFor="let user of (dataModel.sessionStats.users | objectToArray)"><span class="key">{{ user.id | userName | async }}</span><span>{{ 'APP.MAIN.TICKETS.COUNT_SESSIONS' | translate:user }}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="px-3 mb-2" *ngIf="dataModel.mode === 'detail'">
                            <div class="t-container container-fluid">
                                <div class="t-head row sm-hide">
                                    <div class="t-cell col-1">#</div>
                                    <div class="t-cell col-2">{{ 'APP.MAIN.TICKETS.DATE' | translate }} ({{ timezone }})</div>
                                    <div class="t-cell col-3">{{ 'APP.MAIN.TICKETS.ROOM_NAME' | translate }}</div>
                                    <div class="t-cell col-3">{{ 'APP.MAIN.TICKETS.PARTICIPANTS' | translate }}</div>
                                    <div class="t-cell col-2">{{ 'APP.MAIN.TICKETS.DURATION' | translate }}</div>
                                    <div class="t-cell col-1"></div>
                                </div>
                                <div class="t-row row bg-white" *ngIf="dataModel.sessions && dataModel.sessions.length === 0">
                                    <div class="no-tickets-message text-muted py-2">{{ 'APP.MAIN.TICKETS.NO_ATTACHED_SESSIONS' | translate }}</div>    
                                </div>
                                <div class="t-row row" *ngFor="let s of dataModel.sessions; index as i;" (click)="onSessionDetails(s, dataModel.modalId)" >
                                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">#</div>
                                    <div class="t-cell col-6 col-md-4 col-lg-1">{{ i+1 }}</div>
                                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.TICKETS.DATE' | translate }} ({{ timezone }})</div>
                                    <div class="t-cell col-6 col-md-4 col-lg-2">{{ s.create_time | utcCalc | async }}</div>
                                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.TICKETS.ROOM_NAME' | translate }}</div>
                                    <div class="t-cell col-6 col-md-4 col-lg-3">{{ s.room_id | roomName | async }}</div>
                                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.TICKETS.PARTICIPANTS' | translate }}</div>
                                    <div class="t-cell col-6 col-md-4 col-lg-3">
                                        <div *ngFor="let name of s.users | getIds | filterGuests:s.guest_users | userNames | async" class="mt-1">{{ name }}</div>
                                        <div *ngFor="let guest of s.guest_users | objectToArray" class="mt-1">{{ guest.name }} <span class="text-info">{{ 'APP.MAIN.TICKETS.GUEST' | translate }}</span></div>
                                    </div>
                                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold">{{ 'APP.MAIN.TICKETS.DURATION' | translate }}</div>
                                    <div class="t-cell col-6 col-md-4 col-lg-2">{{ s | sessionDuration }}</div>
                                    <div class="t-cell sm-show col-6 col-md-8 font-weight-bold"></div>
                                    <div class="t-cell col-6 col-md-4 col-lg-1">
                                        <a *ngIf="sessionExportEnabled && !webdavEnabled && !s.session_files_deleted" (click)="onSessionExportModal($event, s.room_id, s.id, dataModel.modalId);" style="cursor: pointer;">
                                            <img src="assets/export_session.svg">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="px-3 mb-2" *ngIf="mailToUrl && dataModel.mode === 'detail'">
                            <a class="btn btn-outline-info btn-block" style="font-size: 15px;" [href]="mailToUrl" target="_blank">{{ 'APP.MAIN.TICKETS.SEND_VIA_EMAIL' | translate }}</a>
                        </div>

                        <div class="px-3 mb-2" *ngIf="dataModel.mode === 'edit'">
                            <button class="btn btn-outline-danger btn-block" style="font-size: 15px;" (click)="callbacks.deleteTicket(dataModel.ticket)">{{ 'APP.MAIN.TICKETS.DELETE_TICKET' | translate }}</button>
                        </div>
                    </div>
                    <div class="m-footer">
                        <a *ngIf="dataModel.mode !== 'edit'" class="text-dark" (click)="callbacks.close()">{{ (dataModel.mode === 'create' ? 'APP.MAIN.TICKETS.CANCEL' : 'APP.MAIN.TICKETS.CLOSE') | translate }}</a>
                        <a *ngIf="dataModel.mode === 'edit'" class="text-dark" (click)="callbacks.cancelEdit()">{{ 'APP.MAIN.TICKETS.CANCEL' | translate }}</a>
                        <a *ngIf="dataModel.mode === 'edit'" class="text-info" (click)="ticketForm.valid && !dataModel.pristine ? callbacks.save(ticketForm) : null;" [class.disabled]="!ticketForm.valid || dataModel.pristine">{{ 'APP.MAIN.TICKETS.SAVE' | translate }}</a>
                        <a *ngIf="dataModel.mode === 'create'" class="text-info" (click)="ticketForm.valid ? callbacks.create(ticketForm) : null;" [class.disabled]="!ticketForm.valid">{{ 'APP.MAIN.TICKETS.CREATE' | translate }}</a>
                    </div>            
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deleteTicketTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3">
                    {{ 'APP.MAIN.TICKETS.DELETE_TICKET_MODAL.TITLE' | translate }}
                </div>
                <div class="card-body">
                    <div class="m-body padded">
                      <div [innerHTML]="'APP.MAIN.TICKETS.DELETE_TICKET_MODAL.MESSAGE' | translate:dataModel"></div>
                    </div>
                    <div class="m-footer">
                        <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.TICKETS.DELETE_TICKET_MODAL.CANCEL' | translate }}</a>
                        <a class="text-danger" (click)="callbacks.delete()">{{ 'APP.MAIN.TICKETS.DELETE_TICKET_MODAL.DELETE' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>