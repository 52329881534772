<div class="sidebar-container">
    <div class="sidebar-brand px-3">

        <div *ngIf="('design' | env).logoText" class="logo">
            <img class="logo" [src]="('design' | env).logo">
            <span class="ml-2">{{ ('design' | env).logoText }}</span>
        </div>
        <div *ngIf="!('design' | env).logoText" class="only-logo">
            <img class="logo" [class.wide]="('design' | env).wideLogo" [src]="('design' | env).logo">
        </div>
        <a class="float-right" (click)="toggleSidebar()"><i class="mi mi-arrow-back"></i></a>
    </div>

    <ul class="sidebar-menu">
        <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="/" (click)="onSidebarLinkClicked()">
            <i class="mi mi-dashboard blue-color mr-3"></i><span>{{ 'APP.MAIN.SIDEBAR.DASHBOARD' | translate }}</span>
        </a></li>
        <li routerLinkActive="active"><a routerLink="/rooms" (click)="onSidebarLinkClicked()">
            <i class="mi mi-contact-phone green-color mr-3"></i><span>{{ 'APP.MAIN.SIDEBAR.ROOMS' | translate }}</span>
        </a></li>
        <li routerLinkActive="active"><a routerLink="/users" (click)="onSidebarLinkClicked()">
            <i class="mi mi-person red-color mr-3"></i><span>{{ 'APP.MAIN.SIDEBAR.USERS' | translate }}</span>
        </a></li>
        <li routerLinkActive="active"><a routerLink="/sessions" (click)="onSidebarLinkClicked()">
            <i class="mi mi-people yellow-color mr-3"></i><span>{{ 'APP.MAIN.SIDEBAR.SESSIONS' | translate }}</span>
        </a></li>
        <li *ngIf="ticketAvailable" routerLinkActive="active"><a routerLink="/tickets" (click)="onSidebarLinkClicked()">
            <i class="mi mi-style dark-blue-color mr-3"></i><span>{{ 'APP.MAIN.SIDEBAR.TICKETS' | translate }}</span>
        </a></li>
        <li routerLinkActive="active"><a routerLink="/storage" (click)="onSidebarLinkClicked()">
            <i class="mi mi-storage orange-color mr-3"></i><span>{{ 'APP.MAIN.SIDEBAR.STORAGE' | translate }}</span>
        </a></li>
        <li *ngIf="reportChart" routerLinkActive="active"><a routerLink="/reports" (click)="onSidebarLinkClicked()">
            <i><img src="assets/img/reports-icon.svg"  width="22" height="22"></i>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{ 'APP.MAIN.SIDEBAR.REPORTS.REPORTS' | translate }}</span>
        </a></li>
        <li routerLinkActive="active"><a routerLink="/settings" (click)="onSidebarLinkClicked()">
            <i class="mi mi-settings gray-color mr-3"></i><span>{{ 'APP.MAIN.SIDEBAR.SETTINGS' | translate }}</span>
        </a></li>
        <li><a (click)="onLicenseClicked()">
            <i class="mi mi-payment color-dark mr-3"></i><span>{{ 'APP.MAIN.SIDEBAR.LICENSE' | translate }}</span>
        </a></li>
        <li *ngIf="workflowAvailable && !('design' | env).showWorkflowEditorLink"><a (click)="openWorkflowEditor('https://workflow.vsight.io')" class="mt-2">
            <i class="mi mi-build purple-color mr-3"></i><span>{{ 'APP.MAIN.SIDEBAR.WORKFLOW' | translate }}</span>
        </a></li>
        <li *ngIf="workflowAvailable && ('design' | env).showWorkflowEditorLink" style="position: relative;" dropdown #dropdown="bs-dropdown">
            <a class="mt-2" id="wf-toggle" dropdownToggle aria-controls="wf-menu">
                <i class="mi mi-build purple-color mr-3"></i><span>{{ 'APP.MAIN.SIDEBAR.WORKFLOW' | translate }}</span>
            </a>
            <ul id="wf-menu" *dropdownMenu class="dropdown-menu py-0" role="menu" aria-expanded="false" aria-labelledby="wf-toggle">
                <li><a class="dropdown-item" (click)="openWorkflowEditor('http://localhost:4200')">local</a></li>
                <li><a class="dropdown-item" (click)="openWorkflowEditor('https://workflow-dev.vsight.io')">develop</a></li>
            </ul>
        </li>
    </ul>
</div>

<ng-template #licenseTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3">{{ 'APP.MAIN.SIDEBAR.LICENSE_MODAL.TITLE' | translate }}</div>
                <div class="card-body">
                    <div class="m-body">
                        <div class="text-center mt-2" *ngIf="!dataModel.license">{{ 'APP.MAIN.SIDEBAR.LICENSE_MODAL.LOADING' | translate }}</div>
                        <div class="t-container t-no-hover" *ngIf="dataModel.license">
                            <table mat-table [dataSource]="dataSource">
 
                                <ng-container matColumnDef="name">
                                  <th mat-header-cell *matHeaderCellDef> Name </th>
                                  <td mat-cell *matCellDef="let element" class="licence-label"> {{ 'APP.MAIN.SIDEBAR.LICENSE_MODAL.'+ element[0] | uppercase | translate }} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="value">
                                    <th mat-header-cell *matHeaderCellDef> Value </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div *ngIf="element[1].constructor.name == 'Array'">
                                            <div *ngFor="let elementTraining of element[1]" class="mt-2 mb-2">
                                                <div class="mb-2">{{'APP.MAIN.SIDEBAR.LICENSE_MODAL.LICENCE_HOURS' | translate:{hours:elementTraining.time} }}</div>
                                                <div><span>{{'APP.MAIN.SIDEBAR.LICENSE_MODAL.CONSUMED' | translate}}:</span> {{elementTraining.time_usage | durationFormat: 'ms':'hhmmss'}}</div>
                                                
                                            </div>
                                        </div>
                                        <div *ngIf="element[1].constructor.name !== 'Array'"> {{element[1]}} </div>
                                    </td>
                                  </ng-container>
                              
                                 <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                              </table>
                        </div>
                    </div>
                    <div class="m-footer">
                        <a class="text-info" (click)="callbacks.close()">{{ 'APP.MAIN.SIDEBAR.LICENSE_MODAL.CLOSE' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>