<div class="card my-4">
  <div class="card-header d-flex justify-content-between align-items-center">
    <span class="card-header-title">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.ARCHIVES' | translate }}</span>
    <button class="btn btn-info" (click)="refreshArchives()" [disabled]="!pendingExists || refreshing">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.REFRESH' | translate }}</button>
  </div>
  <div class="card-body px-0 py-1">
    <ng-container *ngIf="archives && showArchives; else loadingArchives">
      <div class="t-container t-no-hover container-fluid" *ngIf="archives.length !== 0; else noArchive">
          <div class="t-head row sm-hide pl-3">
              <div class="t-cell col-1">#</div>
              <div class="t-cell col-3">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.CREATED_AT' | translate }} ({{ timezone }})</div>
              <div class="t-cell col-2 text-lg-center">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.SIZE' | translate }}</div>
              <div class="t-cell col-2 text-lg-center">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.STORAGE' | translate }}</div>
              <div class="t-cell col-2">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.STATUS' | translate }}</div>
              <div class="t-cell col-2"></div>
          </div>

          <div class="t-row row pl-3" *ngFor="let archive of archives; index as i;">
              <div class="t-cell col-1 sm-hide">{{ i+1 }}</div>
              <div class="t-cell sm-show col-6">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.CREATED_AT' | translate }} ({{ timezone }})</div>
              <div class="t-cell col-6 col-lg-3">{{ archive.create_time | utcCalc | async }}</div>
              <div class="t-cell sm-show col-6">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.SIZE' | translate }}</div>
              <div class="t-cell col-6 col-lg-2 text-lg-center">{{ (archive.size ? archive.size : 0) | fileSize }}</div>
              <div class="t-cell sm-show col-6">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.STORAGE' | translate }}</div>
              <div class="t-cell col-6 col-lg-2 text-lg-center" *ngIf="!webdavEnabled">
                <img *ngIf="archive.storage_shared === true" style="width: 20px; height: 20px; object-fit: contain;" [src]="('design' | env).squareLogo">  
                <img *ngIf="archive.storage_shared === false" style="width: 20px; height: 20px; object-fit: contain;" src="assets/img/azure_blue.png">
              </div>
              <div class="t-cell col-6 col-lg-2 text-lg-center" *ngIf="webdavEnabled">
                <div class="text-info font-weight-bold" style="font-size: 12px;"><span style="color: #4a688f">WEB</span><span style="color: #e6646b">DAV</span></div>
              </div>
              <div class="t-cell sm-show col-6">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.STATUS' | translate }}</div>
              <div class="t-cell col-6 col-lg-2"><span class="badge" style="font-size: 10px;" [ngClass]="getBadgeClass(archive)">{{ getArchiveStatusKey(archive) | translate }}</span></div>
              <div class="t-cell sm-show col-0 col-lg-6"></div>
              <div class="t-cell col-12 col-lg-2">
                <div class="btn-group d-flex justify-content-center" *ngIf="!webdavEnabled && archive.storage_status === 'uploaded'">
                  <button type="button" class="btn btn-sm btn-warning" (click)="onWatchArchive(archive)">
                    <i class="mi mi-play-arrow"></i><span class="d-inline d-lg-none">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.PLAY' | translate }}</span>
                  </button>
                  <!--
                  <button type="button" class="btn btn-sm btn-info" (click)="onDownloadArchive(archive)">
                    <i class="mi mi-cloud-download" style="font-size: 16px;"></i><span class="d-inline d-lg-none">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DOWNLOAD' | translate }}</span>
                  </button>
                  -->
                  <div class="dropdown-items" dropdown>
                    <button [id]="'dropdown-button-'+1" (click)="onDownloadArchive(archive)" dropdownToggle type="button" class="btn btn-sm btn-info dropdown-toggle" [attr.aria-controls]="'dropdown-'+1">
                        <i class="mi mi-cloud-download"></i><span class="d-inline d-lg-none">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DOWNLOAD' | translate }}</span>
                    </button>
                    <ul [id]="'dropdown-'+1" *dropdownMenu class="dropdown-ul dropdown-menu p-1" role="menu" [attr.aria-labelledby]="'dropdown-button-'+1">
                        <li role="menuitem">
                            <a class="dropdown-item" *ngIf="downloadLinks[archive.id]?.loading">Generating...</a>
                            <a class="dropdown-item" *ngIf="downloadLinks[archive.id]?.url" [href]="downloadLinks[archive.id]?.url" download="record.mp4" target="_blank">Download</a>
                        </li>
                    </ul>
                  </div>
                  <button type="button" class="btn btn-sm btn-danger" (click)="onDeleteArchive(archive)">
                    <i class="mi mi-delete"></i><span class="d-inline d-lg-none">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DELETE' | translate }}</span>
                  </button>
                </div>
                <!--<a class="btn btn-sm btn-info py-0 text-white" style="cursor: pointer; font-size: 11px;" *ngIf="archive.storage_status === 'uploaded'" (click)="onDownloadArchive(archive)">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DOWNLOAD' | translate }}</a>-->
              </div>
          </div>
      </div>
    </ng-container>
  </div><!--card-body-->
</div><!--card-->

<ng-template #loadingArchives>
  <div class="text-center my-2">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.LOADING' | translate }}</div>
</ng-template>

<ng-template #noArchive>
  <div class="text-center my-2">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.NO_ARCHIVE_MESSAGE' | translate }}</div>
</ng-template>

<!--
<ng-template #downloadArchiveTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DOWNLOAD_ARCHIVE.TITLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <p *ngIf="!dataModel.failed && !dataModel.succeeded" class="font-weight-bold text-secondary">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DOWNLOAD_ARCHIVE.GENERATING' | translate }}</p>
            <p *ngIf="dataModel.succeeded" class="font-weight-bold text-success">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DOWNLOAD_ARCHIVE.GENERATED' | translate }}</p>
            <p *ngIf="dataModel.failed" class="font-weight-bold text-danger">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DOWNLOAD_ARCHIVE.CANNOT_GENERATED' | translate }}</p>
          </div>
          <div class="m-footer">
            <ng-container *ngIf="!dataModel.failed">
              <a class="text-secondary" (click)="callbacks.cancel()">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DOWNLOAD_ARCHIVE.CANCEL' | translate }}</a>
              <a *ngIf="dataModel.succeeded" class="text-info text-decoration-none" [href]="dataModel.url" download="filename.mp4">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DOWNLOAD_ARCHIVE.DOWNLOAD' | translate }}</a>
            </ng-container>
            <a *ngIf="dataModel.failed" class="text-secondary" (click)="callbacks.cancel()">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DOWNLOAD_ARCHIVE.CLOSE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
-->

<ng-template #watchArchiveTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog-wide modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ selectedArchive.create_time | utcCalc | async }} {{ timezone }}</div>
        <div class="card-body">
          <div class="m-body">
            <div *ngIf="dwUrl">
              <video class="azuremediaplayer amp-default-skin" controls data-setup='{"techOrder": ["azureHtml5JS", "flashSS", "html5FairPlayHLS","silverlightSS", "html5"], "nativeControlsForTouch": false}'>
                <source [src]="dwUrl" type="video/mp4"/>
              </video>
            </div>
            <div *ngIf="!dwUrl">
              <p>Please wait...</p>
            </div>
            <div class="d-flex justify-content-end">
              <a *ngIf="selectedArchive" class="btn btn-outline-success mt-2" style="height: 38px;" [href]="dwUrl" download="filename.mp4">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DOWNLOAD_ARCHIVE.DOWNLOAD' | translate }}</a>
              <button class="btn btn-outline-dark m-2" (click)="callbacks.close()">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DOWNLOAD_ARCHIVE.CLOSE' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteArchiveTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DELETE_ARCHIVE.TITLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <p class="text-secondary">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DELETE_ARCHIVE.MESSAGE' | translate }}</p>
            <p *ngIf="!dataModel.shared" class="font-weight-bold text-danger mt-2">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DELETE_ARCHIVE.CAUTION' | translate }}</p>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DELETE_ARCHIVE.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.delete()">{{ 'APP.MAIN.ARCHIVES.ARCHIVE_LIST.DELETE_ARCHIVE.DELETE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>