<div class="authenticate-wrapper">
    <div class="authenticate-container">
        <div class="logo-container" *ngIf="('design' | env).logoText">
            <img class="logo-image" [src]="('design' | env).logo">
        </div>
            <div class="logo-text ml-2">{{ ('design' | env).logoText }}</div>
        <div class="logo-container" *ngIf="!('design' | env).logoText">
            <img class="logo-only-image" [class.wide]="('design' | env).wideLogo" [src]="('design' | env).logo">
        </div>
        <div class="py-5" style="position: relative;">
            <div id="loader" style="position: relative !important; height: unset !important;" *ngIf="loading">
                <div class="spinner"></div>
            </div>
            <div class="text-center" *ngIf="!loading">
                <div>{{ 'APP.MAIN.AUTHENTICATE.ERROR.ERROR_MESSAGE' | translate }}</div>
            </div>
        </div>
    </div>
</div>