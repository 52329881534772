import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { SessionService } from '@services/session.service';
import { Subscription } from 'rxjs';
import { AccountService } from '@services/account.service';

@Component({
  selector: 'app-session-charts',
  templateUrl: './session-charts.component.html',
  styleUrls: ['./session-charts.component.scss']
})
export class SessionChartsComponent implements OnInit, OnDestroy {

  @ViewChild('chart', { static: true }) chart: ElementRef;

  chartData: any;
  sessionCountSub: Subscription = null;

  sessionCount = 0;

  accountDataSub: Subscription = null;
  timezone: string = "UTC";

  constructor(
    private accountService: AccountService,
    private sessionService: SessionService
  ) { }

  daysInMonth(year: number, month: number){
    return month===2?year&3||!(year%25)&&year&15?28:29:30+(month+(month>>3)&1);
  }

  ngOnDestroy() {
    if (this.sessionCountSub) { this.sessionCountSub.unsubscribe() }
  }

  ngOnInit() {
    this.accountDataSub = this.accountService.accountData.subscribe(accountData => {
      this.timezone = accountData.timezone;
    });
    this.sessionCountSub = this.sessionService.getLastMonthSessionCount().subscribe(sessionCountList => {
      this.sessionCount = sessionCountList.length;

      this.sessionService.getAccountTimezoneDateString().then(serverDateString => {
        if (sessionCountList.length > 0) {
          // Add today if today is greater than last count
          if (this.compareDateLabels(serverDateString, sessionCountList[0].label) === 1) {
            sessionCountList.unshift({ label: serverDateString, value: 0 });
          }
        } else {
          // Add today for all zero padded chart
          sessionCountList.unshift({ label: serverDateString, value: 0 });
        }

        const data = {
          labels: [],
          dataset: []
        }

        const yearMonths: any = {};
        let i = 29;
        let j = 0;
        data.dataset[i] = sessionCountList[j].value;
        data.labels[i] = sessionCountList[j].label;


        let newLastMonthArray = []
        let today = this.formatDate(new Date())
        newLastMonthArray.push(today)
        for(let dataIndex = parseInt(today.split('-')[2])-1; dataIndex > 0; dataIndex--) {
          let newDataFirstPart = today.substring(0,7)
          let finalPart
          if(dataIndex<10){
            finalPart = '0'+ dataIndex
          }else{
            finalPart = dataIndex.toString()
          }
          newDataFirstPart + '-' + dataIndex
          newLastMonthArray.push(newDataFirstPart + '-' + finalPart)
        }
        let monthLength = newLastMonthArray.length
       
        if(newLastMonthArray.length < 30) {
          let daysInMonth = this.daysInMonth((new Date()).getFullYear(),(new Date()).getMonth())
          let year = today.substring(0,4)
          if(parseInt(today.split('-')[1]) == 1) {
            year = (parseInt(today.split('-')[1])-1).toString()
          }
          
          let newMonth = parseInt(today.split('-')[1])-1
          let newMonthData

          if(newMonth<10) {
            newMonthData = '0' + newMonth
          }else{
            newMonthData = newMonth.toString()
          }
          for(let dataIndex = daysInMonth; newLastMonthArray.length < 30; dataIndex--) {
            let newDataFirstPartMonthBefore = year + '-'+ newMonthData
            let finalPart
            if(dataIndex<10){
              finalPart = '0'+ dataIndex
            }else{
              finalPart = dataIndex.toString()
            }
            newDataFirstPartMonthBefore + '-' + dataIndex
            newLastMonthArray.push(newDataFirstPartMonthBefore + '-' + finalPart)
          }
        }
        let valueArray=[]
        for(let i = 0; i < 30; i++) {
          valueArray.push(0)
        }
        newLastMonthArray.forEach((res,index) => {
          let session = sessionCountList.find(response => response.label == res)
          if(session) {
            valueArray[index] = session.value
          }
        })
        data.labels = newLastMonthArray.reverse()
        data.dataset = valueArray.reverse()

        let ym = data.labels[i].split('-')[0] + '/' + data.labels[i].split('-')[1];
        let ym2 = data.labels[j].split('-')[0] + '/' + data.labels[j].split('-')[1];

        if (!yearMonths[ym]) {
          yearMonths[ym] = i;
        }
        if (!yearMonths[ym2]) {
          yearMonths[ym2] = i - monthLength ;
        }
        const months = Object.keys(yearMonths);
        months.forEach((month, index) => {
          if (months[index+1]) {
            yearMonths[month] = Math.round((yearMonths[months[index]] + yearMonths[months[index+1]]) / 2);
          } else {
            yearMonths[month] = Math.round((yearMonths[months[index]] + 1) / 2);
          }
        });

        if (this.chartData) {
          this.chartData.destroy();
        }
        this.chartData = new Chart(this.chart.nativeElement.getContext('2d'), {
          type: 'line',
          data: {
            labels: data.labels,
            datasets: [{
              label: "Sessions",
              xAxisID:'xAxis1',
              data: data.dataset,
              lineTension: 0,
              backgroundColor: "rgba(101, 194, 220, 0.1)",
              pointBackgroundColor: "#67c2dc",
              pointHoverBackgroundColor: "#eef9fc",
              pointHoverBorderColor: "#67c2dc",
              pointRadius: 2,
              pointHoverRadius: 2,
              borderWidth: 2,
              borderColor: "#67c2dc",
              fill: true
            }]
          },
          options: {
            scales: {
              xAxes: [
                {
                  id: 'xAxis1',
                  type: "category",
                  ticks: {
                    callback: function(label: string, index) {
                      return label.split("-")[2];
                    }
                  },
                  gridLines: {
                    display: false
                  }
                },
                {
                  id:'xAxis2',
                  type:"category",
                  ticks:{
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    callback: function(label: string, index) {
                      if (Object.values(yearMonths).includes(index)) {
                        return label.split("-")[0] + '/' + label.split("-")[1];
                      } else {
                        return "";
                      }
                    }
                  },
                  gridLines: {
                    display: false,
                  }
                }
              ],
              yAxes:[{
                ticks:{
                  beginAtZero: true,
                  callback: function(value: number, index, values) {
                    if (Math.floor(value) === value) {
                      return value;
                    } else {
                      return "";
                    }
                  }
                },
                gridLines: {
                  display: true
                }
              }]
            },
            legend: {
              display: false
            },
            title: {
              display: false
            },
            tooltips: {
              callbacks: {
                title: (tooltipItems, data) => ''
              }
            }
          }
        });
      });
    });
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  compareDateLabels(l1: string, l2: string) {
    const s1 = l1.split('-').map(s => parseInt(s));
    const s2 = l2.split('-').map(s => parseInt(s));

    if (s1[0] > s2[0]) { return 1; }
    else if (s1[0] < s2[0]) { return -1; }
    else if (s1[1] > s2[1]) { return 1; }
    else if (s1[1] < s2[1]) { return -1; }
    else if (s1[2] > s2[2]) { return 1; }
    else if (s1[2] < s2[2]) { return -1; }
    else { return 0; }
  }
}
