import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from "@services/user.service";

@Pipe({name: 'userNames'})
export class UserNamesPipe implements PipeTransform {

    constructor(private userService: UserService) {}

    transform(value: string[], args?: any) {
        return this.userService.allUsers.pipe(map(users => {
            return value
            .map(uid => {
                const u = users.find(user => user.id === uid);
                return u ? u.name : "Unknown";
            })
            .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
        }));
    }
}