import { Component, OnInit, ViewChild } from '@angular/core';

import { environment } from 'environments/environment';

import { AuthService } from '@services/auth.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { FlashMessageService } from '@services/support/flash-message.service';
import { LoaderService } from '@services/support/loader.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { Language } from '@models/Language';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // Login form object
  @ViewChild('loginForm', { static: true }) form: NgForm;

  // Login form binded data
  loginUser = {
    account: '',
    username: '',
    password: ''
  }

  // Booleans for enable and disable login button
  isLoginProgress: boolean = false;
  isFormValid: boolean = false;

  authSubscription: Subscription = null;

  accountNameRemeber: boolean = false;

  verification = {
    open: false,
    code: null
  };
  verifyUser: {
    account: string,
    username: string,
    password: string
  };

  msLoginUrl: string = null;
  msLoginMode: boolean = false;

  currentLang: Language;
  allLangs: Language[] = [];
  showLangs: boolean = false;
  langSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private multilanguageService: MultilanguageService,
    private loaderService: LoaderService,
    private flashMessageService: FlashMessageService
  ) {
    this.route.params.subscribe(params => {
      this.loginUser.account = params['account_name'];
    });
  }

  ngOnInit() {
    if (environment.azureAdConfig && environment.azureAdConfig['name']) {
      this.msLoginUrl = this.renderTemplate(environment.azureAdConfig.msLoginUrl, { name: environment.azureAdConfig['name'] })
    }

    const savedAccountName = localStorage.getItem('vsightAdminAccountName');
    if (savedAccountName) {
      this.accountNameRemeber = true;
      this.loginUser.account = savedAccountName;
    }

    // Navigate main if user already authenticated
    this.authSubscription = this.authService.getAuth().subscribe(auth => {
      if (auth) {
        this.router.navigate(['/']);
      }
    });

    // Listen for form status
    this.form.statusChanges.subscribe(result => {
      if (result == 'VALID') {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    });

    this.currentLang = this.multilanguageService.currentLang;
    this.allLangs = this.multilanguageService.allLangs;
    this.langSub = this.multilanguageService.onLangChange.subscribe(change => {
      this.currentLang = change.lang;
    });
  }

  onLanguageSelected(lang: Language) {
    this.multilanguageService.setCurrentLanguage(lang.code);
  }

  ngOnDestroy() {
    if (this.authSubscription) { this.authSubscription.unsubscribe() }
    if (this.langSub) { this.langSub.unsubscribe() }
  }

  renderTemplate(templateStr: string, args: any): string {
    templateStr = templateStr.replace(/`/g, '\\`');
    const keys = Object.keys(args);
    const fn = new Function(...keys, 'return `' + templateStr + '`');
    return fn(...keys.map(key => args[key]));
  }

  loginWithMicrosoft(form: NgForm) {
    if (this.msLoginUrl) {
      window.location.href = this.msLoginUrl;
    } else {
      if (form.value.account && form.value.account.length >= 2) {
        if (this.accountNameRemeber) { localStorage.setItem('vsightAdminAccountName', form.value.account) }
        else { localStorage.removeItem('vsightAdminAccountName') }

        window.location.href = this.renderTemplate(environment.azureAdConfig.msLoginUrl, { name: form.value.account });
      } else {
        this.msLoginMode = true;
        form.controls.account.markAsTouched();
      }
    }
  }

  onLogin(form: NgForm) {
    if (!navigator.onLine) {
      this.flashMessageService.showTranslated('APP.LOGIN.NO_INTERNET_ERROR');
    } else {
      // Start login process if form valid
      // Form always valid. When form not valid -> Login button disabled
      this.isLoginProgress = true;
      this.loaderService.show();

      this.authService.login(form.value.account, form.value.username, form.value.password)
      .then(result => {
        if (result.status && result.status === "verification-sended") {
          this.verifyUser = {
            account: form.value.account,
            username: form.value.username,
            password: form.value.password
          };
          this.verification.open = true;
          this.loaderService.hide();
          this.isLoginProgress = false;
        } else {
          this.authService.loginToFirebase(result.token)
          .then(() => {
            if (this.accountNameRemeber) { localStorage.setItem('vsightAdminAccountName', this.loginUser.account) }
            else { localStorage.removeItem('vsightAdminAccountName') }
          })
          .finally(() => {
            this.loaderService.hide();
            this.isLoginProgress = false;
          });
        }
      })
      .catch(error => {
        this.loaderService.hide();
        this.isLoginProgress = false;
        this.showLoginError(error);
      });
    }
  }

  onVerify(verificationCode: string) {
    this.loaderService.show();
    this.authService.verifyLoginCode(this.verifyUser.account, this.verifyUser.username, this.verifyUser.password, verificationCode)
    .then(result => {
      return this.authService.loginToFirebase(result.token)
      .then(() => {
        if (this.accountNameRemeber) { localStorage.setItem('vsightAdminAccountName', this.loginUser.account) }
        else { localStorage.removeItem('vsightAdminAccountName') }
      });
    })
    .catch(error => this.showLoginError(error, true))
    .finally(() => this.loaderService.hide());
  }

  showLoginError(error: any, verificationError: boolean = false) {
    if (error instanceof HttpErrorResponse) {
      if (error.error === 'wrong-username-password') {
        this.flashMessageService.showTranslated('APP.LOGIN.USERNAME_PASSWORD_ERROR');
      } else if (error.error === 'account-disabled') {
        this.flashMessageService.showTranslatedWithData('APP.LOGIN.ACCOUNT_DISABLED', environment.design);
        if (verificationError) { this.verification.open = false }
      } else if (error.error === 'invalid-verification-code') {
        this.flashMessageService.showTranslated('APP.LOGIN.INVALID_VERIFICATION_CODE');
      } else {
        this.flashMessageService.showTranslated('APP.LOGIN.UNKNOWN_ERROR');
      }
    } else {
      this.flashMessageService.showTranslated('APP.LOGIN.UNKNOWN_ERROR');
    }
  }

  onTermsofService() {
    let url = window.location.origin+"/terms";

    const termsUrls = environment.design['termsUrls'];
    const defaultTermsLang = environment.design['defaultTermsLang'];
    if (termsUrls) {
      if (termsUrls[this.multilanguageService.currentLang.code]) {
        url = termsUrls[this.multilanguageService.currentLang.code];
      } else if (defaultTermsLang && termsUrls[defaultTermsLang]) {
        url = termsUrls[defaultTermsLang];
      }
    }
    window.open(url);
  }

  onPrivacyPolicy() {
    let url = window.location.origin+"/privacy";

    const privacyUrls = environment.design['privacyUrls'];
    const defaultPrivacyLang = environment.design['defaultPrivacyLang'];
    if (privacyUrls) {
      if (privacyUrls[this.multilanguageService.currentLang.code]) {
        url = privacyUrls[this.multilanguageService.currentLang.code];
      } else if (defaultPrivacyLang && privacyUrls[defaultPrivacyLang]) {
        url = privacyUrls[defaultPrivacyLang];
      }
    }
    window.open(url);
  }
}
