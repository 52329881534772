import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CanActivate, Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class PrivacyPolicyGuard implements CanActivate {
  constructor(
    private router: Router
  ) { }

  canActivate() {
    if (environment.design["legalAppName"]) { return true }
    else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}