<div class="requirement-container px-3">
  <div class="requirement-content">
    <div class="requirement-title-secondary">{{ 'APP.MAIN.SETTINGS.SYS_REQ.ANDROID_TITLE' | translate }}</div>
    <ul>
      <li>
          <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.ANDROID_VERSION' | translate }}</p>
      </li>
      <li>
        <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.ANDROID_RAM' | translate }}</p>
      </li>
      <li>
        <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.ANDROID_CORE_SPEED' | translate }}</p>
      </li>
      <li>
        <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.ANDROID_ARCORE' | translate }}<a href="https://developers.google.com/ar/devices">{{ 'APP.MAIN.SETTINGS.SYS_REQ.ANDROID_ARCORE_DEVICE' | translate }}</a></p>
      </li>
    </ul>

    <hr>

    <div class="requirement-title-secondary">{{ 'APP.MAIN.SETTINGS.SYS_REQ.IOS_TITLE' | translate }}</div>
    <ul>
      <li>
        <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.IOS_VERSION' | translate }}</p>
      </li>
      <li>
        <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.IOS_IPHONE' | translate }}</p>
      </li>
      <li>
        <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.IOS_IPAD' | translate }}</p>
      </li>
      <li>
        <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.IOS_ARKIT' | translate }}<a href="https://developer.apple.com/library/archive/documentation/DeviceInformation/Reference/iOSDeviceCompatibility/DeviceCompatibilityMatrix/DeviceCompatibilityMatrix.html">{{ 'APP.MAIN.SETTINGS.SYS_REQ.IOS_ARKIT_DEVICE' | translate }}</a></p>
      </li>
    </ul>

    <hr>

    <div class="requirement-title-secondary">{{ 'APP.MAIN.SETTINGS.SYS_REQ.NETWORK_TITLE' | translate }}</div>
    <ul>
      <li>
          <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.NETWORK_BODY' | translate }}</p>
      </li>
    </ul>

    <hr>

    <div class="requirement-title-secondary">{{ 'APP.MAIN.SETTINGS.SYS_REQ.BROWSERS_TITLE' | translate }}</div>
    <ul>
      <li>
        <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.CHROME' | translate }}</p>
      </li> 
      <li>
        <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.FIREFOX' | translate }}</p>
      </li> 
      <li>
        <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.SAFARI' | translate }}</p>
      </li> 
      <li>
        <p>{{ 'APP.MAIN.SETTINGS.SYS_REQ.EDGE' | translate }}</p>
      </li> 
    </ul>
  </div>
</div>