<div class="container-fluid">
    <div class="row info-boxes">
        <div class="info-box col-6 col-xl-3 mb-2 mb-md-3 pl-0 pr-2">
            <div class="card red-background">
                <div class="info-content px-2 px-md-3 px-xl-4 py-3 py-xl-4">
                    <div class="info-text-container">
                        <div class="info-text-number">{{ userCount }}</div>
                        <div class="info-text">{{ 'APP.MAIN.DASHBOARD.INFO_BOXES.USERS' | translate }}</div>
                    </div>
                    <i class="info-icon mi mi-person"></i>
                </div>
                <a class="info-link py-xl-2" routerLink="/users">{{ 'APP.MAIN.DASHBOARD.INFO_BOXES.USERS_LINK' | translate }}</a>
            </div>
        </div>
        <div class="info-box col-6 col-xl-3 mb-2 mb-md-3 pr-0 pr-xl-2 pl-2">
            <div class="card green-background">
                <div class="info-content px-2 px-md-3 px-xl-4 py-3 py-xl-4">
                    <div class="info-text-container">
                        <div class="info-text-number">{{ roomCount }}</div>
                        <div class="info-text">{{ 'APP.MAIN.DASHBOARD.INFO_BOXES.ROOMS' | translate }}</div>
                    </div>
                    <i class="info-icon mi mi-call"></i>
                </div>
                <a class="info-link py-xl-2" routerLink="/rooms">{{ 'APP.MAIN.DASHBOARD.INFO_BOXES.ROOMS_LINK' | translate }}</a>
            </div>
        </div>
        <div class="info-box col-6 col-xl-3 mb-2 mb-md-3 pl-0 pl-xl-2 pr-2">
            <div class="card yellow-background">
                <div class="info-content px-2 px-md-3 px-xl-4 py-3 py-xl-4">
                    <div class="info-text-container">
                        <div class="info-text-number">{{ sessionCount }}</div>
                        <div class="info-text">{{ 'APP.MAIN.DASHBOARD.INFO_BOXES.SESSION_COUNT' | translate }}</div>
                    </div>
                    <i class="info-icon mi mi-people"></i>
                </div>
                <a class="info-link py-xl-2" routerLink="/sessions">{{ 'APP.MAIN.DASHBOARD.INFO_BOXES.SESSIONS_LINK' | translate }}</a>
            </div>
        </div>
        <div class="info-box col-6 col-xl-3 mb-2 mb-md-3 pr-0 pl-2">
            <div class="card blue-background">
                <div class="info-content px-2 px-md-3 px-xl-4 py-3 py-xl-4">
                    <div class="info-text-container">
                        <div class="info-text-number">{{ durationHours }}<span> h </span>{{ durationMinutes }}<span> m</span></div>
                        <div class="info-text">{{ 'APP.MAIN.DASHBOARD.INFO_BOXES.SESSION_TIME' | translate }}</div>
                    </div>
                    <i class="info-icon mi mi-timer"></i>
                </div>
                <a class="info-link py-xl-2" routerLink="/sessions">{{ 'APP.MAIN.DASHBOARD.INFO_BOXES.SESSIONS_LINK' | translate }}</a>
            </div>
        </div>
    </div>
</div>