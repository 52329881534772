import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { LoaderService } from '@services/support/loader.service';

@Component({
  selector: 'app-login-from-remote',
  templateUrl: './login-from-remote.component.html',
  styleUrls: ['./login-from-remote.component.scss']
})

export class LoginFromRemoteComponent implements OnInit {

  authSubscription: Subscription = null;
  loading: Boolean = true

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    // Navigate main if user already authenticated
    this.authSubscription = this.authService.getAuth().subscribe(auth => {
      if (auth) {
        this.router.navigate(['/']);
      }
    });
    this.authenticate();
  }

  async authenticate() {
    const params = await this.route.queryParams.pipe(first()).toPromise();
    if (!(params && params.token)) {
      this.loading = false;
      this.router.navigate(['/']);
      return;
    }

    this.loaderService.show();
    const token = atob(decodeURIComponent(params.token));

    this.authService.loginToFirebase(token)
    .catch(error => { this.loading = false; })
    .finally(() => this.loaderService.hide());
  }

}
