import { Component, OnInit, Input, OnDestroy, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Step } from '@models/Step';
import { DetailPageService } from '@services/support/detail-page.service';
import { StepDetailsComponent } from '../step-details/step-details.component';
import { StationService } from '@services/station.service';
import { Subscription } from 'rxjs';
import { ModalService } from '@services/support/modal.service';
import { LoaderService } from '@services/support/loader.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-step-list',
  templateUrl: './step-list.component.html',
  styleUrls: ['./step-list.component.scss']
})
export class StepListComponent implements OnInit, OnDestroy {

  @ViewChild("deleteStepTemplate", { static: true }) private deleteStepTemplate: TemplateRef<any>;

  @Input() stationId: string;
  @Input() stationName: string;
  @Input() noNewStep: boolean = false;

  steps: Step[] = null;
  stepsSub: Subscription = null;

  constructor(
    private stationService: StationService,
    private detailPageService: DetailPageService,
    private modalService: ModalService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.stepsSub = this.stationService.getStepsStream(this.stationId).subscribe(slist => {
      this.steps = slist.sort((a, b) => a.order - b.order);
    });
  }

  onNewStep() {
    const detail = this.detailPageService.loadComponent2(StepDetailsComponent).instance;
    detail.action = StepDetailsComponent.ACTION_NEW;
    detail.stationId = this.stationId;

    const index = this.steps.length;
    this.listenForStepChanges(detail, index);
  }

  onStepUp(step: Step) {
    this.stationService.stepUp(this.stationId, step.id);
  }

  onStepDown(step: Step) {
    this.stationService.stepDown(this.stationId, step.id);
  }

  onStepDetails(step: Step, index: number) {
    const detail = this.detailPageService.loadComponent2(StepDetailsComponent).instance;
    detail.action = StepDetailsComponent.ACTION_DETAILS;
    detail.stationId = this.stationId;
    detail.stepId = step.id;

    this.listenForStepChanges(detail, index);
  }

  onStepEdit(step: Step, index: number) {
    const detail = this.detailPageService.loadComponent2(StepDetailsComponent).instance;
    detail.action = StepDetailsComponent.ACTION_EDIT;
    detail.stationId = this.stationId;
    detail.stepId = step.id;

    this.listenForStepChanges(detail, index);
  }

  listenForStepChanges(detail: StepDetailsComponent, index: number) {
    if (index > 0 && this.steps[index-1].mainImage) {
      detail.previousStep = this.steps[index-1].id;
    }
    detail.hasPrevious = index > 0;
    detail.hasNext = index < (this.steps.length - 1);

    detail.changeStep.subscribe((move: "next"|"previous"|"current") => {
      if (move === 'current' && this.steps[index]) {
        this.onStepDetails(this.steps[index], index);
      }
      if (move === 'previous' && this.steps[index-1]) {
        this.onStepDetails(this.steps[index-1], index-1);
      }
      if (move === 'next' && this.steps[index+1]) {
        this.onStepDetails(this.steps[index+1], index+1);
      }
    });
  }

  onStepDelete(step: Step) {
    const modalId = this.modalService.show({
      template: this.deleteStepTemplate,
      context: {
        dataModel: step,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          delete: () =>  {
            this.loaderService.show();
            this.stationService.deleteStep(this.stationId, step.id)
            .finally(() => {
              this.modalService.hide(modalId);
              this.loaderService.hide();
            });
          }
        }
      }
    });
  }

  onDrop(event: CdkDragDrop<Step[]>) {
    this.stationService.stepChange(this.stationId, this.steps[event.previousIndex].id, event.currentIndex);
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  ngOnDestroy() {
    if (this.stepsSub) { this.stepsSub.unsubscribe() }
  }
}
