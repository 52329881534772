<div class="user-list-container">
  <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
          <span class="card-header-title">{{ 'APP.MAIN.USERS.USER_LIST.TITLE' | translate }}</span>
          <!-- dropdown -->
          <div class="d-flex justify-content-end">
              <div class="btn-group" dropdown>
                  <button *ngIf="detailed" [id]="'dropdown-button-'+1" dropdownToggle type="button" class="btn btn-sm btn-success dropdown-toggle" [attr.aria-controls]="'dropdown-'+1">
                      <span>{{ 'APP.MAIN.USERS.USER_LIST.IMPORT_FROM' | translate }}</span>
                  </button>
                  <ul [id]="'dropdown-'+1" *dropdownMenu class="dropdown-menu dropdown-menu-right py-1" style="transform: translateX(12px) !important; min-width: 100%;" role="menu" [attr.aria-labelledby]="'dropdown-button-'+1">
                      <li role="menuitem" style="display: list-item;">
                          <a class="dropdown-item" (click)="onUploadExcel()">{{ 'APP.MAIN.USERS.USER_LIST.EXCEL' | translate }}</a>
                      </li>
                  </ul>
              </div>
              <button class="btn btn-outline-success" *ngIf="detailed" (click)="onNewUser()">{{ 'APP.MAIN.USERS.USER_LIST.NEW_USER' | translate }}</button>
          </div>
      </div>

      <div *ngIf="detailed" class="filters container-fluid">
          <div class="row">
              <div class="col-sm mb-2 mt-2">
                  <input 
                      class="field form-control py-2" 
                      placeholder="{{ 'APP.MAIN.USERS.NAME_USERNAME' | translate }}"
                      #name="ngModel" 
                      [(ngModel)]="nameUserName"
                      name="nameUserName" 
                      [ngClass]="{'is-invalid':name.errors && name.touched}"
                      type="text" 
                      (ngModelChange)="onSearchTextChange($event)" 
                      style="font-size: 13px; height: 36px;"
                  >
              </div>
              <div class="col-sm mb-2 mt-2">
                  <ng-select 
                      [items]="roomNames" 
                      name="rooms" 
                      [clearSearchOnAdd]="true" 
                      [closeOnSelect]="false"
                      placeholder="{{ 'APP.MAIN.USERS.ALL_ROOMS' | translate }}" 
                      [multiple]="true" 
                      [(ngModel)]="selectedRoomFilter" 
                      (change)="onFilterChanged()"
                      style="font-size: 13px;"
                  >
                  </ng-select>
              </div>
              <div class="col-sm mb-2 mt-2">
                  <ng-select 
                      [items]="roles" 
                      name="roles" 
                      [clearSearchOnAdd]="true" 
                      [closeOnSelect]="false"
                      placeholder="{{ 'APP.MAIN.USERS.ALL_ROLES' | translate }}" 
                      [multiple]="true" 
                      [(ngModel)]="selectedRoleFilter" 
                      (change)="onFilterChanged()"
                      style="font-size: 13px;"
                  >
                  </ng-select>
              </div>
          </div>
      </div>

      <div class="card-body px-0 py-1">
          <div class="text-center my-2" *ngIf="users.length === 0">{{ 'APP.MAIN.USERS.USER_LIST.NO_USER_MESSAGE' | translate }}</div>
          <div class="t-container container-fluid" *ngIf="users.length !== 0">
              <div class="t-head row sm-hide pl-3" *ngIf="detailed" [ngClass]="{'button-pad': detailed}">
                  <div class="t-cell col-1">#</div>
                  <div class="t-cell" [ngClass]="{'col-3': detailed, 'col-7': !detailed}">{{ 'APP.MAIN.USERS.USER_LIST.NAME' | translate }}</div>
                  <div class="t-cell col-2" *ngIf="detailed">{{ 'APP.MAIN.USERS.USER_LIST.USERNAME' | translate }}</div>
                  <div class="t-cell col-3" *ngIf="detailed">{{ 'APP.MAIN.USERS.USER_LIST.ROOMS' | translate }}</div>
                  <div class="t-cell" [ngClass]="{'col-2': detailed, 'col-4': !detailed}">{{ 'APP.MAIN.USERS.USER_LIST.ROLE' | translate }}</div>
                  <div class="t-cell col-1" *ngIf="detailed"></div>
              </div>

              <div class="t-row row pl-3" *ngFor="let user of users; index as i;"
                      (click)="onUserDetails($event, user)" [ngClass]="{'button-pad': detailed}">
                  <div class="t-cell col-6 col-lg-1" [ngClass]="{'sm-hide': !detailed}">
                      <span class="sm-hide-inline mr-1">{{ i+1 }}</span>
                      <img *ngIf="detailed && user.ad_user" style="width: 14px; height: 14px;" src="assets/img/azure_blue.png">
                  </div>
                  <div class="t-cell sm-show col-6" *ngIf="detailed"></div>
                  <div class="t-cell sm-show col-6">{{ 'APP.MAIN.USERS.USER_LIST.NAME' | translate }}</div>
                  <div class="t-cell col-6" [ngClass]="{'col-lg-3': detailed, 'col-lg-7': !detailed}">
                      <span>{{ user.name }}</span>
                      <span *ngIf="user.auth.disabled" class="badge badge-danger ml-2">{{ 'APP.MAIN.USERS.USER_LIST.DISABLED' | translate }}</span>
                  </div>
                  <div class="t-cell sm-show col-6" *ngIf="detailed">{{ 'APP.MAIN.USERS.USER_LIST.USERNAME' | translate }}</div>
                  <div class="t-cell col-6 col-lg-2" *ngIf="detailed">{{ user.ad_user ? user.user_principal_name : user.auth.username }}</div>
                  <div class="t-cell sm-show col-6" *ngIf="detailed">{{ 'APP.MAIN.USERS.USER_LIST.ROOMS' | translate }}</div>
                  <div class="t-cell col-6 col-lg-3" *ngIf="detailed">
                    <div *ngIf="user.rooms">

                      <ng-container *ngIf="!user.isExpanded">
                        <div  *ngFor="let roomName of getUserRooms(user.rooms) | slice:0:4" class="pt-2">
                            <span>{{ roomName }}</span>
                            </div>
                    </ng-container>
                   
                    <ng-container *ngIf="user.isExpanded">
                        <div *ngFor="let roomName of getUserRooms(user.rooms) ; let i = index" class="pt-2">
                        <span>{{ roomName }}</span>
                        </div>
                    </ng-container>
                    <div class="pt-2">
                      <a  (click)="toggleItems($event,user)" class="text-muted pt-2 show-more"  *ngIf="getUserRooms(user.rooms).length > 4 && !user.isExpanded">
                        {{ 'APP.MAIN.SHARED.SHOW_MORE' | translate:{count:getUserRooms(user.rooms).length-4} }} <i class="mi mi-keyboard-arrow-down"></i>
                      </a>
                     
                    </div>
                       <div class="pt-2">
                        <a  (click)="toggleItems($event,user)" class="text-muted pt-2 show-more"  *ngIf="getUserRooms(user.rooms).length > 4 && user.isExpanded">
                          {{ 'APP.MAIN.SHARED.SHOW_LESS' | translate }}  <i class="mi mi-keyboard-arrow-up"></i>
                        </a>
                       </div>
                      

                    </div>                        
                  </div>
                  <div class="t-cell sm-show col-6">{{ 'APP.MAIN.USERS.USER_LIST.ROLE' | translate }}</div>
                  <div class="t-cell col-6" [ngClass]="{'col-lg-2': detailed, 'col-lg-4': !detailed}">
                    <span *ngIf="user.coadmin">{{ 'APP.MAIN.USERS.USER_LIST.COADMIN' | translate }}</span>
                    <ng-container *ngIf="!user.coadmin">
                      <span *ngIf="user.role === 'admin'">{{ 'APP.MAIN.USERS.USER_LIST.ADMIN' | translate }}</span>
                      <span *ngIf="user.role === 'coadmin'">{{ 'APP.MAIN.USERS.USER_LIST.COADMIN' | translate }}</span>
                      <span *ngIf="user.role === 'expert'">{{ (licenseType === 'concurrent_user_based' ? 'APP.MAIN.USERS.USER_LIST.USER' : 'APP.MAIN.USERS.USER_LIST.EXPERT') | translate }}</span>
                      <span *ngIf="user.role === 'user'">{{ (licenseType === 'concurrent_user_based' ? 'APP.MAIN.USERS.USER_LIST.USER' : 'APP.MAIN.USERS.USER_LIST.SUBSCRIBER') | translate }}</span>    
                    </ng-container>
                  </div>
                  <div class="t-cell col-12 col-lg-1" *ngIf="detailed">
                    <div class="btn-group d-flex">
                      <button type="button" class="btn btn-sm btn-warning" (click)="onUserDetails($event, user)">
                          <i class="mi mi-remove-red-eye"></i><span class="d-inline d-lg-none">{{ 'APP.MAIN.USERS.USER_LIST.DETAILS' | translate }}</span>
                      </button>
                      <button type="button" class="btn btn-sm btn-info" (click)="onUserEdit($event, user)" [class.disabled]="user.auth.disabled">
                          <i class="mi mi-edit"></i><span class="d-inline d-lg-none">{{ 'APP.MAIN.USERS.USER_LIST.EDIT' | translate }}</span>
                      </button>
                      <button *ngIf="!user.ad_user" type="button" class="btn btn-sm" [ngClass]="{'btn-secondary': !user.auth.disabled, 'btn-primary': user.auth.disabled}" (click)="onUserToggle($event, user)"
                          [class.disabled]="user.role === 'admin'">
                          <i class="mi mi-block" [ngStyle]="{ opacity: !user.auth.disabled ? 0.4 : 1.0 }"></i>
                          <span *ngIf="!user.auth.disabled" class="d-inline d-lg-none">{{ 'APP.MAIN.USERS.USER_LIST.DISABLE' | translate }}</span>
                          <span *ngIf="user.auth.disabled" class="d-inline d-lg-none">{{ 'APP.MAIN.USERS.USER_LIST.ENABLE' | translate }}</span>
                      </button>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<ng-template #toggleTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div *ngIf="dataModel.auth.disabled" class="card-header py-3">{{ 'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.TITLE_ENABLE' | translate }}</div>
        <div *ngIf="!dataModel.auth.disabled" class="card-header py-3">{{ 'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.TITLE_DISABLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <div *ngIf="dataModel.auth.disabled" [innerHTML]="'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.MESSAGE_ENABLE' | translate:dataModel"></div>
            <div *ngIf="!dataModel.auth.disabled" [innerHTML]="'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.MESSAGE_DISABLE' | translate:dataModel"></div>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.no()">{{ 'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.CANCEL' | translate }}</a>
            <a *ngIf="dataModel.auth.disabled" class="text-primary" (click)="callbacks.yes()">{{ 'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.ENABLE' | translate }}</a>
            <a *ngIf="!dataModel.auth.disabled" class="text-secondary" (click)="callbacks.yes()">{{ 'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.DISABLE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uploadExcelTemplate let-dataModel="dataModel" let-callbacks="callbacks">
<div class="modal-dialog" role="document">
  <div class="upload-modal modal-content">
    <div class="card">
      <div class="card-header py-3">{{ 'APP.MAIN.USERS.USER_LIST.IMPORT_FILE' | translate }}</div>
      <div class="card-body">
        <div class="m-body padded">
          <p class="sample-format">{{ 'APP.MAIN.USERS.USER_LIST.DOWNLOAD_SAMPLE' | translate }}</p>
          <p class="sample-format mt-2">{{ 'APP.MAIN.USERS.USER_LIST.SAMPLE_FORMAT' | translate }}</p>
          <a class="btn btn-sm btn-warning mt-3" href="https://firebasestorage.googleapis.com/v0/b/vsight-remote-angular-dev.appspot.com/o/Excel%20User%20Upload%20Example%20(1).xlsx?alt=media&token=8618ff18-1ee6-4c3b-a379-f3749f1ddf61" download="sample_excel_file.xls" target="_blank">{{ 'APP.MAIN.USERS.USER_LIST.CLICK_TO_DOWNLOAD' | translate }}</a>
          <hr />
          <label class="btn btn-sm btn-info rounded text-white" for="file">{{ 'APP.MAIN.USERS.USER_LIST.CHOOSE_A_FILE' | translate }}</label>
          <input class="file" type="file" accept=".xlsx" id="file" (change)="uploadFiles($event)"/>
          <div class="mt-2 font-weight-bold">{{ uploadedFile?.name }}</div>
          <div class="mt-2">{{ 'APP.MAIN.USERS.USER_LIST.ALLOWED_TYPES' | translate }}</div>
        </div>
        <div class="m-footer">
          <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.USERS.USER_LIST.CANCEL' | translate }}</a>
          
          <a class="text-secondary" *ngIf="!fileUploaded" (click)="callbacks.importFile()">{{ 'APP.MAIN.USERS.USER_LIST.IMPORT_FILE' | translate }}</a>
          <a class="text-success" *ngIf="fileUploaded" (click)="callbacks.importFile()">{{ 'APP.MAIN.USERS.USER_LIST.IMPORT_FILE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-template>

<ng-template #resultsExcelTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="results-excel-modal modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.USERS.USER_LIST.IMPORT_FILE' | translate }}</div>
        <div class="card-body">
          <div class="m-body p-0">
            <div class="t-container container-fluid">
              <div class="t-head row sm-hide ml-2">
                <div class="t-cell col-6 col-lg-2">#<span class="ml-2">{{ 'APP.MAIN.USERS.USER_LIST.NAME' | translate }}</span></div>
                <div class="t-cell col-6 col-lg-2">{{ 'APP.MAIN.USERS.USER_LIST.EMAIL' | translate }}</div>
                <div class="t-cell col-6 col-lg-2">{{ 'APP.MAIN.USERS.USER_LIST.ROLE' | translate }}</div>
                <div class="t-cell col-6 col-lg-2">{{ 'APP.MAIN.USERS.USER_LIST.USERNAME' | translate }}</div>
                <div class="t-cell col-6 col-lg-2">{{ 'APP.MAIN.USERS.USER_LIST.PASSWORD' | translate }}</div>
                <div class="t-cell col-6 col-lg-2">{{ 'APP.MAIN.USERS.USER_LIST.PERMISSIONS' | translate }}</div>
              </div>
              <div class="t-row row" *ngFor="let user of excelUploadUsers; index as i;">
                <strong class="t-cell sm-show col-6">#<span class="ml-2">{{ 'APP.MAIN.USERS.USER_LIST.NAME' | translate }}</span></strong>
                <div class="t-cell col-6 col-lg-2 d-flex justify-content-start">
                  <div class="d-flex flex-column bd-highlight">
                    <div class="bd-highlight d-flex justify-content-start">{{ i+1 }}<span class="user-value ml-2">{{ user.name.value }}</span>
                      <div *ngIf="user.name.hasError && user.name.hasError === true && !user.err">
                        <i class="mi mi-close text-danger cross-tick"></i>
                      </div>
                    </div>
                    <div class="bd-highlight"><span class="text-danger">{{ user.name.errorMessage }}</span></div>
                  </div>
                </div>
                <strong class="t-cell sm-show col-6">{{ 'APP.MAIN.USERS.USER_LIST.EMAIL' | translate }}</strong>
                <div class="t-cell col-6 col-lg-2 d-flex justify-content-start">
                  <div class="d-flex flex-column bd-highlight">
                    <div class="bd-highlight d-flex justify-content-start">
                      <span class="user-value">{{ user.email.value }}</span>
                      <div *ngIf="user.email.hasError && user.email.hasError === true && !user.err">
                        <i class="mi mi-close text-danger cross-tick warning"></i>
                      </div>
                    </div>
                    <div class="bd-highlight"><span class="text-danger">{{ user.email.errorMessage }}</span></div>
                  </div>
                </div>
                <strong class="t-cell sm-show col-6">{{ 'APP.MAIN.USERS.USER_LIST.ROLE' | translate }}</strong>
                <div class="t-cell col-6 col-lg-2 d-flex justify-content-start">
                  <div class="d-flex flex-column bd-highlight">
                    <div class="bd-highlight d-flex justify-content-start">
                      <span class="user-value">{{ user.role.value }}</span>
                      <div *ngIf="user.role.hasError && user.role.hasError === true && !user.err">
                        <i class="mi mi-close text-danger cross-tick"></i>
                      </div>
                    </div>
                    <div class="bd-highlight"><span class="text-danger">{{ user.role.errorMessage }}</span></div>
                  </div>
                </div>
                <strong class="t-cell sm-show col-6">{{ 'APP.MAIN.USERS.USER_LIST.USERNAME' | translate }}</strong>
                <div class="t-cell col-6 col-lg-2 d-flex justify-content-start">
                  <div class="d-flex flex-column bd-highlight">
                    <div class="bd-highlight d-flex justify-content-start">
                      <span class="user-value">{{ user.username.value }}</span>
                      <div *ngIf="user.username.hasError && user.username.hasError === true && !user.err">
                        <i class="mi mi-close text-danger cross-tick"></i>
                      </div>
                    </div>
                    <div class="bd-highlight"><span class="text-danger">{{ user.username.errorMessage }}</span></div>
                  </div>
                </div>
                <strong class="t-cell sm-show col-6">{{ 'APP.MAIN.USERS.USER_LIST.PASSWORD' | translate }}</strong>
                <div class="t-cell col-6 col-lg-2 d-flex justify-content-start">
                  <div class="d-flex flex-column bd-highlight">
                    <div class="bd-highlight d-flex justify-content-start">
                      <span class="user-value">{{ user.password.value }}</span>
                      <div *ngIf="user.password.hasError && user.password.hasError === true && !user.err">
                        <i class="mi mi-close text-danger cross-tick"></i>
                      </div>
                    </div>
                    <div class="bd-highlight"><span class="text-danger">{{ user.password.errorMessage }}</span></div>
                  </div>
                </div>
                <strong class="permissions t-cell sm-show col-7">{{ 'APP.MAIN.USERS.USER_LIST.PERMISSIONS' | translate }}</strong>
                <div class="t-row row col-6 col-lg-2" style="justify-content: left !important;">
                  <div *ngIf="!user.err" class="bd-highlight">
                    <i class="mi" [ngClass]="{'mi-done': user.allow_archiving.value, 'mi-close': !user.allow_archiving.value}"></i>
                    <i class="mi" [ngClass]="{'mi-done': user.allow_calling.value, 'mi-close': !user.allow_calling.value}"></i>
                    <i class="mi" [ngClass]="{'mi-done': user.allow_join_link.value, 'mi-close': !user.allow_join_link.value}"></i>
                    <i class="mi" [ngClass]="{'mi-done': user.call_archive_perm_needed.value, 'mi-close': !user.call_archive_perm_needed.value}"></i>
                  </div>
                  <div *ngIf="user.allow_archiving.errorMessage || user.allow_calling.errorMessage ||
                              user.allow_join_link.errorMessage || user.call_archive_perm_needed.errorMessage"
                        class="bd-highlight">
                    <i class="mi mi-close text-danger"></i>
                    <span class="text-danger">{{ 'APP.MAIN.USERS.USER_LIST.CORRECT_BOOLEAN' | translate }}</span>
                  </div>
                </div>
                <div *ngIf="user.err" class="col-12">
                  <i class="mi mi-close text-danger cross-tick warning"></i><span class="text-danger">{{ user.err }}</span>
                </div>
                <div *ngIf="user.success" class="col-12">
                  <i class="mi mi-success text-success cross-tick"></i><span class="text-success">{{ user.success }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.USERS.USER_LIST.CANCEL' | translate }}</a>
          <a [ngClass]="{'text-secondary': isAnyError, 'text-success': !isAnyError}" (click)="callbacks.importUsers()">{{ 'APP.MAIN.USERS.USER_LIST.IMPORT_USERS' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #successfullyDeployedTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog success-modal" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.USERS.USER_LIST.UPLOAD_SUCCEEDED' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <i class="mi mi-check-circle mr-1 text-success"></i><span>{{ 'APP.MAIN.USERS.USER_LIST.SUCCESSFULLY_REGISTERED' | translate }}</span>
          </div>
          <div class="m-footer">
            <a class="text-primary" (click)="callbacks.close()">{{ 'APP.MAIN.USERS.USER_LIST.CLOSE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #failureDeployedTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog failure-deployed-modal" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.USERS.USER_LIST.IMPORT_FAILED' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">{{ excelUserUploadError }}</div>
          <div class="list-of-users mb-2" *ngIf="repeatedUsers?.length > 0">{{ 'APP.MAIN.USERS.USER_LIST.LIST_OF_THE_USERS' | translate }}</div>
          <div class="repeated-users">
            <div class="m-body" *ngFor="let ru of repeatedUsers">{{ ru }}</div>
          </div>
          <div class="m-footer">
            <a class="text-primary" (click)="callbacks.close()">{{ 'APP.MAIN.USERS.USER_LIST.CLOSE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>