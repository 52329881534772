import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullscreenService {

  isFullscreen: Observable<boolean>;
  private _isFullscreen: boolean = false;
  private isFullscreenSource = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isFullscreen = this.isFullscreenSource.asObservable();

    document.addEventListener("fullscreenchange", () => {
      this._isFullscreen = !this._isFullscreen;
      this.isFullscreenSource.next(this._isFullscreen);
    });
    document.addEventListener("webkitfullscreenchange", () => {
      this._isFullscreen = !this._isFullscreen;
      this.isFullscreenSource.next(this._isFullscreen);
    });
    document.addEventListener("mozfullscreenchange", () => {
      this._isFullscreen = !this._isFullscreen;
      this.isFullscreenSource.next(this._isFullscreen);
    });
    document.addEventListener("MSFullscreenChange", () => {
      this._isFullscreen = !this._isFullscreen;
      this.isFullscreenSource.next(this._isFullscreen);
    });
  }

  requestFullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  exitFullscreen() {
    const _document: any = document;

    if (_document.exitFullscreen) {
      _document.exitFullscreen();
    } else if (_document.webkitExitFullscreen) {
      _document.webkitExitFullscreen();
    } else if (_document.mozCancelFullScreen) {
      _document.mozCancelFullScreen();
    } else if (_document.msExitFullscreen) {
      _document.msExitFullscreen();
    }
  }
}
