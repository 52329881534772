import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  sidebarState: Observable<boolean>;
  private sidebarStateSource = new BehaviorSubject<boolean>(false);

  constructor() {
    this.sidebarState = this.sidebarStateSource.asObservable();
  }

  openSidebar() {
    this.sidebarStateSource.next(true);
  }

  closeSidebar() {
    this.sidebarStateSource.next(false);
  }

  async toggleSidebar() {
    const state = await this.sidebarState.pipe(first()).toPromise();
    this.sidebarStateSource.next(!state);
  }
}
