import { Component, OnInit, ViewChild, ChangeDetectorRef, TemplateRef, Output, EventEmitter } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { DetailPageService } from '@services/support/detail-page.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UploadModel } from '@models/UploadModel';
import { FileShareService } from '@services/support/file-share.service';
import { ModalService } from '@services/support/modal.service';
import { LoaderService } from '@services/support/loader.service';
import { StationService } from '@services/station.service';
import { UploadFile } from '@models/UploadFile';
import { CollaborationService } from '@services/remote/collaboration.service';
import { first, auditTime } from 'rxjs/operators';
import { ImageCollaborationService } from '@services/remote/image-collaboration.service';
import { ImageCollaborationComponent } from 'app/components/main/remote/image-collaboration/image-collaboration.component';

@Component({
  selector: 'app-step-details',
  templateUrl: './step-details.component.html',
  styleUrls: ['./step-details.component.scss']
})
export class StepDetailsComponent implements OnInit {

  static ACTION_DETAILS: string = "details";
  static ACTION_EDIT: string = "edit";
  static ACTION_NEW: string = "new";
  /**** Access in template ****/
  get ACTION_DETAILS() { return StepDetailsComponent.ACTION_DETAILS; }
  get ACTION_EDIT() { return StepDetailsComponent.ACTION_EDIT; }
  get ACTION_NEW() { return StepDetailsComponent.ACTION_NEW; }

  @ViewChild('stepForm', { static: true }) stepForm: NgForm;
  @ViewChild("uploadModalTemplate", { static: true }) private uploadModalTemplate: TemplateRef<any>;
  @ViewChild("deleteStepTemplate", { static: true }) private deleteStepTemplate: TemplateRef<any>;
  @ViewChild(ImageCollaborationComponent, { static: false }) imageCollaboration: ImageCollaborationComponent;

  @Output() changeStep = new EventEmitter<"next"|"previous"|"current">();

  hasPrevious: boolean;
  hasNext: boolean;

  action: string;
  actionTitle: string = "";

  stationId: string;
  stepId: string;

  formPristine: boolean = true;
  formLoading: boolean = true;
  formValueSub: Subscription = null;

  orjStep: any;
  step: any = {
    name: '',
    description: '',
    photoNeeded: false,
    cycleTime: null,
    mainImage: '',
//    iconImage1: '',
    video: ''
  };

  media: {[key:string] : { status: "changed"|"deleted", file?: any}} = {
//    iconImage1: null,
    mainImage: null,
    video: null
  };

  disabledControls: any = {
//    iconImage1: false,
    mainImage: false,
    video: false
  };

  uploadModel: UploadModel = {
    uploadFiles: [],
    uploading: false,
    completed: false,
    totalBytes: 0,
    transferredBytes: 0
  };

  uploadModalId: number;

  frame: any = null;
  resizeSub: Subscription = null;

  imageDeleted: boolean = false;
  videoDeleted: boolean = false;

  imageSelected: boolean = false;
  videoSelected: boolean = false;

  selectedImage: File = null;
  selectedVideo: File = null;

  previousStep: string = null;

  constructor(
    private stationService: StationService,
    private detailPageService: DetailPageService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private fileShareService: FileShareService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private collaborationService: CollaborationService,
    private imageCollaborationService: ImageCollaborationService
  ) { }

  ngOnInit() {
    this.resizeSub = fromEvent(window, 'resize')
    .pipe(auditTime(500))
    .subscribe((event) => {
      if (this.frame) {
        this.imageCollaborationService.triggerResize();
      }
    });

    if (this.action === this.ACTION_NEW) {
      this.orjStep = {
        name: '',
        description: '',
        photoNeeded: false,
        cycleTime: null,
        mainImage: '',
//        iconImage1: '',
        video: ''
      };
      this.formLoading = false;
      this.translateService.get('APP.MAIN.STATIONS.STEP_DETAILS.TITLE_NEW').toPromise()
      .then(result => { this.actionTitle = result; });
    } else {
      if (this.action === this.ACTION_DETAILS) {
        this.translateService.get('APP.MAIN.STATIONS.STEP_DETAILS.TITLE_DETAILS').toPromise()
        .then(result => { this.actionTitle = result; });
      } else if (this.action === this.ACTION_EDIT) {
        this.translateService.get('APP.MAIN.STATIONS.STEP_DETAILS.TITLE_EDIT').toPromise()
        .then(result => { this.actionTitle = result; });
      }
      this.loadStepData();
    }

    this.formValueSub = this.stepForm.valueChanges.subscribe(value => {
      this.detectPristine();
    });
  }

  detectPristine() {
    const rawValue = this.stepForm.control.getRawValue();

    let pristine = true;
    if (this.orjStep) {
      Object.keys(rawValue).forEach(property => {
        if (rawValue[property] !== this.orjStep[property]) {
          pristine = false;
        }
      });
    }
    if (this.step.mainImage && this.imageDeleted) {
      pristine = false;
    }
    if (this.step.video && this.videoDeleted) {
      pristine = false;
    }
    if (!this.step.mainImage && this.imageSelected) {
      pristine = false;
    }
    if (!this.step.video && this.videoSelected) {
      pristine = false;
    }
    this.formPristine = pristine;
  }

  onChangeStep(direction: "next"|"previous") {
    this.changeStep.emit(direction);
  }

  ngAfterViewChecked() {
    if (this.action === this.ACTION_DETAILS) {
      this.stepForm.control.disable();
      Object.keys(this.disabledControls).forEach(property => {
        this.disabledControls[property] = true;
      });
      this.changeDetector.detectChanges();
    }
  }

  loadStepData() {
    this.stationService.getStep(this.stationId, this.stepId).then(step => {
      this.orjStep = {
        name: step.name,
        description: step.description,
        photoNeeded: step.photoNeeded === true ? true : false,
        cycleTime: step.cycleTime,
        mainImage: step.mainImage ? step.mainImage : '',
//       iconImage1: step.iconImage1 ? step.iconImage1 : '',
        video: step.video ? step.video : '',
      };
      Object.keys(this.orjStep).forEach(key => {
        this.step[key] = this.orjStep[key];
      });
      this.formLoading = false;

      if (this.action === this.ACTION_EDIT) {
        this.onEdit();
      }
    });
  }

  onMediaChange(property: string, event: any, mediaElement: HTMLMediaElement, nameInput: HTMLInputElement) {
    if (event.target.files[0]) {
      nameInput.value = event.target.files[0].name;
      mediaElement.src = URL.createObjectURL(event.target.files[0]);

      this.media[property] = {
        status: "changed",
        file: event.target.files[0]
      };

      this.detectPristine();
    } else {
      nameInput.value = '';
    }
  }
/*
  onMediaDelete(property: string, mediaElement: HTMLMediaElement, fileInput: HTMLInputElement, nameInput: HTMLInputElement) {
    if (this.orjStep[property] !== '') {
      this.media[property] = {
        status: "deleted"
      };
    } else {
      this.media[property] = null;
    }

    this.detectPristine();
    mediaElement.src = '';
    nameInput.value = '';
    fileInput.value = '';
  }
*/
  async onPreviousImage() {
    this.frame = null;
    const coll = await this.collaborationService.collaborationStepToCurrent(this.stationId, this.previousStep);
    if (coll) {
      this.collaborationService.setCurrentCollaborationPath(this.stationId);
      this.frame = { type: coll.collaboration_data.type, isCollaboration: true, collaborationData: coll.collaboration_data};
      this.imageSelected = true;
      this.detectPristine();
    } else {
      console.log('there is no collaboration in previous frame');
    }
  }

  async onImageChoose(event: any) {
    this.frame = null;
    if (event.target.files[0]) {
      this.selectedImage = event.target.files[0];
      
      const upFile: any = {
        file: this.selectedImage,
        extension: this.selectedImage.name.split('.').pop().toLowerCase(),
        progress: 0,
        type: 'mainImage'
      };
      await this.uploadFiles([upFile]);

      if (upFile.url) {
        const coll = this.collaborationService.createCollaboration(upFile.key, upFile.file.name, upFile.url)
        await this.collaborationService.saveCollaborationToCurrent(coll, this.stationId);
        this.collaborationService.setCurrentCollaborationPath(this.stationId);

        this.frame = { type: coll.collaboration_data.type, isCollaboration: true, collaborationData: coll.collaboration_data};
        this.imageSelected = true;
        this.detectPristine();
      }
    }
  }

  onVideoChoose(event: any, videoElement: HTMLMediaElement) {
    if (event.target.files[0]) {
      this.selectedVideo = event.target.files[0];
      this.videoSelected = true;
      videoElement.src = URL.createObjectURL(event.target.files[0]);
    }
    this.detectPristine();
  }

  onMediaDelete(imageFileInput: HTMLInputElement, videoFileInput: HTMLInputElement) {
    if (this.step.mainImage || this.imageSelected) {
      this.imageSelected = false;
      this.imageDeleted = true;
      this.selectedImage = null;
      imageFileInput.value = '';
    }
    if (this.step.video || this.videoSelected) {
      this.videoSelected = false;
      this.videoDeleted = true;
      this.selectedVideo = null;
      videoFileInput.value = '';
    }
    this.detectPristine();
  }

  async resetForm() {
    Object.keys(this.step).forEach(prop => {
      this.step[prop] = this.orjStep[prop];
    });

    Object.keys(this.stepForm.controls).forEach(c => {
      this.stepForm.controls[c].markAsUntouched();
    });

    if (this.step.mainImage && this.imageDeleted) {
      this.frame = null;
      const coll = await this.loadStepCollaboration(this.stepId);
      if (coll) {
        this.frame = { type: coll.collaboration_data.type, isCollaboration: true, collaborationData: coll.collaboration_data};
        this.imageDeleted = true;
      }
    } else {
      this.imageSelected = false;
      this.imageDeleted = false;
      this.videoSelected = false;
      this.videoDeleted = false;
    }
    this.detectPristine();
  }

  onBack() {
    this.detailPageService.removeComponent2();
  }

  async onEdit() {
    this.action = this.ACTION_EDIT;
    this.stepForm.control.enable();

    if (this.step.mainImage) {
      const coll = await this.loadStepCollaboration(this.stepId);
      if (coll) {
        this.imageDeleted = true;
        this.detectPristine();
      }
    }
  }

  async loadStepCollaboration(step_id: string) {
    const coll = await this.collaborationService.collaborationStepToCurrent(this.stationId, step_id);
    if (coll) {
      this.collaborationService.setCurrentCollaborationPath(this.stationId);
      this.frame = { type: coll.collaboration_data.type, isCollaboration: true, collaborationData: coll.collaboration_data};
      this.imageSelected = true;
      return coll;
    } else {
      console.log('there is no collaboration in step: '+step_id);
      return null;
    }
  }

  cancelEdit() {
    this.action = this.ACTION_DETAILS;
    this.stepForm.control.disable();

    this.frame = null;
    Object.keys(this.step).forEach(prop => {
      this.step[prop] = this.orjStep[prop];
    });
    this.imageSelected = false;
    this.imageDeleted = false;
    this.videoSelected = false;
    this.videoDeleted = false;
  }

  onDelete() {
    const modalId = this.modalService.show({
      template: this.deleteStepTemplate,
      context: {
        dataModel: this.step,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          delete: () => {
            this.loaderService.show();
            this.stationService.deleteStep(this.stationId, this.stepId)
            .then(() => {
              return this.detailPageService.removeComponent2();
            })
            .finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
            });
          }
        }
      }
    });
  }

  async detectStepChanges(orjStation, rawValue) {
    let changes: {stationId: string, stepId: string, step: any} = {
      stationId: this.stationId,
      stepId: this.stepId,
      step: {},
    };

    Object.keys(rawValue).forEach(property => {
      if (rawValue[property] !== orjStation[property]) {
        changes.step[property] = rawValue[property];
      }
    });

    if (changes.step.description === "") {
      changes.step.description = null;
    }

    const files: UploadFile[] = [];
    if (this.imageSelected) {
      const image = await this.imageCollaboration.createJpegFromCollaboration()
      if (image) {
        files.push({
          file: image,
          extension: image.name.split('.').pop().toLowerCase(),
          progress: 0,
          type: 'mainImage'
        });
      }
    } else if (this.step.mainImage && this.imageDeleted) {
      changes.step.mainImage = null;
    }
    if (this.videoSelected) {
      files.push({
        file: this.selectedVideo,
        extension: this.selectedVideo.name.split('.').pop().toLowerCase(),
        progress: 0,
        type: 'video'
      });
    } else if (this.step.video && this.videoDeleted) {
      changes.step.video = null;
    }
    if (files.length > 0) {
      await this.uploadFiles(files)

      files.forEach(file => {
        changes.step[file.type] = file.url;
      });
    }
    return changes;
  }

  onSubmit(form: NgForm) {
    const rawValue = form.control.getRawValue();

    if (this.action === this.ACTION_EDIT) {
      this.detectStepChanges(this.orjStep, rawValue)
      .then(changes => {
        this.loaderService.show();
        this.stationService.editStep(changes)
        .then(() => {
          if (changes.step.mainImage) {
            this.collaborationService.collaborationCurrentToStep(this.stationId, this.stepId);
          }
        })
        .finally(() => {
          this.loadDetail(this.stepId);
          this.loaderService.hide();
        });
      });
    } else if (this.action === this.ACTION_NEW) {
      this.detectStepChanges(this.orjStep, rawValue)
      .then(changes => {
        if (changes.step.photoNeeded !== true) {
          changes.step.photoNeeded = false;
        }
        this.loaderService.show();
        this.stationService.createStep(changes)
        .then(stepId => {
          if (changes.step.mainImage) {
            return this.collaborationService.collaborationCurrentToStep(this.stationId, stepId).then(() => stepId);
          }
          return stepId;
        })
        .then(stepId => this.loadDetail(stepId))
        .finally(() => this.loaderService.hide());
      });
    }
  }

  loadDetail(stepId: string) {
    this.changeStep.emit("current");
  }

  uploadFiles(uploadFiles: UploadFile[]) {
    return new Promise<void>((resolve) => {
      this.uploadModel.uploadFiles = uploadFiles;
      this.uploadModel.uploading = false;
      this.uploadModel.completed = false;
      this.uploadModel.totalBytes = 0,
      this.uploadModel.transferredBytes = 0,
      this.uploadModalId = this.modalService.show({
        template: this.uploadModalTemplate,
        context: {
          dataModel: this.uploadModel,
          callbacks: {
            close: () => this.modalService.hide(this.uploadModalId),
            cancel: () => this.uploadModel.uploadFiles.forEach(upload => {
              if (upload.uploadTask.snapshot.state === 'running') {
                upload.uploadTask.cancel();
              }
            })
          }
        }
      });
      this.fileShareService.upload(this.uploadModel, this.stationId).subscribe(upload => {}, error => {}, () => {
        this.modalService.hide(this.uploadModalId);
        resolve();
      });
    });
  }

  ngOnDestroy() {
    if (this.formValueSub) { this.formValueSub.unsubscribe() }
    if (this.resizeSub) { this.resizeSub.unsubscribe() }

    if (this.changeStep) { this.changeStep.complete() }
  }
}
