import { Component, OnInit, Renderer2, Input } from '@angular/core';
import { User } from '@models/User';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { LogMessage } from '@models/LogMessage';
import { SessionService } from '@services/session.service';
import { Session } from '@models/Session';

@Component({
  selector: 'app-session-chat',
  templateUrl: './session-chat.component.html',
  styleUrls: ['./session-chat.component.scss']
})
export class SessionChatComponent implements OnInit {

  @Input('session') session: Session;

  currentUser: User = null;

  logMessages: LogMessage[] = [];
  private logMessagesSub: Subscription = null;
  private logFilesSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private renderer: Renderer2
  ) {
    this.currentUser = this.authService.currentUser;
  }

  ngOnInit() {
    this.logMessagesSub = this.sessionService.getSessionLogs(this.session).subscribe(messages => {
      this.logMessages = messages;
    });
  }

  ngOnDestroy() {
    if (this.logMessagesSub) { this.logMessagesSub.unsubscribe() }
    if (this.logFilesSub) { this.logFilesSub.unsubscribe() }
  }

  getMessageTranslationKey(message: LogMessage) {
    switch (message.type) {
      case "info":
        if (message.type2 === "joinRoom") {
          return "APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.JOIN_ROOM";
        } else if (message.type2 === "leaveRoom") {
          return "APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.LEAVE_ROOM";
        }
        break;
      case "request":
        if (message.type2 === "screenshot") {
          return "APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.REQUEST_SCREENSHOT";
        } else if (message.type2 === "reaction") {
          return "APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.REACTION";
        }
        break;
      case "file":
        if (message.type2 === "image") {
          return "APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.SHARE_IMAGE";
        } else if (message.type2 === "screenshot") {
          return "APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.SHARE_SCREENSHOT";
        } else if (message.type2 === "pdf") {
          return "APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.SHARE_PDF";
        } else if (message.type2 === "other") {
          return "APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.SHARE_OTHER";
        }
        break;
      case "object":
        return "APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.SHARE_OBJECT";
      case "collaboration":
        return "APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.COLLABORATED";
    }
    return "";
  }

  getExtension(filename: string): string {
    return filename.split('.').pop().toLowerCase()
  }

  messageHasFile(message: LogMessage) {
    if (message.type === "file" || message.type === "object") {
      return true;
    } else {
      return false;
    }
  }

  messageHasCollaborateButton(message: LogMessage) {
    if (message.type2 === "other") {
      return false;
    } else {
      return true;
    }
  }
}
