import { Component, OnInit, ViewChild, TemplateRef, Input, OnDestroy } from '@angular/core';

import { RoomDetailsComponent } from '../room-details/room-details.component';

import { AccountService } from '@services/account.service';
import { RoomService } from '@services/room.service';
import { DetailPageService } from '@services/support/detail-page.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { ModalService } from '@services/support/modal.service';
import { LoaderService } from '@services/support/loader.service';
import { UserService } from '@services/user.service';

import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { User } from '@models/User';

import { Room } from '@models/Room';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-room-list',
  templateUrl: './room-list.component.html',
  styleUrls: ['./room-list.component.scss']
})
export class RoomListComponent implements OnInit, OnDestroy {

  @ViewChild("toggleTemplate", { static: true }) private toggleTemplate: TemplateRef<any>;
  @Input() detailed: boolean = true;

  rooms: Room[] = [];
  roomsSub: Subscription = null;

  users: User[] = [];
  userNames: any[] = [];
  userSub: Subscription = null;

  searchTextChangedSource: Subject<string> = new Subject<string>();
  searchTextChangedSub: Subscription = null;

  allRooms: any[] = null;

  textfieldInput: any = false;

  selectedUserFilter: string[] = []
  roomName: string = "";

  constructor(
    private accountService: AccountService,
    private roomService: RoomService,
    private modalService: ModalService,
    private detailPageService: DetailPageService,
    private flashMessageService: FlashMessageService,
    private loaderService: LoaderService,
    private userService: UserService,
  ) {
    // Code piece runs when user touches the textfield search.
    this.searchTextChangedSub = this.searchTextChangedSource.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(text => {
      this.textfieldInput = text;
      var selectedUsers = [...this.selectedUserFilter];
      this.rooms = this.allRooms
        .filter(room => {
          let roomNameSelected = text ? room.room_data.name.toLowerCase().match(text.toLowerCase()) : true;
          return roomNameSelected;
        })
        .filter(room => {
          var users = Object.keys(room.room_data.users).reverse();
          if (users.length === 0 && selectedUsers.length === 0) { return true }
          else {
            for (var i=0; i<users.length; i++){
              var value = room.room_data.users[users[i]];
              let userSelected = (selectedUsers && selectedUsers.length > 0) ? selectedUsers.includes(value.name) : true;
              if (userSelected === true) { 
                return userSelected;
              }
            }
            return false;
          }
        })
        .sort((a, b) => {
          if (!a.room_data.disabled && b.room_data.disabled === true) {
            return -1;
          } else if (a.room_data.disabled === true && !b.room_data.disabled) {
            return 1;
          } else {
            return a.room_data.name.toLocaleLowerCase().localeCompare(b.room_data.name.toLocaleLowerCase());
          }
        })
    })
  }

  ngOnInit() {
    this.roomsSub = this.roomService.rooms.subscribe(rooms => {
        let disableFilteredRoomList = rooms
        .filter(room => {
          let roomNameSelected = this.textfieldInput ? room.room_data.name.toLowerCase().match(this.textfieldInput.toLowerCase()) : true;
          return roomNameSelected;
        })
        .filter(room => {
          var users = Object.keys(room.room_data.users).reverse();
          if (users.length === 0 && this.selectedUserFilter.length === 0) { return true }
          else {
            for (var i=0; i<users.length; i++){
              var value = room.room_data.users[users[i]];
              let userSelected = (this.selectedUserFilter && this.selectedUserFilter.length > 0) ? this.selectedUserFilter.includes(value.name) : true;
              if (userSelected === true) { 
                return userSelected;
              }
            }
            return false;
          }
        })
        .sort((a, b) => {
        if (!a.room_data.disabled && b.room_data.disabled === true) {
          return -1;
        } else if (a.room_data.disabled === true && !b.room_data.disabled) {
          return 1;
        } else {
          return a.room_data.name.toLocaleLowerCase().localeCompare(b.room_data.name.toLocaleLowerCase());
        }
      });
      this.rooms = [...disableFilteredRoomList]
      this.allRooms = rooms.sort((a, b) => a.room_data.name.toLocaleLowerCase().localeCompare(b.room_data.name.toLocaleLowerCase()));
    });

    this.userSub = this.userService.users.subscribe(u => {
      this.users = u.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
      this.userNames = this.users.map(user => user.name);
    });
  }

  ngOnDestroy() {
    if (this.roomsSub) { this.roomsSub.unsubscribe() }
    if (this.userSub) { this.userSub.unsubscribe() }
    if (this.searchTextChangedSub) { this.searchTextChangedSub.unsubscribe() }
  }

  toggleItems(event: Event, roomData) {
    event.stopPropagation();
    roomData['isExpanded'] = !!!roomData['isExpanded'];
  }

  getRoomUsers(roomUsers: any) {
    return (roomUsers ? Object.keys(roomUsers).map(userId => roomUsers[userId]) : [])
    .sort((a, b) => {
      if (a.in_room && !b.in_room) {
        return -1;
      } else if (!a.in_room && b.in_room) {
        return 1;
      } else {
        return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
      }
    });
  }

  async onNewRoom() {
    const accountData = await this.accountService.accountData.pipe(first()).toPromise();
    if (accountData.license.room_limit && !isNaN(accountData.license.room_limit) && accountData.license.room_limit > 0 && this.rooms.length >= accountData.license.room_limit) {
      this.flashMessageService.showTranslatedWithData("APP.MAIN.ROOMS.ROOM_LIST.ROOM_LIMIT_REACHED", environment.design);
      return;
    }
    const detail = this.detailPageService.loadComponent(RoomDetailsComponent).instance;
    detail.action = RoomDetailsComponent.ACTION_NEW;
  }

  onRoomDetails(e: MouseEvent, room: Room) {
    e.stopPropagation();

    const detail = this.detailPageService.loadComponent(RoomDetailsComponent).instance;
    detail.action = RoomDetailsComponent.ACTION_DETAILS;
    detail.roomId = room.id;
  }

  onRoomEdit(e: MouseEvent, room: Room) {
    e.stopPropagation();

    if (!room.room_data.disabled) {
      const detail = this.detailPageService.loadComponent(RoomDetailsComponent).instance;
      detail.action = RoomDetailsComponent.ACTION_EDIT;
      detail.roomId = room.id;
    }
    
  }

  onFilterChanged() {
    var selectedUsers = [...this.selectedUserFilter];

    this.rooms = this.allRooms
      .filter(room => {
        let roomNameSelected = this.textfieldInput ? room.room_data.name.toLowerCase().match(this.textfieldInput.toLowerCase()) : true;
        return roomNameSelected;
      })
      .filter(room => {
        var users = Object.keys(room.room_data.users).reverse();
        if (users.length === 0 && selectedUsers.length === 0) { return true }
        else {
          for(var i = 0; i < users.length; i++){
            var value = room.room_data.users[users[i]];
            let userSelected = (selectedUsers && selectedUsers.length > 0) ? selectedUsers.includes(value.name) : true;
            if (userSelected === true) {
              return userSelected;
            }
          }
          return false;
        }
      })
      .sort((a, b) => {
        if (!a.room_data.disabled && b.room_data.disabled === true) {
          return -1;
        } else if (a.room_data.disabled === true && !b.room_data.disabled) {
          return 1;
        } else {
          return a.room_data.name.toLocaleLowerCase().localeCompare(b.room_data.name.toLocaleLowerCase());
        }
      })
  }

  replaceSpecialCharactersWithSpace(text: string): string {
    return text.replace(/([.?*+^$[\]\\(){}|-])/g, ' ');
  }

  onSearchTextChange(text: string): void {
    this.searchTextChangedSource.next(this.replaceSpecialCharactersWithSpace(text));
  }
  
  onRoomToggle(e: MouseEvent, room: Room) {
    e.stopPropagation();

    const modalId = this.modalService.show({
      template: this.toggleTemplate,
      context: {
        dataModel: room.room_data,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.loaderService.show();
            this.roomService.toggleRoomStatus(room.id)
            .then(disabled => {
              if (disabled) {
                this.flashMessageService.showTranslated('APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.DISABLED', { cssClass: 'alert-success' });
              } else {
                this.flashMessageService.showTranslated('APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.ENABLED', { cssClass: 'alert-success' });
              }
            })
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.ERROR'))
            .finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
            });
          }
        }
      }
    });
  }
}
