<div class="not-found-container">
    <div class="logo-container">
        <img class="logo-image" [ngStyle]="('design' | env).logoStyle" [src]="('design' | env).logo">
        <div *ngIf="('design' | env).logoText" class="logo-text ml-2">{{ ('design' | env).logoText }}</div>
    </div>
    <div class="not-found-title mt-5 mb-3 mx-2 text-center">
        {{ 'APP.MAIN.NOT_FOUND.NOT_FOUND_TITLE' | translate }}
    </div>
    <div class="not-found-message mx-2 text-center">
        {{ 'APP.MAIN.NOT_FOUND.URL_MISSPELLED' | translate }}
    </div>
</div>