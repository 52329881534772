import { Directive, EventEmitter, ElementRef, Input, OnChanges, SimpleChanges, Output, HostListener, Renderer2, HostBinding } from '@angular/core';

@Directive({
    selector: '[fakeDisabled]'
})
export class FakeDisabledDirective implements OnChanges {

    @Input('fakeDisabled') fakeDisabled: boolean;

    @Input('disabledClass') disabledClass: string = "disabled";

    @Output("enabledClick") enabledClick: EventEmitter<any> = new EventEmitter<any>();
    @Output("disabledClick") disabledClick: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private renderer: Renderer2,
        private el: ElementRef
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fakeDisabled) {
            this.fakeDisabled = changes.fakeDisabled.currentValue;
            this.updateClass();
        }
        if (changes.disabledClass) {
            this.disabledClass = changes.disabledClass.currentValue;
            this.updateClass();
        }
    }

    private updateClass() {
        if (this.disabledClass) {
            if (this.fakeDisabled) {
                this.renderer.addClass(this.el.nativeElement, this.disabledClass);
            } else {
                this.renderer.removeClass(this.el.nativeElement, this.disabledClass);
            }
        }
    }

    @HostListener("click", ["$event"])
    public onClick(event: MouseEvent): void {
        if (this.fakeDisabled) {
            /*event.stopPropagation();
            event.preventDefault();
            event.cancelBubble = true;
            event.stopImmediatePropagation();*/
            this.disabledClick.emit(event);
        } else {
            this.enabledClick.emit(event);
        }
    }
}