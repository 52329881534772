<div class="station-details-container px-md-3 px-lg-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                    <a class="back-link" (click)="onBack()">
                        <i class="mi mi-arrow-back"></i><span>{{ 'APP.MAIN.STATIONS.STATION_DETAILS.BACK' | translate }}</span>
                    </a>
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <span class="card-header-title">{{ actionTitle }}</span>
                        <button *ngIf="action === ACTION_DETAILS && !formLoading" class="btn btn-info" (click)="onEdit()">
                            {{ 'APP.MAIN.STATIONS.STATION_DETAILS.EDIT' | translate }}
                        </button>
                        <button *ngIf="action === ACTION_NEW && !formLoading" class="btn btn-danger" (click)="resetForm(ACTION_NEW)" [disabled]="formPristine || stationForm.control.disabled">
                            {{ 'APP.MAIN.STATIONS.STATION_DETAILS.RESET' | translate }}
                        </button>
                        <ng-container *ngIf="action === ACTION_EDIT && !formLoading">
                            <button *ngIf="!formPristine" class="btn btn-danger" (click)="resetForm(ACTION_EDIT)" [disabled]="stationForm.control.disabled">
                                {{ 'APP.MAIN.STATIONS.STATION_DETAILS.REVERT' | translate }}
                            </button>
                            <button *ngIf="formPristine" class="btn btn-secondary" (click)="cancelEdit()">
                                {{ 'APP.MAIN.STATIONS.STATION_DETAILS.CANCEL' | translate }}
                            </button>
                        </ng-container>
                    </div>
                    <div class="card-body">
                        <form #stationForm="ngForm" (ngSubmit)="onSubmit(stationForm)">
                            <div class="form-row mb-3">
                                <div class="form-group col-12">
                                    <label for="name">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.NAME' | translate }}</label>
                                    <input #name="ngModel" class="form-control" type="text" name="name" placeholder="{{ 'APP.MAIN.STATIONS.STATION_DETAILS.NAME' | translate }}" 
                                        [ngClass]="{'is-invalid':name.errors && name.touched}"
                                        [(ngModel)]="station.name" minlength="1" maxlength="100" required>
                                    <small class="invalid-feedback mx-2" [hidden]="!name.errors?.required">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.NAME_REQUIRED' | translate }}</small>
                                    <small class="invalid-feedback mx-2" [hidden]="!name.errors?.minlength">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.NAME_LENGTH' | translate }}</small>
                                </div>

                                <div class="form-group col-12">
                                    <label for="description">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.DESCRIPTION' | translate }}</label>
                                    <input #description="ngModel" class="form-control" type="text" name="description" placeholder="{{ 'APP.MAIN.STATIONS.STATION_DETAILS.DESCRIPTION' | translate }}" 
                                        [ngClass]="{'is-invalid':description.errors && description.touched}"
                                        [(ngModel)]="station.description" minlength="1" maxlength="100" required>
                                    <small class="invalid-feedback mx-2" [hidden]="!description.errors?.required">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.DESCRIPTION_REQUIRED' | translate }}</small>
                                    <small class="invalid-feedback mx-2" [hidden]="!description.errors?.minlength">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.DESCRIPTION_LENGTH' | translate }}</small>
                                </div>
                            </div>
                            <div class="form-row mb-4">
                                <div class="col-6 mb-2">
                                    <div class="form-group h-100 d-flex flex-column justify-content-between mb-0">
                                        <label for="technicalDrawingName">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.TECHNICAL_DRAWING' | translate }}</label>
                                        <img #technicalDrawingImg class="media-control-img" [src]="station.technicalDrawing">

                                        <div class="media-control-inputs mt-2">
                                            <a class="media-control-delete" [ngClass]="{'disabled text-secondary': disabledControls.video, 'text-danger': !disabledControls.video}" (click)="onMediaDelete('technicalDrawing', technicalDrawingImg, technicalDrawingFile, technicalDrawingName)"><i class="mi mi-delete"></i></a>
                                            <input class="media-control-name form-control" #technicalDrawingName type="text" name="technicalDrawingName" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHOOSE_FILE' | translate }}" (click)="technicalDrawingFile.click()" [disabled]="disabledControls.technicalDrawing">
                                            <input class="media-control-file d-none" #technicalDrawingFile type="file" name="technicalDrawingFile" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TECHNICAL_DRAWING' | translate }}" accept="image/x-png,image/jpeg" (change)="onMediaChange('technicalDrawing', $event, technicalDrawingImg, technicalDrawingName)" hidden>
                                            <input class="media-control-content d-none" [(ngModel)]="station.technicalDrawing" #technicalDrawing="ngModel" type="text" name="technicalDrawing" hidden>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 mb-2">
                                    <div class="form-group h-100 d-flex flex-column justify-content-between mb-0">
                                        <label for="handToolsName">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.HAND_TOOLS' | translate }}</label>
                                        <img #handToolsImg class="media-control-img" [src]="station.handTools">

                                        <div class="media-control-inputs mt-2">
                                            <a class="media-control-delete" [ngClass]="{'disabled text-secondary': disabledControls.video, 'text-danger': !disabledControls.video}" (click)="onMediaDelete('handTools', handToolsImg, handToolsFile, handToolsName)"><i class="mi mi-delete"></i></a>
                                            <input class="media-control-name form-control" #handToolsName type="text" name="handToolsName" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHOOSE_FILE' | translate }}" (click)="handToolsFile.click()" [disabled]="disabledControls.handTools">
                                            <input class="media-control-file d-none" #handToolsFile type="file" name="handToolsFile" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.HAND_TOOLS' | translate }}" accept="image/x-png,image/jpeg" (change)="onMediaChange('handTools', $event, handToolsImg, handToolsName)" hidden>
                                            <input class="media-control-content d-none" [(ngModel)]="station.handTools" #handTools="ngModel" type="text" name="handTools" hidden>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group h-100 d-flex flex-column justify-content-between mb-0">
                                        <label for="finishedProductName">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.FINISHED_PRODUCT' | translate }}</label>
                                        <img #finishedProductImg class="media-control-img" [src]="station.finishedProduct">

                                        <div class="media-control-inputs mt-2">
                                            <a class="media-control-delete" [ngClass]="{'disabled text-secondary': disabledControls.video, 'text-danger': !disabledControls.video}" (click)="onMediaDelete('finishedProduct', finishedProductImg, finishedProductFile, finishedProductName)"><i class="mi mi-delete"></i></a>
                                            <input class="media-control-name form-control" #finishedProductName type="text" name="finishedProductName" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHOOSE_FILE' | translate }}" (click)="finishedProductFile.click()" [disabled]="disabledControls.finishedProduct">
                                            <input class="media-control-file d-none" #finishedProductFile type="file" name="finishedProductFile" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.FINISHED_PRODUCT' | translate }}" accept="image/x-png,image/jpeg" (change)="onMediaChange('finishedProduct', $event, finishedProductImg, finishedProductName)" hidden>
                                            <input class="media-control-content d-none" [(ngModel)]="station.finishedProduct" #finishedProduct="ngModel" type="text" name="finishedProduct" hidden>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group h-100 d-flex flex-column justify-content-between mb-0">
                                        <label style="display: block;" for="videoName">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.VIDEO' | translate }}</label>
                                        <video #videoElement class="media-control-video" controls="">
                                            <source [src]="station.video" type="video/mp4">
                                        </video>
                
                                        <div class="media-control-inputs mt-2">
                                            <a class="media-control-delete" [ngClass]="{'disabled text-secondary': disabledControls.video, 'text-danger': !disabledControls.video}" (click)="onMediaDelete('video', videoElement, videoFile, videoName)"><i class="mi mi-delete"></i></a>
                                            <input class="media-control-name form-control" #videoName type="text" name="videoName" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHOOSE_FILE' | translate }}" (click)="videoFile.click()" [disabled]="disabledControls.video">
                                            <input class="media-control-file d-none" #videoFile type="file" name="videoFile" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.VIDEO' | translate }}" accept="video/mp4,video/x-m4v" (change)="onMediaChange('video', $event, videoElement, videoName)" hidden>
                                            <input class="media-control-content d-none" [(ngModel)]="station.video" #video="ngModel" type="text" name="video" hidden>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button class="btn btn-info btn-md-inline btn-block" type="submit" [disabled]="!stationForm.valid || formPristine || formLoading">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.SAVE' | translate }}</button>
                        </form>
                    </div><!-- card-body -->
                </div><!-- card -->

                <div class="card my-4" *ngIf="action === ACTION_DETAILS && stationQrCode">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <span class="card-header-title">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.QR_TITLE' | translate }}</span>
                        <button class="btn btn-info" (click)="onDownloadQR()">
                            {{ 'APP.MAIN.STATIONS.STATION_DETAILS.DOWNLOAD_QR' | translate }}
                        </button>
                    </div>
                    <div class="card-body p-0">
                        <img [src]="stationQrCode" alt="QR Code" style="width: 100%; height: 300px; object-fit: contain;">
                    </div>
                </div>

                <div class="mx-4" *ngIf="action === ACTION_EDIT">
                    <button  class="btn btn-outline-danger btn-md-inline btn-block mt-3" (click)="onDelete()">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.DELETE_STATION' | translate }}</button>
                </div>
            </div>
            <div class="col-lg-6 mt-3 mt-lg-0">
                <app-step-list [stationName]="orjStation?.name" [stationId]="stationId" [noNewStep]="action === ACTION_NEW"></app-step-list>
            </div>
        </div>
    </div><!-- container-fluid -->
</div><!-- station-details-container -->

<ng-template #deleteStationTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.DELETE_MODAL.TITLE' | translate }}</div>
                <div class="card-body">
                    <div class="m-body padded" [innerHTML]="'APP.MAIN.STATIONS.STATION_DETAILS.DELETE_MODAL.MESSAGE' | translate:dataModel"></div>
                    <div class="m-footer">
                        <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.DELETE_MODAL.CANCEL' | translate }}</a>
                        <a class="text-danger" (click)="callbacks.delete()">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.DELETE_MODAL.DELETE' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #uploadModalTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3">
                    <span class="card-header-title mr-2">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.UPLOAD_MEDIA_FILES' | translate }}</span>
                    <span class="card-header-title" *ngIf="dataModel.totalBytes !== 0 && dataModel.uploading">{{ dataModel.transferredBytes / dataModel.totalBytes | percent }}</span>
                </div>
                <div class="card-body">
                    <div class="m-body upload-modal-body">
                        <div class="d-block" *ngFor="let uploadFile of dataModel.uploadFiles; let i = index">

                            <div class="upload-file d-flex justify-content-between align-items-center px-3">
                                <!-- SHOW PROGRESS -->
                                <div class="upload-progress" [ngStyle]="{'width.%':uploadFile.progress}"
                                    [class.error]="uploadFile.uploadTask?.snapshot.state === 'canceled'"
                                    [class.success]="uploadFile.uploadTask?.snapshot.state === 'success'">

                                    <p *ngIf="uploadFile.progress > 7" class="text-dark bg-light p-1 rounded">{{ uploadFile.progress }}%</p>
                                </div>

                                <div class="file-info">
                                    <i class="file-icon mi mi-insert-drive-file"></i>
                                    <p class="file-name" title="{{ uploadFile.file.name }}">{{ uploadFile.file.name }}</p>
                                </div>

                                <!-- SHOW STATUS -->
                                <a *ngIf="uploadFile.uploadTask?.snapshot.state === 'canceled'" class="file-canceled text-danger" >
                                    <i class="mi mi-cancel"></i>
                                </a>
                                <a *ngIf="uploadFile.uploadTask?.snapshot.state === 'success'" class="file-succeeded text-success">
                                    <i class="mi mi-check-circle"></i>
                                </a>

                            </div>

                            <hr class="upload-divider m-0">
                        </div>
                    </div>
                    <div class="m-footer upload-modal-footer">
                        <a *ngIf="dataModel.uploading" class="text-danger" (click)="callbacks.cancel()">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.CANCEL' | translate }}</a>
                        <a *ngIf="dataModel.completed || !dataModel.uploading" class="text-dark" (click)="callbacks.close()">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.CLOSE' | translate }}</a>
                        <a class="text-info" (click)="callbacks.upload()" [ngClass]="{'disabled': dataModel.uploading || dataModel.completed}">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.START_UPLOAD' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>