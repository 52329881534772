<div class="settings-container">
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.ARCHIVE.TITLE' | translate }}</span>
    </div>
    <div class="card-body px-4 px-md-5">
      <ng-container *ngIf="rooms && accountData">
        <ng-container *ngIf="accountData.features.archiving">
          <div class="container-fluid">
            <div class="row mb-3">
              <div class="col-9 col-xl-10 font-weight-bold">{{ 'APP.MAIN.SETTINGS.ARCHIVE.ROOMS' | translate }}</div>
              <div class="col-3 col-xl-2 font-weight-bold">{{ 'APP.MAIN.SETTINGS.ARCHIVE.RECORD_STATUS' | translate }}</div>
            </div>
          </div>
          <div class="container-fluid mb-4" style="overflow-x: scroll; max-height: 200px;">
            <div class="row d-flex align-items-center" *ngFor="let room of rooms; index as i;">
              <div class="col-9 col-xl-10 d-inline">{{ room.room_data.name }}</div>
              <div class="col-3 col-xl-2">
                <div class="custom-control centered-checkbox custom-checkbox" [class.disabled]="true">
                  <input type="checkbox" class="custom-control-input" id="{{ 'auto-archive'+i }}" [disabled]="true" [(ngModel)]="room.room_data.auto_archive">
                  <label class="custom-control-label" [attr.for]="'auto-archive'+i">
                    <span>{{ (room.room_data.auto_archive ? 'APP.MAIN.SETTINGS.ARCHIVE.AUTO' : (room.room_data.peer_to_peer ? 'APP.MAIN.SETTINGS.ARCHIVE.P2P' : 'APP.MAIN.SETTINGS.ARCHIVE.MANUAL')) | translate }}</span>
                    <a *ngIf="room.room_data.peer_to_peer" class="peer-to-peer-info text-info ml-2" (click)="onPeerToPeerInfo()">(?)</a>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <hr>
        </ng-container>
        <div class="container-fluid mb-4">
          <div class="row d-flex align-items-center">
            <div class="col-9 col-xl-10">{{ 'APP.MAIN.SETTINGS.ARCHIVE.AUTO_RECORD' | translate }}</div>
            <div class="col-3 col-xl-2">
              <div class="switch">
                <input type="checkbox" id="video-switch" name="switch" [disabled]="!accountData.features.archiving" [(ngModel)]="alwaysArchive">
                <label for="video-switch"></label>
              </div>
            </div>
          </div>
          <div class="row text-success mt-1" *ngIf="accountData.features.archiving && !accountData.always_archive">
            <div class="col-9" style="font-size: 12px;">{{ 'APP.MAIN.SETTINGS.ARCHIVE.CHANGE_INDIVIDUALLY' | translate }}</div>
          </div>
          <div class="row text-danger mt-1" *ngIf="!accountData.features.archiving">
            <div class="col-9" style="font-size: 12px;">{{ 'APP.MAIN.SETTINGS.ARCHIVE.NO_ARCHIVE' | translate }}</div>
          </div>
        </div>

        <button class="btn btn-info btn-block" [disabled]="this.accountData.always_archive === this.alwaysArchive" (click)="onSaveAlwaysArchive()">{{ 'APP.MAIN.SETTINGS.ARCHIVE.SAVE' | translate }}</button>
      </ng-container>
    </div>
  </div>

  <div class="card mt-4" *ngIf="accountData?.add_ons?.fullhd">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.ALWAYS_FULL_HD.TITLE' | translate }}</span>
    </div>
    <div class="card-body px-4 px-md-5">
      <div class="container-fluid mb-4">
        <div class="row d-flex align-items-center">
          <div class="col-9 col-xl-10">{{ 'APP.MAIN.SETTINGS.ALWAYS_FULL_HD.FULL_HD_MESSAGE' | translate }}</div>
          <div class="col-3 col-xl-2">
            <div class="switch">
              <input type="checkbox" id="full-hd-switch" name="full-hd-switch" [disabled]="!accountData.add_ons.fullhd" [(ngModel)]="alwaysFullHd">
              <label for="full-hd-switch"></label>
            </div>
          </div>
        </div>
        <div class="row text-success mt-1" *ngIf="accountData.add_ons.fullhd && !accountData.always_full_hd">
          <div class="col-9" style="font-size: 12px;">{{ 'APP.MAIN.SETTINGS.ALWAYS_FULL_HD.INDIVIDUAL_MESSAGE' | translate }}</div>
        </div>
      </div>

      <button class="btn btn-info btn-block" [disabled]="accountData.always_full_hd === alwaysFullHd" (click)="onSaveAlwaysFullHd()">{{ 'APP.MAIN.SETTINGS.ALWAYS_FULL_HD.SAVE' | translate }}</button>
    </div>
  </div>

  <div class="card mt-4">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.TITLE' | translate }}</span>
    </div>
    <div class="card-body py-4 px-4 px-md-5">
      <div class="recipients-message mb-2">{{ 'APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.MESSAGE' | translate:('design' | env) }}</div>
      <tag-input #tagInput [(ngModel)]="emailRecipients" (onAdd)="emailListChanged()" (onRemove)="emailListChanged()" [placeholder]="emailPholder" [secondaryPlaceholder]="emailSecPholder"
        theme="minimal" [validators]="[validMailValidator]" [errorMessages]="invalidEmailError" [animationDuration]="{enter: '0ms', leave: '0ms'}" [addOnBlur]="true"></tag-input>

      <div class="recipients-warning-text mt-2">{{ 'APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.WARNING' | translate }}</div>

      <button class="btn btn-info btn-block mt-2" [ngStyle]="{'cursor': recipientsChanged ? 'pointer' : 'default'}" [fakeDisabled]="!recipientsChanged" (enabledClick)="onSaveEmailList()">{{ 'APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.SAVE' | translate }}</button>
    </div>
  </div>

  <div class="card mt-4">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.TIMEZONE.TITLE' | translate }}</span>
    </div>
    <div class="card-body py-4 px-4 px-md-5">
      <!--<div class="recipients-message mb-2">{{ 'APP.MAIN.SETTINGS.TIMEZONE.MESSAGE' | translate }}</div>-->
      <select #timezone [(ngModel)]="tzone" class="form-control" name="timezone">
        <option *ngFor="let zone of timezones" [ngValue]="zone">{{ zone }}</option>
      </select>
      <button class="btn btn-info btn-block mt-3" [disabled]="orjTzone === tzone" (click)="onChangeTimezone()">{{ 'APP.MAIN.SETTINGS.TIMEZONE.SAVE' | translate }}</button>
    </div>
  </div>

  <div class="card password-policy mt-4">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.PASSWORD_POLICY.TITLE' | translate }}</span>
    </div>
    <div class="card-body py-4 px-4 px-md-5">
      <div class="d-flex justify-content-between align-items-center">
        <div class="title">{{ 'APP.MAIN.SETTINGS.PASSWORD_POLICY.SPECIFY_RULE' | translate }}</div>
        <div class="switch">
          <input class="password-length-area" type="checkbox" id="password-switch" name="switch" [checked]="strongEnabled" (change)="enablePasswordSetting()">
          <label for="password-switch"></label>
        </div>
      </div>
      <div class="row mt-4" *ngIf="strongEnabled">
        <div class="col-sm">
          <div class="text-left mb-2">
            <div class='title'>{{ 'APP.MAIN.SETTINGS.PASSWORD_POLICY.MIN_LENGTH' | translate }}</div>
          </div>
          <div class="d-flex flex-row align-items-center mb-3 mt-3">
            <ng-select 
              class="select-length" 
              [items]="passwordLengths" 
              [disabled]="!strongEnabled" 
              [clearable]="false" 
              [searchable]="false" 
              name="passwordlength" 
              placeholder="" 
              [(ngModel)]="passwordLength"
              (change)="minimumCharacterClick()"
            ></ng-select>
          </div>
        </div>
        <div class="col-sm">
          <div class="text-left">
            <div class='title'>{{ 'APP.MAIN.SETTINGS.PASSWORD_POLICY.FOLLOWING_CRITERIAS' | translate }}</div>
          </div>
          <div class="form-group mt-3">
            <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
              <input 
                class="custom-control-input" 
                [checked]="strongEnabled && AZSelected" 
                type="checkbox" 
                value="" 
                (change)="AZClick()" 
                id="uppercase_enabled" 
                name="uppercase_enabled"
                [ngStyle]="{'cursor': !strongEnabled ? 'auto' : 'pointer'}"
                [disabled]="!strongEnabled"
              >
              <label class="custom-control-label" for="uppercase_enabled">{{ 'APP.MAIN.SETTINGS.PASSWORD_POLICY.UPPERCASE' | translate }}&nbsp;
              <label class="custom-control-identifier">(A-Z)</label></label>
            </div>
          </div>
          <div class="form-group mt-1">
            <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
              <input
                class="custom-control-input" 
                [checked]="strongEnabled && azSelected" 
                type="checkbox" 
                value="" 
                (change)="azClick()" 
                id="lowercase_enabled"
                name="lowercase_enabled" 
                [ngStyle]="{'cursor': !strongEnabled ? 'auto' : 'pointer'}" 
                [disabled]="!strongEnabled"
              >
              <label class="custom-control-label" for="lowercase_enabled">{{ 'APP.MAIN.SETTINGS.PASSWORD_POLICY.LOWERCASE' | translate }}&nbsp;
              <label class="custom-control-identifier">(a-z)</label></label>
            </div>
          </div>
          <div class="form-group mt-1">
            <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
              <input
                class="custom-control-input" 
                [checked]="strongEnabled && numberSelected" 
                type="checkbox" 
                value="" 
                (change)="numberClick()" 
                id="number_enabled"
                name="number_enabled"
                [ngStyle]="{'cursor': !strongEnabled ? 'auto' : 'pointer'}" 
                [disabled]="!strongEnabled"
              >
              <label class="custom-control-label" for="number_enabled">{{ 'APP.MAIN.SETTINGS.PASSWORD_POLICY.NUMBER' | translate }}&nbsp;
              <label class="custom-control-identifier">(0-9)</label></label>
            </div>
          </div>
          <div class="form-group mt-1">
            <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
              <input
                class="custom-control-input" 
                [checked]="strongEnabled && specialCharacterSelected" 
                type="checkbox" 
                value="" 
                (change)="specialCharactersClick()" 
                id="special_enabled"
                name="special_enabled" 
                [ngStyle]="{'cursor': !strongEnabled ? 'auto' : 'pointer'}" 
                [disabled]="!strongEnabled"
              >
              <label class="custom-control-label" for="special_enabled">{{ 'APP.MAIN.SETTINGS.PASSWORD_POLICY.SPECIAL' | translate }}&nbsp;
              <label class="custom-control-identifier">(-*/?#&())</label></label>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row-reverse mt-3">
        <button class="btn btn-info btn-block" (click)="onPasswordControlGenerate()" [disabled]="!changeDetected">{{ 'APP.MAIN.SETTINGS.PASSWORD_POLICY.SAVE' | translate }}</button>
      </div>
    </div>
  </div>

  <div class="card mt-4">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.TITLE' | translate }}</span>
      <button *ngIf="!isContactEdit"  class="btn btn-info" style="font-size: 14px;" (click)="enableContactEdit()">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.EDIT' | translate }}</button>
      <button *ngIf="isContactEdit" [disabled]="contactsForm.controls.formGroupList.length > 2" class="btn btn-outline-success ml-auto mr-2" style="font-size: 14px;" (click)="addFormGroup()">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.NEW' | translate }}</button>
      <button *ngIf="isContactEdit && formPristine" class="btn btn-secondary" style="font-size: 14px;" (click)="disableContactEdit()">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.CANCEL' | translate }}</button>
      <button *ngIf="isContactEdit && !formPristine" class="btn btn-danger" style="font-size: 14px;" (click)="revertChanges()">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.REVERT' | translate }}</button>
    </div>
    <div class="card-body py-4 px-4 px-md-5">
      <form [formGroup]="contactsForm" autocomplete="off">
        <div *ngIf="isContactEdit">
          <div class="mb-4" [formGroup]="formGroupList" *ngFor="let formGroupList of contactsForm.controls.formGroupList.controls; index as groupIndex;">
            <div class="d-flex justify-content-between align-items-center col-12 mb-0 p-0">
              <span class="ml-2" style="font-size: 16px;">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.CONTACT' | translate }} {{ groupIndex+1 }}</span>
              <button type="button" class="btn btn-sm btn-danger" (click)="removeFormGroup(groupIndex);">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.DELETE' | translate }}</button>
            </div>
            <div class="d-flex flex-wrap">
              <div class="form-group col-12 col-lg-6 mb-0 p-0">
                <input class="form-control contact-input mt-1" type="text" formControlName="department" placeholder="{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.DEPARTMENT' | translate }}*"
                  [ngClass]="{'is-invalid':formGroupList.controls.department?.errors && formGroupList.controls.department.touched}">
                <small class="invalid-feedback mx-2" [hidden]="!formGroupList.controls.department.errors?.required">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.DEP_REQ' | translate }}</small>  
              </div>
              <div class="form-group col-12 col-lg-6 mb-0 p-0">
                <input class="form-control contact-input mt-1 ml-auto" type="text" formControlName="name" placeholder="{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.NAME' | translate }}*"
                  [ngClass]="{'is-invalid':formGroupList.controls.name?.errors && formGroupList.controls.name.touched}">
                <small class="invalid-feedback mx-2" [hidden]="!formGroupList.controls.name.errors?.required">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.NAME_REQ' | translate }}</small>  
              </div>
              <div class="form-group col-12 col-lg-6 mb-0 p-0">
                <input class="form-control contact-input mt-1" type="text" formControlName="email" placeholder="{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.EMAIL' | translate }}*"
                  [ngClass]="{'is-invalid':formGroupList.controls.email?.errors && formGroupList.controls.email.touched}">
                <small class="invalid-feedback mx-2" [hidden]="!formGroupList.controls.email.errors?.required">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.EMAIL_REQ' | translate }}</small>  
                <small class="invalid-feedback mx-2" [hidden]="!formGroupList.controls.email.errors?.pattern">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.INVALID_EMAIL' | translate }}</small>  
              </div>
              <div class="form-group col-12 col-lg-6 mb-0 p-0">
                <input class="form-control contact-input mt-1 ml-auto" type="text" formControlName="phone" placeholder="{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.PHONE' | translate }}"
                  [ngClass]="{'is-invalid':formGroupList.controls.phone.errors && formGroupList.controls.phone.touched}">
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!isContactEdit">
          <div class="d-flex flex-row mb-4" *ngFor="let formGroupList of contactsForm.controls.formGroupList.controls; index as groupIndex;">
            <div class="row w-100">
              <div class="d-flex justify-content-between align-items-center col-12 mb-0">
                <span style="font-size: 16px;">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.CONTACT' | translate }} {{ groupIndex+1 }}</span>
                <!-- class="ml-2" -->
              </div>
              <div class="col-6 col-md-4 col-lg-3 col-xl-2 mb-0 mt-2">
                <span style="font-weight: 700;">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.DEPARTMENT' | translate }}: </span>
              </div>
              <div class="col-6 col-md-8 col-lg-3 col-xl-4 mb-0 mt-2">
                <span>{{formGroupList.controls.department.value}}</span>
              </div>
              <div class="col-6 col-md-4 col-lg-2 mb-0 mt-2">
                <span style="font-weight: 700;">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.NAME' | translate }}: </span>
              </div>
              <div class="col-6 col-md-8 col-lg-4 mb-0 mt-2">
                <span>{{formGroupList.controls.name.value}}</span>
              </div>
              <div class="col-6 col-md-4 col-lg-3 col-xl-2 mb-0 mt-2">
                <span style="font-weight: 700;">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.EMAIL' | translate }}: </span>
              </div>
              <div class="col-6 col-md-8 col-lg-3 col-xl-4 mb-0 mt-2">
                <span>{{formGroupList.controls.email.value}}</span>
              </div>
              <div class="col-6 col-md-4 col-lg-2 mb-0 mt-2">
                <span style="font-weight: 700;">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.PHONE' | translate }}: </span>
              </div>
              <div class="col-6 col-md-8 col-lg-4 mb-0 mt-2">
                <span>{{formGroupList.controls.phone.value || "-"}}</span>
              </div>
            </div>
          </div>
        </div>
        <button *ngIf="isContactEdit" [disabled]="!contactsForm.valid || formPristine" class="btn btn-info btn-block mt-2" (click)="contactsFormSubmit(contactsForm)">{{ 'APP.MAIN.SETTINGS.CONTACT_INFO.SAVE' | translate }}</button>
      </form>
    </div>
  </div>

  <div class="card mt-4">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_AUTH.TITLE' | translate }}</span>
    </div>
    <div class="card-body py-4 px-4 px-md-5">
      <div class="d-flex justify-content-between align-items-center">
        <div>{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_AUTH.ADMIN_2FA' | translate }}</div>
        <button *ngIf="!twoFactorAdmin" class="btn btn-info btn-sm ml-3" (click)="onChangeTwoFactor('enableadmin')" [disabled]="!allowTwoFactor">{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_AUTH.ENABLE' | translate }}</button>
        <button *ngIf="twoFactorAdmin" class="btn btn-danger btn-sm ml-3" (click)="onChangeTwoFactor('disableall')" [disabled]="!allowTwoFactor">{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_AUTH.DISABLE' | translate }}</button>
      </div>
<!--
      <hr>
      <div class="container-fluid">
        <div class="row d-flex align-items-center">
          <div class="col-9">
            <div class="font-weight-bold">Remote Two Factor Authentication</div>
            <div class="mt-2">You can enable two factor authentication for all users.</div>
            <div *ngIf="!twoFactorAdmin" class="mt-2 text-danger">You must enable admin two factor before all users.</div>
          </div>
          <div class="col-3">
            <button *ngIf="!twoFactorOthers" class="btn btn-info btn-sm" [disabled]="!(twoFactorAdmin && allowTwoFactor)" (click)="onChangeTwoFactor('enableothers')">Enable</button>
            <button *ngIf="twoFactorOthers" class="btn btn-danger btn-sm" (click)="onChangeTwoFactor('disableexceptadmin')" [disabled]="!allowTwoFactor">Disable Except Admin</button>
          </div>
        </div>
      </div>
-->
      <div *ngIf="!allowTwoFactor" class="disabled-overlay" [dynamicTooltip]="'APP.MAIN.SETTINGS.TWO_FACTOR_AUTH.TWO_FACTOR_UNAVAILABLE'" [tooltipDisabled]="allowTwoFactor" [tooltipDelay]="200"></div>
    </div>
  </div><!-- card -->

  <div class="card mt-4" *ngIf="customEmailAvailable">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.WHITELABEL_SETTINGS.TITLE' | translate }}</span>
    </div>
    <div class="card-body py-4 px-4 px-md-5">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <div class="font-weight-bold">{{ 'APP.MAIN.SETTINGS.WHITELABEL_SETTINGS.SYSTEM_EMAIL_TITLE' | translate }}</div>
          <div class="mt-2">{{ 'APP.MAIN.SETTINGS.WHITELABEL_SETTINGS.SYSTEM_EMAIL_MESSAGE' | translate }}</div>
        </div>
        <button *ngIf="!customEmailEnabled" class="btn btn-info btn-sm ml-3" (click)="onEnableCustomSystemEmail()">{{ 'APP.MAIN.SETTINGS.WHITELABEL_SETTINGS.ENABLE' | translate }}</button>
        <button *ngIf="customEmailEnabled" class="btn btn-danger btn-sm ml-3" (click)="onDisableCustomSystemEmail()">{{ 'APP.MAIN.SETTINGS.WHITELABEL_SETTINGS.DISABLE' | translate }}</button>
      </div>
    </div>
  </div>

  <div class="card mt-4" *ngIf="adIntegrationAvailable">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.AZURE_AD.AZURE_AD_INTEGRATION' | translate }}</span>
    </div>
    <div class="card-body py-4 px-4 px-md-5">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <div class="font-weight-bold">{{ 'APP.MAIN.SETTINGS.AZURE_AD.SINGLE_SIGN_ON' | translate }}</div>
          <div class="mt-2">{{ 'APP.MAIN.SETTINGS.AZURE_AD.CONFIGURE_SAML' | translate }}</div>
        </div>
        <button *ngIf="!samlConfigured" class="btn btn-info btn-sm ml-3" (click)="addSamlConfiguration()">{{ 'APP.MAIN.SETTINGS.AZURE_AD.ADD_SAML_CONFIGURATION' | translate }}</button>
        <button *ngIf="samlConfigured" class="btn btn-danger btn-sm ml-3" (click)="removeSamlConfiguration()">{{ 'APP.MAIN.SETTINGS.AZURE_AD.REMOVE_SAML_CONFIGURATION' | translate }}</button>
      </div>
      <div *ngIf="samlConfigured" class="row mt-3">
        <div class="col-12">
          <div class="font-weight-bold text-info mb-2">{{ 'APP.MAIN.SETTINGS.AZURE_AD.INFORMATION' | translate }}</div>
        </div>
        <div class="col-12 col-xl-8">
          <form #samlDataFormFixed="ngForm" autocomplete="off">
            <div class="form-row">
              <div class="form-group col-9">
                <label for="entityId">{{ 'APP.MAIN.SETTINGS.AZURE_AD.IDENTIFIER' | translate }}</label>
                <input class="form-control" type="text" name="entityId" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.IDENTIFIER' | translate }}" [value]="azureAdConfig.samlEntityId" [disabled]="true">
              </div>
              <div class="form-group col-3">
                <label style="display: block; opacity: 0;">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                <button #copyEntityIdFixed class="copy-button btn btn-sm border w-100" [copy-clipboard]="azureAdConfig.samlEntityId" [select-text]="copyEntityIdFixed" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-9">
                <label for="replyUrl">{{ 'APP.MAIN.SETTINGS.AZURE_AD.REPLY_URL' | translate }}</label>
                <input class="form-control" type="text" name="replyUrl" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.REPLY_URL' | translate }}" [value]="azureAdConfig.samlReplyUrl" [disabled]="true">
              </div>
              <div class="form-group col-3">
                <label style="display: block; opacity: 0;">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                <button #copyReplyUrlFixed class="copy-button btn btn-sm border w-100" [copy-clipboard]="azureAdConfig.samlReplyUrl" [select-text]="copyReplyUrlFixed" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-9">
                <label for="replyUrl2">{{ 'APP.MAIN.SETTINGS.AZURE_AD.REPLY_URL2' | translate }}</label>
                <input class="form-control" type="text" name="replyUrl2" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.REPLY_URL2' | translate }}" [value]="azureAdConfig.samlReplyUrl2" [disabled]="true">
              </div>
              <div class="form-group col-3">
                <label style="display: block; opacity: 0;">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                <button #copyReplyUrl2Fixed class="copy-button btn btn-sm border w-100" [copy-clipboard]="azureAdConfig.samlReplyUrl2" [select-text]="copyReplyUrl2Fixed" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-9">
                <label for="replyUrl3">{{ 'APP.MAIN.SETTINGS.AZURE_AD.REPLY_URL3' | translate }}</label>
                <input class="form-control" type="text" name="replyUrl3" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.REPLY_URL3' | translate }}" [value]="azureAdConfig.samlReplyUrl3" [disabled]="true">
              </div>
              <div class="form-group col-3">
                <label style="display: block; opacity: 0;">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                <button #copyReplyUrl3Fixed class="copy-button btn btn-sm border w-100" [copy-clipboard]="azureAdConfig.samlReplyUrl3" [select-text]="copyReplyUrl3Fixed" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <hr>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <div class="font-weight-bold">{{ 'APP.MAIN.SETTINGS.AZURE_AD.USER_PROVISIONING' | translate }}</div>
          <div class="mt-2">{{ 'APP.MAIN.SETTINGS.AZURE_AD.SCIM_USER_PROVISIONING' | translate }}</div>
        </div>
        <button *ngIf="!scimConfigured" class="btn btn-info btn-sm ml-3" (click)="addScimConfiguration()">{{ 'APP.MAIN.SETTINGS.AZURE_AD.ADD_SCIM_CONFIGURATION' | translate }}</button>
        <button *ngIf="scimConfigured" class="btn btn-danger btn-sm ml-3" (click)="removeScimConfiguration()">{{ 'APP.MAIN.SETTINGS.AZURE_AD.REMOVE_SCIM_CONFIGURATION' | translate }}</button>
      </div>
      <div *ngIf="scimConfigured" class="row mt-3">
        <div class="col-12">
          <div class="font-weight-bold text-info mb-2">{{ 'APP.MAIN.SETTINGS.AZURE_AD.INFO' | translate }}</div>
        </div>
        <div class="col-12 col-xl-8">
          <form #scimDataFormFixed="ngForm" autocomplete="off">
            <div class="form-row">
              <div class="form-group col-9">
                <label for="scimEndpoint">{{ 'APP.MAIN.SETTINGS.AZURE_AD.ENDPOINT' | translate }}<span class="required-field">*</span></label>
                <input class="form-control" type="text" name="scimEndpoint" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.ENDPOINT' | translate }}" [value]="azureAdConfig.scimEndpoint" [disabled]="true">
              </div>
              <div class="form-group col-3">
                <label style="display: block; opacity: 0;">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                <button #copyScimEndpointFixed class="copy-button btn btn-sm border w-100" [copy-clipboard]="azureAdConfig.scimEndpoint" [select-text]="copyScimEndpointFixed" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-4" *ngIf="oidcAvailable">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.OAUTH_OIDC_TITLE' | translate }}</span>
    </div>
    <div class="card-body py-4 px-4 px-md-5">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <div class="font-weight-bold">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.OAUTH_OIDC' | translate }}</div>
          <div class="mt-2">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.CONFIGURE_OAUTH_OIDC' | translate }}</div>
        </div>
        <button *ngIf="!oidcConfigured" class="btn btn-info btn-sm ml-3" (click)="addOIDCConfiguration()">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.ADD_OAUTH_OIDC_CONFIGURATION' | translate }}</button>
        <button *ngIf="oidcConfigured" class="btn btn-danger btn-sm ml-3" (click)="removeOIDCConfiguration()">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.REMOVE_OAUTH_OIDC_CONFIGURATION' | translate }}</button>
      </div>
      <div *ngIf="oidcConfigured" class="row mt-3">
        <div class="col-12">
          <div class="font-weight-bold text-info mb-2">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.INFORMATION' | translate }}</div>
        </div>
        <div class="col-12 col-xl-8">
          <form #oidcDataFormFixed="ngForm" autocomplete="off">
            <div class="form-row">
              <div class="form-group col-9">
                <label for="initiateLoginUrl">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.INITIATE_LOGIN_URL' | translate }}</label>
                <input class="form-control" type="text" name="initiateLoginUrl" placeholder="{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.INITIATE_LOGIN_URL' | translate }}" [value]="oauthOIDCConfig.initiateLoginUrl" [disabled]="true">
              </div>
              <div class="form-group col-3">
                <label style="display: block; opacity: 0;">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</label>
                <button #initiateLoginUrlFixed class="copy-button btn btn-sm border w-100" [copy-clipboard]="oauthOIDCConfig.initiateLoginUrl" [select-text]="initiateLoginUrlFixed" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</button>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-9">
                <label for="redirectUrl">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.REDIRECT_URL' | translate }}</label>
                <input class="form-control" type="text" name="redirectUrl" placeholder="{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.REDIRECT_URL' | translate }}" [value]="oauthOIDCConfig.redirectUrl" [disabled]="true">
              </div>
              <div class="form-group col-3">
                <label style="display: block; opacity: 0;">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</label>
                <button #redirectUrlFixed class="copy-button btn btn-sm border w-100" [copy-clipboard]="oauthOIDCConfig.redirectUrl" [select-text]="redirectUrlFixed" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-4" *ngIf="webhooksAvailable">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.TITLE' | translate }}</span>
      <button class="btn btn-info" style="font-size: 14px;" (click)="onAddNewWebhook()">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.NEW_WEBHOOK' | translate }}</button>
    </div>
    <div class="card-body py-4 px-4 px-md-5">
      <form #webhooksForm="ngForm" autocomplete="off">
        <div class="form-row px-4">
          <div *ngIf="webhooks.length === 0">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.NO_WEBHOOK' | translate }}</div>
          <div class="form-group d-flex align-items-center w-100 pl-0" *ngIf="webhooks.length > 0">
            <div class="font-weight-bold mr-2" style="width: 150px;">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.EVENT' | translate }}</div>
            <div class="font-weight-bold" style="flex-grow: 1;">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.URL' | translate }}</div>
          </div>
          <div *ngFor="let webhook of webhooks" class="form-group d-flex align-items-center mt-2 w-100 pl-0">
            <input class="form-control mr-2" style="width: 150px;" [value]="webhook.event" [disabled]="true" type="text">
            <input class="form-control" style="flex-grow: 1;" [value]="webhook.url" [disabled]="true" type="text">
            <button class="btn btn-danger btn-sm h-100 ml-2" (click)="onRemoveWebhook(webhook)">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.REMOVE' | translate }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="card mt-4">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.SYS_REQ.TITLE' | translate }}</span>
    </div>
    <div class="card-body py-4 px-4">
      <app-system-requirements></app-system-requirements>
    </div>
  </div>

  <!-- card -->

<!--
  <div class="voice-commands card mt-4">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span class="card-header-title">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.TITLE' | translate }}</span>
      <button class="btn btn-outline-success" (click)="onNewVoiceCommand()">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.NEW_COMMAND' | translate }}</button>
    </div>
    <div class="card-body p-0">
      <div class="text-center py-3 bg-white" *ngIf="!voiceCommands">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.LOADING' | translate }}</div>
      <div class="text-center py-3 bg-white" *ngIf="voiceCommands && voiceCommands.length === 0">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.NO_COMMAND' | translate }}</div>
      <div *ngIf="voiceCommands && voiceCommands.length > 0" cdkDropList #voiceCommandList="cdkDropList" [cdkDropListData]="voiceCommands" (cdkDropListDropped)="onCommandDrop($event)" class="t-container container-fluid">
        <div class="t-head t-draggable row sm-hide">
          <div class="t-cell col-6 col-lg-4">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.COMMAND' | translate }}</div>
          <div class="t-cell col-6 col-lg-4">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.TRANSLATION' | translate }}</div>
          <div class="t-cell col-6 col-lg-2">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.LANGUAGE' | translate }}</div>
          <div class="t-cell col-6 col-lg-2"></div>
        </div>
        <div class="t-row t-draggable row bg-white" *ngFor="let command of voiceCommands; index as i;" cdkDrag>
          <div class="drag-handle" cdkDragHandle><i class="mi mi-drag-handle"></i></div>
          <div class="t-cell sm-show col-6 font-weight-bold">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.COMMAND' | translate }}</div>
          <div class="t-cell col-6 col-lg-4">{{ command.original.text }}</div>
          <div class="t-cell sm-show col-6 font-weight-bold">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.TRANSLATION' | translate }}</div>
          <div class="t-cell col-6 col-lg-4">{{ command.translation.text }}</div>
          <div class="t-cell sm-show col-6 font-weight-bold">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.LANGUAGE' | translate }}</div>
          <div class="t-cell col-6 col-lg-2">{{ command.translation.language }}</div>
          <div class="t-cell sm-show col-6"></div>
          <div class="t-cell col-6 col-lg-2">BUTTONS</div>
        </div>
      </div>
      <div *ngIf="!allowVoiceCommands" class="disabled-overlay" [dynamicTooltip]="'APP.MAIN.SETTINGS.VOICE_COMMANDS.UNAVAILABLE'" [tooltipDisabled]="allowVoiceCommands" [tooltipDelay]="200"></div>
    </div>
  </div>
-->
</div>
<!--
<ng-template #addCommandTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.ADD_MODAL.TITLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body">
            
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.close()">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.ADD_MODAL.CLOSE' | translate }}</a>
            <a class="text-success" (click)="callbacks.add()">{{ 'APP.MAIN.SETTINGS.VOICE_COMMANDS.ADD_MODAL.ADD' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
-->

<ng-template #configureSamlTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.AZURE_AD.ADD_SAML' | translate }}</div>
        <div class="card-body">
          <div class="m-body pt-4 px-5">

            <div class="font-weight-bold text-info mb-2">{{ 'APP.MAIN.SETTINGS.AZURE_AD.ADD_SAML' | translate }}</div>
            <form #samlDataForm="ngForm" autocomplete="off">
              <div class="form-row">
                <div class="form-group col-10">
                  <label for="entityId">{{ 'APP.MAIN.SETTINGS.AZURE_AD.IDENTIFIER' | translate }}</label>
                  <input class="form-control" type="text" name="entityId" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.IDENTIFIER' | translate }}" [value]="azureAdConfig.samlEntityId" [disabled]="true">
                </div>
                <div class="form-group col-2">
                  <label style="opacity: 0;">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                  <button #copyEntityId class="copy-button btn btn-sm border" [copy-clipboard]="azureAdConfig.samlEntityId" [select-text]="copyEntityId" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-10">
                  <label for="replyUrl">{{ 'APP.MAIN.SETTINGS.AZURE_AD.REPLY_URL' | translate }}</label>
                  <input class="form-control" type="text" name="replyUrl" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.REPLY_URL' | translate }}" [value]="azureAdConfig.samlReplyUrl" [disabled]="true">
                </div>
                <div class="form-group col-2">
                  <label style="opacity: 0;">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                  <button #copyReplyUrl class="copy-button btn btn-sm border" [copy-clipboard]="azureAdConfig.samlReplyUrl" [select-text]="copyReplyUrl" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-10">
                  <label for="replyUrl2">{{ 'APP.MAIN.SETTINGS.AZURE_AD.REPLY_URL2' | translate }}</label>
                  <input class="form-control" type="text" name="replyUrl2" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.REPLY_URL2' | translate }}" [value]="azureAdConfig.samlReplyUrl2" [disabled]="true">
                </div>
                <div class="form-group col-2">
                  <label style="opacity: 0;">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                  <button #copyReplyUrl2 class="copy-button btn btn-sm border" [copy-clipboard]="azureAdConfig.samlReplyUrl2" [select-text]="copyReplyUrl2" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-10">
                  <label for="replyUrl3">{{ 'APP.MAIN.SETTINGS.AZURE_AD.REPLY_URL3' | translate }}</label>
                  <input class="form-control" type="text" name="replyUrl3" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.REPLY_URL3' | translate }}" [value]="azureAdConfig.samlReplyUrl3" [disabled]="true">
                </div>
                <div class="form-group col-2">
                  <label style="opacity: 0;">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                  <button #copyReplyUrl3 class="copy-button btn btn-sm border" [copy-clipboard]="azureAdConfig.samlReplyUrl3" [select-text]="copyReplyUrl3" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                </div>
              </div>
            </form>

            <div class="font-weight-bold text-info mt-3 mb-2">{{ 'APP.MAIN.SETTINGS.AZURE_AD.INFORMATION_VSIGHT' | translate }}</div>
            <form #samlForm="ngForm" autocomplete="off" (ngSubmit)="callbacks.enable(samlForm)">
              <div class="form-group">
                <label for="ssoUrl">{{ 'APP.MAIN.SETTINGS.AZURE_AD.SSO' | translate }}<span class="required-field">*</span></label>
                <input #ssoUrl="ngModel" class="form-control" type="text" name="ssoUrl" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.SSO' | translate }}"
                    [ngClass]="{'is-invalid':ssoUrl.errors && ssoUrl.touched}" [(ngModel)]="dataModel.ssoUrl" required>
                <small class="invalid-feedback mx-2" [hidden]="!ssoUrl.errors?.required">{{ 'APP.MAIN.SETTINGS.AZURE_AD.SSO_URL_REQUIRED' | translate }}</small>
              </div>

              <div class="form-group">
                <label for="cert">{{ 'APP.MAIN.SETTINGS.AZURE_AD.CERTIFICATE' | translate }}<span class="required-field">*</span></label>
                <textarea #cert="ngModel" class="form-control" rows="3" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.CERTIFICATE' | translate }}" [(ngModel)]="dataModel.cert"
                    name="cert" [ngClass]="{'is-invalid':cert.errors && cert.touched}" required></textarea>
                <small class="invalid-feedback mx-2" [hidden]="!cert.errors?.required">{{ 'APP.MAIN.SETTINGS.AZURE_AD.CERTIFICATE_REQUIRED' | translate }}</small>
              </div>
            </form>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.SETTINGS.AZURE_AD.CANCEL' | translate }}</a>
            <a class="text-success" (click)="callbacks.add(samlForm)" [class.disabled]="!samlForm.valid">{{ 'APP.MAIN.SETTINGS.AZURE_AD.ADD' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #configureScimTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.AZURE_AD.ADD_SCIM_CONFIGURATION' | translate }}</div>
        <div class="card-body">
          <div class="m-body pt-4 px-5">

            <div class="font-weight-bold text-info mb-2">{{ 'APP.MAIN.SETTINGS.AZURE_AD.INFORMATION' | translate }}</div>
            <form #scimDataForm="ngForm" autocomplete="off">
              <div class="form-row">
                <div class="form-group col-10">
                  <label for="scimEndpoint">{{ 'APP.MAIN.SETTINGS.AZURE_AD.ENDPOINT' | translate }}<span class="required-field">*</span></label>
                  <input class="form-control" type="text" name="scimEndpoint" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.ENDPOINT' | translate }}" [value]="azureAdConfig.scimEndpoint" [disabled]="true">
                </div>
                <div class="form-group col-2">
                  <label style="opacity: 0;">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</label>
                  <button #copyScimEndpoint class="copy-button btn btn-sm border w-100" [copy-clipboard]="azureAdConfig.scimEndpoint" [select-text]="copyScimEndpoint" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                </div>
              </div>

              <div class="form-row">
                <div class="col-10">
                  <button class="btn btn-info btn-sm" type="button" (click)="callbacks.generateToken()" [disabled]="scimConfigured">{{ 'APP.MAIN.SETTINGS.AZURE_AD.GENERATE_TOKEN_FOR_SCIM' | translate }}</button>
                </div>
                <div class="col-2">
                  <button #copyScimToken [class.disabled]="!dataModel.scimToken" [disabled]="!dataModel.scimToken" class="copy-button btn btn-sm border w-100" [copy-clipboard]="dataModel.scimToken" [select-text]="copyScimToken" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.AZURE_AD.COPY' | translate }}</button>
                </div>
                <div class="form-group col-12 mt-1">
                  <textarea class="form-control" rows="4" placeholder="{{ 'APP.MAIN.SETTINGS.AZURE_AD.GENERATE_TOKEN_FOR_SCIM' | translate }}" name="scimToken" [value]="dataModel.scimToken" [disabled]="true"></textarea>
                </div>
              </div>
            </form>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.close()">{{ scimConfigured ? 'Close' : 'Cancel' }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #configureOIDCTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.ADD_OIDC' | translate }}</div>
        <div class="card-body">
          <div class="m-body pt-4 px-5" style="max-height: 400px; overflow: scroll;">

            <div class="font-weight-bold text-info mb-2">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.ADD_OIDC' | translate }}</div>
            <form #oidcDataForm="ngForm" autocomplete="off">
              <div class="form-row">
                <div class="form-group col-10">
                  <label for="initiateLoginUrl">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.INITIATE_LOGIN_URL' | translate }}</label>
                  <input class="form-control" type="text" name="initiateLoginUrl" placeholder="{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.INITIATE_LOGIN_URL' | translate }}" [value]="oauthOIDCConfig.initiateLoginUrl" [disabled]="true">
                </div>
                <div class="form-group col-2">
                  <label style="opacity: 0;">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</label>
                  <button #copyInitiateLoginUrl class="copy-button btn btn-sm border" [copy-clipboard]="oauthOIDCConfig.initiateLoginUrl" [select-text]="copyInitiateLoginUrl" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</button>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-10">
                  <label for="redirectUrl">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.REDIRECT_URL' | translate }}</label>
                  <input class="form-control" type="text" name="redirectUrl" placeholder="{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.REDIRECT_URL' | translate }}" [value]="oauthOIDCConfig.redirectUrl" [disabled]="true">
                </div>
                <div class="form-group col-2">
                  <label style="opacity: 0;">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</label>
                  <button #copyRedirectUrl class="copy-button btn btn-sm border" [copy-clipboard]="oauthOIDCConfig.redirectUrl" [select-text]="copyRedirectUrl" (copied)="copied($event)">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.COPY' | translate }}</button>
                </div>
              </div>
            </form>

            <div class="font-weight-bold text-info mt-3 mb-2">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.INFORMATION_VSIGHT' | translate }}</div>
            <form #oidcForm="ngForm" autocomplete="off" (ngSubmit)="callbacks.add(oidcForm)">
              <div class="form-group">
                <label for="discoveryEndpoint">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.DISCOVERY_ENDPOINT' | translate }}<span class="required-field">*</span></label>
                <input #discoveryEndpoint="ngModel" class="form-control" type="text" name="discoveryEndpoint" placeholder="{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.DISCOVERY_ENDPOINT' | translate }}"
                    [ngClass]="{'is-invalid':discoveryEndpoint.errors && discoveryEndpoint.touched}" [(ngModel)]="dataModel.discoveryEndpoint" required>
                <small class="invalid-feedback mx-2" [hidden]="!discoveryEndpoint.errors?.required">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.DISCOVERY_ENDPOINT_REQUIRED' | translate }}</small>
              </div>

              <div class="form-group">
                <label for="clientId">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.CLIENT_ID' | translate }}<span class="required-field">*</span></label>
                <input #clientId="ngModel" class="form-control" type="text" name="clientId" placeholder="{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.CLIENT_ID' | translate }}"
                    [ngClass]="{'is-invalid':clientId.errors && clientId.touched}" [(ngModel)]="dataModel.clientId" required>
                <small class="invalid-feedback mx-2" [hidden]="!clientId.errors?.required">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.CLIENT_ID_REQUIRED' | translate }}</small>
              </div>

              <div class="form-group">
                <label for="clientSecret">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.CLIENT_SECRET' | translate }}<span class="required-field">*</span></label>
                <input #clientSecret="ngModel" class="form-control" type="text" name="clientSecret" placeholder="{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.CLIENT_SECRET' | translate }}"
                    [ngClass]="{'is-invalid':clientSecret.errors && clientSecret.touched}" [(ngModel)]="dataModel.clientSecret" required>
                <small class="invalid-feedback mx-2" [hidden]="!clientSecret.errors?.required">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.CLIENT_SECRET_REQUIRED' | translate }}</small>
              </div>

              <div class="form-group">
                <label for="responseType">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.RESPONSE_TYPE' | translate }}</label>
                <select #responseType [(ngModel)]="dataModel.responseType" class="form-control" name="responseType">
                  <option [ngValue]="'code'">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.RESPONSE_TYPE_CODE' | translate }}</option>
                </select>
              </div>

              <div class="form-group">
                <label for="logoutUrl">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.LOGOUT_URL' | translate }}</label>
                <input #logoutUrl="ngModel" class="form-control" type="text" name="logoutUrl" placeholder="{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.LOGOUT_URL' | translate }}"
                    [ngClass]="{'is-invalid':logoutUrl.errors && logoutUrl.touched}" [(ngModel)]="dataModel.logoutUrl">
              </div>

            </form>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.CANCEL' | translate }}</a>
            <a class="text-success" (click)="callbacks.add(oidcForm)" [class.disabled]="!oidcForm.valid">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.ADD' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #removeSamlTemplate let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.AZURE_AD.REMOVE_SAML_CONFIGURATION' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            {{ 'APP.MAIN.SETTINGS.AZURE_AD.REMOVE_SAML_CONFIGURATION_QUESTION' | translate }}
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.SETTINGS.AZURE_AD.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.remove()">{{ 'APP.MAIN.SETTINGS.AZURE_AD.REMOVE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #removeScimTemplate let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.AZURE_AD.ADD_SCIM_CONFIGURATION' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            {{ 'APP.MAIN.SETTINGS.AZURE_AD.REMOVE_SCIM_CONFIGURATION_QUESTION' | translate }}
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.SETTINGS.AZURE_AD.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.remove()">{{ 'APP.MAIN.SETTINGS.AZURE_AD.REMOVE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #removeOIDCTemplate let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.REMOVE_OIDC_CONFIGURATION' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            {{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.REMOVE_OIDC_CONFIGURATION_QUESTION' | translate }}
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.remove()">{{ 'APP.MAIN.SETTINGS.OAUTH_OIDC.REMOVE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #alwaysArchiveTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ (dataModel.alwaysArchive ? 'APP.MAIN.SETTINGS.ALWAYS_ARCHIVE.AUTO_RECORD' : 'APP.MAIN.SETTINGS.ALWAYS_ARCHIVE.CHANGE_INDIVIDUALLY') | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <p class="text-secondary">{{ (dataModel.alwaysArchive ? 'APP.MAIN.SETTINGS.ALWAYS_ARCHIVE.AUTO_MESSAGE' : 'APP.MAIN.SETTINGS.ALWAYS_ARCHIVE.INDIVIDUAL_MESSAGE') | translate }}</p>
            <p class="mt-2">{{ 'APP.MAIN.SETTINGS.ALWAYS_ARCHIVE.WARNING' | translate }}</p>
            <p *ngIf="dataModel.p2pCount > 0" class="font-weight-bold text-danger mt-2">{{ 'APP.MAIN.SETTINGS.ALWAYS_ARCHIVE.CAUTION' | translate }}</p>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.SETTINGS.ALWAYS_ARCHIVE.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.change()">{{ (dataModel.alwaysArchive ? 'APP.MAIN.SETTINGS.ALWAYS_ARCHIVE.PROCEED' : 'APP.MAIN.SETTINGS.ALWAYS_ARCHIVE.CHANGE') | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #alwaysFullHdTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ (dataModel.alwaysFullHd ? 'APP.MAIN.SETTINGS.FULL_HD_MODAL.FULL_HD' : 'APP.MAIN.SETTINGS.FULL_HD_MODAL.CHANGE_INDIVIDUALLY') | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <p class="text-secondary">{{ (dataModel.alwaysFullHd ? 'APP.MAIN.SETTINGS.FULL_HD_MODAL.FULL_HD_MESSAGE' : 'APP.MAIN.SETTINGS.FULL_HD_MODAL.INDIVIDUAL_MESSAGE') | translate }}</p>
            <p class="mt-2 text-danger">{{ 'APP.MAIN.SETTINGS.FULL_HD_MODAL.WARNING' | translate }}</p>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.SETTINGS.FULL_HD_MODAL.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.change()">{{ (dataModel.alwaysFullHd ? 'APP.MAIN.SETTINGS.FULL_HD_MODAL.PROCEED' : 'APP.MAIN.SETTINGS.FULL_HD_MODAL.CHANGE') | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #peerToPeerTemplate let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.WHAT_IS_P2P.TITLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body">
            <div class="pt-3 px-3 font-weight-bold">{{ 'APP.MAIN.SETTINGS.WHAT_IS_P2P.BETWEEN_CLIENTS' | translate }}</div>
            <div class="pt-2 px-3 font-weight-bold">{{ 'APP.MAIN.SETTINGS.WHAT_IS_P2P.REDUCED_LATENCY' | translate }}</div>
            <img alt="Peer to Peer" class="w-100" src="assets/img/peertopeer.png">
            <div class="px-3 text-danger font-weight-bold">{{ 'APP.MAIN.SETTINGS.WHAT_IS_P2P.ARCHIVE_UNAVAILABLE' | translate }}</div>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.close()">{{ 'APP.MAIN.SETTINGS.WHAT_IS_P2P.CLOSE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addEmailTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.ADD_EMAIL.TITLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <p class="text-secondary">{{ (dataModel.forAdmin ? 'APP.MAIN.SETTINGS.ADD_EMAIL.ADMIN' : 'APP.MAIN.SETTINGS.ADD_EMAIL.ALL') | translate }}</p>
            <div class="mt-2" *ngIf="dataModel.users">
              <div class="my-2 text-danger">{{ 'APP.MAIN.SETTINGS.ADD_EMAIL.USER_MAILS' | translate }}</div>
              <a (click)="callbacks.profile(user.id)" class="font-weight-bold d-block mt-1" style="cursor: pointer" *ngFor="let user of dataModel.users">{{ user.name }}</a>
            </div>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.close()">{{ 'APP.MAIN.SETTINGS.ADD_EMAIL.CLOSE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #twoFactorAdminTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div *ngIf="dataModel.action === 'enableadmin'" class="card-header py-3">{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_MODAL.ENABLE_ADMIN' | translate }}</div>
        <div *ngIf="dataModel.action === 'disableall'" class="card-header py-3">{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_MODAL.DISABLE_ADMIN' | translate }}</div>
        <div *ngIf="dataModel.action === 'enableothers'" class="card-header py-3">{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_MODAL.ENABLE_ALL' | translate }}</div>
        <div *ngIf="dataModel.action === 'disableexceptadmin'" class="card-header py-3">{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_MODAL.DISABLE_ALL' | translate }}</div>

        <div class="card-body">
          <div class="m-body padded">
            <div [ngClass]="{'d-none': dataModel.codeSended}">
              <p class="mx-auto">{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_MODAL.VERIFICATION_SENDED' | translate }}</p>
            </div>
            <div [ngClass]="{'d-none': !dataModel.codeSended}">
              <form #tfaForm="ngForm" autocomplete="off" (ngSubmit)="callbacks.proceed()">
                  <div class="form-group mx-3">
                    <input #oneTimeCode="ngModel" class="form-control" type="text" name="oneTimeCode" placeholder="{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_MODAL.VERIFICATION_CODE' | translate }}" [(ngModel)]="dataModel.code"
                        [ngClass]="{'is-invalid':oneTimeCode.errors && oneTimeCode.touched}" style="font-size: 14px;" required>
                  </div>
              </form>
            </div>
          </div>
          <div class="m-footer">
            <a class="text-dark" (click)="callbacks.close()">{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_MODAL.CANCEL' | translate }}</a>
            <a *ngIf="!dataModel.codeSended" class="text-info" (click)="callbacks.send()">{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_MODAL.SEND_CODE' | translate }}</a>
            <a *ngIf="dataModel.codeSended" class="text-info" (click)="tfaForm.valid ? callbacks.proceed() : null" [class.disabled]="!tfaForm.valid">{{ 'APP.MAIN.SETTINGS.TWO_FACTOR_MODAL.PROCEED' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #enableSystemEmailTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.CONFIGURE_CUSTOM_SERVICE_EMAIL' | translate }}</div>
        <div class="card-body">
          <div class="m-body pt-4 px-5">
            <form #customEmailForm="ngForm" class="system-email-form" autocomplete="off" (ngSubmit)="callbacks.enable(customEmailForm)">

              <div class="form-group">
                <label for="senderName">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_NAME' | translate }}<span class="required-field">*</span></label>
                <input #senderName="ngModel" class="form-control" type="text" name="senderName" placeholder="e.g., VSight no-reply"
                    [ngClass]="{'is-invalid':senderName.errors && senderName.touched}" [(ngModel)]="dataModel.name" required>
                <small class="invalid-feedback mx-2" [hidden]="!senderName.errors?.required">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_NAME_IS_REQUIRED' | translate }}</small>
              </div>

              <div class="form-group">
                <label for="senderHost">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_HOST' | translate }}<span class="required-field">*</span></label>
                <input #senderHost="ngModel" class="form-control" type="text" name="senderHost" placeholder="e.g., smtp.gmail.com"
                    [ngClass]="{'is-invalid':senderHost.errors && senderHost.touched}" [(ngModel)]="dataModel.host" required>
                <small class="invalid-feedback mx-2" [hidden]="!senderHost.errors?.required">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_HOST_IS_REQUIRED' | translate }}</small>
              </div>

              <div class="form-group">
                <label for="senderPort">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_PORT' | translate }}<span class="required-field">*</span></label>
                <select #senderPort [(ngModel)]="dataModel.port" class="form-control" name="senderPort">
                  <option [ngValue]="25">25</option>
                  <option [ngValue]="465">465</option>
                  <option [ngValue]="587">587</option>
                  <option [ngValue]="2587">2587</option>
                </select>
              </div>

              <div class="form-group">
                <label for="senderEmail">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_EMAIL' | translate }}<span class="required-field">*</span></label>
                <input #senderEmail="ngModel" class="form-control" type="text" name="senderEmail" placeholder="e.g., service@vsight.io" [(ngModel)]="dataModel.email"
                    [ngClass]="{'is-invalid': senderEmail.errors && senderEmail.touched }" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$" required>
                <small class="invalid-feedback mx-2" [hidden]="!senderEmail.errors?.required">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SENDER_EMAIL_IS_REQUIRED' | translate }}</small>
                <small class="invalid-feedback mx-2" [hidden]="!senderEmail.errors?.pattern">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.EMAIL_FORMAT_IS_INVALID' | translate }}</small>
              </div>

              <div class="form-group">
                <label for="senderUser">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.USER' | translate }}<span class="required-field">*</span></label>
                <input #senderUser="ngModel" class="form-control" type="text" name="senderUser" placeholder="e.g., service@vsight.io" [(ngModel)]="dataModel.user"
                    [ngClass]="{'is-invalid': senderUser.errors && senderUser.touched }" required>
                <small class="invalid-feedback mx-2" [hidden]="!senderUser.errors?.required">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.USER_IS_REQUIRED' | translate }}</small>
              </div>

              <div class="form-group">
                <label for="pass">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.PASSWORD' | translate }}<span class="required-field">*</span></label>
                <input #pass="ngModel" class="form-control" type="password" name="pass" placeholder="{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.PASSWORD' | translate }}"
                    [(ngModel)]="dataModel.pass" [ngClass]="{'is-invalid': pass.errors && pass.touched}" required autocomplete="new-password">
                <small class="invalid-feedback mx-2" [hidden]="!pass.errors?.required">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.PASSWORD_IS_REQUIRED' | translate }}</small>
              </div>
            </form>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.CANCEL' | translate }}</a>
            <a class="text-success" (click)="callbacks.enable(customEmailForm)" [class.disabled]="!customEmailForm.valid">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.VERIFY' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #verifySystemEmailTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.VERIFY_CUSTOM_SERVICE_EMAIL' | translate }}</div>

        <div class="card-body">
          <div class="m-body padded">
            <div class="mx-auto">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SEND_VERIFICATION_CODE' | translate }}</div>
            <form #verifyEmailForm="ngForm" autocomplete="off" (ngSubmit)="callbacks.verify(verifyEmailForm)">
              <div class="form-group">
                <input #verificationCode="ngModel" class="form-control" type="text" name="verificationCode" placeholder="{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.SEND_VERIFICATION_CODE' | translate }}"
                    [(ngModel)]="dataModel.code" [ngClass]="{'is-invalid':verificationCode.errors && verificationCode.touched}" required>
              </div>
            </form>
          </div>
          <div class="m-footer">
            <a class="text-dark" (click)="callbacks.close()">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.CANCEL' | translate }}</a>
            <a class="text-info" (click)="verifyEmailForm.valid ? callbacks.verify(verifyEmailForm) : null" [class.disabled]="!verifyEmailForm.valid">{{ 'APP.MAIN.SETTINGS.ENABLE_SYSTEM_EMAIL.VERIFY' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #disableSystemEmailTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.DISABLE_SYSTEM_EMAIL.DISABLE_CUSTOM_SERVICE_EMAIL' | translate }}</div>

        <div class="card-body">
          <div class="m-body padded">
            <div class="mx-auto">{{ 'APP.MAIN.SETTINGS.DISABLE_SYSTEM_EMAIL.SYSTEM_EMAILS_SEND' | translate }}</div>
          </div>
          <div class="m-footer">
            <a class="text-dark" (click)="callbacks.cancel()">{{ 'APP.MAIN.SETTINGS.DISABLE_SYSTEM_EMAIL.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.disable()">{{ 'APP.MAIN.SETTINGS.DISABLE_SYSTEM_EMAIL.DISABLE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #newWebhookTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog modal-dialog-wide" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.TITLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <form #newWebhookForm="ngForm" autocomplete="off">
              <div class="form-group d-flex align-items-center w-100 pl-0 mb-2">
                <div class="font-weight-bold mr-2" style="width: 150px;">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.EVENT' | translate }}</div>
                <div class="font-weight-bold" style="flex-grow: 1;">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.URL' | translate }}</div>
              </div>
              <div class="form-group d-flex align-items-center w-100 pl-0 mb-0">
                <select class="form-control mr-2" style="width: 150px; min-width: 150px;" [(ngModel)]="dataModel.webhook.event" name="webhookEvent">
                  <option [ngValue]="'SessionEnd'">SessionEnd</option>
                </select>
                <input class="form-control" [(ngModel)]="dataModel.webhook.url" style="flex-grow: 1;" type="text" name="webhookUrl" required
                  placeholder="{{ 'APP.MAIN.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.PLACEHOLDER' | translate }}">
              </div>
            </form>
            <div class="mt-4 font-weight-bold">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.WARNING' | translate }}</div>
          </div>
          <div class="m-footer">
            <a class="text-dark" (click)="callbacks.cancel()">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.CANCEL' | translate }}</a>
            <a class="text-info" (click)="callbacks.add()" [class.disabled]="!newWebhookForm.valid">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.ADD_WEBHOOK_MODAL.ADD' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #removeWebhookTemplate let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.TITLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <div>{{ 'APP.MAIN.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.MESSAGE' | translate }}</div>
          </div>
          <div class="m-footer">
            <a class="text-dark" (click)="callbacks.cancel()">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.remove()">{{ 'APP.MAIN.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.REMOVE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>