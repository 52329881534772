<div class="room-details-container px-md-3 px-lg-5">
  <div class="container-fluid">
    <div class="card">
      <a class="back-link" (click)="onBack()">
        <i class="mi mi-arrow-back"></i><span>{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.BACK' | translate }}</span>
      </a>
      <div class="card-header d-flex justify-content-between align-items-center">
        <span class="card-header-title">{{ actionTitle }}</span>
        <span *ngIf="room.room_data.disabled" class="card-header-title text-danger">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ROOM_DISABLED' | translate }}</span>
        <button *ngIf="action === ACTION_DETAILS && !formLoading" class="btn btn-info" (click)="onEdit()" [disabled]="room.room_data.disabled">
          {{ 'APP.MAIN.ROOMS.ROOM_DETAILS.EDIT' | translate }}
        </button>
        <button *ngIf="action === ACTION_NEW && !formLoading" class="btn btn-danger"
              (click)="resetForm()" [disabled]="formPristine || roomForm.control.disabled">
          {{ 'APP.MAIN.ROOMS.ROOM_DETAILS.RESET' | translate }}
        </button>
        <ng-container *ngIf="action === ACTION_EDIT && !formLoading">
            <button *ngIf="!formPristine" class="btn btn-danger" (click)="resetForm()" [disabled]="roomForm.control.disabled">
              {{ 'APP.MAIN.ROOMS.ROOM_DETAILS.REVERT' | translate }}
            </button>
          <button *ngIf="formPristine" class="btn btn-secondary" (click)="cancelEdit()">
            {{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CANCEL' | translate }}
          </button>
        </ng-container>
      </div>
      <div class="card-body px-lg-5">

        <form #roomForm="ngForm" (ngSubmit)="onSubmit(roomForm)">
          <div class="form-row">

            <div class="form-group col-md-4">
              <label for="iconName">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ICON' | translate }}</label>

              <div class="icon-view d-flex">
                <img class="icon-img mr-2" [src]="room.room_data.icon">

                <input class="icon-name form-control" #iconName type="text" name="iconName" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHOOSE_FILE' | translate }}" (click)="iconFile.click()">
                <input class="icon-file d-none" #iconFile type="file" name="iconFile" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ICON' | translate }}" accept="image/png,image/jpeg" (change)="onIconChange($event)" hidden>
                <input class="icon-content d-none" [(ngModel)]="room.room_data.icon" #icon="ngModel" type="text" name="icon" hidden>
                <input class="d-none" [(ngModel)]="room.room_data.icon_default" type="checkbox" #icon_default="ngModel" name="icon_default" hidden>
              </div>
            </div>

            <div class="form-group col-md-8">
              <label for="name">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ROOM_NAME' | translate }}</label>
              <input #name="ngModel" class="form-control" type="text" name="name" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ROOM_NAME' | translate }}" 
                  [ngClass]="{'is-invalid':name.errors && name.touched}"
                  [(ngModel)]="room.room_data.name" minlength="1" maxlength="60" required>
              <small class="invalid-feedback mx-2" [hidden]="!name.errors?.required">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ROOM_NAME_REQUIRED' | translate }}</small>
              <small class="invalid-feedback mx-2" [hidden]="!name.errors?.minlength">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ROOM_NAME_LENGTH' | translate }}</small>
            </div>
          </div>

          <div class="form-group">
            <label for="description">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.DESCRIPTION' | translate }}</label>
            <input #description="ngModel" class="form-control" type="text" name="description" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.DESCRIPTION' | translate }}" 
                  [ngClass]="{'is-invalid':description.errors && description.touched}"
                  [(ngModel)]="room.room_data.description" minlength="1" maxlength="100" required>
            <small class="invalid-feedback mx-2" [hidden]="!description.errors?.required">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.DESCRIPTION_REQUIRED' | translate }}</small>
            <small class="invalid-feedback mx-2" [hidden]="!description.errors?.minlength">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.DESCRIPTION_LENGTH' | translate }}</small>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center" [class.disabled]="!accountArchiveAllowed">
                <input #auto_archive="ngModel" type="checkbox" class="custom-control-input" id="auto_archive" name="auto_archive" [(ngModel)]="room.room_data.auto_archive"
                [disabled]="!accountArchiveAllowed || accountAlwaysArchive || (peerToPeerAllowed && room.room_data.peer_to_peer)">
                <label class="custom-control-label" for="auto_archive">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.AUTO_ARCHIVE' | translate }}</label>
              </div>
              <ng-container *ngIf="accountArchiveAllowed">
                <label *ngIf="!(peerToPeerAllowed && room.room_data.peer_to_peer) && accountAlwaysArchive" class="text-danger" for="auto_archive">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ALWAYS_ARCHIVE_ON' | translate }}</label>
                <label *ngIf="(peerToPeerAllowed && room.room_data.peer_to_peer)" class="text-danger" for="auto_archive">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.PEER_TO_PEER_NO_ARCHIVE' | translate }}</label>
              </ng-container>
              <label *ngIf="!accountArchiveAllowed" class="text-danger" for="auto_archive">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ARCHIVE_UNAVAILABLE' | translate }}</label>
            </div>

            <div class="form-group col-12" *ngIf="('design' | env).showArchivePermission">
              <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center" [class.disabled]="!accountArchiveAllowed">
                <input #archive_perm_needed="ngModel" type="checkbox" class="custom-control-input" id="archive_perm_needed" name="archive_perm_needed" [(ngModel)]="room.room_data.archive_perm_needed"
                [disabled]="!accountArchiveAllowed || (peerToPeerAllowed && room.room_data.peer_to_peer)">
                <label class="custom-control-label" for="archive_perm_needed">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ARCHIVE_PERMISSION_NEEDED' | translate }}</label>
              </div>
              <label *ngIf="peerToPeerAllowed && room.room_data.peer_to_peer" class="text-danger" for="archive_perm_needed">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.PEER_TO_PEER_NO_ARCHIVE' | translate }}</label>
              <label *ngIf="!accountArchiveAllowed" class="text-danger" for="archive_perm_needed">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ARCHIVE_UNAVAILABLE' | translate }}</label>
            </div>

            <div class="form-group col-12">
              <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
                <input #peer_to_peer="ngModel" type="checkbox" class="custom-control-input" id="peer_to_peer" name="peer_to_peer" [(ngModel)]="room.room_data.peer_to_peer"
                  (change)="onPeerToPeerChange($event)" [disabled]="!peerToPeerAllowed || (trainingRoomAllowed && room.room_data.training_room)">
                <label class="custom-control-label" for="peer_to_peer">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.PEER_TO_PEER' | translate }}</label>
                <a class="peer-to-peer-info text-info ml-2" (click)="onPeerToPeerInfo()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_PEER_TO_PEER' | translate }}</a>
              </div>
              <label *ngIf="(peerToPeerAllowed && room.room_data.training_room)" class="text-danger" for="peer_to_peer">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TRAINING_ROOM_NO_PEER_TO_PEER' | translate }}</label>
              <label *ngIf="!peerToPeerAllowed" class="text-danger" for="peer_to_peer">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.PEER_TO_PEER_UNAVAILABLE' | translate }}</label>
            </div>

            <div class="form-group col-12" *ngIf="accountFullHdAllowed">
              <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
                <input #full_hd="ngModel" type="checkbox" class="custom-control-input" id="full_hd" name="full_hd" [(ngModel)]="room.room_data.full_hd" [disabled]="accountAlwaysFullHd">
                <label class="custom-control-label" for="full_hd">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.FULL_HD' | translate }}</label>
              </div>
              <label *ngIf="accountAlwaysFullHd" class="text-danger" for="full_hd">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ALWAYS_FULL_HD_ON' | translate }}</label>
              <label *ngIf="room.room_data.full_hd && !accountAlwaysFullHd" class="text-info" for="full_hd">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.FULL_HD_DISADVANTAGES' | translate }}</label>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
                <input #training_room="ngModel" type="checkbox" class="custom-control-input" id="training_room" name="training_room" [(ngModel)]="room.room_data.training_room"
                  (change)="onTrainingRoomChange($event)" [disabled]="!trainingRoomAllowed">
                <label class="custom-control-label" for="training_room">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TRAINING_ROOM' | translate }}</label>
              </div>
              <label *ngIf="!trainingRoomAllowed" class="text-danger" for="training_room">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TRAINING_ROOM_UNAVAILABLE' | translate }}</label>
            </div>

            <div class="form-group col-md-12" *ngIf="room.room_data.training_room">
              <label for="training_license">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TRAINING_LICENSE' | translate }}</label>
              <select #training_license [(ngModel)]="room.room_data.training_license" class="form-control" name="training_license" required>
                <option *ngFor="let tLicense of trainingLicenses" [ngValue]="tLicense.id">{{ tLicense.name }}</option>
              </select>
              <label *ngIf="!room.room_data.training_license" class="text-danger" for="training_license">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TRAINING_LICENSE_REQUIRED' | translate }}</label>
            </div>

            <div class="form-group col-md-12">
              <label for="users">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.USERS' | translate }}</label>
              <ng-select [items]="users" name="users" bindLabel="name2" bindValue="id" [multiple]="true" [clearSearchOnAdd] = "true" (change)="roomUserListChanged($event)"
                placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.SELECT_USERS' | translate }}" [(ngModel)]="room.room_data.users" [closeOnSelect]="false" [loading]="usersLoading">
              </ng-select>
            </div>

            <div class="form-group col-md-12" *ngIf="room.room_data.training_room">
              <label for="training_master">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TRAINING_MASTER' | translate }}</label>
              <select #training_master [(ngModel)]="room.room_data.training_master" class="form-control" name="training_master" required>
                <option *ngFor="let user of room.room_data.users" [ngValue]="user">{{ user | userName | async }}</option>
              </select>
              <label *ngIf="!room.room_data.training_master" class="text-danger" for="training_master">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TRAINING_MASTER_REQUIRED' | translate }}</label>
            </div>
          </div>
          <button class="btn btn-info btn-md-inline btn-block" type="submit" [disabled]="!roomForm.valid || formPristine || formLoading">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.SAVE' | translate }}</button>
        </form>

      </div>
    </div>

    <div class="mt-3" *ngIf="action === ACTION_DETAILS && !room.room_data.disabled">
      <app-session-list [type]="'room'" [roomName]="orjRoom?.room_data.name" [roomId]="roomId"></app-session-list>
    </div>

    <ng-container *ngIf="action === ACTION_EDIT">
      <div class="mx-4 mx-lg-5">
        <button  class="btn btn-outline-secondary btn-md-inline btn-block mt-3" (click)="onToggle()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.DISABLE_ROOM' | translate }}</button>
      </div>
      <div class="mx-4 mx-lg-5">
        <button  class="btn btn-outline-danger btn-md-inline btn-block mt-3" (click)="onDelete()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM' | translate }}</button>
      </div>
    </ng-container>

    <ng-container *ngIf="action === ACTION_DETAILS && room.room_data.disabled">
      <div class="mx-4 mx-lg-5">
        <button  class="btn btn-primary btn-md-inline btn-block mt-3" (click)="onToggle()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ENABLE_ROOM' | translate }}</button>
      </div>
      <div class="mx-4 mx-lg-5">
        <button  class="btn btn-outline-danger btn-md-inline btn-block mt-3" (click)="onDelete()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM' | translate }}</button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #archiveChangeTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.TITLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <div *ngIf="dataModel.p2p_changed || dataModel.auto_archive_changed">
              <span>{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.MESSAGE_START' | translate }}</span>
              
              <strong *ngIf="dataModel.auto_archive_changed">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.AUTO_ARCHIVE' | translate }}</strong>
              <span *ngIf="dataModel.auto_archive_changed && dataModel.p2p_changed">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.AND' | translate }}</span>
              
              <strong *ngIf="dataModel.p2p_changed">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.PEER_TO_PEER' | translate }}</strong>
              <span >{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.MESSAGE_END' | translate }}</span>
            </div>
            <div class="font-weight-bold text-danger mt-2" *ngIf="dataModel.p2p_on">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.CAUTION' | translate:dataModel }}</div>
            <div class="font-weight-bold text-danger mt-2" *ngIf="dataModel.training_room_changed">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.TRAINING_CAUTION' | translate }}</div>
          </div>
          <div class="m-footer">
            <a class="text-secondary" (click)="callbacks.no()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.CANCEL' | translate }}</a>
            <a class="text-info" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHANGE_AUTO_ARCHIVE_MODAL.SAVE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #toggleTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div *ngIf="dataModel.disabled" class="card-header py-3">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.TITLE_ENABLE' | translate }}</div>
        <div *ngIf="!dataModel.disabled" class="card-header py-3">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.TITLE_DISABLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <div *ngIf="dataModel.disabled" [innerHTML]="'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.MESSAGE_ENABLE' | translate:dataModel"></div>
            <div *ngIf="!dataModel.disabled" [innerHTML]="'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.MESSAGE_DISABLE' | translate:dataModel"></div>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.no()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.CANCEL' | translate }}</a>
            <a *ngIf="dataModel.disabled" class="text-primary" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.ENABLE' | translate }}</a>
            <a *ngIf="!dataModel.disabled" class="text-secondary" (click)="callbacks.yes()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.DISABLE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #delRoomTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3">
                    {{ 'APP.MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM_MODAL.TITLE' | translate }}
                </div>
                <div class="card-body">
                    <div class="m-body padded">
                      <div [innerHTML]="'APP.MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM_MODAL.MESSAGE' | translate:dataModel.room_data"></div>
                    </div>
                    <div class="m-footer">
                        <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM_MODAL.CANCEL' | translate }}</a>
                        <a class="text-danger" (click)="callbacks.delete()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM_MODAL.DELETE' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #peerToPeerTemplate let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_P2P.TITLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body">
            <div class="pt-3 px-3 font-weight-bold">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_P2P.BETWEEN_CLIENTS' | translate }}</div>
            <div class="pt-2 px-3 font-weight-bold">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_P2P.REDUCED_LATENCY' | translate }}</div>
            <img alt="Peer to Peer" class="w-100" src="assets/img/peertopeer.png">
            <div class="px-3 text-danger font-weight-bold">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_P2P.ARCHIVE_UNAVAILABLE' | translate }}</div>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.close()">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.WHAT_IS_P2P.CLOSE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>