import { Component, OnInit, Input } from '@angular/core';
import { SessionService } from '@services/session.service';
import { Subscription } from 'rxjs';
import { Session } from '@models/Session';
import { User } from '@models/User';
import { UserService } from '@services/user.service';
import { DetailPageService } from '@services/support/detail-page.service';
import { SessionDetailsComponent } from '../session-details/session-details.component';
import { AccountService } from '@services/account.service';

@Component({
  selector: 'app-session-list',
  templateUrl: './session-list.component.html',
  styleUrls: ['./session-list.component.scss']
})
export class SessionListLastComponent implements OnInit {

  static TYPE_LAST_SESSIONS: string = "last";
  static TYPE_ROOM_SESSIONS: string = "room";
  /**** Access in template ****/
  get TYPE_LAST_SESSIONS() { return SessionListLastComponent.TYPE_LAST_SESSIONS; }
  get TYPE_ROOM_SESSIONS() { return SessionListLastComponent.TYPE_ROOM_SESSIONS; }

  @Input() type: string = this.TYPE_LAST_SESSIONS;
  @Input() roomId: string;
  @Input() roomName: string;

  sessions: Session[] = null;
  sessionSub: Subscription = null;

  users: User[] = [];
  userSub: Subscription = null;

  accountDataSub: Subscription = null;
  timezone: string = "UTC";

  constructor(
    private accountService: AccountService,
    private userService: UserService,
    private sessionService: SessionService,
    private detailPageService: DetailPageService
  ) { }

  ngOnInit() {
    this.accountDataSub = this.accountService.accountData.subscribe(accountData => {
      this.timezone = accountData.timezone;
    });

    this.userSub = this.userService.allUsers.subscribe(u => {
      this.users = u;
    });

    if (this.type === this.TYPE_LAST_SESSIONS) {
      this.sessionSub = this.sessionService.lastSessions.subscribe(s => {
        this.sessions = s;
      });
    } else {
      this.sessionSub = this.sessionService.getRoomSessions(this.roomId).subscribe(s => {
        this.sessions = s;
      });
    }
  }

  ngOnDestroy() {
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
    if (this.userSub) { this.userSub.unsubscribe() }
    if (this.sessionSub) { this.sessionSub.unsubscribe() }
  }

  onSessionDetails(session: Session) {
    const detail = this.detailPageService.loadComponent2(SessionDetailsComponent).instance;
    detail.session = session;
  }

  getUserNames(userList: any) {
    return (userList ? Object.keys(userList).map(userId => {
      const u = this.users.find(u => u.id === userId);
      return u ? u.name : null;
    }) : [])
    .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
  }
}
