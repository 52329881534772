import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import { first, map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  sessionExportRequest: Subject<[string, string, boolean, number?]> = new Subject<[string, string, boolean, number?]>();

  constructor(
    private authService: AuthService,
    private afdb: AngularFireDatabase,
    private http: HttpClient
  ) { }

  getTicketList() {
    return this.afdb.list<any>(`accounts/${this.authService.currentUser.account_id}/ticket_system/tickets`).snapshotChanges(["child_added", "child_changed", "child_removed"]).pipe(
      map(tSnaps => tSnaps.map(snap => {
        const t = snap.payload.val();
        t.id = snap.key;
        return t;
      }))
    );
  }

  getRoomTicketList(roomId: string) {
    return this.afdb.list<any>(`accounts/${this.authService.currentUser.account_id}/ticket_system/tickets`, q => q.orderByChild('room_id').equalTo(roomId)).snapshotChanges(["child_added", "child_changed", "child_removed"]).pipe(
      map(tSnaps => tSnaps.map(snap => {
        const t = snap.payload.val();
        t.id = snap.key;
        return t;
      }))
    );
  }

  getTicketData(ticketId: string) {
    return this.afdb.object<any>(`accounts/${this.authService.currentUser.account_id}/ticket_system/tickets/${ticketId}`).valueChanges();
  }

  getTicketSessions(ticketId: string) {
    return this.afdb.list<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, q => q.orderByChild('attached_ticket').equalTo(ticketId)).snapshotChanges().pipe(
      map(sList => {
        return sList.map(s => {
          const session = s.payload.val();
          session.id = s.key;
          return session;
        });
      })
    );
  }

  createTicket(ticketData: any) {
    return this.http.post<any>(environment.endPoints.createticket, {token: this.authService.currentUser.token, ticket: ticketData}).pipe(first()).toPromise();
  }

  attachTicket(ticketId: string, roomId: string, sessionId: string) {
    return this.http.post<any>(environment.endPoints.attachticket, {token: this.authService.currentUser.token, ticket_id: ticketId, room_id: roomId, session_id: sessionId}).pipe(first()).toPromise();
  }

  updateTicket(changes: any) {
    return this.http.post<any>(environment.endPoints.updateticket, {token: this.authService.currentUser.token, changes: changes}).pipe(first()).toPromise();
  }

  deleteTicket(ticketId: string) {
    return this.http.post<any>(environment.endPoints.deleteticket, {token: this.authService.currentUser.token, ticket: ticketId}).pipe(first()).toPromise();
  }
}
