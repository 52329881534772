import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { ModalService } from '@services/support/modal.service';
import { LoaderService } from '@services/support/loader.service';
import { AccountService } from '@services/account.service';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FlashMessageService } from '@services/support/flash-message.service';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss']
})
export class StorageComponent implements OnInit, OnDestroy {

  @ViewChild("checkAzureTemplate", { static: true }) private checkAzureTemplate: TemplateRef<any>;
  @ViewChild("connectAzureTemplate", { static: true }) private connectAzureTemplate: TemplateRef<any>;
  @ViewChild("disconnectAzureTemplate", { static: true }) private disconnectAzureTemplate: TemplateRef<any>;

  azureInfo: any = null;
  allowArchiving: boolean = false;
  allowAzureIntegration: boolean = false;
  accountDataSub: Subscription = null;

  azureCredentials: any = {
    accountName: "",
    containerName: "",
    accountKey: ""
  }

  sharedAzureProgress = [];

  webdavEnabled: boolean = false;

  constructor(
    private accountService: AccountService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private flashMessageService: FlashMessageService
  ) { }

  ngOnInit() {
    this.accountDataSub = this.accountService.accountData.subscribe(data => {
      this.azureInfo = data.third_parties.azure;
      this.allowArchiving = data.features.archiving;
      this.allowAzureIntegration = data.features.azureintegration;
      this.webdavEnabled = data.add_ons.webdav;

      if ((this.azureInfo.shared_storage.expire > 0) || (this.azureInfo.shared_storage.expire === 0 && this.azureInfo.shared_storage.dedicated === 0)) {
        this.sharedAzureProgress = [
          {
            value: 100,
            type: 'info',
            label: 'unlimited'
          }
        ];
      } else if (this.azureInfo.shared_storage.dedicated > 0) {
        let percent = (this.azureInfo.shared_storage.size / (this.azureInfo.shared_storage.dedicated * 1073741824));
        percent = (percent > 1) ? 1 : percent;
        this.sharedAzureProgress = [
          {
            value: (percent * 100),
            type: percent < 0.9 ? 'info' : 'danger',
            label: (percent * 100).toFixed(0) +'%'
          }
        ];
      }
    });
  }

  ngOnDestroy() {
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
  }

  onCheckAzure(connect: boolean) {
    if (this.allowAzureIntegration) {
      this.loaderService.show();
      this.accountService.checkArchiveStatuses()
      .then(result => {
        if (result.pending) {
          const modalId = this.modalService.show({
            template: this.checkAzureTemplate,
            context: {
              dataModel: { connect: connect },
              callbacks: {
                cancel: () => {
                  this.modalService.hide(modalId);
                },
                continue: () => {
                  this.modalService.hide(modalId);
                  if (connect) {
                    this.onConnectAzure();
                  } else {
                    this.onDisconnectAzure();
                  }
                }
              }
            }
          });
        } else {
          if (connect) {
            this.onConnectAzure();
          } else {
            this.onDisconnectAzure();
          }
        }
      })
      .catch(error => {
        console.log(error);
        this.flashMessageService.show('Cannot connect Azure cloud storage.');
      })
      .finally(() => {
        this.loaderService.hide();
      });
    }
  }

  onConnectAzure() {
    const modalId = this.modalService.show({
      template: this.connectAzureTemplate,
      context: {
        dataModel: this.azureCredentials,
        callbacks: {
          cancel: () => {
            this.azureCredentials = {
              accountName: "",
              containerName: "",
              accountKey: ""
            }
            this.modalService.hide(modalId);
          },
          connect: (azureForm: NgForm) => {
            this.loaderService.show();
            azureForm.control.disable();

            this.accountService.connectAzureAccount(azureForm.value.accountName, azureForm.value.containerName, azureForm.value.accountKey)
            .then(() => {
              this.modalService.hide(modalId);
              this.flashMessageService.show("Azure cloud storage connected.", { cssClass: 'alert-success' });
            })
            .catch(error => {
              azureForm.control.enable();
              this.flashMessageService.show('Cannot connect Azure cloud storage.');
            })
            .finally(() => {
              this.azureCredentials = {
                accountName: "",
                containerName: "",
                accountKey: ""
              }
              this.loaderService.hide();
            });
          }
        }
      }
    });
  }

  onDisconnectAzure() {
    const modalId = this.modalService.show({
      template: this.disconnectAzureTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => {
            this.modalService.hide(modalId);
          },
          disconnect: () => {
            this.loaderService.show();

            this.accountService.disconnectAzureAccount()
            .then(() => this.flashMessageService.show("Azure cloud storage disconnected.", { cssClass: 'alert-success' }))
            .catch(error => this.flashMessageService.show('Cannot disconnect Azure cloud storage.'))
            .finally(() => {
              this.modalService.hide(modalId);
              this.loaderService.hide();
            });
          }
        }
      }
    });
  }
}
