import { Pipe, PipeTransform } from "@angular/core";
import { User } from "@models/User";

@Pipe({name: 'userUsernameRole'})
export class UserUsernameRolePipe implements PipeTransform {

    constructor() { }

    transform(user: User, licenseType: string): string  {
        const username = user.ad_user ? user.user_principal_name : user.auth.username;
        if (user.coadmin) {
            return `${user.name} (${username} / Co-Admin)`;
        }
        switch (user.role) {
            case "admin":
                return `${user.name} (${username} / Admin)`;
            case "coadmin":
                return `${user.name} (${username} / Co-Admin)`;
            case "expert":
                return `${user.name} (${username} / Expert)`;
            default:
                return `${user.name} (${username} / ${licenseType === "concurrent_user_based" ? "User" : "Subscriber"})`;
        }
    }
}