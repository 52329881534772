import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { AccountService } from '@services/account.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { RoomService } from '@services/room.service';
import { Room } from '@models/Room';
import { ModalService } from '@services/support/modal.service';
import { LoaderService } from '@services/support/loader.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { UserService } from '@services/user.service';
import { AuthService } from '@services/auth.service';
import { UserDetailsComponent } from '../users/user-details/user-details.component';
import { DetailPageService } from '@services/support/detail-page.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, NgForm, FormGroup, FormControl, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@models/User';
import { environment } from 'environments/environment';
//import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

//  @ViewChild("addCommandTemplate", { static: true }) private addCommandTemplate: TemplateRef<any>;
  @ViewChild("alwaysArchiveTemplate", { static: true }) private alwaysArchiveTemplate: TemplateRef<any>;
  @ViewChild("alwaysFullHdTemplate", { static: true }) private alwaysFullHdTemplate: TemplateRef<any>;
  @ViewChild("peerToPeerTemplate", { static: true }) private peerToPeerTemplate: TemplateRef<any>;
  @ViewChild("addEmailTemplate", { static: true }) private addEmailTemplate: TemplateRef<any>;
  @ViewChild("twoFactorAdminTemplate", { static: true }) private twoFactorAdminTemplate: TemplateRef<any>;
  @ViewChild("enableSystemEmailTemplate", { static: true }) private enableSystemEmailTemplate: TemplateRef<any>;
  @ViewChild("verifySystemEmailTemplate", { static: true }) private verifySystemEmailTemplate: TemplateRef<any>;
  @ViewChild("disableSystemEmailTemplate", { static: true }) private disableSystemEmailTemplate: TemplateRef<any>;
  @ViewChild("configureSamlTemplate", { static: true }) private configureSamlTemplate: TemplateRef<any>;
  @ViewChild("configureScimTemplate", { static: true }) private configureScimTemplate: TemplateRef<any>;
  @ViewChild("configureOIDCTemplate", { static: true }) private configureOIDCTemplate: TemplateRef<any>;
  @ViewChild("removeSamlTemplate", { static: true }) private removeSamlTemplate: TemplateRef<any>;
  @ViewChild("removeScimTemplate", { static: true }) private removeScimTemplate: TemplateRef<any>;
  @ViewChild("removeOIDCTemplate", { static: true }) private removeOIDCTemplate: TemplateRef<any>;
  @ViewChild("newWebhookTemplate", { static: true }) private newWebhookTemplate: TemplateRef<any>;
  @ViewChild("removeWebhookTemplate", { static: true }) private removeWebhookTemplate: TemplateRef<any>;

  timezones: string[] = ["UTC-12", "UTC-11", "UTC-10", "UTC-9", "UTC-8", "UTC-7", "UTC-6", "UTC-5", "UTC-4", "UTC-3", "UTC-2", "UTC-1",
    "UTC", "UTC+1", "UTC+2", "UTC+3", "UTC+4", "UTC+5", "UTC+6", "UTC+7", "UTC+8", "UTC+9", "UTC+10", "UTC+11", "UTC+12", "UTC+13", "UTC+14"];
  orjTzone: string = "UTC";
  tzone: string = "UTC";

  passwordLengths: number[] = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 24, 28, 32, 64];
  passwordLength: number = 6;
  AZSelected: boolean = false;
  AZClicked: boolean = false;
  azSelected: boolean = false;
  azClicked: boolean = false;
  numberSelected: boolean = false;
  numberClicked: boolean = false;
  specialCharacterSelected: boolean = false;
  specialCharacterClicked: boolean = false;
  oldPasswordLength: number = 6;
  strongEnabled: boolean = false;
  changeDetected: boolean = false;

  alwaysArchive: boolean = false;
  alwaysFullHd: boolean = false;
  allowTwoFactor: boolean = false;
  twoFactorAdmin: boolean = false;
  twoFactorOthers: boolean = false;
  customEmailAvailable: boolean = false;
  customEmailEnabled: boolean = false;
  adIntegrationAvailable: boolean = false;
  oidcAvailable: boolean = false;

  customEmailCredentials = { name: "", host: "", port: 465, user: "", pass: ""/*, test: ""*/ };

  emailRecipients: any[] = [];
  recipientsChanged: boolean = false;
  validMailValidator = Validators.pattern(/^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
  invalidEmailError = {pattern: ""};
  emailPholder = "";
  emailSecPholder = "";

  accountData: any = null;
  accountDataSub: Subscription = null;

  rooms: Room[] = null;
  roomsSub: Subscription = null;

  users: User[] = [];
  usersSub: Subscription = null;

  azureAdConfig;
  oauthOIDCConfig;

  samlConfigured: boolean = false;
  scimConfigured: boolean = false;
  oidcConfigured: boolean = false;

  formPristine: boolean = true;
  formValueSub: Subscription = null;
  contactsForm;
  contactsFormGroupArray: FormGroup[] = [];
  orjContactsFormGroupArray: FormGroup[] = [];
  contactData: any;
  isContactEdit: boolean = false;

  webhooksAvailable: boolean = false;
  webhooks: any[] = []

/*
  // TODO replace when database connected
  voiceCommands: any[] = [
    { original: { text: 'Turn Left', language: 'en' }, translation: { text: 'Sola Dön', language: 'tr', url: 'www.google.com' } },
    { original: { text: 'Turn Right', language: 'en' }, translation: { text: 'Sağa Dön', language: 'tr', url: 'www.google.com' } },
    { original: { text: 'Look Up', language: 'en' }, translation: { text: 'Yukarıya Bak', language: 'tr', url: 'www.google.com' } },
    { original: { text: 'Look Down', language: 'en' }, translation: { text: 'Aşağıya Bak', language: 'tr', url: 'www.google.com' } }
  ];
  allowVoiceCommands: boolean = true;
*/
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private roomService: RoomService,
    private accountService: AccountService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private flashMessageService: FlashMessageService,
    private detailPageService: DetailPageService
  ) { }

  ngOnInit() {
    if (environment.azureAdConfig) {
      const name = environment.azureAdConfig['name'] ? environment.azureAdConfig['name'] : this.authService.currentUser.auth.account_name;

      this.azureAdConfig = {
        accountNameAsName: environment.azureAdConfig['name'] ? false : true,
        name: name,
        samlEntityId: this.renderTemplate(environment.azureAdConfig.samlEntityId, { name: name }),
        samlReplyUrl: this.renderTemplate(environment.azureAdConfig.samlReplyUrl, { name: name }),
        samlReplyUrl2: this.renderTemplate(environment.azureAdConfig.samlReplyUrl2, { name: name }),
        samlReplyUrl3: this.renderTemplate(environment.azureAdConfig.samlReplyUrl3, { name: name }),
        scimEndpoint: this.renderTemplate(environment.azureAdConfig.scimEndpoint, { name: name }),
        msLoginUrl: this.renderTemplate(environment.azureAdConfig.msLoginUrl, { name: name })
      }
    }
    if (environment.oauthOIDCConfig) {
      const name = this.authService.currentUser.auth.account_name;
      this.oauthOIDCConfig = {
        name: name,
        initiateLoginUrl: this.renderTemplate(environment.oauthOIDCConfig.initiateLoginUrl, { name: name }),
        redirectUrl: this.renderTemplate(environment.oauthOIDCConfig.redirectUrl, { name: name })
      }
    }

    this.roomsSub = this.roomService.rooms.subscribe(rooms => {
      this.rooms = rooms.sort((a, b) => a.room_data.name.toLocaleLowerCase().localeCompare(b.room_data.name.toLocaleLowerCase()));
    });
    this.usersSub = this.userService.users.subscribe(users => {
      this.users = users;
    });
    this.accountDataSub = this.accountService.accountData.subscribe(accountData => {
      if (accountData.timezone !== this.orjTzone) {
        this.tzone = accountData.timezone;
      }
      this.orjTzone = accountData.timezone;
      this.twoFactorAdmin = accountData.two_factor_auth && accountData.two_factor_auth.admin;
      this.twoFactorOthers = accountData.two_factor_auth && accountData.two_factor_auth.others;

      this.allowTwoFactor = accountData.features.twofactorauth;
      this.alwaysArchive = accountData.always_archive;
      this.alwaysFullHd = accountData.always_full_hd;

      this.webhooksAvailable = accountData.add_ons.remotewebhooks;
      if (accountData.webhooks) {
        const eventNames = Object.keys(accountData.webhooks)
        this.webhooks = []
        for (const eventName of eventNames) {
          const webhookIds = Object.keys(accountData.webhooks[eventName])
          const eventWebhooks = webhookIds.map(wid => {
            const webhook = accountData.webhooks[eventName][wid]
            webhook.id = wid
            return webhook
          })
          eventWebhooks.forEach(wh => this.webhooks.push(wh))
        }
      } else {
        this.webhooks = []
      }

      this.customEmailAvailable = accountData.add_ons.customserviceemail;
      this.customEmailEnabled = accountData.email_config && !accountData.email_config.auth_shared;
      this.adIntegrationAvailable = environment.design.showAzureAdIntegration && accountData.add_ons.azureadintegration;
      this.oidcAvailable = accountData.add_ons.oidcsso;

      this.samlConfigured = accountData.ad_config && accountData.ad_config.saml_configured;
      this.scimConfigured = accountData.ad_config && accountData.ad_config.scim_configured;
      this.oidcConfigured = accountData.ad_config && accountData.ad_config.oidc_configured;

      if (accountData.password_policy) {
        this.passwordLength = accountData.password_policy.options.length ? accountData.password_policy.options.length : 6;
        this.strongEnabled = accountData.password_policy.strong ? accountData.password_policy.strong : false;
        this.oldPasswordLength = this.passwordLength;
        this.AZSelected = accountData.password_policy.options.AZ ? accountData.password_policy.options.AZ : false;
        this.azSelected = accountData.password_policy.options.az ? accountData.password_policy.options.az : false;
        this.numberSelected = accountData.password_policy.options.number ? accountData.password_policy.options.number : false;
        this.specialCharacterSelected = accountData.password_policy.options.special ? accountData.password_policy.options.special : false;
      }

      this.emailRecipients = (accountData.email_recipients ? accountData.email_recipients : []).map(r => ({value: r, display: r}));

      this.contactData = accountData.contacts;

      this.accountData = accountData;
    });

    this.translateService.get('APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.PLACEHOLDER').pipe(first()).toPromise().then(tr => {
      this.emailPholder = tr;
    });
    this.translateService.get('APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.SECONDARY_PLACEHOLDER').pipe(first()).toPromise().then(tr => {
      this.emailSecPholder = tr;
    });
    this.translateService.get('APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.INVALID_EMAIL').pipe(first()).toPromise().then(tr => {
      this.invalidEmailError.pattern = tr;
    });
    this.createForm();

    this.formValueSub = this.contactsForm.valueChanges.subscribe(value => {
      let pristine = true;
      if (this.orjContactsFormGroupArray) {
        if (this.orjContactsFormGroupArray.length === value.formGroupList.length) {
          for (let i = 0; i < this.orjContactsFormGroupArray.length; i++) {
            if (this.orjContactsFormGroupArray[i].controls.id.value !== value.formGroupList[i].id)
              pristine = false;
            if (this.orjContactsFormGroupArray[i].controls.department.value !== value.formGroupList[i].department)
              pristine = false;
            if (this.orjContactsFormGroupArray[i].controls.name.value !== value.formGroupList[i].name)
              pristine = false;
            if (this.orjContactsFormGroupArray[i].controls.email.value !== value.formGroupList[i].email)
              pristine = false;
            if (this.orjContactsFormGroupArray[i].controls.phone.value !== value.formGroupList[i].phone)
              pristine = false;
          }
        } else {
          pristine = false;
        }
      }
      this.formPristine = pristine;
    })
  }

  onAddNewWebhook() {
    const dataModel = { webhook: { event: "SessionEnd", url: "" } };
    const modalId = this.modalService.show({
      template: this.newWebhookTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          add: () => {
            this.loaderService.show()
            this.accountService.addWebhook(dataModel.webhook)
              .then(() => this.modalService.hide(modalId))
              .catch(error => {
                if (error.error) {
                  if (error.error === 'webhook-notification-failed') {
                    this.flashMessageService.showTranslated("APP.MAIN.SETTINGS.WEBHOOKS.API_ERROR")
                  } else {
                    this.flashMessageService.showTranslated("APP.MAIN.SETTINGS.WEBHOOKS.ERROR")
                  }
                } else {
                  this.flashMessageService.showTranslated("APP.MAIN.SETTINGS.WEBHOOKS.ERROR")
                }
              })
              .finally(() => this.loaderService.hide())
          }
        }
      }
    });
  }

  onRemoveWebhook(webhook: any) {
    const modalId = this.modalService.show({
      template: this.removeWebhookTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          remove: () => {
            this.loaderService.show()
            this.accountService.removeWebhook(webhook)
              .then(() => this.modalService.hide(modalId))
              .catch(error => this.flashMessageService.showTranslated("APP.MAIN.SETTINGS.SESSION_WEBHOOKS.ERROR"))
              .finally(() => this.loaderService.hide())
          }
        }
      }
    });
  }

  enablePasswordSetting() {
    this.strongEnabled = !this.strongEnabled;
    this.changeDetected = true;
  }

  AZClick() {
    this.AZSelected = !this.AZSelected;
    this.AZClicked = !this.AZClicked;
    this.changeDetected = true;
  }

  azClick() {
    this.azSelected = !this.azSelected;
    this.azClicked = !this.azClicked;
    this.changeDetected = true;
  }

  numberClick() {
    this.numberSelected = !this.numberSelected;
    this.numberClicked = !this.numberClicked;
    this.changeDetected = true;
  }

  specialCharactersClick() {
    this.specialCharacterSelected = !this.specialCharacterSelected;
    this.specialCharacterClicked = !this.specialCharacterClicked;
    this.changeDetected = true;
  }

  minimumCharacterClick() {
    this.changeDetected = true;
  }

  async onPasswordControlGenerate() {
    this.loaderService.show();
    this.accountService.savePasswordSettings(this.AZSelected, this.azSelected, this.numberSelected, this.specialCharacterSelected, this.passwordLength, this.strongEnabled)
      .then(() => {
        this.changeDetected = false;
        this.flashMessageService.showTranslated("APP.MAIN.SETTINGS.PASSWORD_POLICY.SUCCESS", { cssClass: 'alert-success' })
      })
      .catch(err => {
        this.flashMessageService.showTranslated("APP.MAIN.SETTINGS.PASSWORD_POLICY.ERROR")
      })
      .finally(() => this.loaderService.hide())
  }

  renderTemplate(templateStr: string, args: any): string {
    templateStr = templateStr.replace(/`/g, '\\`');
    const keys = Object.keys(args);
    const fn = new Function(...keys, 'return `' + templateStr + '`');
    return fn(...keys.map(key => args[key]));
  }

  addSamlConfiguration() {
    const dataModel = { ssoUrl: "", cert: "" };
    const modalId = this.modalService.show({
      template: this.configureSamlTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          add: (samlForm: NgForm) => {
            if (!samlForm.valid) {
              return;
            }
            this.loaderService.show();
            samlForm.control.disable();

            this.accountService.addSamlConfiguration(this.azureAdConfig.name, samlForm.value.ssoUrl, samlForm.value.cert)
            .then(() => this.modalService.hide(modalId))
            .catch(error => {
              this.flashMessageService.showTranslated("There is an error occured.");
              samlForm.control.enable();
            })
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  removeSamlConfiguration() {
    const modalId = this.modalService.show({
      template: this.removeSamlTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          remove: () => {
            this.loaderService.show();
            this.accountService.removeSamlConfiguration(this.azureAdConfig.name)
            .then(() => this.modalService.hide(modalId))
            .catch(error => this.flashMessageService.showTranslated("There is an error occured."))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  addScimConfiguration() {
    const dataModel = { scimToken: "", tokenGenerated: false };
    const modalId = this.modalService.show({
      template: this.configureScimTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          close: () => this.modalService.hide(modalId),
          generateToken: () => {
            this.loaderService.show();
            this.accountService.addScimConfiguration(this.azureAdConfig.name)
            .then(config => {
              dataModel.scimToken = config.token;
            })
            .catch(error => this.flashMessageService.showTranslated("There is an error occured."))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  removeScimConfiguration() {
    const modalId = this.modalService.show({
      template: this.removeScimTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          remove: () => {
            this.loaderService.show();
            this.accountService.removeScimConfiguration(this.azureAdConfig.name)
            .then(() => this.modalService.hide(modalId))
            .catch(error => this.flashMessageService.showTranslated("There is an error occured."))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  addOIDCConfiguration() {
    const dataModel = {
      discoveryEndpoint: "",
      clientId: "",
      clientSecret: "",
      responseType: "code",
      logoutUrl: ""
    };
    const modalId = this.modalService.show({
      template: this.configureOIDCTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          add: (oidcForm: NgForm) => {
            if (!oidcForm.valid) {
              return;
            }
            this.loaderService.show();
            oidcForm.control.disable();

            this.accountService.addOidcConfiguration(this.oauthOIDCConfig.name, oidcForm.value.discoveryEndpoint, oidcForm.value.clientId, oidcForm.value.clientSecret, [oidcForm.value.responseType], oidcForm.value.logoutUrl, [this.oauthOIDCConfig.redirectUrl])
            .then(() => this.modalService.hide(modalId))
            .catch(error => {
              this.flashMessageService.showTranslated("There is an error occured.");
              oidcForm.control.enable();
            })
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  removeOIDCConfiguration() {
    const modalId = this.modalService.show({
      template: this.removeOIDCTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          remove: () => {
            this.loaderService.show();
            this.accountService.removeOidcConfiguration(this.azureAdConfig.name)
            .then(() => this.modalService.hide(modalId))
            .catch(error => this.flashMessageService.showTranslated("There is an error occured."))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  copied(event) {
    this.flashMessageService.show("Copied", { cssClass: "alert-success" });
  }

  createForm() {
    if (this.contactData){
      for (const contact of Object.keys(this.contactData)){
        this.contactsFormGroupArray.push(new FormGroup({
          id: new FormControl(contact),
          department: new FormControl(this.contactData[contact].department),
          name: new FormControl(this.contactData[contact].name),
          email: new FormControl(this.contactData[contact].email),
          phone: new FormControl(this.contactData[contact].phone)
        }));
        this.orjContactsFormGroupArray.push(new FormGroup({
          id: new FormControl(contact),
          department: new FormControl(this.contactData[contact].department),
          name: new FormControl(this.contactData[contact].name),
          email: new FormControl(this.contactData[contact].email),
          phone: new FormControl(this.contactData[contact].phone)
        }));
      }
    }
    this.contactsForm = new FormGroup({
      formGroupList: new FormArray(this.contactsFormGroupArray)
    });
  }

  addFormGroup() {
    const formGroupList = this.contactsForm.controls.formGroupList as FormArray;
    formGroupList.push(new FormGroup({
      id: new FormControl(this.accountService.createContactID()),
      department: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, this.validMailValidator]),
      phone: new FormControl("")
    }));
  }

  removeFormGroup(index: number) {
    const formGroupList = this.contactsForm.controls.formGroupList as FormArray;
    formGroupList.removeAt(index);
  }

  disableContactEdit() {
      this.isContactEdit = false;
  }

  enableContactEdit() {
    this.isContactEdit = true;
  }

  revertChanges() {
    const formGroupList = this.contactsForm.controls.formGroupList as FormArray;
    formGroupList.clear();
    for (const formGroup of this.orjContactsFormGroupArray) {
      formGroupList.push(new FormGroup({
        id: new FormControl(formGroup.controls.id.value),
        department: new FormControl(formGroup.controls.department.value),
        name: new FormControl(formGroup.controls.name.value),
        email: new FormControl(formGroup.controls.email.value),
        phone: new FormControl(formGroup.controls.phone.value)
      }))
    }
  }

  contactsFormSubmit(form: FormGroup) {
    const contactList = {
      contacts: []
    };
    this.loaderService.show();
    for (const formGroup of form.controls.formGroupList.value) {
      contactList.contacts.push({
        id: formGroup.id,
        department: formGroup.department,
        name: formGroup.name,
        email: formGroup.email,
        phone: formGroup.phone
      });
    }
    this.accountService.changeContactSetting(contactList)
    .then(() => {
      this.flashMessageService.showTranslated("APP.MAIN.SETTINGS.CONTACT_INFO.SUCCESS", { cssClass: 'alert-success' })
      this.orjContactsFormGroupArray = [];
      if (contactList) {
        for (const contact of (contactList.contacts)){
          this.orjContactsFormGroupArray.push(new FormGroup({
            id: new FormControl(contact.id),
            department: new FormControl(contact.department),
            name: new FormControl(contact.name),
            email: new FormControl(contact.email),
            phone: new FormControl(contact.phone)
          }));
        }
        this.formPristine = true;
      }
      this.isContactEdit = false;
    })
    .catch(error => {
      this.flashMessageService.showTranslated("APP.MAIN.SETTINGS.CONTACT_INFO.FAILED")
      if (error.error) {
        if (error.error === 'permission-denied')
          throw new Error('permission-denied');
        else if (error.error === 'invalid-parameters')
          throw new Error('invalid-parameters');
        else 
          throw new Error('internal-error');
      }
    })
    .finally(() => this.loaderService.hide());
  }

  ngOnDestroy() {
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
    if (this.roomsSub) { this.roomsSub.unsubscribe() }
    if (this.usersSub) { this.usersSub.unsubscribe() }
    if (this.formValueSub) { this.formValueSub.unsubscribe() }
  }

  onSaveAlwaysArchive() {
    const p2pCount = this.rooms ? this.rooms.filter(r => r.room_data.peer_to_peer).length : 0;
    const modalId = this.modalService.show({
      template: this.alwaysArchiveTemplate,
      context: {
        dataModel: { alwaysArchive: this.alwaysArchive, p2pCount: p2pCount },
        callbacks: {
          cancel: () => {
            this.modalService.hide(modalId);
          },
          change: () => {
            this.loaderService.show();
            this.accountService.changeAlwaysRecordSetting(this.alwaysArchive)
            .then(() => this.flashMessageService.show("Automatic archive setting changed.", { cssClass: 'alert-success' }))
            .catch(error => this.flashMessageService.show('Cannot change automatic archive setting.'))
            .finally(() => {
              this.modalService.hide(modalId);
              this.loaderService.hide();
            });
          }
        }
      }
    });
  }

  onSaveAlwaysFullHd() {
    const modalId = this.modalService.show({
      template: this.alwaysFullHdTemplate,
      context: {
        dataModel: { alwaysFullHd: this.alwaysFullHd },
        callbacks: {
          cancel: () => {
            this.modalService.hide(modalId);
          },
          change: () => {
            this.loaderService.show();
            this.accountService.changeAlwaysFullHdSetting(this.alwaysFullHd)
            .then(() => this.flashMessageService.show("FullHD setting changed.", { cssClass: 'alert-success' }))
            .catch(error => this.flashMessageService.show('Cannot change FullHD setting.'))
            .finally(() => {
              this.modalService.hide(modalId);
              this.loaderService.hide();
            });
          }
        }
      }
    });
  }

  emailListChanged() {
    this.recipientsChanged = true;
  }

  onSaveEmailList() {
    this.loaderService.show();
    this.accountService.changeEmailRecipientsSetting(this.emailRecipients.map(r => r.value))
    .then(() => { this.recipientsChanged = false; })
    .catch(error => this.flashMessageService.showTranslated("APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.CANNOT_CHANGE"))
    .finally(() => this.loaderService.hide());
  }

  onChangeTimezone() {
    this.loaderService.show();
    this.accountService.changeTimezoneSetting(this.tzone)
    .catch(error => this.flashMessageService.showTranslated("APP.MAIN.SETTINGS.TIMEZONE.CANNOT_CHANGE"))
    .finally(() => this.loaderService.hide());
  }

  onPeerToPeerInfo() {
    const modalId = this.modalService.show({
      template: this.peerToPeerTemplate,
      context: {
        dataModel: null,
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }

  onChangeTwoFactor(action: string) {
    if (action === 'enableadmin') {
      const noEmailUsers = this.users.filter(u => (u.role === "admin" || u.role === "coadmin" || u.coadmin) && (u.email === '' || !u.email))
      const dataModel = {
        forAdmin: true,
        users: noEmailUsers
      };
      if (noEmailUsers.length > 0) {
        const modalId = this.modalService.show({
          template: this.addEmailTemplate,
          context: {
            dataModel: dataModel,
            callbacks: {
              close: () => { this.modalService.hide(modalId) },
              profile: (userId: string) => {
                this.openUserDetail(userId);
                this.modalService.hide(modalId);
              }
            }
          }
        });
      } else {
        this.changeTwoFactor(action);
      }
    } else if (action === 'enableothers') {
      const noEmailUsers = this.users.filter(u => (!u.email || u.email === ""));
      if (noEmailUsers.length > 0) {
        const modalId = this.modalService.show({
          template: this.addEmailTemplate,
          context: {
            dataModel: {
              forAdmin: false,
              users: noEmailUsers
            },
            callbacks: {
              close: () => { this.modalService.hide(modalId) },
              profile: (userId: string) => {
                this.openUserDetail(userId);
                this.modalService.hide(modalId);
              }
            }
          }
        });
      } else {
        this.changeTwoFactor(action);
      }
    } else {
      this.changeTwoFactor(action);
    }
  }

  changeTwoFactor(action: string) {
    const twoFactorModel = {
      action: action,
      codeSended: false,
      code: null
    };
    const modalId = this.modalService.show({
      template: this.twoFactorAdminTemplate,
      context: {
        dataModel: twoFactorModel,
        callbacks: {
          close: () => { this.modalService.hide(modalId) },
          send: () => {
            this.loaderService.show();
            this.authService.twoFactorSendCode(action)
            .then(() => {
              twoFactorModel.codeSended = true;
            })
            .catch(error => this.showTwoFactorError(error))
            .finally(() => this.loaderService.hide());
          },
          proceed: () => {
            this.loaderService.show();
            this.authService.twoFactorProceed(action, twoFactorModel.code)
            .then(() => {
              this.modalService.hide(modalId);
              if (action === 'enableadmin' || action === 'enableothers') {
                this.flashMessageService.show("Two factor authentication enabled", { cssClass: "alert-success" });
              } else {
                this.flashMessageService.show("Two factor authentication disabled.", { cssClass: "alert-success" });
              }
            })
            .catch(error => this.showTwoFactorError(error))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  showTwoFactorError(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.error === 'email-required') {
        this.flashMessageService.show("Please fill required email address in user profile.");
      } else if (error.error === 'invalid-verification-code') {
        this.flashMessageService.showTranslated("Invalid two-step verification code.");
      } else {
        this.flashMessageService.showTranslated("There is an error occured.");
      }
    } else {
      this.flashMessageService.showTranslated("There is an error occured.");
    }
  }

  openUserDetail(userId: string = null) {
    const detail = this.detailPageService.loadComponent(UserDetailsComponent).instance;
    detail.action = UserDetailsComponent.ACTION_DETAILS;
    detail.userId = userId ? userId : this.authService.currentUser.id;
  }

  onEnableCustomSystemEmail() {
    const modalId = this.modalService.show({
      template: this.enableSystemEmailTemplate,
      context: {
        dataModel: this.customEmailCredentials,
        callbacks: {
          cancel: () => {
            this.customEmailCredentials = { name: "", host: "", port: 465, user: "", pass: ""/*, test: ""*/ };
            this.modalService.hide(modalId);
          },
          enable: (customEmailForm: NgForm) => {
            if (!customEmailForm.valid) {
              return;
            }
            this.loaderService.show();
            customEmailForm.control.disable();

            this.accountService.setServiceEmail({
              name: customEmailForm.value.senderName,
              host: customEmailForm.value.senderHost,
              port: customEmailForm.value.senderPort,
              email: customEmailForm.value.senderEmail,
              user: customEmailForm.value.senderUser,
              pass: customEmailForm.value.pass/*,
              test: customEmailForm.value.testEmail*/
            })
            .then(() => {
              this.modalService.hide(modalId);
              this.verifyCustomSystemEmail();
            })
            .catch(error => {
              if (error instanceof HttpErrorResponse) {
                if (error.error === 'cannot-send-email') {
                  this.flashMessageService.show("Service email credentials cannot verified.");
                } else {
                  this.flashMessageService.showTranslated("There is an error occured.");
                }
              } else {
                this.flashMessageService.showTranslated("There is an error occured.");
              }
              customEmailForm.control.enable();
            })
            .finally(() => {
              this.customEmailCredentials = { name: "", host: "", port: 465, user: "", pass: ""/*, test: ""*/ };
              this.loaderService.hide();
            });
          }
        }
      }
    });
  }

  verifyCustomSystemEmail() {
    const verifyModel = { code: null };
    const modalId = this.modalService.show({
      template: this.verifySystemEmailTemplate,
      context: {
        dataModel: verifyModel,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          verify: (verifyEmailForm: NgForm) => {
            this.loaderService.show();
            verifyEmailForm.control.disable();

            this.accountService.verifyServiceEmail(verifyEmailForm.value.verificationCode)
            .then(() => {
              this.modalService.hide(modalId);
              this.flashMessageService.show('Custom service email enabled.', { cssClass: "alert-success" })
            })
            .catch(error => {
              verifyEmailForm.control.enable();
              this.flashMessageService.show('Cannot verify custom service email.');
            })
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  onDisableCustomSystemEmail() {
    const modalId = this.modalService.show({
      template: this.disableSystemEmailTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          disable: () => {
            this.loaderService.show();
            this.accountService.disableServiceEmail()
            .then(() => {
              this.modalService.hide(modalId);
              this.flashMessageService.show('Custom service email disabled successfully.', { cssClass: "alert-success" });
            })
            .catch(error => this.flashMessageService.show('Cannot disable custom service email.'))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  /*
  onNewVoiceCommand() {
    const modalId = this.modalService.show({
      template: this.addCommandTemplate,
      context: {
        dataModel: null,
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
          },
          add: () => {}
        }
      }
    });
  }

  onCommandDrop(event: CdkDragDrop<any[]>) {
    //this.stationService.stepChange(this.stationId, this.steps[event.previousIndex].id, event.currentIndex);
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }
  */
}
