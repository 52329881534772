import { Injectable, Type, ComponentRef, ViewContainerRef, ComponentFactoryResolver, ComponentFactory } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DetailPageService {

  container: ViewContainerRef;
  container2: ViewContainerRef;

  constructor(
    private resolver: ComponentFactoryResolver
  ) { }

  private getComponentFactory<T>(component: Type<T>): ComponentFactory<T> {
    return this.resolver.resolveComponentFactory<T>(component);
  }

  private addComponent<T>(container: ViewContainerRef, factory: ComponentFactory<T>): ComponentRef<T> {
    return container.createComponent(factory);
  }

  setDetailContainer(container: ViewContainerRef) {
    this.container = container;
  }

  loadComponent<T>(component: Type<T>): ComponentRef<T>  {
    this.container.clear();
    return this.addComponent(this.container, this.getComponentFactory(component));
  }

  removeComponent() {
    this.container.clear();
  }

  setDetailContainer2(container: ViewContainerRef) {
    this.container2 = container;
  }

  loadComponent2<T>(component: Type<T>): ComponentRef<T>  {
    this.container2.clear();
    return this.addComponent(this.container2, this.getComponentFactory(component));
  }

  removeComponent2() {
    this.container2.clear();
  }
}
