import { Injectable } from '@angular/core';

// Router imports
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Authservice for control authentication
import { AuthService } from '@services/auth.service';

// rxjs imports
import { Observable, of } from 'rxjs';
import { map, tap, take, first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    // Return observable of <auhtenticated or not>
    return this.authService.getAuth().pipe(
      take(1),
      map(user => {
        if (!user) {
          this.router.navigate(['/login']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}