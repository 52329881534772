import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './auth.service';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { filter, map, first, timeout } from 'rxjs/operators';
import { RoomData } from '../models/RoomData';
import { Room } from '../models/Room';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class RoomService {

  allRoomsAndMeetings: Observable<Room[]>;

  allRooms: Observable<Room[]>;
  rooms: Observable<Room[]>;
  private allRoomsSource = new BehaviorSubject<Room[]>(null);
  private allRoomsSub: Subscription = null;

  private allMeetingsSource = new BehaviorSubject<Room[]>(null);
  private allRoomsAndMeetingsSource = new BehaviorSubject<Room[]>(null);
  private allMeetingsSub: Subscription = null;

  defaultRoomIcon: string = null;

  constructor(
    private authService: AuthService,
    private afdb: AngularFireDatabase,
    private http: HttpClient
  ) {
    this.allRooms = this.allRoomsSource.asObservable().pipe(filter(rooms => !!rooms));
    this.allRoomsAndMeetings = this.allRoomsAndMeetingsSource.asObservable().pipe(filter(rooms => !!rooms));
    this.rooms = this.allRooms.pipe(map(rooms => rooms.filter(r => !r.room_data.deleted && !r.room_data.personal_room && !r.room_data.meeting_room)));

    this.authService.on("login", this.onLogin);
    this.authService.on("logout", this.onLogout);

    // Load and minimize default room icon before needed
    this.loadDefaultRoomIcon();
  }

  private onLogin = (account_id: string) => {
    this.startListennigAllRooms(account_id);
  }

  private onLogout = () => {
    this.stopListenningAllRooms();
  }

  startListennigAllRooms(account_id: string) {
    if (this.allRoomsSub) { this.allRoomsSub.unsubscribe() }
    if (this.allMeetingsSub) { this.allMeetingsSub.unsubscribe() }

    this.allRoomsSub = this.afdb.list<Room>(`accounts/${account_id}/rooms`).snapshotChanges().pipe(
      map(roomSnaps => roomSnaps.map(snap => this.getRoomFromRoomData(snap.payload.val().room_data, snap.key)))
    ).subscribe(rooms => {
      this.allRoomsSource.next(rooms);
      this.mergeRoomsAndMeetings();
    });

    this.allMeetingsSub = this.afdb.list<any>(`accounts/${account_id}/meetings_meta`).snapshotChanges().pipe(
      map(meetingsSnap => meetingsSnap.map(snap => this.getRoomFromMeetingData(snap.payload.val(), snap.key)))
    ).subscribe(meetings => {
      this.allMeetingsSource.next(meetings);
      this.mergeRoomsAndMeetings();
    })
  }

  mergeRoomsAndMeetings() {
    const rooms = this.allRoomsSource.value ? this.allRoomsSource.value : []
    const meetings = (this.allMeetingsSource.value ? this.allMeetingsSource.value : []).filter(r1 => rooms.findIndex(r2 => r2.id === r1.id) < 0)

    this.allRoomsAndMeetingsSource.next(rooms.concat(meetings))
  }

  stopListenningAllRooms() {
    if (this.allRoomsSub) { this.allRoomsSub.unsubscribe() }
    if (this.allMeetingsSub) { this.allMeetingsSub.unsubscribe() }

    this.allRoomsSource.next(null);
    this.allMeetingsSource.next(null);
  }

  getRoomFromRoomData(roomData: RoomData, roomId: string): Room {
    if (!roomData.users) {
      roomData.users = {}
    }
    return {
      id: roomId,
      room_data: roomData
    }
  }

  getRoomFromMeetingData(meetingData: any, meetingId: string): Room {
    return {
      id: meetingData.room_id,
      room_data: {
        name: meetingData.room_name,
        description: meetingData.room_description,

        meeting_room: meetingData.creator,
        start: meetingData.start,
        end: meetingData.end,
        link_id: meetingId,

        users: {}
      }
    }
  }

  getRoom(roomId: string): Promise<Room> {
    return this.allRooms.pipe(first(), map(rooms => rooms ? rooms.find(room => room.id === roomId) : null)).toPromise()
  }

  updateRoom(changes) {
    return this.http.post(environment.endPoints.updateroom,
      { token: this.authService.currentUser.token, changes: changes }).pipe(first()).toPromise()
    .then(async(result) => {
      return (await new Promise<void>(resolve => {
        const sub = this.allRooms.pipe(
          timeout(5000),
          map(rooms => rooms ? rooms.find(room => room.id === changes.room_id) : null)
        ).subscribe(
          room => {
            if (room) {
              const roomUsers = room.room_data.users ? Object.keys(room.room_data.users) : [];
              if (Object.keys(changes.room_data).every(p => changes.room_data[p] === room.room_data[p]) &&
                      changes.deleted_users.every(d => roomUsers.indexOf(d) < 0)  && changes.inserted_users.every(i => roomUsers.indexOf(i) > -1)) {
                if (sub) { sub.unsubscribe() }
                resolve();
              }
            }
          },
          error => { resolve() });
      }));
    });
  }

  createRoom(newRoom) {
    return this.http.post(environment.endPoints.createroom,
      { token: this.authService.currentUser.token, room: newRoom }).pipe(first()).toPromise()
    .then((result: any) => {
      return result.room;
    });
  }

  deleteRoom(roomId: string) {
    return this.http.post(environment.endPoints.deleteroom,
      { token: this.authService.currentUser.token, room: roomId }).pipe(first()).toPromise()
    .then((result: any) => {
      return result.room;
    });
  }

  toggleRoomStatus(roomId: string): Promise<boolean> {
    return this.http.post(environment.endPoints.toggleroom, { token: this.authService.currentUser.token, room: roomId }).pipe(first()).toPromise()
    .then(async(result: any) => {
      return (await new Promise<boolean>(resolve => {
        const sub = this.allRooms.pipe(
          timeout(5000),
          map(rooms => rooms ? rooms.find(room => room.id === roomId) : null)
        ).subscribe(
          room => {
            if (room) {
              if (!room.room_data.disabled == !result.disabled) {
                if (sub) { sub.unsubscribe() }
                resolve(result.disabled);
              }
            }
          }, error => {
            resolve(result.disabled);
          }
        );
      }));
    });
  }

  loadDefaultRoomIcon() {
    // Load rounded white background
    const loadBg = new Promise<HTMLImageElement>((resolve, reject) => {
      const bgImage = new Image();
      bgImage.src = "assets/img/rounded_bg.png";
      bgImage.onload = () => { resolve(bgImage) }
      bgImage.onerror = () => { reject() }
    });

    // Load transparent square icon
    const loadIcon = new Promise<HTMLImageElement>((resolve, reject) => {
      const iconImage = new Image();
      iconImage.src = "assets/img/square_logo.png";
      iconImage.onload = () => { resolve(iconImage) }
      iconImage.onerror = () => { reject() }
    });

    // Create default room icon with rounded white background
    loadBg.then(bgImage => loadIcon.then(iconImage => [bgImage, iconImage]))
    .then(([bgImage, iconImage]) => {
      const cnv: HTMLCanvasElement = document.createElement("canvas");
      cnv.width = 64;
      cnv.height = 64;
      const ctx: CanvasRenderingContext2D = cnv.getContext('2d');
      ctx.drawImage(bgImage, 0, 0, 64, 64);
      ctx.drawImage(iconImage, 0, 0, 64, 64);
      this.defaultRoomIcon = cnv.toDataURL("image/png", 1);
    });
  }
}