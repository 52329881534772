import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";

import { AccountService } from "@services/account.service";
import { map } from "rxjs/operators";
import { UtilityService } from "@services/support/utility.service";

@Pipe({name: 'utcCalc'})
export class UtcCalcPipe implements PipeTransform {

    constructor(private accountService: AccountService) {}

    transform(timestamp: number, appendTimezone?: boolean): Observable<string> {
        return this.accountService.accountData.pipe(map(data => {
            const d = new Date(timestamp+UtilityService.timezoneOffset+UtilityService.timezones[data.timezone]);
            const formatted = `${("0"+d.getDate()).slice(-2)}.${("0"+(d.getMonth()+1)).slice(-2)}.${d.getFullYear()} ${("0" + d.getHours()).slice(-2)}:${("0" + d.getMinutes()).slice(-2)}`;
            return appendTimezone ? `${formatted} ${data.timezone}` : formatted;
        }));
    }
}