import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './others/guards/auth.guard';
import { PrivacyPolicyGuard } from './others/guards/privacy-policy.guard';

import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/others/not-found/not-found.component';
import { MainComponent } from './components/main/main.component';
import { DashboardComponent } from './components/main/content/dashboard/dashboard.component';
import { UserListComponent } from './components/main/content/users/user-list/user-list.component';
import { RoomListComponent } from './components/main/content/rooms/room-list/room-list.component';
import { SessionsComponent } from './components/main/content/sessions/sessions.component';
import { TicketsComponent } from './components/main/content/tickets/tickets.component';
import { TermsComponent } from './components/others/terms/terms.component';
import { PrivacyComponent } from './components/others/privacy/privacy.component';
import { StorageComponent } from './components/main/content/storage/storage.component';
import { SettingsComponent } from './components/main/content/settings/settings.component';

import { StationListComponent } from './components/main/content/stations/station-list/station-list.component';
import { AuthenticateComponent } from './components/authenticate/authenticate.component';
import { AdLoginComponent } from './components/externals/ad-login/ad-login.component';
import { AdErrorComponent } from './components/externals/ad-error/ad-error.component';
import { LoginFromRemoteComponent } from './components/login-from-remote/login-from-remote.component';
import { ReportsChartComponent } from './components/reports-chart/reports-chart.component';

const routes: Routes = [
  { path: '', component: MainComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent },
      { path: 'users', component: UserListComponent },
      { path: 'rooms', component: RoomListComponent },
      { path: 'sessions', component: SessionsComponent },
      { path: 'tickets', component: TicketsComponent },
      { path: 'storage', component: StorageComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'workflow', component: StationListComponent },
      { path: 'reports', component: ReportsChartComponent }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'login/:account_name', component: LoginComponent },
  { path: 'authenticate', component: AuthenticateComponent },
  { path: 'adlogin', component: AdLoginComponent },
  { path: 'aderror', component: AdErrorComponent },
  { path: 'login-from-remote', component: LoginFromRemoteComponent},
  { path: 'terms', component: TermsComponent, canActivate: [PrivacyPolicyGuard] },
  { path: 'privacy', component: PrivacyComponent, canActivate: [PrivacyPolicyGuard] },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
