import { Component, OnDestroy, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { NavigationService } from '@services/support/navigation.service';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { User } from '@models/User';
import { DetailPageService } from '@services/support/detail-page.service';
import { UserDetailsComponent } from '../content/users/user-details/user-details.component';
import { ModalService } from '@services/support/modal.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { Language } from '@models/Language';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  @Input('openProfileRequest') openProfileRequest: Subject<void> = null;

  @ViewChild("logoutTemplate", { static: true }) private logoutTemplate: TemplateRef<any>;

  profileSub: Subscription = null;

  sidebarOpen: boolean = false;
  sidebarSub: Subscription = null;

  currentUser: User = null;
  userSub: Subscription = null;

  appVersion: string = "";

  currentLang: Language;
  allLangs: Language[] = [];
  showLangs: boolean = false;
  langSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private detailPageService: DetailPageService,
    private navigationService: NavigationService,
    private modalService: ModalService,
    private multilanguageService: MultilanguageService
  ) {
    this.sidebarSub = this.navigationService.sidebarState.subscribe(state => {
      this.sidebarOpen = state;
    });
  }

  ngOnInit() {
    this.appVersion = environment.version;
    this.userSub = this.authService.user.subscribe(user => {
      this.currentUser = user;
    });

    this.currentLang = this.multilanguageService.currentLang;
    this.allLangs = this.multilanguageService.allLangs;
    
    this.langSub = this.multilanguageService.onLangChange.subscribe(change => {
      this.currentLang = change.lang;
    });

    this.profileSub = this.openProfileRequest.subscribe(() => this.onProfile());
  }

  toggleSidebar() {
    this.navigationService.toggleSidebar();
  }

  ngOnDestroy() {
    if (this.sidebarSub) { this.sidebarSub.unsubscribe() }
    if (this.userSub) { this.userSub.unsubscribe() }
    if (this.profileSub) { this.profileSub.unsubscribe() }
  }

  onProfile() {
    this.detailPageService.removeComponent2();
    const detail = this.detailPageService.loadComponent(UserDetailsComponent).instance;
    detail.action = UserDetailsComponent.ACTION_DETAILS;
    detail.userId = this.authService.currentUser.id;
  }

  onLogout() {
    const modalId = this.modalService.show({
      template: this.logoutTemplate,
      context: {
        dataModel: null,
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
          },
          logout: () => {
            this.modalService.hide(modalId);
            this.authService.logout();
          }
        }
      }
    });
  }

  onLanguageSelected(lang: Language) {
    this.multilanguageService.setCurrentLanguage(lang.code);
  }

}
