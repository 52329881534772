import { Component, OnInit, ViewChild, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { DetailPageService } from '@services/support/detail-page.service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StationService } from '@services/station.service';
import { UploadModel } from '@models/UploadModel';
import { ModalService } from '@services/support/modal.service';
import { FileShareService } from '@services/support/file-share.service';
import { UploadFile } from '@models/UploadFile';
import { LoaderService } from '@services/support/loader.service';
import { AngularFireDatabase } from '@angular/fire/database';

declare var QRCode: any;

@Component({
  selector: 'app-station-details',
  templateUrl: './station-details.component.html',
  styleUrls: ['./station-details.component.scss']
})
export class StationDetailsComponent implements OnInit {

  static ACTION_DETAILS: string = "details";
  static ACTION_EDIT: string = "edit";
  static ACTION_NEW: string = "new";
  /**** Access in template ****/
  get ACTION_DETAILS() { return StationDetailsComponent.ACTION_DETAILS; }
  get ACTION_EDIT() { return StationDetailsComponent.ACTION_EDIT; }
  get ACTION_NEW() { return StationDetailsComponent.ACTION_NEW; }

  @ViewChild('stationForm', { static: true }) stationForm: NgForm;
  @ViewChild("uploadModalTemplate", { static: true }) private uploadModalTemplate: TemplateRef<any>;
  @ViewChild("deleteStationTemplate", { static: true }) private deleteStationTemplate: TemplateRef<any>;

  action: string;
  actionTitle: string = "";

  stationId: string;

  formPristine: boolean = true;
  formLoading: boolean = true;
  formValueSub: Subscription = null;

  orjStation: any;
  station: any = {
    name: "",
    description: "",
    finishedProduct: "",
    handTools: "",
    technicalDrawing: "",
    video: ""
  };

  media: {[key:string] : { status: "changed"|"deleted", file?: any}} = {
    finishedProduct: null,
    handTools: null,
    technicalDrawing: null,
    video: null
  };

  disabledControls: any = {
    finishedProduct: false,
    handTools: false,
    technicalDrawing: false,
    video: false
  };

  uploadModel: UploadModel = {
    uploadFiles: [],
    uploading: false,
    completed: false,
    totalBytes: 0,
    transferredBytes: 0
  };

  uploadModalId: number;

  stationQrCode = null;

  constructor(
    private stationService: StationService,
    private detailPageService: DetailPageService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private fileShareService: FileShareService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private afdb: AngularFireDatabase
  ) { }

  ngOnInit() {
    if (this.action === this.ACTION_NEW) {
      this.stationId = this.afdb.createPushId();
      this.orjStation = {
        name: '',
        description: '',
        finishedProduct: '',
        handTools: '',
        technicalDrawing: '',
        video: ''
      };
      this.formLoading = false;
      this.translateService.get('APP.MAIN.STATIONS.STATION_DETAILS.TITLE_NEW').toPromise()
      .then(result => { this.actionTitle = result; });
    } else {
      if (this.action === this.ACTION_DETAILS) {
        this.translateService.get('APP.MAIN.STATIONS.STATION_DETAILS.TITLE_DETAILS').toPromise()
        .then(result => { this.actionTitle = result; });
      } else if (this.action === this.ACTION_EDIT) {
        this.translateService.get('APP.MAIN.STATIONS.STATION_DETAILS.TITLE_EDIT').toPromise()
        .then(result => { this.actionTitle = result; });
      }
      this.loadStationData();
    }

    this.formValueSub = this.stationForm.valueChanges.subscribe(value => {
      this.detectPristine();
    });
  }

  detectPristine() {
    const rawValue = this.stationForm.control.getRawValue();

    let pristine = true;
    if (this.orjStation) {
      Object.keys(rawValue).forEach(property => {
        if (rawValue[property] !== this.orjStation[property]) {
          pristine = false;
        }
      });
    }
    Object.keys(this.media).forEach(property => {
      if (this.media[property]) {
        pristine = false;
      }
    });
    this.formPristine = pristine;
  }

  ngAfterViewChecked() {
    if (this.action === this.ACTION_DETAILS) {
      this.stationForm.control.disable();
      Object.keys(this.disabledControls).forEach(property => {
        this.disabledControls[property] = true;
      });
      this.changeDetector.detectChanges();
    }
  }

  loadStationData() {
    this.stationService.getStation(this.stationId).then(station => {
      this.orjStation = {
        name: station.name,
        description: station.description,
        finishedProduct: station.finishedProduct ? station.finishedProduct : '',
        handTools: station.handTools ? station.handTools : '',
        technicalDrawing: station.technicalDrawing ? station.technicalDrawing : '',
        video: station.video ? station.video : ''
      };
      Object.keys(this.orjStation).forEach(key => {
        this.station[key] = this.orjStation[key];
      });
      QRCode.toDataURL(station.qrCode, { width: 300, type: "image/png", errorCorrectionLevel: "M" })
      .then(code => { this.stationQrCode = code });
      this.formLoading = false;
    });
  }

  onMediaChange(property: string, event: any, mediaElement: HTMLMediaElement | HTMLImageElement, nameInput: HTMLInputElement) {
    if (event.target.files[0]) {
      nameInput.value = event.target.files[0].name;
      mediaElement.src = URL.createObjectURL(event.target.files[0]);

      this.media[property] = {
        status: "changed",
        file: event.target.files[0]
      };

      this.detectPristine();
    } else {
      nameInput.value = '';
    }
  }

  onMediaDelete(property: string, mediaElement: HTMLMediaElement | HTMLImageElement, fileInput: HTMLInputElement, nameInput: HTMLInputElement) {
    if (this.orjStation[property] !== '') {
      this.media[property] = {
        status: "deleted"
      };
    } else {
      this.media[property] = null;
    }

    this.detectPristine();
    mediaElement.src = '';
    nameInput.value = '';
    fileInput.value = '';
  }

  resetForm(action) {
    const detail = this.detailPageService.loadComponent(StationDetailsComponent).instance;
    detail.action = action;
    detail.stationId = this.stationId;
  }

  cancelEdit() {
    const detail = this.detailPageService.loadComponent(StationDetailsComponent).instance;
    detail.action = StationDetailsComponent.ACTION_DETAILS;
    detail.stationId = this.stationId;
  }

  onBack() {
    this.detailPageService.removeComponent();
  }

  onEdit() {
    const detail = this.detailPageService.loadComponent(StationDetailsComponent).instance;
    detail.action = StationDetailsComponent.ACTION_EDIT;
    detail.stationId = this.stationId;
  }

  onDelete() {
    const modalId = this.modalService.show({
      template: this.deleteStationTemplate,
      context: {
        dataModel: this.station,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          delete: () => {
            this.loaderService.show();
            this.stationService.deleteStation(this.stationId).then(() => {
              return this.detailPageService.removeComponent();
            })
            .finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
            });
          }
        }
      }
    });
  }

  onDownloadQR() {
    if (this.stationQrCode) {
      this.fileShareService.saveBase64File(this.stationQrCode.slice('data:image/png;base64,'.length), 'image/png', `${this.station.name}.png`);
    }
  }

  async detectStationChanges(orjStation, rawValue) {
    let changes: {id: string, station: any} = {
      id: this.stationId,
      station: {},
    };

    Object.keys(rawValue).forEach(property => {
      if (this.media[property]) {
        if (this.media[property].status === 'deleted') {
          changes.station[property] = null;
        }
      } else if (rawValue[property] !== orjStation[property]) {
        changes.station[property] = rawValue[property];
      }
    });

    const files: UploadFile[] = [];
    Object.keys(this.media).forEach(property => {
      if (this.media[property] && this.media[property].status === 'changed') {
        files.push({
          file: this.media[property].file,
          extension: this.media[property].file.name.split('.').pop().toLowerCase(),
          progress: 0,
          type: property
        });
      }
    });

    if (files.length > 0) {
      await this.uploadFiles(files);
      this.modalService.hide(this.uploadModalId);
  
      files.forEach(file => {
        changes.station[file.type] = file.url;
      });  
    }
    return changes;
  }

  onSubmit(form: NgForm) {
    const rawValue = form.control.getRawValue();

    if (this.action === this.ACTION_EDIT) {
      this.detectStationChanges(this.orjStation, rawValue).then(changes => {
        this.loaderService.show();
        this.stationService.editStation(changes)
        .finally(() => {
          this.loadDetail(this.stationId);
          this.loaderService.hide();
        });
      });
    } else if (this.action === this.ACTION_NEW) {
      this.detectStationChanges(this.orjStation, rawValue).then(changes => {
        this.loaderService.show();
        this.stationService.createStation(changes)
        .then(stationId => this.loadDetail(stationId))
        .finally(() => this.loaderService.hide());
      });
    }
  }

  loadDetail(stationId: string) {
    const detail = this.detailPageService.loadComponent(StationDetailsComponent).instance;
    detail.action = StationDetailsComponent.ACTION_DETAILS;
    detail.stationId = stationId;
  }

  uploadFiles(uploadFiles: UploadFile[]) {
    return new Promise<void>((resolve) => {
      this.uploadModel.uploadFiles = uploadFiles;
      this.uploadModel.uploading = false;
      this.uploadModel.completed = false;
      this.uploadModel.totalBytes = 0,
      this.uploadModel.transferredBytes = 0,
      this.uploadModalId = this.modalService.show({
        template: this.uploadModalTemplate,
        context: {
          dataModel: this.uploadModel,
          callbacks: {
            close: () => this.modalService.hide(this.uploadModalId),
            cancel: () => this.uploadModel.uploadFiles.forEach(upload => {
              if (upload.uploadTask.snapshot.state === 'running') {
                upload.uploadTask.cancel();
              }
            }),
            upload: () => this.fileShareService.upload(this.uploadModel, this.stationId).subscribe(upload => {}, error => {}, () => {
              resolve();
            })
          }
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.formValueSub) { this.formValueSub.unsubscribe() }
  }
}
