<div class="user-details-container px-md-3 px-lg-5">
  <div class="container-fluid">
    <div class="card">
      <a class="back-link" (click)="onBack()">
        <i class="mi mi-arrow-back"></i><span>{{ 'APP.MAIN.USERS.USER_DETAILS.BACK' | translate }}</span>
      </a>
      <div class="card-header d-flex justify-content-between align-items-center">
        <span class="card-header-title">{{ actionTitle }}</span>
        <span *ngIf="user.disabled" class="card-header-title text-danger">{{ 'APP.MAIN.USERS.USER_DETAILS.USER_DISABLED' | translate }}</span>
        <button *ngIf="action === ACTION_DETAILS && !formLoading" class="btn btn-info" (click)="onEdit()" [disabled]="user.disabled">
          {{ 'APP.MAIN.USERS.USER_DETAILS.EDIT' | translate }}
        </button>
        <button *ngIf="action === ACTION_NEW && !formLoading" class="btn btn-danger"
              (click)="resetForm()" [disabled]="formPristine || userForm.control.disabled">
          {{ 'APP.MAIN.USERS.USER_DETAILS.RESET' | translate }}
        </button>
        <ng-container *ngIf="action === ACTION_EDIT && !formLoading">
          <button *ngIf="!formPristine" class="btn btn-danger" (click)="resetForm()" [disabled]="userForm.control.disabled">
            {{ 'APP.MAIN.USERS.USER_DETAILS.REVERT' | translate }}
          </button>
          <button *ngIf="formPristine" class="btn btn-secondary" (click)="cancelEdit()">
            {{ 'APP.MAIN.USERS.USER_DETAILS.CANCEL' | translate }}
          </button>
        </ng-container>
      </div>
      <div class="card-body px-lg-5">
        <form #userForm="ngForm" autocomplete="off" (ngSubmit)="onUserFormSubmit(userForm)" [mustMatch]="['password', 'confirmPassword']">
          <div class="form-row">
            <div class="form-group col-md-4 pr-md-3">
              <label for="role">{{ 'APP.MAIN.USERS.USER_DETAILS.USER_ROLE' | translate }}</label>
              <select #role [(ngModel)]="user.role" class="form-control" name="role" [disabled]="user.role === 'admin'" (change)="onRoleChange(user.role)">
                <option *ngIf="user.role === 'admin'" [ngValue]="'admin'">{{ 'APP.MAIN.USERS.USER_DETAILS.ADMIN' | translate }}</option>
                <option *ngIf="accountCoAdminAllowed" [ngValue]="'coadmin'">{{ 'APP.MAIN.USERS.USER_DETAILS.COADMIN' | translate }}</option>
                <option [ngValue]="'user'" selected>{{ (licenseType === 'expert_user_based' ? 'APP.MAIN.USERS.USER_DETAILS.SUBSCRIBER' : 'APP.MAIN.USERS.USER_DETAILS.USER') | translate }}</option>
                <option [ngValue]="'expert'" *ngIf="licenseType === 'expert_user_based'">{{ 'APP.MAIN.USERS.USER_DETAILS.EXPERT' | translate }}</option>
              </select>
            </div>
            <div class="form-group col-md-8 pl-md-3">
              <label for="name">{{ 'APP.MAIN.USERS.USER_DETAILS.NAME' | translate }}<span class="required-field">*</span></label>
              <input #name="ngModel" class="form-control" type="text" name="name" placeholder="{{ 'APP.MAIN.USERS.USER_DETAILS.NAME' | translate }}" 
                  [ngClass]="{'is-invalid':name.errors && name.touched}"
                  [(ngModel)]="user.name" required maxlength="60" pattern="^[a-zA-Z0-9ıİğĞüÜşŞöÖçÇ][a-zA-Z0-9ıİğĞüÜşŞöÖçÇ\-._ ]{0,59}$">
              <small class="invalid-feedback mx-2" [hidden]="!name.errors?.required">{{ 'APP.MAIN.USERS.USER_DETAILS.NAME_REQUIRED' | translate }}</small>
              <small class="invalid-feedback mx-2" [hidden]="!name.errors?.pattern">
                <div>{{ 'APP.MAIN.USERS.USER_DETAILS.NAME_START_WITH' | translate }}</div>
                <div>{{ 'APP.MAIN.USERS.USER_DETAILS.NAME_CONTAINS' | translate }}</div>
                <div>{{ 'APP.MAIN.USERS.USER_DETAILS.NAME_LENGTH' | translate }}</div>
              </small>
            </div>
          </div>
          <div class="form-row" *ngIf="action !== ACTION_DETAILS && orjUser?.role !== 'expert' && user.role === 'expert' && expertLimitReached">
            <div class="form-group col-12 text-danger">
              {{ 'APP.MAIN.USERS.USER_DETAILS.REACHED_MAX_NUMBER_EXPERT_LIMIT' | translate }}
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4 pr-md-3" *ngIf="!ad_user">
              <label for="username">{{ 'APP.MAIN.USERS.USER_DETAILS.USERNAME' | translate }}<span class="required-field">*</span></label>
              <input #username="ngModel" class="form-control" type="text" name="username" placeholder="{{ 'APP.MAIN.USERS.USER_DETAILS.USERNAME' | translate }}" 
                  [ngClass]="{'is-invalid': username.errors && username.touched || username.errors?.alreadyTaken}"
                  [(ngModel)]="user.username" required maxlength="30" [alreadyTaken]="pendingUsernames" pattern="^[a-zA-Z0-9_-]{1,30}$">
              <small class="invalid-feedback mx-2" [hidden]="!username.errors?.required">{{ 'APP.MAIN.USERS.USER_DETAILS.USERNAME_REQUIRED' | translate }}</small>
              <small class="invalid-feedback mx-2" [hidden]="!username.errors?.alreadyTaken">{{ 'APP.MAIN.USERS.USER_DETAILS.USERNAME_ALREADY_TAKEN' | translate }}</small>
              <small class="invalid-feedback mx-2" [hidden]="!username.errors?.pattern">
                <div>{{ 'APP.MAIN.USERS.USER_DETAILS.USERNAME_CONTAINS' | translate }}</div>
                <div>{{ 'APP.MAIN.USERS.USER_DETAILS.USERNAME_LENGTH' | translate }}</div>
              </small>
            </div>
            <div class="form-group col-md-4 pr-md-3" *ngIf="ad_user">
              <label for="userPrincipalName">{{ 'APP.MAIN.USERS.USER_DETAILS.USERNAME' | translate }}<span class="required-field">*</span></label>
              <input #userPrincipalName="ngModel" class="form-control" type="text" name="userPrincipalName" placeholder="{{ 'APP.MAIN.USERS.USER_DETAILS.USERNAME' | translate }}" [(ngModel)]="user_principal_name">
            </div>

            <div class="form-group col-md-8 pl-md-3">
              <label for="email">{{ 'APP.MAIN.USERS.USER_DETAILS.EMAIL' | translate }}</label>
              <input #email="ngModel" class="form-control" type="text" name="email" placeholder="{{ 'APP.MAIN.USERS.USER_DETAILS.EMAIL' | translate }}"
                  [ngClass]="{'is-invalid': email.errors && email.touched }" [required]="(user.role === 'admin' || user.role === 'coadmin') ? twoFactorAdmin : twoFactorOthers"
                  [(ngModel)]="user.email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$">
              <small class="invalid-feedback mx-2" [hidden]="!email.errors?.required">{{ 'APP.MAIN.USERS.USER_DETAILS.EMAIL_REQUIRED' | translate }}</small>
              <small class="invalid-feedback mx-2" [hidden]="!email.errors?.pattern">{{ 'APP.MAIN.USERS.USER_DETAILS.EMAIL_INVALID' | translate }}</small>
            </div>

            <div class="form-group col-md-4 pr-md-3" *ngIf="('design' | env).showLabels">
              <label for="label">{{ 'APP.MAIN.USERS.USER_DETAILS.LABEL' | translate }}</label>
              <ng-select [items]="labels"
                          [loading]="accountLabelsLoading"
                          [(ngModel)]="user.label"
                          [addTag]="addTagPromise.bind(this)"
                          [multiple]="false"
                          [disabled]="user.role !== 'expert'"
                          class="mt-2 detail"
                          name="label"
                          (change)="onLabelChange($event)">
                <ng-template ng-tag-tmp let-search="searchTerm">
                  <b>{{ 'APP.MAIN.USERS.USER_DETAILS.CREATE_NEW_LABELS' | translate }}</b>: {{search}}
                </ng-template>
              </ng-select>
            </div>

          </div>

         

          <div class="form-row">
            <div class="form-group col-12">
              <div class="custom-control custom-checkbox  custom-checkbox-sm d-flex align-items-center" [class.disabled]="!accountArchiveAllowed">
                <input #allow_archiving="ngModel" type="checkbox" class="custom-control-input" id="allow_archiving" name="allow_archiving" [disabled]="!accountArchiveAllowed" [(ngModel)]="user.allow_archiving">
                <label class="custom-control-label" for="allow_archiving">{{ 'APP.MAIN.USERS.USER_DETAILS.ALLOW_ARCHIVING' | translate }}</label>
              </div>
              <label [ngClass]="{'d-none': accountArchiveAllowed}" class="text-danger" for="allow_archiving">{{ 'APP.MAIN.USERS.USER_DETAILS.ARCHIVE_UNAVAILABLE' | translate }}</label>
            </div>

            <div class="form-group col-12" *ngIf="accountCallingAllowed">
              <div class="custom-control custom-checkbox  custom-checkbox-sm d-flex align-items-center" [class.disabled]="!accountCallingAllowed">
                <input #allow_calling="ngModel" type="checkbox" class="custom-control-input" id="allow_calling" name="allow_calling" [disabled]="!accountCallingAllowed" [(ngModel)]="user.allow_calling" (ngModelChange)="onAllowCallingChange($event)">
                <label class="custom-control-label" for="allow_calling">{{ 'APP.MAIN.USERS.USER_DETAILS.ALLOW_CALLING' | translate }}</label>
                <a class="calling-info text-info ml-2" (click)="onCallingInfo()">(?)</a>
              </div>
              <label [ngClass]="{'d-none': accountCallingAllowed}" class="text-danger" for="allow_calling">{{ 'APP.MAIN.USERS.USER_DETAILS.CALLING_UNAVAILABLE' | translate }}</label>
            </div>
            <div class="form-group pl-4 col-xl-6">
                <select class="form-control" #allow_seeing_all_users [(ngModel)]="user.allow_seeing_all_users" name="allow_seeing_all_users" [disabled]="!accountCallingAllowed || !user.allow_calling">
                  <option [ngValue]="false">{{ 'APP.MAIN.USERS.USER_DETAILS.PERMISSION_TO_SEE_USERS_IN_SAME_ROOM' | translate }}</option>
                  <option [ngValue]="true">{{ 'APP.MAIN.USERS.USER_DETAILS.PERMISSION_TO_SEE_USERS_ALL' | translate }}</option>
                </select>
            </div>

            <div class="form-group col-12">
              <div class="custom-control custom-checkbox  custom-checkbox-sm d-flex align-items-center" [class.disabled]="!accountJoinLinkAllowed">
                <input #allow_join_link="ngModel" type="checkbox" class="custom-control-input" id="allow_join_link" name="allow_join_link" [disabled]="!accountJoinLinkAllowed" [(ngModel)]="user.allow_join_link">
                <label class="custom-control-label" for="allow_join_link">{{ 'APP.MAIN.USERS.USER_DETAILS.ALLOW_JOIN_LINK' | translate }}</label>
              </div>
              <label [ngClass]="{'d-none': accountJoinLinkAllowed, 'text-danger': !accountJoinLinkAllowed}" for="allow_join_link">{{ 'APP.MAIN.USERS.USER_DETAILS.JOIN_LINK_UNAVAILABLE' | translate }}</label>
            </div>
          </div>

          <div class="form-row" *ngIf="('design' | env).showArchivePermission">
            <div class="col-12 font-weight-bold text-info mb-2">{{ 'APP.MAIN.USERS.USER_DETAILS.PERSONAL_ROOM_SETTINGS' | translate }}</div>
            <div class="form-group col-12">
              <div class="custom-control custom-checkbox  custom-checkbox-sm d-flex align-items-center" [class.disabled]="!accountArchiveAllowed">
                <input #call_archive_perm_needed="ngModel" type="checkbox" class="custom-control-input" id="call_archive_perm_needed" name="call_archive_perm_needed" [disabled]="!accountArchiveAllowed" [(ngModel)]="user.call_archive_perm_needed">
                <label class="custom-control-label" for="call_archive_perm_needed">{{ 'APP.MAIN.USERS.USER_DETAILS.CALL_ARCHIVE_PERM_NEEDED' | translate }}</label>
              </div>
              <label [ngClass]="{'d-none': accountArchiveAllowed}" class="text-danger" for="call_archive_perm_needed">{{ 'APP.MAIN.USERS.USER_DETAILS.ARCHIVE_UNAVAILABLE' | translate }}</label>
            </div>
            <div class="form-group col-12" *ngIf="accountFullHdAllowed">
              <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
                <input #call_full_hd="ngModel" type="checkbox" class="custom-control-input" id="call_full_hd" name="call_full_hd" [(ngModel)]="user.call_full_hd" [disabled]="accountAlwaysFullHd">
                <label class="custom-control-label" for="call_full_hd">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.FULL_HD' | translate }}</label>
              </div>
              <label *ngIf="accountAlwaysFullHd" class="text-danger" for="call_full_hd">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.ALWAYS_FULL_HD_ON' | translate }}</label>
              <label *ngIf="user.call_full_hd && !accountAlwaysFullHd" class="text-info" for="call_full_hd">{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.FULL_HD_DISADVANTAGES' | translate }}</label>
            </div>
          </div>

          <div class="form-group password-change" *ngIf="action === ACTION_NEW">
            <div class="row">
              <div class="col-sm">
                <div class="form-group">
                  <label for="password">{{ 'APP.MAIN.USERS.USER_DETAILS.PASSWORD' | translate }}<span class="required-field">*</span></label>
                  <input 
                    #password="ngModel" 
                    class="form-control" 
                    type="password" 
                    name="password" 
                    placeholder="{{ 'APP.MAIN.USERS.USER_DETAILS.PASSWORD' | translate }}" 
                    [ngClass]="{'is-invalid': password.errors && password.touched}"
                    [(ngModel)]="passwordGroup.password"
                    [customRegexList]="regexList"
                    whitespaceValidator
                    undesiredPasswordListValidator
                    [minlengthValidator]='minPassLength' 
                    [ngStyle]="{'width': action !== ACTION_DETAILS ? '100%' : '50%'}"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                    required
                  >
                  <small class="invalid-feedback" style="display: inline;" *ngIf="password.errors?.whitespace">{{ 'APP.MAIN.USERS.USER_DETAILS.NOT_WHITESPACE' | translate }}</small>
                  <small class="invalid-feedback" style="display: inline;" *ngIf="password.errors?.undesiredPassword">{{ 'APP.MAIN.USERS.USER_DETAILS.DO_NOT_CREATE_SIMPLE' | translate }}</small>
                </div>
                <div class="form-group">
                  <label for="confirmPassword">{{ 'APP.MAIN.USERS.USER_DETAILS.CONFIRM_PASSWORD' | translate }}<span class="required-field">*</span></label>
                  <input 
                    #confirmPassword="ngModel" 
                    class="form-control" 
                    type="password" 
                    name="confirmPassword" 
                    placeholder="{{ 'APP.MAIN.USERS.USER_DETAILS.CONFIRM_PASSWORD' | translate }}" 
                    [(ngModel)]="passwordGroup.confirmPassword"
                    [ngStyle]="{'width': action !== ACTION_DETAILS ? '100%' : '50%'}"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                    required
                  >
                  <small class="invalid-feedback" style="display: inline;" [hidden]="!confirmPassword.errors?.mustMatch">{{ 'APP.MAIN.USERS.USER_DETAILS.CONFIRM_PASSWORD_NOTMATCH' | translate }}</small>
                </div>
              </div>
              <div class="col-sm custom-control-results">
                <div class="title mb-2">{{ 'APP.MAIN.USERS.USER_DETAILS.CREATE_NEW_PASSWORD' | translate }}</div>
                <div class="d-flex align-items-center" *ngIf="AZContains">
                  <i class="mi mi-done mr-1" [ngClass]="{'unconfirmed': password.errors?.containsUppercase, 'confirmed': !password.errors?.containsUppercase}"></i>
                  <p style="font-size: 12px;">{{ 'APP.MAIN.USERS.USER_DETAILS.UPPERCASE' | translate }}&nbsp;<label class="custom-control-identifier">(A-Z)</label></p>
                </div>
                <div class="d-flex align-items-center" *ngIf="azContains">
                  <i class="mi mi-done mr-1" [ngClass]="{'unconfirmed': password.errors?.containsLowercase, 'confirmed': !password.errors?.containsLowercase}"></i>
                  <p style="font-size: 12px;">{{ 'APP.MAIN.USERS.USER_DETAILS.LOWERCASE' | translate }}&nbsp;<label class="custom-control-identifier">(a-z)</label></p>
                </div>
                <div class="d-flex align-items-center" *ngIf="numberContains">
                  <i class="mi mi-done mr-1" [ngClass]="{'unconfirmed': password.errors?.containsNumber, 'confirmed': !password.errors?.containsNumber}"></i>
                  <p style="font-size: 12px;">{{ 'APP.MAIN.USERS.USER_DETAILS.NUMBER' | translate }}&nbsp;<label class="custom-control-identifier">(0-9)</label></p>
                </div>
                <div class="d-flex align-items-center" *ngIf="specialCharacterContains">
                  <i class="mi mi-done mr-1" [ngClass]="{'unconfirmed': password.errors?.containsSpecialCharacter, 'confirmed': !password.errors?.containsSpecialCharacter}"></i>
                  <p style="font-size: 12px;">{{ 'APP.MAIN.USERS.USER_DETAILS.SPECIAL' | translate }}&nbsp;<label class="custom-control-identifier">(-*/?#&())</label></p>
                </div>
                <div class="d-flex align-items-center">
                  <i class="mi mi-done mr-1" [ngClass]="{'confirmed': !password.errors?.minlengthAccessed, 'unconfirmed': password.errors?.minlengthAccessed}"></i>
                  <p style="font-size: 12px;">{{ 'APP.MAIN.USERS.USER_DETAILS.AT_LEAST_PASSWORD_LENGTH' | translate:{passwordlength:minPassLength} }}</p>
                </div>
              </div>    
            </div>
          </div>

          <div class="form-group" *ngIf="accountAutoJoinRoomAllowed">
            <label for="default_room">{{ 'APP.MAIN.USERS.USER_DETAILS.DEFAULT_ROOM' | translate }}</label>
            <ng-select [items]="selectedUserRooms" name="default_room" bindLabel="name" bindValue="id" [clearable]= "true" [(ngModel)]="user.default_room" [loading]="roomsLoading"
              placeholder="{{ 'APP.MAIN.USERS.USER_DETAILS.DEFAULT_ROOM' | translate }}">
            </ng-select>
          </div>

          <div class="form-group">
            <label for="rooms">{{ 'APP.MAIN.USERS.USER_DETAILS.ROOMS' | translate }}</label>
            <ng-select [items]="rooms" name="rooms" bindLabel="name" bindValue="id" [multiple]="true" [clearSearchOnAdd] = "true" (change)="userRoomsChanged($event)"
              placeholder="{{ 'APP.MAIN.USERS.USER_DETAILS.SELECT_ROOMS' | translate }}" [(ngModel)]="user.rooms" [closeOnSelect]="false" [loading]="roomsLoading">
            </ng-select>
          </div>

          <button class="btn btn-info btn-md-inline btn-block" type="submit"
              [disabled]="!userForm.valid || formPristine || formLoading || (orjUser?.role !== 'expert' && user.role === 'expert' && expertLimitReached)">{{ 'APP.MAIN.USERS.USER_DETAILS.SAVE' | translate }}</button>
        </form>
      </div>
    </div>

    <div class="card my-4" *ngIf="integrationAllowed && action !== ACTION_NEW">
      <div class="card-header d-flex justify-content-between align-items-center">
        <span class="card-header-title">{{ 'APP.MAIN.USERS.USER_DETAILS.SINGLE_SIGN_ON_CODE' | translate }}</span>
      </div>
      <div class="card-body px-lg-5">
        <a #code class="integration d-block rounded w-100 p-2" style="cursor: pointer;" (click)="integrationCode ? null : getIntegrationCode()"
          [class.disabled]="integrationLoading" [copy-clipboard]="integrationCode" [select-text]="code" (copied)="integrationCodeCopied()">
          <span *ngIf="integrationCode">{{ integrationCode }}</span>
          <span *ngIf="!integrationCode">{{ (integrationLoading ? 'APP.MAIN.USERS.USER_DETAILS.GENERATING' : 'APP.MAIN.USERS.USER_DETAILS.GENERATE_CODE') | translate }}</span>
        </a>
        <div class="integration-hint text-info mt-2" *ngIf="integrationCode">{{ 'APP.MAIN.USERS.USER_DETAILS.CLICK_CODE_FOR_COPY' | translate }}</div>
      </div>
    </div>

    <!--  PASSWORD CHANGE  -->
    <div class="password-change card my-4" *ngIf="action !== ACTION_NEW && !ad_user">
      <div class="card-header d-flex justify-content-between align-items-center">
        <span class="card-header-title">{{ 'APP.MAIN.USERS.USER_DETAILS.CHANGE_PASSWORD_TITLE' | translate }}</span>
      </div>
      <div class="card-body px-lg-5">
        <div class="form-col">
          <form #passwordForm="ngForm" autocomplete="off" (ngSubmit)="onPasswordFormSubmit(passwordForm)" [mustMatch]="['password', 'confirmPassword']">
            <div class="row">
              <div class="col-sm">
                <div class="form-group">
                  <label for="password">{{ 'APP.MAIN.USERS.USER_DETAILS.PASSWORD' | translate }}<span class="required-field">*</span></label>
                  <input
                    #password="ngModel" 
                    class="form-control" 
                    type="password" 
                    name="password" 
                    placeholder="{{ 'APP.MAIN.USERS.USER_DETAILS.PASSWORD' | translate }}"
                    [(ngModel)]="passwordGroup.password"
                    [customRegexList]="regexList"
                    whitespaceValidator
                    undesiredPasswordListValidator
                    [minlengthValidator]='minPassLength'
                    [ngStyle]="{'background-color': action === ACTION_EDIT ? 'white' : '#e9ecef', 'width': action !== ACTION_DETAILS ? '100%' : '50%'}"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                    required
                  />
                  <small class="invalid-feedback" style="display: inline;" *ngIf="password.errors?.whitespace">{{ 'APP.MAIN.USERS.USER_DETAILS.NOT_WHITESPACE' | translate }}</small>
                  <small class="invalid-feedback" style="display: inline;" *ngIf="password.errors?.undesiredPassword">{{ 'APP.MAIN.USERS.USER_DETAILS.DO_NOT_CREATE_SIMPLE' | translate }}</small>
                </div>
                <div class="form-group">
                  <label for="confirmPassword">{{ 'APP.MAIN.USERS.USER_DETAILS.CONFIRM_PASSWORD' | translate }}<span class="required-field">*</span></label>
                  <input 
                    #confirmPassword="ngModel"
                    class="form-control" 
                    type="password" 
                    name="confirmPassword" 
                    placeholder="{{ 'APP.MAIN.USERS.USER_DETAILS.CONFIRM_PASSWORD' | translate }}" 
                    [(ngModel)]="passwordGroup.confirmPassword"
                    [ngStyle]="{'background-color': action === ACTION_EDIT ? 'white' : '#e9ecef', 'width': action !== ACTION_DETAILS ? '100%' : '50%'}"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                    required
                  />
                  <small class="invalid-feedback mx-2" style="display: inline;" *ngIf="confirmPassword.errors?.mustMatch">{{ 'APP.MAIN.USERS.USER_DETAILS.CONFIRM_PASSWORD_NOTMATCH' | translate }}</small>
                </div>
              </div>
              <div class="col-sm custom-control-results" *ngIf="action === ACTION_EDIT && !formLoading">
                <div class="title mb-2">{{ 'APP.MAIN.USERS.USER_DETAILS.CREATE_NEW_PASSWORD' | translate }}</div>
                <div class="d-flex align-items-center" *ngIf="AZContains">
                  <i class="mi mi-done mr-1" [ngClass]="{'unconfirmed': action !== ACTION_EDIT || (password.errors?.containsUppercase), 'confirmed': !(password.errors?.containsUppercase) && action === ACTION_EDIT}"></i>
                  <label style="font-size: 12px;">{{ 'APP.MAIN.USERS.USER_DETAILS.UPPERCASE' | translate }}&nbsp;
                  <label class="custom-control-identifier">(A-Z)</label></label>
                </div>
                <div class="d-flex align-items-center" *ngIf="azContains">
                  <i class="mi mi-done mr-1" [ngClass]="{'unconfirmed': action !== ACTION_EDIT || (password.errors?.containsLowercase), 'confirmed': !(password.errors?.containsLowercase) && action === ACTION_EDIT}"></i>
                  <label style="font-size: 12px;">{{ 'APP.MAIN.USERS.USER_DETAILS.LOWERCASE' | translate }}&nbsp;
                  <label class="custom-control-identifier">(a-z)</label></label>
                </div>
                <div class="d-flex align-items-center" *ngIf="numberContains">
                  <i class="mi mi-done mr-1" [ngClass]="{'unconfirmed': action !== ACTION_EDIT || (password.errors?.containsNumber), 'confirmed': !(password.errors?.containsNumber) && action === ACTION_EDIT}"></i>
                  <label style="font-size: 12px;">{{ 'APP.MAIN.USERS.USER_DETAILS.NUMBER' | translate }}&nbsp;
                  <label class="custom-control-identifier">(0-9)</label></label>
                </div>
                <div class="d-flex align-items-center" *ngIf="specialCharacterContains">
                  <i class="mi mi-done mr-1" [ngClass]="{'unconfirmed': action !== ACTION_EDIT || (password.errors?.containsSpecialCharacter), 'confirmed': !(password.errors?.containsSpecialCharacter) && action === ACTION_EDIT}"></i>
                  <label style="font-size: 12px;">{{ 'APP.MAIN.USERS.USER_DETAILS.SPECIAL' | translate }}&nbsp;
                  <label class="custom-control-identifier">(-*/?#&())</label></label>
                </div>
                <div class="d-flex align-items-center">
                  <i class="mi mi-done mr-1" [ngClass]="{'confirmed': !password.errors?.minlengthAccessed, 'unconfirmed': password.errors?.minlengthAccessed}"></i>
                  <label style="font-size: 12px;">{{ 'APP.MAIN.USERS.USER_DETAILS.AT_LEAST_PASSWORD_LENGTH' | translate:{passwordlength:minPassLength} }}</label>
                </div>
              </div>
            </div>
            <button class="btn btn-success btn-md-inline btn-block" type="submit" [disabled]="!passwordForm.valid || passwordChanging">{{ 'APP.MAIN.USERS.USER_DETAILS.CHANGE_PASSWORD' | translate }}</button>
          </form>
        </div>
      </div>
    </div>

    <!--  QR CODE  -->
    <div class="card my-4" *ngIf="action === ACTION_DETAILS && !ad_user">
      <div class="card-header d-flex justify-content-between align-items-center">
        <span class="card-header-title">{{ 'APP.MAIN.USERS.USER_DETAILS.QR_CODE_TITLE' | translate }}</span>
      </div>
      <div class="card-body">
        <div class="container-fluid">
          <span class="d-block text-center" *ngIf="user.disabled">
            {{ 'APP.MAIN.USERS.USER_DETAILS.QR_ENABLE_FIRST' | translate }}
          </span>
          <div class="row" *ngIf="!user.disabled">
            <ng-container *ngIf="!qrData">
              <div class="col-lg-12" *ngIf="qrLoading">
                  <div class="qr-error-message">{{ 'APP.MAIN.USERS.USER_DETAILS.QR_CODE_LOADING' | translate }}</div>
              </div>

              <ng-container *ngIf="!qrLoading">
                <div class="col-lg-6 text-center text-lg-left">
                  <button class="btn btn-danger qr-generate-btn" (click)="onGenerateQR()" [disabled]="generateDisabled">{{ 'APP.MAIN.USERS.USER_DETAILS.GENERATE_QR' | translate }}</button>
                </div>
                <div class="col-lg-6 d-flex align-items-center text-center text-lg-left">
                  <div class="qr-error-message w-100 mt-3 mt-lg-0">{{ 'APP.MAIN.USERS.USER_DETAILS.NO_QR_CODE' | translate }}</div>
                </div>
              </ng-container>
            </ng-container>
            <div class="col-lg-6 order-lg-2" *ngIf="qrData">
              <div class="qr-container d-flex justify-content-center">
                <qrcode [qrdata]="qrData" [width]="300" [errorCorrectionLevel]="'M'" #qr></qrcode>
              </div>
            </div>
            <div class="col-lg-6 order-lg-1 d-flex align-items-center" *ngIf="qrData">
              <div class="qr-info mx-auto w-70">
                <div class="qr-title text-center text-lg-left my-4">{{ 'APP.MAIN.USERS.USER_DETAILS.QR_MESSAGE' | translate }}</div>
                <!-- <div class="qr-message text-center text-lg-left">1. Lorem ipsum dolor sit amet.</div>
                <div class="qr-message text-center text-lg-left mt-2">2. Lorem ipsum dolor sit amet consectetur.</div>
                <div class="qr-message text-center text-lg-left mt-2 mb-3">3. Lorem, ipsum dolor.</div> -->
                <button class="btn btn-info btn-block" (click)="onDownloadQR()">{{ 'APP.MAIN.USERS.USER_DETAILS.DOWNLOAD' | translate }}</button>
                <button class="btn btn-warning btn-block" (click)="onRegenerateQR()">{{ 'APP.MAIN.USERS.USER_DETAILS.REGENERATE' | translate }}</button>
                <button class="btn btn-danger btn-block" (click)="onRemoveQR()">{{ 'APP.MAIN.USERS.USER_DETAILS.REMOVE' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="user.role !== 'admin' && action === ACTION_EDIT">
      <div class="mx-4 mx-lg-5">
        <button class="btn btn-outline-secondary btn-md-inline btn-block mt-3" (click)="onToggle()" [disabled]="ad_user">{{ 'APP.MAIN.USERS.USER_DETAILS.DISABLE_USER' | translate }}</button>
      </div>
      <div class="mx-4 mx-lg-5">
        <button class="btn btn-outline-danger btn-md-inline btn-block mt-3" (click)="onDelete()" [disabled]="ad_user">{{ 'APP.MAIN.USERS.USER_DETAILS.DELETE_USER' | translate }}</button>
      </div>
    </ng-container>

    <ng-container *ngIf="user.role !== 'admin' && action === ACTION_DETAILS && user.disabled">
      <div class="mx-4 mx-lg-5">
        <button class="btn btn-primary btn-md-inline btn-block mt-3" (click)="onToggle()" [disabled]="ad_user">{{ 'APP.MAIN.USERS.USER_DETAILS.ENABLE_USER' | translate }}</button>
      </div>
      <div class="mx-4 mx-lg-5">
        <button class="btn btn-outline-danger btn-md-inline btn-block mt-3" (click)="onDelete()" [disabled]="ad_user">{{ 'APP.MAIN.USERS.USER_DETAILS.DELETE_USER' | translate }}</button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #callInfoTemplate let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">{{ 'APP.MAIN.USERS.USER_DETAILS.CALL_INFO_MODAL.TITLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">{{ 'APP.MAIN.USERS.USER_DETAILS.CALL_INFO_MODAL.MESSAGE' | translate }}</div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.close()">{{ 'APP.MAIN.USERS.USER_DETAILS.CALL_INFO_MODAL.CLOSE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #removeQrTemplate let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">
          {{ 'APP.MAIN.USERS.USER_DETAILS.QR_REMOVE_TITLE' | translate }}
        </div>
        <div class="card-body">
          <div class="m-body padded">
            {{ 'APP.MAIN.USERS.USER_DETAILS.QR_REMOVE_MESSAGE' | translate }}
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.no()">{{ 'APP.MAIN.USERS.USER_DETAILS.QR_REMOVE_NO' | translate }}</a>
            <a class="text-danger" (click)="callbacks.yes()">{{ 'APP.MAIN.USERS.USER_DETAILS.QR_REMOVE_YES' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #regenerateQrTemplate let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">
          {{ 'APP.MAIN.USERS.USER_DETAILS.QR_REGENERATE_TITLE' | translate }}
        </div>
        <div class="card-body">
          <div class="m-body padded">
            {{ 'APP.MAIN.USERS.USER_DETAILS.QR_REGENERATE_MESSAGE' | translate }}
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.no()">{{ 'APP.MAIN.USERS.USER_DETAILS.QR_REGENERATE_NO' | translate }}</a>
            <a class="text-danger" (click)="callbacks.yes()">{{ 'APP.MAIN.USERS.USER_DETAILS.QR_REGENERATE_YES' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #changePassTemplate let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">
          {{ 'APP.MAIN.USERS.USER_DETAILS.CHANGE_PASSWORD_MODAL.TITLE' | translate }}
        </div>
        <div class="card-body">
          <div class="m-body padded">
            <div>{{ 'APP.MAIN.USERS.USER_DETAILS.CHANGE_PASSWORD_MODAL.MESSAGE' | translate }}</div>
            <div class="text-danger font-weight-bold mt-2">{{ 'APP.MAIN.USERS.USER_DETAILS.CHANGE_PASSWORD_MODAL.CAUTION' | translate }}</div>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.no()">{{ 'APP.MAIN.USERS.USER_DETAILS.CHANGE_PASSWORD_MODAL.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.yes()">{{ 'APP.MAIN.USERS.USER_DETAILS.CHANGE_PASSWORD_MODAL.CHANGE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #updateUserTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">
          {{ 'APP.MAIN.USERS.USER_DETAILS.UPDATE_USER_MODAL.TITLE' | translate }}
        </div>
        <div class="card-body">
          <div class="m-body padded">
            <ng-container *ngIf="dataModel.username">
              <div>{{ 'APP.MAIN.USERS.USER_DETAILS.UPDATE_USER_MODAL.USENAME_MESSAGE' | translate }}</div>
              <div class="text-danger font-weight-bold mt-2">{{ 'APP.MAIN.USERS.USER_DETAILS.UPDATE_USER_MODAL.USENAME_CAUTION' | translate }}</div>
            </ng-container>
            <ng-container *ngIf="dataModel.email">
              <div [ngClass]="{'mt-3': dataModel.username}">{{ 'APP.MAIN.USERS.USER_DETAILS.UPDATE_USER_MODAL.EMAIL_MESSAGE' | translate }}</div>
              <div class="text-danger font-weight-bold mt-2">{{ 'APP.MAIN.USERS.USER_DETAILS.UPDATE_USER_MODAL.EMAIL_CAUTION' | translate }}</div>
            </ng-container>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.no()">{{ 'APP.MAIN.USERS.USER_DETAILS.UPDATE_USER_MODAL.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.yes()">{{ 'APP.MAIN.USERS.USER_DETAILS.UPDATE_USER_MODAL.CHANGE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #withoutRoomTemplate let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">
          {{ 'APP.MAIN.USERS.USER_DETAILS.WITHOUT_ROOM_MODAL.TITLE' | translate }}
        </div>
        <div class="card-body">
          <div class="m-body padded">
            <div>{{ 'APP.MAIN.USERS.USER_DETAILS.WITHOUT_ROOM_MODAL.MESSAGE' | translate }}</div>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.no()">{{ 'APP.MAIN.USERS.USER_DETAILS.WITHOUT_ROOM_MODAL.CANCEL' | translate }}</a>
            <a class="text-danger" (click)="callbacks.yes()">{{ 'APP.MAIN.USERS.USER_DETAILS.WITHOUT_ROOM_MODAL.CREATE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #toggleTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div *ngIf="dataModel.disabled" class="card-header py-3">{{ 'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.TITLE_ENABLE' | translate }}</div>
        <div *ngIf="!dataModel.disabled" class="card-header py-3">{{ 'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.TITLE_DISABLE' | translate }}</div>
        <div class="card-body">
          <div class="m-body padded">
            <div *ngIf="dataModel.disabled" [innerHTML]="'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.MESSAGE_ENABLE' | translate:dataModel"></div>
            <div *ngIf="!dataModel.disabled" [innerHTML]="'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.MESSAGE_DISABLE' | translate:dataModel"></div>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.no()">{{ 'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.CANCEL' | translate }}</a>
            <a *ngIf="dataModel.disabled" class="text-primary" (click)="callbacks.yes()">{{ 'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.ENABLE' | translate }}</a>
            <a *ngIf="!dataModel.disabled" class="text-secondary" (click)="callbacks.yes()">{{ 'APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.DISABLE' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #delUserTemplate let-dataModel="dataModel" let-callbacks="callbacks">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="card">
        <div class="card-header py-3">
          {{ 'APP.MAIN.USERS.USER_DETAILS.DELETE_USER_MODAL.TITLE' | translate }}
        </div>
        <div class="card-body">
          <div class="m-body padded">
            <div [innerHTML]="'APP.MAIN.USERS.USER_DETAILS.DELETE_USER_MODAL.MESSAGE' | translate:dataModel"></div>
          </div>
          <div class="m-footer">
            <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.USERS.USER_DETAILS.DELETE_USER_MODAL.CANCEL' | translate:dataModel.user }}</a>
            <a class="text-danger" (click)="callbacks.delete()">{{ 'APP.MAIN.USERS.USER_DETAILS.DELETE_USER_MODAL.DELETE' | translate:dataModel.user }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
