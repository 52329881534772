import { Directive, Input, OnInit, OnDestroy, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[addNativeElement]'
})
export class AddNativeElementDirective implements OnInit, OnChanges, OnDestroy {
    @Input('addNativeElement') addNativeElement: any;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        if (this.addNativeElement) {
            this.addNativeElement.nativeElement = this.el.nativeElement;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.addNativeElement && changes.addNativeElement && changes.addNativeElement.currentValue) {
            this.addNativeElement.nativeElement = this.el.nativeElement;
        }
    }

    ngOnDestroy() {
        if (this.addNativeElement) {
            this.addNativeElement.nativeElement = null;
        }
    }
}