import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent implements OnInit, OnDestroy {

  loading: boolean = true;

  authSubscription: Subscription = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // Navigate main if user already authenticated
    this.authSubscription = this.authService.getAuth().subscribe(auth => {
      if (auth) {
        this.router.navigate(['/']);
      }
    });
    this.authenticate();
  }

  ngOnDestroy() {
    if (this.authSubscription) { this.authSubscription.unsubscribe() }
  }

  async authenticate() {
    const params = await this.route.queryParams.pipe(first()).toPromise();
    if (!(params && params.id)) {
      this.loading = false;
      return;
    }

    this.authService.authenticateSSO(params.id)
    .then(result => this.authService.loginToFirebase(result.token))
    .catch(error => { this.loading = false; });
  }
}
