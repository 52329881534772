<div class="sidebar" [ngClass]="{'sidebar-open': sidebarOpen}">
    <app-sidebar (sideLinkClicked)="onSidebarLinkClicked()"></app-sidebar>
</div>
<div class="main-container" [ngClass]="{'sidebar-open': sidebarOpen}">
    <div class="main-overlay" *ngIf="sidebarOpen" (click)="toggleSidebar()"></div>
    <app-navbar [openProfileRequest]="openProfileRequest"></app-navbar>

    <div class="main-content px-md-3 px-lg-5" #mainContent>
        <div *ngIf="!isDataLoaded" class="loader">
            <div class="spinner"></div>
        </div>
        <div class="container-fluid">
            <router-outlet *ngIf="isDataLoaded"></router-outlet>
        </div>
    </div>

    <template #detailContainer></template>
    <template #detailContainer2></template>
</div>

<ng-template #idleTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3">{{ 'APP.MAIN.IDLE_MODAL.TITLE' | translate }}</div>
                <div class="card-body">
                    <div class="m-body px-3 py-4">
                        <p class="text-secondary">{{ 'APP.MAIN.IDLE_MODAL.MESSAGE' | translate }}</p>
                        <p class="text-danger font-weight-bold mt-2">{{ dataModel.countMessage }}</p>
                    </div>
                    <div class="m-footer">
                        <a class="text-info" (click)="callbacks.ok()">{{ 'APP.MAIN.IDLE_MODAL.OK' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #sessionExportTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog modal-dialog-wide session-export-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header px-4">
                <h5 class="modal-title">{{ 'APP.MAIN.GET_EXPORT_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body p-4">
                <div class="mb-3 ml-1" style="font-size: 14px;">{{ 'APP.MAIN.GET_EXPORT_MODAL.MESSAGE1' | translate }}</div>
                <div class="mb-3 ml-1" style="font-size: 14px;" [innerHTML]="'APP.MAIN.GET_EXPORT_MODAL.MESSAGE2' | translate"></div>
                <div class="form-group mb-1">
                    <div class="input-group">
                        <input type="text" class="form-control" [class.is-invalid]="dataModel.exportName.length === 0" [disabled]="isExportNameLocked" style="font-size: 14px;" maxlength="72" required
                            placeholder="{{ 'APP.MAIN.GET_EXPORT_MODAL.EXPORT_NAME_PLACEHOLDER' | translate }}" [(ngModel)]="dataModel.exportName" (ngModelChange)="onExportNameChange($event)">
                        <div class="input-group-append">
                            <span class="input-group-text" style="font-size: 14px; cursor: default;">.zip</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isExportNameLocked" class="ml-1" style="font-size: 14px;">{{ 'APP.MAIN.GET_EXPORT_MODAL.EXPORT_NAME_NOT_LOCKED' | translate }} <span *ngIf="exportNameAuthor"> {{ 'APP.MAIN.GET_EXPORT_MODAL.EXPORT_NAME_RENAMED' | translate }} {{ exportNameAuthor }}</span></div>
                <div *ngIf="isExportNameLocked" class="ml-1" style="font-size: 14px;">{{ 'APP.MAIN.GET_EXPORT_MODAL.EXPORT_NAME_LOCKED' | translate }} <span *ngIf="exportNameAuthor"> {{ 'APP.MAIN.GET_EXPORT_MODAL.EXPORT_NAME_RENAMED' | translate }} {{ exportNameAuthor }}</span></div>
                <div class="form-group mt-3 mb-0">
                    <tag-input #tagInput name="emailRecipients" style="font-size: 14px;" [(ngModel)]="dataModel.emailRecipients" placeholder="{{ 'APP.MAIN.EMAIL_RECIPIENTS.PLACEHOLDER' | translate }}"
                        secondaryPlaceholder="{{ 'APP.MAIN.EMAIL_RECIPIENTS.SECONDARY_PLACEHOLDER' | translate }}" theme="minimal" [validators]="[validMailValidator]" [errorMessages]="invalidEmailError"
                        [animationDuration]="{enter: '0ms', leave: '0ms'}" [addOnBlur]="true"></tag-input>
                </div>

                <div class="mt-3 ml-1 text-info" style="font-size: 14px;"><span>{{ 'APP.MAIN.GET_EXPORT_MODAL.NOTE' | translate }}</span><a (click)="callbacks.openProfile()" class="ml-1" style="cursor: pointer; text-decoration: underline;">{{ 'APP.MAIN.GET_EXPORT_MODAL.OPEN_PROFILE' | translate }}</a></div>
            </div>
            <div class="modal-footer px-4">
                <a class="text-dark" (click)="callbacks.close()">{{ 'APP.MAIN.GET_EXPORT_MODAL.CLOSE' | translate }}</a>
                <a class="text-info" [class.disabled]="dataModel.emailRecipients.length === 0 || dataModel.exportName.length === 0" (click)="callbacks.getexport()">{{ 'APP.MAIN.GET_EXPORT_MODAL.SEND' | translate }}</a>
            </div>
        </div>
    </div>
</ng-template>