<div class="chat-container">  
    <div class="chat-list-area">
      <div class="chat-message mt-1" *ngFor="let message of logMessages; let i = index">
        <div class="text-message left" *ngIf="message.type === 'text'">
            <div class="owner-info my-1" *ngIf="!(i>0 && message.owner === logMessages[i-1].owner && logMessages[i-1].type === 'text')">
                {{ message.owner_info }}
            </div>
            <div class="chat-bubble">
                <div class="chat-text text-left">{{ message.content }}</div>
                <div class="chat-time text-right text-muted">{{ message.create_time | date:'HH:mm' }}</div>
            </div>
        </div>
  
        <div class="other-message" *ngIf="message.type !== 'text'">
            <div class="message-circle" [ngClass]="{ 'wide': messageHasFile(message) }">
                <div>{{ getMessageTranslationKey(message) | translate:message }}</div>
                <i *ngIf="message.type2 === 'reaction'" class="mi reaction" [ngClass]="'mi-'+message.content"></i>
                <div class="btn-group w-100" dropdown *ngIf="messageHasFile(message)">
                    <ng-container *ngIf="!message.deleted">
                        <div class="file-box mt-2">
                            <div class="file-info">
                                <div class="file-icon">
                                    <i class="mi mi-insert-drive-file"></i>
                                    <label class="file-extension">{{ getExtension(message.extra) }}</label>
                                </div>
                                <span class="file-name">{{ message.extra }}</span>
                            </div>
                            <!--<a class="file-button">
                                <i class="mi mi-gesture"></i>
                            </a>-->
                        </div>
                        <ul id="dropdown-more" *dropdownMenu class="dropdown-menu dropdown-menu-right py-1" role="menu" aria-labelledby="button-more">
                            <li role="menuitem"><a class="dropdown-item px-2">
                                <i class="mi mi-cloud-download mr-3"></i><span>{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.DOWNLOAD' | translate }}</span>
                            </a></li>
                            <li *ngIf="false && (message.type2 === 'screenshot' || message.owner === currentUser.id)" role="menuitem"><a class="dropdown-item px-2">
                                <i class="mi mi-delete mr-3"></i><span>{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.DELETE' | translate }}</span>
                            </a></li>
                            <li role="menuitem"><a class="dropdown-item px-2">
                                <i class="mi mi-info mr-3"></i><span>{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.DETAILS' | translate }}</span>
                            </a></li>
                        </ul>
                    </ng-container>
                    <div class="deleted-file-box mt-2" *ngIf="message.deleted">
                        <div class="deleted-icon">
                            <i class="mi mi-block"></i>
                        </div>
                        <span class="deleted-message">{{ 'APP.MAIN.SESSIONS.SESSION_DETAILS.SESSION_CHAT.FILE_DELETED' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>