<div *ngIf="loaderService.showLoader | async" class="loader">
    <div class="spinner"></div>
</div>

<app-modal></app-modal>
<app-flash-message></app-flash-message>

<router-outlet></router-outlet>

<link href="//amp.azure.net/libs/amp/latest/skins/amp-default/azuremediaplayer.min.css" rel="stylesheet">
<script src= "//amp.azure.net/libs/amp/latest/azuremediaplayer.min.js"></script>

<ng-template #updateTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3">{{ 'APP.UPDATE_MODAL.TITLE' | translate }}</div>
                <div class="card-body">
                    <div class="m-body padded">{{ 'APP.UPDATE_MODAL.MESSAGE' | translate }}</div>
                    <div class="m-footer">
                        <a class="text-danger" (click)="callbacks.update()">{{ 'APP.UPDATE_MODAL.UPDATE' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>