<div class="step-details-container px-md-3 px-lg-5">
    <div class="container-fluid h-100">
        <div class="row h-100">
            <div class="col-xl-4">
                <div class="card">
                    <a class="back-link" (click)="onBack()">
                        <i class="mi mi-arrow-back"></i><span>{{ 'APP.MAIN.STATIONS.STEP_DETAILS.BACK' | translate }}</span>
                    </a>
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <span class="card-header-title">{{ actionTitle }}</span>

                        <div class="btn-group" role="group" *ngIf="action === ACTION_DETAILS">
                            <button class="step-change-btn btn btn-sm btn-primary" type="button" (click)="onChangeStep('previous')" [disabled]="!hasPrevious">
                                <span class="d-inline d-xl-none">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.PREVIOUS' | translate }}</span>
                                <span class="d-none d-xl-inline">&#60;</span>
                            </button>
                            <button class="step-change-btn btn btn-sm btn-primary" type="button" (click)="onChangeStep('next')" [disabled]="!hasNext">
                                <span class="d-inline d-xl-none">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.NEXT' | translate }}</span>
                                <span class="d-none d-xl-inline">&#62;</span>
                            </button>
                        </div>

                        <button *ngIf="action === ACTION_DETAILS && !formLoading" class="btn btn-info" (click)="onEdit()">
                            {{ 'APP.MAIN.STATIONS.STEP_DETAILS.EDIT' | translate }}
                        </button>
                        <button *ngIf="action === ACTION_NEW && !formLoading" class="btn btn-danger" (click)="resetForm()" [disabled]="formPristine || stepForm.control.disabled">
                            {{ 'APP.MAIN.STATIONS.STEP_DETAILS.RESET' | translate }}
                        </button>
                        <div class="d-flex flex-row" *ngIf="action === ACTION_EDIT && !formLoading">
                            <button class="btn btn-secondary" (click)="cancelEdit()" [disabled]="stepForm.control.disabled">
                                {{ 'APP.MAIN.STATIONS.STEP_DETAILS.CANCEL' | translate }}
                            </button>
                            <button *ngIf="!formPristine" class="btn btn-danger" (click)="resetForm()" [disabled]="stepForm.control.disabled">
                                {{ 'APP.MAIN.STATIONS.STEP_DETAILS.REVERT' | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <form #stepForm="ngForm" (ngSubmit)="onSubmit(stepForm)">
                            <div class="form-group">
                                <label for="name">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.NAME' | translate }}</label>
                                <input #name="ngModel" class="form-control" type="text" name="name" placeholder="{{ 'APP.MAIN.STATIONS.STEP_DETAILS.NAME' | translate }}" 
                                    [ngClass]="{'is-invalid':name.errors && name.touched}"
                                    [(ngModel)]="step.name" minlength="1" maxlength="100" required>
                                <small class="invalid-feedback mx-2" [hidden]="!name.errors?.required">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.NAME_REQUIRED' | translate }}</small>
                                <small class="invalid-feedback mx-2" [hidden]="!name.errors?.minlength">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.NAME_LENGTH' | translate }}</small>
                            </div>
    
                            <div class="form-group">
                                <label for="description">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.DESCRIPTION' | translate }}</label>
                                <input #description="ngModel" class="form-control" type="text" name="description" placeholder="{{ 'APP.MAIN.STATIONS.STEP_DETAILS.DESCRIPTION' | translate }}" 
                                    [ngClass]="{'is-invalid':description.errors && description.touched}" [(ngModel)]="step.description">
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-checkbox custom-checkbox-sm d-flex align-items-center">
                                    <input #photoNeeded="ngModel" type="checkbox" class="custom-control-input" id="photoNeeded" name="photoNeeded" [(ngModel)]="step.photoNeeded">
                                    <label class="custom-control-label" for="photoNeeded">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.PHOTO_NEEDED' | translate }}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="cycleTime">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.CYCLE_TIME' | translate }}</label>
                                <input #cycleTime="ngModel" class="form-control" type="number" name="cycleTime" placeholder="{{ 'APP.MAIN.STATIONS.STEP_DETAILS.CYCLE_TIME' | translate }}" 
                                    [ngClass]="{'is-invalid':cycleTime.errors && cycleTime.touched}"
                                    [(ngModel)]="step.cycleTime" min="0" step="1">
                            </div>
                            <div class="form-group" *ngIf="step.mainImage">
                                <img class="media-control-img" [src]="step.mainImage">
                            </div>
                            <div class="form-group" *ngIf="step.video">
                                <video class="media-control-video" controls="">
                                    <source [src]="step.video" type="video/mp4">
                                </video>
                            </div>
                            <!--
                            <div class="form-group h-100 d-flex flex-column justify-content-between">
                                <label for="mainImageName">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.MAIN_IMAGE' | translate }}</label>
                                <img #mainImageImg class="media-control-img" [src]="step.mainImage">

                                <div class="media-control-inputs mt-2">
                                    <a class="media-control-delete" [ngClass]="{'disabled text-secondary': disabledControls.video, 'text-danger': !disabledControls.video}" (click)="onMediaDelete('mainImage', mainImageImg, mainImageFile, mainImageName)"><i class="mi mi-delete"></i></a>
                                    <input class="media-control-name form-control" #mainImageName type="text" name="mainImageName" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHOOSE_FILE' | translate }}" (click)="mainImageFile.click()" [disabled]="disabledControls.mainImage">
                                    <input class="media-control-file d-none" #mainImageFile type="file" name="mainImageFile" placeholder="Technical Drawing" accept="image/x-png,image/jpeg" (change)="onMediaChange('mainImage', $event, mainImageImg, mainImageName)" hidden>
                                    <input class="media-control-content d-none" [(ngModel)]="step.mainImage" #mainImage="ngModel" type="text" name="mainImage" hidden>
                                </div>
                            </div>
                            
                            <div class="form-group h-100 d-flex flex-column justify-content-between">
                                <label for="iconImage1Name">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.ICON_IMAGE' | translate }}</label>
                                <img #iconImage1Img class="media-control-img" [src]="step.iconImage1">

                                <div class="media-control-inputs mt-2">
                                    <a class="media-control-delete" [ngClass]="{'disabled text-secondary': disabledControls.video, 'text-danger': !disabledControls.video}" (click)="onMediaDelete('iconImage1', iconImage1Img, iconImage1File, iconImage1Name)"><i class="mi mi-delete"></i></a>
                                    <input class="media-control-name form-control" #iconImage1Name type="text" name="iconImage1Name" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHOOSE_FILE' | translate }}" (click)="iconImage1File.click()" [disabled]="disabledControls.iconImage1">
                                    <input class="media-control-file d-none" #iconImage1File type="file" name="iconImage1File" placeholder="Hand Tools" accept="image/x-png,image/jpeg" (change)="onMediaChange('iconImage1', $event, iconImage1Img, iconImage1Name)" hidden>
                                    <input class="media-control-content d-none" [(ngModel)]="step.iconImage1" #iconImage1="ngModel" type="text" name="iconImage1" hidden>
                                </div>
                            </div>
                            <div class="form-group h-100 d-flex flex-column justify-content-between">
                                <label for="videoName">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.VIDEO' | translate }}</label>
                                <video #videoElement class="media-control-video" controls="">
                                    <source [src]="step.video" type="video/mp4">
                                </video>

                                <div class="media-control-inputs">
                                    <a class="media-control-delete" [ngClass]="{'disabled text-secondary': disabledControls.video, 'text-danger': !disabledControls.video}" (click)="onMediaDelete('video', videoElement, videoFile, videoName)"><i class="mi mi-delete"></i></a>
                                    <input class="media-control-name form-control" #videoName type="text" name="videoName" placeholder="{{ 'APP.MAIN.ROOMS.ROOM_DETAILS.CHOOSE_FILE' | translate }}" (click)="videoFile.click()" [disabled]="disabledControls.video">
                                    <input class="media-control-file d-none" #videoFile type="file" name="videoFile" placeholder="Video" accept="video/mp4,video/x-m4v" (change)="onMediaChange('video', $event, videoElement, videoName)" hidden>
                                    <input class="media-control-content d-none" [(ngModel)]="step.video" #video="ngModel" type="text" name="video" hidden>
                                </div>
                            </div>
                            -->

                            <button class="btn btn-info btn-md-inline btn-block" type="submit" [disabled]="!stepForm.valid || formPristine || formLoading">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.SAVE' | translate }}</button>
                        </form>
                    </div>
                </div><!-- card -->

                <div class="mx-4" *ngIf="action === ACTION_EDIT">
                    <button  class="btn btn-outline-danger btn-md-inline btn-block mt-3" (click)="onDelete()">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.DELETE_STEP' | translate }}</button>
                </div>
            </div>
            <div class="col-xl-8 my-4 my-xl-0">
                <div class="frame-box-container">
                    <div class="frame-box border border-dark" *ngIf="action === ACTION_DETAILS">
                        <img *ngIf="step.mainImage" class="main-content" [src]="step.mainImage" style="object-fit: contain;">
                        <video *ngIf="step.video" class="main-content" controls="">
                            <source [src]="step.video" type="video/mp4">
                        </video>
                        <div class="add-content" *ngIf="!(step.mainImage || step.video)">
                            <p class="text-dark text-center cursor-default mx-2">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.NO_CONTENT' | translate }}</p>
                        </div>
                    </div>

                    <div class="frame-box border border-dark" *ngIf="action !== ACTION_DETAILS" [addNativeElement]="frame">
                        <input class="media-control-file d-none" #mainImageFile type="file" name="mainImageFile" accept="image/x-png,image/jpeg" (change)="onImageChoose($event)" hidden>
                        <input class="media-control-file d-none" #videoFile type="file" name="videoFile" accept="video/mp4,video/x-m4v" (change)="onVideoChoose($event, videoElementt)" hidden>

                        <ng-container *ngIf="frame">
                            <app-image-collaboration #imageCollaboration *ngIf="(step.mainImage && !imageDeleted) || imageSelected" [frame]="frame" [size]="'big'" [external]="false"></app-image-collaboration>
                        </ng-container>

                        <video *ngIf="step.video && !videoDeleted" class="main-content" controls="">
                            <source [src]="step.video" type="video/mp4">
                        </video>
                        <video #videoElementt class="main-content" controls="" [class.d-none]="(step.video && !videoDeleted) || !videoSelected">
                            <source type="video/mp4">
                        </video>
                        <div class="add-content" *ngIf="!((step.mainImage && !imageDeleted) || (step.video && !videoDeleted) || imageSelected || videoSelected)">
                            <button class="d-block btn btn-sm btn-outline-dark mx-auto" [disabled]="!previousStep" (click)="onPreviousImage()">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.ADD_PREVIOUS_IMAGE' | translate }}</button>
                            <p class="text-dark text-center cursor-default my-2">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.OR' | translate }}</p>
                            <button class="d-block btn btn-sm btn-outline-dark mx-auto" (click)="mainImageFile.click()">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.ADD_NEW_IMAGE' | translate }}</button>
                            <p class="text-dark text-center cursor-default my-2">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.OR' | translate }}</p>
                            <button class="d-block btn btn-sm btn-outline-dark mx-auto" (click)="videoFile.click()">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.ADD_VIDEO' | translate }}</button>
                        </div>
                        <div class="add-content content-bottom" *ngIf="(step.mainImage && !imageDeleted) || imageSelected || (step.video && !videoDeleted) || videoSelected">
                            <button class="d-block btn btn-sm btn-outline-danger" (click)="onMediaDelete(mainImageFile, videoFile)">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.REMOVE' | translate }}</button>
                        </div>
                    </div>
                    <!--
                    <div class="frame-box collaboration-frame" *ngIf="frame && step.mainImage && action === ACTION_EDIT" [addNativeElement]="frame">
                        <app-image-collaboration [frame]="frame" [size]="'big'" [external]="false"></app-image-collaboration>
                        <div class="add-content content-bottom d-flex align-items-center">
                            <button class="d-block btn btn-sm btn-outline-success mx-auto">Save</button>
                            <p class="text-dark text-center cursor-default mx-2">or</p>
                            <button class="d-block btn btn-sm btn-outline-danger mx-auto">Remove</button>
                        </div>
                    </div>
                    <div class="frame-box border border-dark" *ngIf="step.mainImage && action === ACTION_DETAILS">
                        <img  class="main-content" [src]="step.mainImage" style="object-fit: contain;">
                    </div>
                    <div class="frame-box border border-dark" *ngIf="step.video && action !== ACTION_NEW">
                        <video class="main-content" controls="">
                            <source [src]="step.video" type="video/mp4">
                        </video>
                        <div class="add-content content-bottom" *ngIf="action === ACTION_EDIT">
                            <button class="d-block btn btn-sm btn-outline-danger">Remove</button>
                        </div>
                    </div>
                    <div class="frame-box border border-dark" *ngIf="(!step.mainImage && !step.video && action === ACTION_EDIT) || action === ACTION_NEW">
                        <div class="add-content">
                            <button class="d-block btn btn-sm btn-outline-dark mx-auto">Add Image</button>
                            <p class="text-dark text-center cursor-default my-2">or</p>
                            <button class="d-block btn btn-sm btn-outline-dark mx-auto">Add Video</button>
                        </div>
                    </div>
                    <div class="frame-box border border-dark" *ngIf="(!step.mainImage && !step.video && action === ACTION_DETAILS)">
                        <div class="add-content">
                            <p class="text-dark text-center cursor-default mx-2">No Content</p>
                        </div>
                    </div>
                    -->
                </div>
            </div>
        </div>

    </div><!-- container-fluid -->
</div><!-- step-details-container -->

<ng-template #deleteStepTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.DELETE_MODAL.TITLE' | translate }}</div>
                <div class="card-body">
                    <div class="m-body padded" [innerHTML]="'APP.MAIN.STATIONS.STEP_DETAILS.DELETE_MODAL.MESSAGE' | translate:dataModel"></div>
                    <div class="m-footer">
                        <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.DELETE_MODAL.CANCEL' | translate }}</a>
                        <a class="text-danger" (click)="callbacks.delete()">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.DELETE_MODAL.DELETE' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #uploadModalTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3">
                    <span class="card-header-title" class="mr-2">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.UPLOAD_MEDIA_FILES' | translate }}</span>
                    <span class="card-header-title" *ngIf="dataModel.totalBytes !== 0 && dataModel.uploading">{{ dataModel.transferredBytes / dataModel.totalBytes | percent }}</span>
                </div>
                <div class="card-body">
                    <div class="m-body upload-modal-body">
                        <div class="d-block" *ngFor="let uploadFile of dataModel.uploadFiles; let i = index">

                            <div class="upload-file d-flex justify-content-between align-items-center px-3">
                                <!-- SHOW PROGRESS -->
                                <div class="upload-progress" [ngStyle]="{'width.%':uploadFile.progress}"
                                    [class.error]="uploadFile.uploadTask?.snapshot.state === 'canceled'"
                                    [class.success]="uploadFile.uploadTask?.snapshot.state === 'success'">

                                    <p *ngIf="uploadFile.progress > 7" class="text-dark bg-light p-1 rounded">{{ uploadFile.progress }}%</p>
                                </div>

                                <div class="file-info">
                                    <i class="file-icon mi mi-insert-drive-file"></i>
                                    <p class="file-name" title="{{ uploadFile.file.name }}">{{ uploadFile.file.name }}</p>
                                </div>

                                <!-- SHOW STATUS -->
                                <a *ngIf="uploadFile.uploadTask?.snapshot.state === 'canceled'" class="file-canceled text-danger" >
                                    <i class="mi mi-cancel"></i>
                                </a>
                                <a *ngIf="uploadFile.uploadTask?.snapshot.state === 'success'" class="file-succeeded text-success">
                                    <i class="mi mi-check-circle"></i>
                                </a>

                            </div>

                            <hr class="upload-divider m-0">
                        </div>
                    </div>
                    <div class="m-footer upload-modal-footer">
                        <!--<a class="text-info" (click)="callbacks.upload()" [ngClass]="{'disabled': dataModel.uploading || dataModel.completed}">Start Upload</a>-->
                        <a *ngIf="dataModel.uploading" class="text-danger" (click)="callbacks.cancel()">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.CANCEL' | translate }}</a>
                        <a *ngIf="dataModel.completed || !dataModel.uploading" class="text-dark" (click)="callbacks.close()">{{ 'APP.MAIN.STATIONS.STEP_DETAILS.CLOSE' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>