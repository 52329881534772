import { AbstractControl } from '@angular/forms';

export function WhitespaceValidator(control:AbstractControl) {
    const value = control.value;
    if (!value) { 
        return null; 
    }

    const whitespaceRegex = /\s/;
    const errors: any = {};
    if (whitespaceRegex.test(value)) { 
        errors['whitespace'] = true; 
    }
    if (Object.keys(errors).length > 0) {
        return { whitespace: true };
    } else {
        return null;
    }
}