import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './auth.service';
import { AccountService } from './account.service';
import { UtilityService } from './support/utility.service';
import { LogMessage } from '@models/LogMessage';
import { LogFile } from '@models/LogFile';

import { map, filter, first } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

import { Session } from '@models/Session';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  lastSessions: Observable<Session[]>;
  private lastSessionsSource = new BehaviorSubject<Session[]>(null);
  private lastSessionsSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private utilityService: UtilityService,
    private afdb: AngularFireDatabase,
    private http: HttpClient
  ) {
    this.lastSessions = this.lastSessionsSource.asObservable().pipe(filter(sessions => !!sessions));
    
    this.authService.on("login", (account_id: string) => {
      this.startListenningLastSessions(account_id);
    });
    this.authService.on("logout", () => {
      this.stopListenningLastSessions();
    });
  }

  startListenningLastSessions(account_id: string) {
    if (this.lastSessionsSub) { this.lastSessionsSub.unsubscribe() }

    this.lastSessionsSub = this.afdb.list<Session>(`accounts/${account_id}/sessions_meta`, ref => ref.orderByKey().limitToLast(3))
    .snapshotChanges(["child_added", "child_changed", "child_removed"]).pipe(
      map(snap => {
        return snap.map(sessionSnap => {
          const session = sessionSnap.payload.val();
          session.id = sessionSnap.key;
          return session;
        });
      }),
      map(s => s.reverse())
    ).subscribe(sessions => {
      this.lastSessionsSource.next(sessions);
    });
  }

  getSessionLogs(session: Session): Observable<LogMessage[]> {
    return this.afdb.list<LogMessage>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session.id}/logs`).valueChanges(["child_added", "child_changed"]);
  }

  getSessionFiles(session: Session): Observable<LogFile[]> {
    return this.afdb.list<LogFile>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session.id}/files`).valueChanges(["child_added", "child_changed"]);
  }

  getSessionObjects(session: Session): Observable<LogFile[]> {
    return this.afdb.list<LogFile>(`accounts/${this.authService.currentUser.account_id}/sessions/${session.room_id}/${session.id}/objects`).valueChanges(["child_added", "child_changed"]);
  }

  stopListenningLastSessions() {
    if (this.lastSessionsSub) { this.lastSessionsSub.unsubscribe() }
    this.lastSessionsSource.next(null);
  }

  getLastMonthSessionCount() {
    return this.afdb.list<number>(`accounts/${this.authService.currentUser.account_id}/statistics/sessions_stats/session_count`,
      ref => ref.orderByKey().limitToLast(30)).snapshotChanges()
    .pipe(
      map(snapList => snapList.map(snap => { return { label: snap.key, value: snap.payload.val() } }).reverse())
    )
  }

  getRoomSessions(roomId: string): Observable<Session[]> {
    return this.afdb.list<Session>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`,
      ref => ref.orderByChild('room_id').equalTo(roomId).limitToLast(3))
    .snapshotChanges(["child_added", "child_changed", "child_removed"]).pipe(
      map(snap => {
        return snap.map(sessionSnap => {
          const session = sessionSnap.payload.val();
          session.id = sessionSnap.key;
          return session;
        });
      }),
      map(s => s.reverse())
    );
  }

  getSessionsAll() {
    return this.afdb.list<Session>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`)
    .snapshotChanges(["child_added", "child_changed", "child_removed"]).pipe(
      map(snap => {
        return snap.map(sessionSnap => {
          const session = sessionSnap.payload.val();
          session.id = sessionSnap.key;
          return session;
        });
      }),
      map(s => s.reverse())
    );
  }

  getSessionsIn(startTime: number, endTime: number) {
    return this.afdb.list<Session>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`,
      ref => ref.orderByChild('create_time').startAt(startTime).endAt(endTime))
    .snapshotChanges(["child_added", "child_changed", "child_removed"]).pipe(
      map(snap => {
        return snap.map(sessionSnap => {
          const session = sessionSnap.payload.val();
          session.id = sessionSnap.key;
          return session;
        });
      }),
      map(s => s.reverse())
    );
  }

  getSession(sessionId: string): Observable<Session> {
    return this.afdb.object<Session>(`accounts/${this.authService.currentUser.account_id}/sessions_meta/${sessionId}`)
    .snapshotChanges().pipe(
      map(sessionSnap => {
        const session = sessionSnap.payload.val();
        session.id = sessionSnap.key;
        return session;
      }
    ));
  }

 
  
  
  getSessionsAtYear(year: number, startDate: Date, endDate: Date): Promise<any[]> {
    const dateStart = new Date(year, 0, 0, 0, 0, 0, 0);
    const oneYearInMilliseconds = 31556952000;
  
    return this.afdb
      .object<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`)
      .query
      .orderByChild("create_time")
      .startAt(dateStart.getTime())
      .endAt(dateStart.getTime() + oneYearInMilliseconds)
      .once("value")
      .then(snap => {
        const sessions = snap.val();
        const filteredSessions = sessions ? Object.values(sessions).filter((session: any) => {
          const sessionDate = new Date(session.start_time);
          return sessionDate >= startDate && sessionDate <= endDate;
        }) : [];
        return filteredSessions;
      });
  }
  
  

  getRooms() {
    return this.afdb.database.ref(`accounts/${this.authService.currentUser.account_id}/rooms`)
    .once("value")
    .then(snap => snap.val())

  }

  getSessionsAtDateRange(startDate: Date, endDate: Date): Promise<any> {
    const startTimestamp = startDate.getTime();
    const endTimestamp = endDate.getTime() + 86399999; 
  
    return this.afdb
      .object<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`)
      .query
      .orderByChild('create_time')
      .startAt(startTimestamp)
      .endAt(endTimestamp)
      .once('value')
      .then((snap) => {
        const sessions: any[] = [];
        snap.forEach((childSnap) => {
          let sessionObj = {id: childSnap.key, ...childSnap.val()}
          sessions.push(sessionObj);
        });
        return sessions;
      });
  }
  

  getSessionData(roomId: string, sessionId: string) {
    return this.afdb.object<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${roomId}/${sessionId}/session_data`).valueChanges();
  }

  exportSession(room_id: string, session_id, recipients: string[]) {
    return this.http.post(environment.endPoints.exportsession, {
      token: this.authService.currentUser.token,
      room: room_id,
      session: session_id,
      from_admin: true,
      recipients: recipients,
      app_name: environment.design.appName
    }).toPromise()
  }

  setExportName(roomId: string, sessionId: string, text: string) {
    this.afdb.object<any>(`accounts/${this.authService.currentUser.account_id}/sessions/${roomId}/${sessionId}/session_data/export_name`).update({name: text, author: this.authService.currentUser.name})
  }

  async getAccountTimezoneDateString() {
    const [offset, timezone] = await Promise.all([
      this.utilityService.getServerTimeOffset(),
      this.accountService.accountData.pipe(first(), map(a => a.timezone)).toPromise()
    ]);
    // Todays timestamp + firebase correction offset + Date object offset (reset to UTC) + Account timezone offset
    // Created object has local timezone metadata, but year-month-day is correct for account timezone
    const serverTime = new Date(Date.now() + offset + UtilityService.timezoneOffset + UtilityService.timezones[timezone]);
    return serverTime.getFullYear() + '-' + ("0"+(serverTime.getMonth()+1)).slice(-2) + '-' + ("0" + serverTime.getDate()).slice(-2);
  }
/*
  getSessions(): Observable<any[]> {
    return this.afdb.list<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`)
    .snapshotChanges(["child_added", "child_changed"])
    .pipe(
      map(sessionSnaps => {
        return sessionSnaps.map(sessionSnap => {
          const s = sessionSnap.payload.val();
          s.id = sessionSnap.key;
          return s;
        }).reverse();
      })
    );
  }

  getSessionsByPage(offset: number, endKey: string): Observable<any[]> {
    return this.afdb.list<any>(`accounts/${this.authService.currentUser.account_id}/sessions_meta`, ref => {
      return endKey ? ref.orderByKey().limitToLast(offset+1).endAt(endKey) : ref.orderByKey().limitToLast(offset+1);
    })
    .snapshotChanges(["child_added", "child_removed", "child_changed"])
    .pipe(
      map(sessionSnaps => {
        return sessionSnaps.map(sessionSnap => {
          const s = sessionSnap.payload.val();
          s.id = sessionSnap.key;
          return s;
        }).reverse();
      })
    );
  }
*/
}
