<nav class="navbar navbar-light navbar-default navbar-static-top navbar-expand px-0" [ngClass]="{'sidebar-open': sidebarOpen}">
  <div class="container-fluid">
    <a class="s-toggler ml-3" (click)="toggleSidebar()"><i class="mi mi-menu"></i></a>
    <div class="navbar-collapse">
        <ul class="navbar-nav ml-auto">
            <!-- Language -->
            <li class="nav-item dropdown" dropdown>
                <a class="nav-link d-flex flex-row align-items-center" id="nav-lang-dropdown-link" dropdownToggle aria-controls="nav-lang-dropdown-menu">
                    <i class="mi mi-language mr-1"></i>
                    <span>{{ currentLang.code | uppercase }}</span>
                </a>
                <ul id="nav-lang-dropdown-menu" *dropdownMenu class="dropdown-menu dropdown-menu-right py-1" role="menu" aria-expanded="false" aria-labelledby="nav-lang-dropdown-link">
                    <div *ngFor="let lang of allLangs">
                        <li class="language-item" role="menuitem" *ngIf="currentLang !== lang">
                            <a class="dropdown-item language-link text-center px-2" (click)="onLanguageSelected(lang)">{{ lang.code | uppercase }}</a>
                        </li>
                    </div>
                </ul>
            </li>

            <li class="nav-item dropdown" *ngIf="currentUser">
                <a class="nav-link d-flex flex-row align-items-center px-2" data-toggle="dropdown" id="dropdownMenuButton" aria-expanded="false" aria-haspopup="true">
                    <i class="account-icon mi mi-account-circle"></i>
                    <div class="d-inline-block">
                        <div class="user-name">{{ currentUser.name }}</div>
                        <div class="company-name">{{ currentUser.company_name }}</div>
                    </div>
                    <i class="down-icon mi mi-keyboard-arrow-down"></i>
                </a>

                <div class="dropdown-menu dropdown-menu-right w-100" aria-labelledby="dropdownMenuButton">
                    <label class="dropdown-item app-version">{{ appVersion }}</label>
                    <a class="dropdown-item" (click)="onProfile()"><i class="mi mi-person-outline mr-3"></i><span>{{ 'APP.MAIN.NAVBAR.PROFILE' | translate }}</span></a>
                    <a class="dropdown-item" (click)="onLogout()"><i class="mi mi-power-settings-new mr-3"></i><span>{{ 'APP.MAIN.NAVBAR.LOGOUT' | translate }}</span></a>
                </div>
            </li>
        </ul>
    </div>
  </div>
</nav>

<ng-template #logoutTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3">{{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL.TITLE' | translate }}</div>
                <div class="card-body">
                    <div class="m-body padded">{{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL.MESSAGE' | translate }}</div>
                    <div class="m-footer">
                        <a class="text-info" (click)="callbacks.close()">{{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL.CANCEL' | translate }}</a>
                        <a class="text-danger" (click)="callbacks.logout()">{{ 'APP.MAIN.NAVBAR.LOGOUT_MODAL.LOGOUT' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>