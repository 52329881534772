<div class="card">
  <div class="card-header d-flex justify-content-between align-items-center">
    <span class="card-header-title">{{ 'APP.MAIN.DASHBOARD.SESSION_CHARTS.TITLE' | translate }} ({{ timezone }})</span>
  </div>
  <div class="card-body">
    <canvas height="100" #chart></canvas>
    <div class="no-session" *ngIf="sessionCount===0">
      <div class="no-session-message">{{ 'APP.MAIN.DASHBOARD.SESSION_CHARTS.NO_SESSION_MESSAGE' | translate }}</div>
    </div>
  </div>
</div>