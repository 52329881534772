import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { User } from '../models/User';
import { map, filter, first, timeout } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // All users except guests
  allUsers: Observable<User[]>;
  users: Observable<User[]>;
  private allUsersSource = new BehaviorSubject<User[]>(null);
  private allUsersSub: Subscription = null;

  usernames: Observable<string[]>;
  private usernamesSource = new BehaviorSubject<string[]>(null);
  private usernamesSub: Subscription = null;

  private accountDataSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private afdb: AngularFireDatabase,
    private http: HttpClient
  ) {
    this.allUsers = this.allUsersSource.asObservable().pipe(
      filter(users => !!users),
      map(users => users.filter(u => !u.guest))
    );
    this.users = this.allUsers.pipe(map(users => users.filter(u => !u.auth.deleted)));
    this.usernames = this.usernamesSource.asObservable().pipe(filter(usernames => !!usernames));

    this.authService.on("login", this.onLogin);
    this.authService.on("logout", this.onLogout);
  }

  generateUserNameWithRole(user: any, licenseType: string) {
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }

    this.accountDataSub = this.accountService.accountData
    .subscribe(accountData => {
      licenseType = accountData.license.type;
    })
    const username = user.ad_user ? user.user_principal_name : user.auth.username;
    if (user.coadmin) {
      user.name2 = `${user.name} (${username} / Co-Admin)`;
      return user;
    }

    switch (user.role) {
      case "admin":
        user.name2 = `${user.name} (${username} / Admin)`;
        return user;
      case "coadmin":
        user.name2 = `${user.name} (${username} / Co-Admin)`;
        return user;
      case "expert":
        user.name2 = `${user.name} (${username} / Expert)`;
        return user;
      default:
        user.name2 = `${user.name} (${username} / ${licenseType === "concurrent_user_based" ? "User" : "Subscriber"})`;
        return user;
    }
  }

  getIntegrationCode(userId: string): Promise<any> {
    return this.http.post<any>(environment.endPoints.remote_sso_integration,
      { token: this.authService.currentUser.token, action: "generate", uid: userId }).pipe(first()).toPromise();
  }

  onLogin = (account_id: string, account_name: string) => {
    this.startListennigAllUsers(account_id);
    this.startListenningUsernames(account_name);
  }

  onLogout = () => {
    this.stopListenningAllUsers();
    this.stopListenningUsernames();
  }

  startListennigAllUsers(account_id: string) {
    if (this.allUsersSub) { this.allUsersSub.unsubscribe() }

    this.allUsersSub = this.afdb.list<User>(`accounts/${account_id}/users`).snapshotChanges().pipe(
      map(snap => {
        return snap.map(userSnap => {
          const user = userSnap.payload.val();
          user.id = userSnap.key;
          return user;
        });
      })
    ).subscribe(users => {
      this.allUsersSource.next(users);
    });
  }

  startListenningUsernames(account_name: string) {
    if (this.usernamesSub) { this.usernamesSub.unsubscribe() }

    this.usernamesSub = this.afdb.object(`usernames/${account_name}`).valueChanges()
    .subscribe(usernames => {
      if (usernames)
        this.usernamesSource.next(Object.keys(usernames));
    });
  }

  stopListenningAllUsers() {
    if (this.allUsersSub) { this.allUsersSub.unsubscribe() }
    this.allUsersSource.next(null);
  }

  stopListenningUsernames() {
    if (this.usernamesSub) { this.usernamesSub.unsubscribe() }
    this.usernamesSource.next(null);
  }

  detectUserChanges(orjUser, formValue) {

    let changes: any = {
      uid: orjUser.id,
      name: formValue.name ? formValue.name : orjUser.name,
      properties: {}
    };
    Object.keys(formValue).filter(x => x !== 'rooms' && x !== 'userPrincipalName').forEach(property => {
      if (formValue[property] !== orjUser[property]) {
        changes.properties[property] = formValue[property];
      }
    });

    const deleted_rooms = orjUser.rooms.filter(x => !formValue.rooms.includes(x));
    const inserted_rooms = formValue.rooms.filter(x => !orjUser.rooms.includes(x));
    if (deleted_rooms.length > 0) {
      changes['deleted_rooms'] = deleted_rooms;
    } else {
      changes['deleted_rooms'] = [];
    }
    if (inserted_rooms.length > 0) {
      changes['inserted_rooms'] = inserted_rooms;
    } else {
      changes['inserted_rooms'] = [];
    }

    return changes;
  }

  listenUserQr(account_name, username: string): Observable<string> {
    return this.afdb.object<string>(`auth/${account_name}/users/${username}/qr/code`).valueChanges();
  }

  removeQr(username: string) {
    return this.http.post(environment.endPoints.generateremoveqr,
      { token: this.authService.currentUser.token, username: username, generate: false }).pipe(first()).toPromise()
    .then(result => {
      return;
    });
  }

  generateQr(username: string) {
    return this.http.post(environment.endPoints.generateremoveqr,
      { token: this.authService.currentUser.token, username: username, generate: true }).pipe(first()).toPromise()
    .then(result => {
      return;
    });
  }

  getUser(userId: string): Promise<User> {
    return this.allUsers.pipe(first(), map(users => users ? users.find(user => user.id === userId) : null)).toPromise();
  }

  updateUser(changes: any) {
    return this.http.post(environment.endPoints.updateuser,
      { token: this.authService.currentUser.token, changes: changes }).pipe(first()).toPromise()
    .then(async(result) => {
      return (await new Promise<void>(resolve => {
        const sub = this.allUsers.pipe(
          timeout(5000),
          map(users => users ? users.find(user => user.id === changes.uid) : null)
        ).subscribe(
          user => {
            if (user) {
              const userRooms = user.rooms ? Object.keys(user.rooms) : [];
              if (Object.keys(changes.properties).every(p => changes.properties[p] === user[p]) &&
                      changes.deleted_rooms.every(d => userRooms.indexOf(d) < 0)  && changes.inserted_rooms.every(i => userRooms.indexOf(i) > -1)) {
                if (sub) { sub.unsubscribe() }
                resolve();
              }
            }
          }, error => {
            resolve();
          }
        );
      }));
    });
  }

  createUser(user: any) {
    return this.http.post(environment.endPoints.createuser,
      { token: this.authService.currentUser.token, user: user }).pipe(first()).toPromise()
    .then((result: any) => {
      return result.uid;
    });
  }

  deleteUser(userId: any) {
    return this.http.post(environment.endPoints.deleteuser,
      { token: this.authService.currentUser.token, uid: userId }).pipe(first()).toPromise()
    .then((result: any) => {
      return result.uid;
    });
  }

  changeUserPassword(userId: string, username: string, password: string) {
    return this.http.post(environment.endPoints.changepsd,
      { token: this.authService.currentUser.token, uid: userId, username: username, password: password }).pipe(first()).toPromise()
    .then(result => {
      return;
    });
  }

  toggleUserStatus(uid: string): Promise<boolean> {
    return this.http.post(environment.endPoints.toggleuser, { token: this.authService.currentUser.token, user: uid }).pipe(first()).toPromise()
    .then(async(result: any) => {
      return (await new Promise<boolean>(resolve => {
        const sub = this.allUsers.pipe(
          timeout(5000),
          map(users => users ? users.find(user => user.id === uid) : null)
        ).subscribe(
          user => {
            if (user) {
              if (!user.auth.disabled == !result.disabled) {
                if (sub) { sub.unsubscribe() }
                resolve(result.disabled);
              }
            }
          }, error => {
            resolve(result.disabled);
          }
        );
      }));
    });
  }

  importUsersConfirm(formData: FormData) {
    const token = this.authService.currentUser.token;
    const url = environment.endPoints.importusersconfirm+`?token=${token}`;
    return this.http.post<any>(url, formData).pipe(first()).toPromise()
      .then(result => {
        return result;
      })
  }

  importUsers(excelUploadUsers: any) {
    const token = this.authService.currentUser.token;
    const url = environment.endPoints.importusers+`?token=${token}`;
    return this.http.post<any>(url, { excel_upload_users: excelUploadUsers, token: token }).pipe(first()).toPromise()
      .then(result => {
        return result;
      })
  }
}