import { Directive, forwardRef, Input } from "@angular/core";
import { NG_ASYNC_VALIDATORS, Validator, AbstractControl, ValidationErrors } from "@angular/forms";

import { AlreadyTaken } from '../validators/already-taken.validator';
import { Observable, of } from "rxjs";

@Directive({
  selector: "[alreadyTaken][ngModel]",
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => AlreadyTakenDirective), multi: true }]
})
export class AlreadyTakenDirective implements Validator {
  @Input('alreadyTaken') alreadyTaken: Observable<string[]> = of([])

  validate(control : AbstractControl) : Observable<ValidationErrors> {
    return AlreadyTaken(control.value, this.alreadyTaken);
  }
}