import { Directive, Input, Output, EventEmitter, HostListener, OnChanges, SimpleChanges } from "@angular/core";

@Directive({ selector: '[copy-clipboard]' })
export class CopyClipboardDirective implements OnChanges {

  @Input("copy-clipboard")
  public payload: string;

  @Input("select-text")
  public select: any;

  @Output("copied")
  public copied: EventEmitter<string> = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.payload) {
      this.payload = changes.payload.currentValue;
    }
  }

  @HostListener("click", ["$event"])
  public onClick(event: MouseEvent): void {
    if (!this.payload)
      return;

    if (this.select && window.getSelection) {
      const selection = window.getSelection();
      var range = document.createRange();
      range.selectNodeContents(this.select);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    event.preventDefault();

    let listener = (e: ClipboardEvent) => {
      let clipboard = e.clipboardData || window["clipboardData"];
      clipboard.setData("text", this.payload.toString());
      e.preventDefault();

      this.copied.emit(this.payload);
      if (this.select && window.getSelection) {
        window.getSelection().removeAllRanges();
      }
    };

    document.addEventListener("copy", listener, false)
    document.execCommand("copy");
    document.removeEventListener("copy", listener, false);
  }
}