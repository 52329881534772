import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';

import { WhitespaceValidator } from '../validators/whitespace-validator.validator';

@Directive({
  selector: '[whitespaceValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => WhitespaceValidatorDirective), multi: true }
  ]
})

export class WhitespaceValidatorDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    return WhitespaceValidator(control);
  }
}