import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewChecked, TemplateRef } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { UserService } from '@services/user.service';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { RoomService } from '@services/room.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { DetailPageService } from '@services/support/detail-page.service';
import { QRCodeComponent } from 'angularx-qrcode';

import { FileShareService } from '@services/support/file-share.service';
import { ModalService } from '@services/support/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@services/support/utility.service';
import { LoaderService } from '@services/support/loader.service';
import { AccountService } from '@services/account.service';
import { environment } from 'environments/environment';

interface Users {  
  role: any;  
  name: any;  
  username: any;  
  email: any;
  allow_video_recording: any;
  allow_generate_join_room_link: any;
  allow_to_see_all_users: any;
  permission_of_start_stop_recording_video_calls: any;
  permission_to_see_user_list_and_make_calls: any;
  permission_of_generate_join_room_links: any;
  require_all_users_permission_to_start_recording: any;
  password: any;
}

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {

  static ACTION_DETAILS: string = "details";
  static ACTION_EDIT: string = "edit";
  static ACTION_NEW: string = "new";
  /**** Access in template ****/
  get ACTION_DETAILS() { return UserDetailsComponent.ACTION_DETAILS; }
  get ACTION_EDIT() { return UserDetailsComponent.ACTION_EDIT; }
  get ACTION_NEW() { return UserDetailsComponent.ACTION_NEW; }

  @ViewChild("callInfoTemplate", { static: true }) private callInfoTemplate: TemplateRef<any>;
  @ViewChild("removeQrTemplate", { static: true }) private removeQrTemplate: TemplateRef<any>;
  @ViewChild("regenerateQrTemplate", { static: true }) private regenerateQrTemplate: TemplateRef<any>;
  @ViewChild("changePassTemplate", { static: true }) private changePassTemplate: TemplateRef<any>;
  @ViewChild("updateUserTemplate", { static: true }) private updateUserTemplate: TemplateRef<any>;
  @ViewChild("withoutRoomTemplate", { static: true }) private withoutRoomTemplate: TemplateRef<any>;
  @ViewChild("toggleTemplate", { static: true }) private toggleTemplate: TemplateRef<any>;
  @ViewChild("delUserTemplate", { static: true }) private delUserTemplate: TemplateRef<any>;
  @ViewChild('userForm', { static: true }) userForm: NgForm;
  @ViewChild('passwordForm', { static: false }) passwordForm: NgForm;
  @ViewChild('qr', { static: false }) qrContainer: QRCodeComponent;

  action: string;
  actionTitle: string = "";

  userId: string;
  labels: string[] = [];
  accountLabelsLoading = true;
  accountLabelsSub: Subscription = null;

  formPristine: boolean = true;
  formLoading: boolean = true;
  formValueSub: Subscription = null;

  rooms = [];
  roomsLoading: boolean = true;

  isThereAnyError: boolean = false;

  orjUser: any;
  user: any = {
    name: "",
    role: "user",
    allow_archiving: false,
    allow_join_link: false,
    allow_calling: false,
    allow_seeing_all_users: false,
    call_archive_perm_needed: false,
    call_full_hd: false,
    username: "",
    email: "",
    disabled: false,
    default_room: null,
    rooms: []
  };

  passwordChanging: boolean = false;
  passwordGroup = {
    password: "",
    confirmPassword: ""
  }

  private roomSub: Subscription = null;

  pendingUsernames: Observable<string[]> = null;

  excelResults = [];
  excelResults2 = [];
  names = [];
  users: Users[] = [];

  qrData: string = null;
  qrSub: Subscription = null;
  qrLoading: boolean = true;
  generateDisabled: boolean = false;

  accountDataSub: Subscription = null;
  accountArchiveAllowed: boolean = false;
  accountJoinLinkAllowed: boolean = false;
  accountCallingAllowed: boolean = false;
  accountCoAdminAllowed: boolean = false;
  accountAutoJoinRoomAllowed: boolean = false;

  licenseType: string = "concurrent_user_based";
  expertLimitReached: boolean = false;

  twoFactorEnabled: boolean = false;
  twoFactorAdmin: boolean = false;
  twoFactorOthers: boolean = false;

  integrationAllowed: boolean = false;
  integrationCode: string = null;
  integrationLoading: boolean = false;
  accountFullHdAllowed: boolean = false;
  accountAlwaysFullHd: boolean = false;

  ad_user: boolean = false;
  user_principal_name: string;

  passwordPolicySub: Subscription = null;
  minPassLength: string = "6";
  AZContains: boolean = false;
  azContains: boolean = false;
  numberContains: boolean = false;
  specialCharacterContains: boolean = false;
  regexList: any = {}

  selectedUserRooms: { id: string, name: string }[] = []

  constructor(
    private userService: UserService,
    private roomService: RoomService,
    private detailPageService: DetailPageService,
    private translateService: TranslateService,
    private fileShareService: FileShareService,
    private flashMessageService: FlashMessageService,
    private changeDetector: ChangeDetectorRef,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private accountService: AccountService,
    private utilityService: UtilityService
  ) {
    this.roomSub = this.roomService.rooms.subscribe(rooms => {
      this.rooms = rooms
        .map(room => { return { id: room.id, name: room.room_data.name } })
        .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
      this.roomsLoading = false;

      this.selectedUserRooms = this.user.rooms.map(rid => this.rooms.find(r => r.id === rid))
    });

    this.accountDataSub = combineLatest([this.userService.users, this.accountService.accountData])
    .subscribe(([users, accountData]) => {
      this.licenseType = accountData.license.type;
      this.accountArchiveAllowed = accountData.features.archiving ? true : false;
      this.accountJoinLinkAllowed = accountData.features.joinwithlink ? true : false;
      this.accountCallingAllowed = accountData.features.calling ? true : false;
      this.accountCoAdminAllowed = accountData.features.coadmin ? true : false;
      this.accountAutoJoinRoomAllowed = accountData.add_ons.autojoinroom ? true : false;
      this.integrationAllowed = accountData.add_ons.sso ? true : false;
      this.accountFullHdAllowed = accountData.add_ons.fullhd ? true : false;
      this.accountAlwaysFullHd = accountData.always_full_hd ? true : false;

      this.twoFactorAdmin = accountData.two_factor_auth && accountData.two_factor_auth.admin;
      this.twoFactorOthers = accountData.two_factor_auth && accountData.two_factor_auth.others;

      if (this.licenseType === 'expert_user_based') {
        const expertLimit = accountData.license.limit2 ? accountData.license.limit2 : 0;
        const expertCount = users.filter(u => u.role === 'expert').length;

        if (expertLimit > 0 && expertCount >= expertLimit) {
          this.expertLimitReached = true;
        } else {
          this.expertLimitReached = false;
        }
      } else {
        this.expertLimitReached = false;
      }
    });
  }

  ngAfterViewChecked() {
    if (this.action === this.ACTION_DETAILS) {
      this.userForm.control.disable();
      if (!this.ad_user) {
        this.passwordForm.control.disable();
      }
    }
    this.disableFieldsIfAdUser();
    this.changeDetector.detectChanges();
  }

  disableFieldsIfAdUser() {
    if (this.action === this.ACTION_EDIT && this.ad_user) {
      if (this.userForm.controls.role) {
        this.userForm.controls.role.disable()
      }
      if (this.userForm.controls.name) {
        this.userForm.controls.name.disable()
      }
      if (this.userForm.controls.userPrincipalName) {
        this.userForm.controls.userPrincipalName.disable()
      }
      if (this.userForm.controls.email) {
        this.userForm.controls.email.disable()
      }
    }
  }

  ngOnInit() {
    this.passwordPolicySub = this.accountService.accountData.subscribe(ad => {
      if (ad) {
        const strongEnabled = ad.password_policy ? ad.password_policy.strong : false;
        if (strongEnabled) {
          const options = ad.password_policy.options;

          this.minPassLength = options.length ? `${options.length}` : this.minPassLength;

          this.AZContains = options.AZ ? options.AZ : this.AZContains;
          if (this.AZContains) { this.regexList["containsUppercase"] = '.*[A-Z].*' }
          
          this.azContains = options.az ? options.az : this.azContains;
          if (this.azContains) { this.regexList["containsLowercase"] = '.*[a-z].*' }
          
          this.numberContains = options.number ? options.number : this.numberContains;
          if (this.numberContains) { this.regexList["containsNumber"] = '.*[0-9].*' }
          
          this.specialCharacterContains = options.special ? options.special : this.specialCharacterContains;
          if (this.specialCharacterContains) { this.regexList["containsSpecialCharacter"] = '.*[-*/?#&()@%+\'!$^:,~_.½£₺é;{}ß>|æ].*' }
        }
      }
    });

    if (this.action === this.ACTION_NEW) {
      
      this.orjUser = {
        name: "",
        role: "user",
        allow_archiving: false,
        allow_join_link: false,
        allow_calling: false,
        allow_seeing_all_users: false,
        call_archive_perm_needed: false,
        call_full_hd: false,
        username: "",
        email: "",
        disabled: false,
        default_room: null,
        rooms: []
      }
      if (environment.design.showLabels) {
        this.orjUser.label = ""
      }
      this.pendingUsernames = this.userService.usernames;
      this.userService.usernames.pipe(first()).toPromise().then(us => this.formLoading = false);
      this.translateService.get('APP.MAIN.USERS.USER_DETAILS.TITLE_NEW').toPromise()
      .then(result => { this.actionTitle = result; });
    } else {
      if (this.action === this.ACTION_DETAILS) {
        this.translateService.get('APP.MAIN.USERS.USER_DETAILS.TITLE_DETAILS').toPromise()
        .then(result => { this.actionTitle = result; });
      } else if (this.action === this.ACTION_EDIT) {
        this.translateService.get('APP.MAIN.USERS.USER_DETAILS.TITLE_EDIT').toPromise()
        .then(result => { this.actionTitle = result; });
      }

      this.loadUserData();
    }

    this.formValueSub = this.userForm.valueChanges.subscribe(value => {
      let pristine = true;
      if (this.orjUser) {
        Object.keys(value).filter(x => x !== 'rooms').forEach(property => {
          if (Object.keys(this.orjUser).includes(property)) {
            if (value[property] !== this.orjUser[property]) {
              pristine = false;
            }
          } else {
            if (value[property] !== this.passwordGroup[property]) {
              pristine = false;
            }
          }
        });
        if (value.rooms && (this.orjUser.rooms.filter(x => !value.rooms.includes(x)).length > 0 ||
            value.rooms.filter(x => !this.orjUser.rooms.includes(x)).length > 0)) {
          pristine = false;
        }
      }
      this.formPristine = pristine;
    });

    if (environment.design.showLabels) {
      this.accountLabelsSub = this.accountService.getAccountLabels()
      .subscribe(labels => {
        this.labels = labels ? labels : [];
        this.accountLabelsLoading = false;
      });
    }
  }

  onRoleChange(role: string) {
    if (role !== "expert") {
      this.user.label = ""
    }
  }

  onLabelChange(data) {
    if (!data) {
      this.user.label = ""
    }
  }

  addTagPromise(name) {
    this.accountLabelsLoading = true;
    this.labels.push(name);
    return this.accountService.setAccountLabels(this.labels).then(() => {
      this.accountLabelsLoading = false;
    });
  }

  onAllowCallingChange(allowCalling) {
    if (!allowCalling) {
      this.user.allow_seeing_all_users = false;
    }
  }

  getIntegrationCode() {
    this.integrationLoading = true;
    this.userService.getIntegrationCode(this.userId)
    .then(result => { this.integrationCode = result.id; })
    .catch(error => this.flashMessageService.show("There is an error occured."))
    .finally(() => { this.integrationLoading = false; });
  }

  integrationCodeCopied() {
    this.flashMessageService.show("Code copied to clipboard.", { cssClass: "alert-success" });
  }

  onCallingInfo() {
    const modalId = this.modalService.show({
      template: this.callInfoTemplate,
      context: {
        dataModel: null,
        callbacks: {
          close: () => this.modalService.hide(modalId)
        }
      }
    });
  }

  onRemoveQR() {
    const modalId = this.modalService.show({
      template: this.removeQrTemplate,
      context: {
        dataModel: null,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.loaderService.show();
            this.userService.removeQr(this.orjUser.username)
            .then(() => this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.QR_REMOVED', { cssClass: 'alert-success' }))
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.CANNOT_REMOVE'))
            .finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
            });
          }
        }
      }
    });
  }

  onGenerateQR() {
    this.generateDisabled = true;
    this.loaderService.show();
    this.userService.generateQr(this.orjUser.username)
    .then(() => this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.QR_GENERATED', { cssClass: 'alert-success' }))
    .catch(error => this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.CANNOT_GENERATE'))
    .finally(() => {
      this.loaderService.hide();
      this.generateDisabled = false;
    });
  }

  onRegenerateQR() {
    const modalId = this.modalService.show({
      template: this.regenerateQrTemplate,
      context: {
        dataModel: null,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.loaderService.show();
            this.userService.generateQr(this.orjUser.username)
            .then(() => this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.QR_GENERATED', { cssClass: 'alert-success' }))
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.CANNOT_GENERATE'))
            .finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
            });
          }
        }
      }
    });
  }

  onDownloadQR() {
    const ch: HTMLCollection = this.qrContainer.qrcElement.nativeElement.children;
    for (let i = 0; i < ch.length; i++) {
      let element = ch[i];
      if (element instanceof HTMLCanvasElement) {
/*
        const cnv: HTMLCanvasElement = document.createElement("canvas");
        const width = element.naturalWidth;
        const height = element.naturalHeight;

        cnv.width = width + 100;
        cnv.height = height + 100;
        const ctx: CanvasRenderingContext2D = cnv.getContext('2d');
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, width + 100, height + 100);
        ctx.drawImage(element, 50, 50, width, height);
*/
        this.fileShareService.convertCanvasToJPEGFile(element, this.orjUser.name+'.jpeg', 1)
        .then(file => {
          this.fileShareService.saveFile(file);
        });
      }
    }
  }

  loadUserData() {
    this.userService.getUser(this.userId)
    .then(user => {
      this.pendingUsernames = this.userService.usernames.pipe( map(us => us.filter(u => u !== user.auth.username) ));
      this.userService.usernames.pipe(first()).toPromise().then(us => this.formLoading = false );

      const uRooms = user.rooms ? Object.keys(user.rooms).filter(r => !user.rooms[r].personal_room && !user.rooms[r].meeting_room) : [];
      this.orjUser = {
        id: user.id,
        name: user.name,
        role: user.coadmin ? 'coadmin' : user.role,
        allow_archiving: user.allow_archiving,
        allow_join_link: user.allow_join_link,
        allow_calling: user.allow_calling,
        allow_seeing_all_users: user.allow_seeing_all_users ? true : false,
        call_archive_perm_needed: user.call_archive_perm_needed ? true : false,
        call_full_hd: user.call_full_hd ? true : false,
        username: user.auth.username,
        email: user.email,
        disabled: user.auth.disabled,
        default_room: user.default_room ? user.default_room : null,
        rooms: uRooms
      }
      if (environment.design.showLabels) {
        this.orjUser.label = user.label ? user.label : "";
      }

      let rs = [...uRooms];
      rs = rs.sort((a, b) => {
        const aa = this.rooms.find(r => r.id === a);
        const bb = this.rooms.find(r => r.id === b);
        return (aa && bb) ? aa.name.toLocaleLowerCase().localeCompare(bb.name.toLocaleLowerCase()) : 0;
      });

      this.user = {
        name: user.name,
        role: user.coadmin ? 'coadmin' : user.role,
        allow_archiving: user.allow_archiving,
        allow_join_link: user.allow_join_link,
        allow_calling: user.allow_calling,
        allow_seeing_all_users: user.allow_seeing_all_users ? true : false,
        call_archive_perm_needed: user.call_archive_perm_needed ? true : false,
        call_full_hd: user.call_full_hd ? true : false,
        username: user.auth.username,
        email: user.email,
        disabled: user.auth.disabled,
        default_room: user.default_room ? user.default_room : null,
        rooms: rs
      }
      if (environment.design.showLabels) {
        this.user.label = user.label ? user.label : "";
      }

      this.ad_user = user.ad_user;
      this.disableFieldsIfAdUser();
      this.user_principal_name = user.user_principal_name;

      if (!this.roomsLoading) {
        this.selectedUserRooms = rs.map(rid => this.rooms.find(r => r.id === rid))
      }

      this.formLoading = false;
      return user;
    })
    .then(user => {
      this.qrSub = this.userService.listenUserQr(user.auth.account_name, user.auth.username)
      .subscribe(qr => {
        if (qr) {
          this.qrLoading = false;
          this.qrData = user.auth.account_name + "!!" + user.auth.username + '!!' + qr + '!!v2';
          this.utilityService.repeatUntilDone(() => {
            if (this.qrContainer) {
              const ch: HTMLCollection = this.qrContainer.qrcElement.nativeElement.children;
              for (let i = 0; i < ch.length; i++) {
                const element = ch[i];
                if (element instanceof HTMLImageElement || element.nodeName === 'IMG' || element.localName === 'img') {
                  element.setAttribute('alt', user.name);
                  element.setAttribute('title', user.name);
                  return true;
                }
              }
            }
            return false;
          }, 10, 100);
        } else {
          this.qrLoading = false;
          this.qrData = null;
        }
      });
    });
  }

  resetForm() {
    Object.keys(this.user).forEach(prop => {
      this.user[prop] = this.orjUser[prop];
    });
    if (this.action === this.ACTION_NEW) {
      this.passwordGroup.password = "";
      this.passwordGroup.confirmPassword = "";
    }

    Object.keys(this.userForm.controls).forEach(c => {
      this.userForm.controls[c].markAsUntouched();
    });
  }

  ngOnDestroy() {
    if (this.passwordPolicySub) { this.passwordPolicySub.unsubscribe() }
    if (this.roomSub) { this.roomSub.unsubscribe() }
    if (this.qrSub) { this.qrSub.unsubscribe() }
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
    if (this.formValueSub) { this.formValueSub.unsubscribe() }
  }

  userRoomsChanged(rooms: any[]) {
    this.selectedUserRooms = rooms
    if (!this.selectedUserRooms.find(r => r.id === this.user.default_room)) {
      this.user.default_room = null
    }
  }

  onUserFormSubmit(form: NgForm) {
    if (this.action === this.ACTION_EDIT) {
      const changes = this.userService.detectUserChanges(this.orjUser, form.control.getRawValue());
      const adminEmailDeleted = this.user.role === 'admin' && changes.properties.email === '';

      if (changes.properties.username || adminEmailDeleted) {
        const modalId = this.modalService.show({
          template: this.updateUserTemplate,
          context: {
            dataModel: {
              username: !!changes.properties.username,
              email: adminEmailDeleted
            },
            callbacks: {
              no: () => {
                this.modalService.hide(modalId);
              },
              yes: () => {
                this.updateUser(changes, form.value)
                .finally(() => {
                  this.modalService.hide(modalId);
                });
              }
            }
          }
        });
      } else {
        this.updateUser(changes, form.value);
      }
    } else if (this.action === this.ACTION_NEW) {
      const newUser: any = {
        name: form.value.name,
        role: form.value.role,
        allow_archiving: this.accountArchiveAllowed ? form.value.allow_archiving : false,
        allow_join_link: this.accountJoinLinkAllowed ? form.value.allow_join_link : false,
        allow_calling: this.accountCallingAllowed ? form.value.allow_calling : false,
        allow_seeing_all_users: this.accountCallingAllowed && form.value.allow_calling ? form.value.allow_seeing_all_users : false,
        call_archive_perm_needed: this.accountArchiveAllowed && form.value.call_archive_perm_needed ? form.value.call_archive_perm_needed : false,
        call_full_hd: this.accountFullHdAllowed && form.value.call_full_hd ? form.value.call_full_hd : false,
        username: form.value.username,
        email: form.value.email,
        rooms: form.value.rooms,
        password: form.value.password
      };
      if (form.value.label) {
        newUser.label = form.value.label
      }
      if (form.value.default_room) {
        newUser.default_room = form.value.default_room
      }

      if (newUser.rooms.length === 0) {
        const modalId = this.modalService.show({
          template: this.withoutRoomTemplate,
          context: {
            dataModel: null,
            callbacks: {
              no: () => {
                this.modalService.hide(modalId);
              },
              yes: () => {
                this.createUser(newUser)
                .finally(() => {
                  this.modalService.hide(modalId);
                });
              }
            }
          }
        });
      } else {
        this.createUser(newUser);
      }
    }
  }

  updateUser(changes: any, formValue: any) {
    const disabledControls = (this.userForm.controls ? Object.values(this.userForm.controls) : []).filter(c => c.disabled);
    this.userForm.control.disable();
    this.loaderService.show();
    return this.userService.updateUser(changes)
    .then(() => {
      this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.USER_UPDATED', { cssClass: 'alert-success' });
      const detail = this.detailPageService.loadComponent(UserDetailsComponent).instance;
      detail.action = UserDetailsComponent.ACTION_DETAILS;
      detail.userId = changes.uid;
    })
    .catch(error => {
      this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.CANNOT_UPDATE');
      this.userForm.control.enable();
      disabledControls.forEach(c => c.disable());
    })
    .finally(() => {
      this.loaderService.hide();
    });
  }

  createUser(newUser: any) {
    const disabledControls = (this.userForm.controls ? Object.values(this.userForm.controls) : []).filter(c => c.disabled);
    this.userForm.control.disable();
    this.loaderService.show();
    return this.userService.createUser(newUser)
    .then(uid => {
      this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.USER_CREATED', { cssClass: 'alert-success' });
      this.detailPageService.removeComponent();
    })
    .catch(error => {
      this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.CANNOT_CREATE');
      this.userForm.control.enable();
      disabledControls.forEach(c => c.disable());
    })
    .finally(() => {
      this.loaderService.hide();
    });
  }

  onBack() {
    this.detailPageService.removeComponent();
  }

  onEdit() {
    const detail = this.detailPageService.loadComponent(UserDetailsComponent).instance;
    detail.action = UserDetailsComponent.ACTION_EDIT;
    detail.userId = this.userId;
  }

  cancelEdit() {
    const detail = this.detailPageService.loadComponent(UserDetailsComponent).instance;
    detail.action = UserDetailsComponent.ACTION_DETAILS;
    detail.userId = this.userId;
  }

  onPasswordFormSubmit(form: NgForm) {
    const modalId = this.modalService.show({
      template: this.changePassTemplate,
      context: {
        dataModel: null,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.passwordChanging = true;
            this.loaderService.show();
            this.userService.changeUserPassword(this.userId, this.orjUser.username, form.value.password)
            .then(() => {
              this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.PASSWORD_CHANGED', { cssClass: 'alert-success' });
              const detail = this.detailPageService.loadComponent(UserDetailsComponent).instance;
              detail.action = UserDetailsComponent.ACTION_DETAILS;
              detail.userId = this.userId;
            })
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.CANNOT_CHANGE'))
            .finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
              this.passwordChanging = false;
            });
          }
        }
      }
    });
  }

  onToggle() {
    const modalId = this.modalService.show({
      template: this.toggleTemplate,
      context: {
        dataModel: this.user,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.loaderService.show();
            this.userService.toggleUserStatus(this.userId)
            .then(disabled => {
              if (disabled) {
                this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.DISABLED', { cssClass: 'alert-success' });
              } else {
                this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.ENABLED', { cssClass: 'alert-success' });
              }

              const detail = this.detailPageService.loadComponent(UserDetailsComponent).instance;
              detail.action = UserDetailsComponent.ACTION_DETAILS;
              detail.userId = this.userId;
            })
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.ERROR'))
            .finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
            });
         }
       }
     }
   });
  }

  onDelete() {
    const modalId = this.modalService.show({
      template: this.delUserTemplate,
      context: {
        dataModel: this.user,
        callbacks: {
          cancel: () => {
            this.modalService.hide(modalId);
          },
          delete: () => {
            this.loaderService.show();
            this.userService.deleteUser(this.userId)
            .then(uid => {
              this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.DELETE_USER_MODAL.DELETED', { cssClass: 'alert-success' });
              this.detailPageService.removeComponent();
            })
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.DELETE_USER_MODAL.ERROR'))
            .finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
            });
          }
        }
      }
    });
  }
}
