import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { DetailPageService } from '@services/support/detail-page.service';
import { SessionService } from '@services/session.service';
import { Session } from '@models/Session';
import { interval, of, Subscription } from 'rxjs';
import { User } from '@models/User';
import { UserService } from '@services/user.service';
import { TicketService } from '@services/ticket.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { ModalService } from '@services/support/modal.service';
import { LoaderService } from '@services/support/loader.service';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { AccountService } from '@services/account.service';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.scss']
})
export class SessionDetailsComponent implements OnInit, OnDestroy {

  @ViewChild("pickTicketTemplate", { static: true }) private pickTicketTemplate: TemplateRef<any>;

  session: Session;

  sessionSub: Subscription = null;

  participants: string[] = [];

  ticketAvailable: boolean = false;

  users: User[] = [];
  userSub: Subscription = null;

  selectedTicket = null;

  attachedTicketId: string = null;
  attachedTicket: any = null;

  licenseType: string = "expert_user_based";
  
  exportRecipients: any[] = [];
  validMailValidator = Validators.pattern(/^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
  invalidEmailError = {pattern: ""};
  emailPholder = "";
  emailSecPholder = "";
  
  sessionExportEnabled: boolean = false;
  webdavEnabled: boolean = false;
  accountDataSub: Subscription = null;

  constructor(
    private accountService: AccountService,
    private userService: UserService,
    private ticketService: TicketService,
    private sessionService: SessionService,
    private detailPageService: DetailPageService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private flashMessageService: FlashMessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.translateService.get('APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.PLACEHOLDER').pipe(first()).toPromise().then(tr => {
      this.emailPholder = tr;
    });
    this.translateService.get('APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.SECONDARY_PLACEHOLDER').pipe(first()).toPromise().then(tr => {
      this.emailSecPholder = tr;
    });
    this.translateService.get('APP.MAIN.SETTINGS.EMAIL_RECIPIENTS.INVALID_EMAIL').pipe(first()).toPromise().then(tr => {
      this.invalidEmailError.pattern = tr;
    });

    this.accountDataSub = this.accountService.accountData
    .subscribe(accountData => {
      this.sessionExportEnabled = accountData.add_ons.sessionexport;
      this.webdavEnabled = accountData.add_ons.webdav;
      this.licenseType = accountData.license.type;
    });

    this.sessionSub = this.sessionService.getSession(this.session.id).pipe(
      map(ses => {
        this.session = ses;
        return ses.attached_ticket;
      }),
      distinctUntilChanged(),
      switchMap(attached_ticket => this.accountService.accountData.pipe(
        map(ad => {
          this.ticketAvailable = ad.add_ons.ticket;
          return attached_ticket && this.ticketAvailable ? attached_ticket : null
        })
      )),
      switchMap(attached_ticket => {
        this.attachedTicketId = attached_ticket;
        if (attached_ticket) {
          return this.ticketService.getTicketData(attached_ticket)
        } else {
          return of(null);
        }
      })
    ).subscribe(ticket => {
      this.attachedTicket = ticket;
    });

    this.userSub = this.userService.users.subscribe(users => {
      this.users = users.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    });
  }

  onSessionExportModal($event, roomId:string, sessionId: string, modalId?: number) {
    $event.stopPropagation();
    this.ticketService.sessionExportRequest.next([roomId,sessionId, true, modalId]);
  }

  ngOnDestroy() {
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
    if (this.sessionSub) { this.sessionSub.unsubscribe() }
    if (this.userSub) { this.userSub.unsubscribe() }
  }

  onBack() {
    this.detailPageService.removeComponent2();
  }

  getUsersName(id: string) {
    const user = this.users.find(u => u.id === id);
    return user ? user.name : "Unknown";
  }

  onTicketClicked(id) {
    this.selectedTicket = id;
  }

  onAttachToTicket() {
    const dataModel = { tickets: [] };
    const ticketSub = this.ticketService.getRoomTicketList(this.session.room_id)
    .subscribe(tickets => { dataModel.tickets = tickets; });

    const modalId = this.modalService.show({
      template: this.pickTicketTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          close: () => {
            this.selectedTicket = null;
            ticketSub.unsubscribe();
            this.modalService.hide(modalId);
          },
          attach: () => {
            this.loaderService.show();
            this.ticketService.attachTicket(this.selectedTicket, this.session.room_id, this.session.id)
            .then(() => {
              ticketSub.unsubscribe();
              this.modalService.hide(modalId);
            })
            .catch(error => this.flashMessageService.show('There is an error occured.'))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }
}
