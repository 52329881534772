<div class="authenticate-wrapper">
    <div class="authenticate-container">
        <div class="logo-container">
            <img class="logo-image" [ngStyle]="('design' | env).logoStyle" [src]="('design' | env).logo">
            <div *ngIf="('design' | env).logoText" class="logo-text ml-2">{{ ('design' | env).logoText }}</div>
        </div>
        <div class="py-5" style="position: relative;" *ngIf="!loading">
            <div class="text-center">
                <div>{{ 'APP.MAIN.AUTHENTICATE.ERROR.ERROR_MESSAGE' | translate }}</div>
            </div>
        </div>
    </div>
</div>