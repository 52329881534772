import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { LoaderService } from '@services/support/loader.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-ad-login',
  templateUrl: './ad-login.component.html',
  styleUrls: ['./ad-login.component.scss']
})
export class AdLoginComponent implements OnInit {

  loading: boolean = true;
  authSubscription: Subscription = null;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    // Navigate main if user already authenticated
    this.authSubscription = this.authService.getAuth().subscribe(auth => {
      if (auth) {
        this.router.navigate(['/']);
      }
    });
    this.authenticate();
  }

  async authenticate() {
    const params = await this.route.queryParams.pipe(first()).toPromise();
    if (!(params && params.token)) {
      this.loading = false;
      return;
    }

    this.loaderService.show();
    const token = atob(decodeURIComponent(params.token));

    this.authService.loginToFirebase(token)
    .catch(error => { this.loading = false; })
    .finally(() => this.loaderService.hide());
  }
}
