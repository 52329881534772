import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, Renderer2, OnChanges, SimpleChanges, TemplateRef} from '@angular/core';

import { ImageCollaborationService } from '@services/remote/image-collaboration.service';
import { CollaborationService } from '@services/remote/collaboration.service';

import { Subscription, Observable } from 'rxjs';

import { Frame } from '@models/Frame';
import { FullscreenService } from '@services/support/fullscreen.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { AuthService } from '@services/auth.service';
import { ModalService } from '@services/support/modal.service';
import { FileShareService } from '@services/support/file-share.service';

import { saveAs } from "file-saver";
import { ArDot } from '@models/ArDot';

@Component({
  selector: 'app-image-collaboration',
  templateUrl: './image-collaboration.component.html',
  styleUrls: ['./image-collaboration.component.scss']
})
export class ImageCollaborationComponent implements OnInit, OnDestroy, OnChanges {

  @Input('frame') frame: Frame;
  @Input('size') size: string;
  @Input('external') external: boolean = false;

  @ViewChild('navbar', { static: true }) navbar:ElementRef;
  @ViewChild('scale', { static: false }) scale:ElementRef;
  @ViewChild('direction', { static: false }) direction:ElementRef;

  @ViewChild("endCollTemplate", { static: true }) private endCollTemplate: TemplateRef<any>;

  collDataSub: Subscription = null;

  annotating: boolean = false;
  annotatingSize: boolean = false;
 
  hide: boolean = true;
  isReleased: boolean = true;
  isClicked: boolean = false;
  isOnButton: boolean = false;
  annotating_view: boolean = false;
  hideForever: boolean = false;
 
  controlInput: Observable<any>;
  controlSub: Subscription = null;

  isFullscreen: boolean = false;
  fullscreenSub: Subscription = null;

  failTimeout: number = 20000;
  showWaitingAsFailed: boolean = false;
  anyUserSucceeded: boolean = false;
  notSucceededUsers: any[] = [];

  controlsOpen: boolean = false;
  collLoadingTimeout: any;
  showColorsDiv: boolean = false;
  showWeightsDiv: boolean = false;
  onText: boolean = false;
  showShapesDiv: boolean = false;
  onShape: boolean = false;
  showWidgetsDiv: boolean = false;
  onWidget: boolean = false;
  onPen: boolean = true;
  onMouse: boolean = false;
  onPointer: boolean = false;
  color: string = "yellow";
  weight_color: string = "transparent";
  red: string = "rgb(255, 0, 10)";
  green: string = "rgb(0, 206, 136)";
  yellow: string = "rgb(255, 195, 0)";
  white: string = "rgb(255, 255, 255)";
  borderColor: string;
  weight: string = "middle";

  image: HTMLImageElement = null;
  canvas: HTMLCanvasElement = null;
  annotationContainer: HTMLDivElement = null;

  arDots: ArDot[] = [];
  pointerCollSub: Subscription = null;

  arColorNames = {
    "dc3545": "red",
    "17a2b8": "blue",
    "28a745": "green",
    "ff9800": "orange",
    "dc35f5": "purple",
    "ffffff": "white"
  };

  constructor(
    private authService: AuthService,
    public collaborationService: CollaborationService,
    public imageCollaborationService: ImageCollaborationService,
    private fullscreenService: FullscreenService,
    private flashMessageService: FlashMessageService,
    private renderer: Renderer2,
    private fileShareService: FileShareService,
    private modalService: ModalService
  ) {
    this.controlInput = imageCollaborationService.getControls;
  }

  ngOnInit() {
    this.collLoadingTimeout = setTimeout(() => {
      if(!this.onShape || !this.onWidget || !this.onText)
        this.controlsOpen = true;
      this.showWaitingAsFailed = true;
      this.notSucceededUsers.map(status => {
        if (this.collaborationService.STATE_WAITING) { status.state = this.collaborationService.STATE_FAILED }
        return status;
      });
      if (this.notSucceededUsers.length > 0) {
        this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.COLLABORATION_SHARED.SOME_USERS_CANNOT_LOAD');
      }
    }, this.failTimeout);
    this.collDataSub = this.collaborationService.getCollaborationData(this.frame.collaborationData.id)
    .subscribe(collaborationData => {

      // annotating change
      if (!this.annotating && collaborationData.annotating) {
        this.annotating = true;
        this.toggleAnnotatingColor(this.annotating);

        if(!this.annotatingSize){
          setTimeout(() => {
            this.imageCollaborationService.triggerResize();
          },1000);
          this.annotatingSize = true;
        }
      }
      if (this.annotating && !collaborationData.annotating) {
        this.annotating = false;
        this.toggleAnnotatingColor(this.annotating);
      }

      const collaborationStatus =  Object.keys(collaborationData.status)
      .filter(userId => userId !== this.authService.currentUser.id)
      .map(userId => collaborationData.status[userId]);

      if (!this.anyUserSucceeded && collaborationStatus.some(status => status.state === this.collaborationService.STATE_SUCCEEDED)) {
        this.anyUserSucceeded = true;
        if(!this.onShape || !this.onWidget || !this.onText)
          this.controlsOpen = true;
      }

      this.notSucceededUsers = collaborationStatus
      .filter(status => status.state !== this.collaborationService.STATE_SUCCEEDED)
      .map(status => {
        if (this.showWaitingAsFailed && status.state === this.collaborationService.STATE_WAITING) {
          status.state = this.collaborationService.STATE_FAILED;
        }
        return status;
      });
    });

    this.controlSub = this.controlInput.subscribe(control => {
      const method = control.type;
      const val = control.value;
      if(method=="hide"){
        this.hideForever = true;
      }
      else if(method=="show"){
        this.hideForever = false;
      }
      else if(method=="openControls"){
        this.controlsOpen = true;
        this.onText = false;
        this.onShape = false;
        this.onWidget = false;
        this.showShapesDiv = false;
        this.showWidgetsDiv = false;
      }
    });

    this.fullscreenSub = this.fullscreenService.isFullscreen.subscribe(state => {
      this.isFullscreen = state;
      this.imageCollaborationService.toggleFullScreen(this.isFullscreen);
    });

    this.imageCollaborationService.startListeningControls();
    this.borderColor = "white";

    this.pointerCollSub = this.imageCollaborationService.getPointerCollaborationDots()
    .subscribe(dots => {
      this.arDots = dots.map(dot => {
        dot.color = this.arColorNames[dot.colorCode]
        return dot;
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.size = changes.size.currentValue;
    this.imageCollaborationService.changeSmall(this.size);
  }

  ngOnDestroy() {
    clearTimeout(this.collLoadingTimeout);

    if (this.fullscreenSub) { this.fullscreenSub.unsubscribe(); }
    if (this.collDataSub) { this.collDataSub.unsubscribe() }
    if (this.controlSub) { this.controlSub.unsubscribe() }
    if (this.pointerCollSub) { this.pointerCollSub.unsubscribe() }

    this.imageCollaborationService.endListeningControls();
  }

  onImage(img: HTMLImageElement) {
    this.image = img;
  }

  onCanvas(canvas: { canvas: HTMLCanvasElement, container: HTMLDivElement }) {
    this.canvas = canvas.canvas;
    this.annotationContainer = canvas.container;
  }

  // REMOTE_CHANGED
  createJpegFromCollaboration() {
    if (this.image && this.canvas && this.annotationContainer) {
      const cnv: HTMLCanvasElement = document.createElement("canvas");
      const width = this.canvas.width;
      const height = this.canvas.height;
  
      cnv.width = width;
      cnv.height = height;
      const ctx: CanvasRenderingContext2D = cnv.getContext('2d');
      ctx.drawImage(this.image, 0, 0, width, height);
      ctx.drawImage(this.canvas, 0, 0, width, height);
  
      const ch: HTMLCollection = this.annotationContainer.children;
      for (let i = 0; i < ch.length; i++) {
        const w = <HTMLElement>ch[i];
        if (w.classList.contains('widget')) {
          const im = <HTMLImageElement>w.firstChild;
          const x = parseFloat(im.getAttribute('data-x'));
          const y = parseFloat(im.getAttribute('data-y'));
          const length = parseFloat(im.getAttribute('data-length'));
          ctx.drawImage(im, width*x, height*y, width*length, width*length);
        }
      }

      let filename = "collaboration_edit.jpeg";
      if (this.frame.collaborationData.name.split('.').length) {
        filename = this.frame.collaborationData.name.substring(0, this.frame.collaborationData.name.length - this.frame.collaborationData.name.split('.').pop().length - 1);
        filename = filename + "_edit.jpeg";
      }
      return this.fileShareService.convertCanvasToJPEGFile(cnv, filename, 0.9)
    } else {
      return null;
    }
  }

  // REMOTE_CHANGED
  onDownload() {
    this.createJpegFromCollaboration()
    .then(file => {
      if (file) {
        saveAs(file, file.name);
      } else {
        this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.COLLABORATION_SHARED.DOWNLOAD_FAILED');
      }
    });
  }

  endCollaboration() {
    const modalId = this.modalService.show({
      template: this.endCollTemplate,
      context: {
        dataModel: null,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.modalService.hide(modalId);
            this.collaborationService.closeCollaboration()
            .then(closed => {
              if (!closed) {
                this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.COLLABORATION_SHARED.ALREADY_ENDED', { cssClass: 'alert-info' });
              }
            })
            .catch(error => {
              this.flashMessageService.showTranslated('APP.MAIN.ROOM.VIDEO_CHAT.COLLABORATION_SHARED.CANNOT_END_COLLABORATION');
            })
          }
        }
      }
    });
  }

  onLineWeightClick(){
    if(this.showWeightsDiv)
      this.weight_color = "transparent";       
    else{
      if(this.color === "red")
        this.weight_color = this.red;
      else if(this.color === "green")
        this.weight_color = this.green;
      else if(this.color === "yellow")
        this.weight_color = this.yellow;
      else if(this.color === "white")
        this.weight_color = this.white;
    }

    this.showWeightsDiv = !this.showWeightsDiv;
    // disable other tools
    this.showColorsDiv = false;   
    this.showShapesDiv = false;
    this.showWidgetsDiv = false;  

  }

  onColorClick(){
    this.showColorsDiv = !this.showColorsDiv;

    // disable other tools
    this.showWeightsDiv = false;
    this.showShapesDiv = false;
    this.showWidgetsDiv = false;
    this.weight_color = "transparent";     

  }

  onTextClick(){
    this.onText = !this.onText;
    this.imageCollaborationService.changeTextClick(this.onText);  
    this.showWeightsDiv = false;
    this.weight_color = "transparent";   
    this.showColorsDiv = false;   
    
    if(this.onText){
      // disable other tools
      this.onWidget = false;
      this.showWidgetsDiv = false;
      this.onShape = false;
      this.onPen = false;
      this.showShapesDiv = false;
      this.controlsOpen = false;
      this.onMouse = false;
      this.onPointer = false;
      

    }
    else
      this.controlsOpen = true;

  }

  onPenClick(){
    // disable other tools
    this.onText = false;
    this.showWidgetsDiv = false;
    this.onWidget = false;
    this.showShapesDiv = false;
    this.onShape = false;
    this.showWeightsDiv = false;
    this.weight_color = "transparent";   
    this.showColorsDiv = false; 
    this.onPen = !this.onPen;
    this.imageCollaborationService.changePenClick(this.onPen);
    this.controlsOpen = true;
    this.onMouse = false;
    this.onPointer = false;

  }

  onPointerClick(){
    this.onPointer = !this.onPointer;
    this.onMouse = false;
    this.onText = false;
    this.showWidgetsDiv = false;
    this.onWidget = false;
    this.showShapesDiv = false;
    this.onShape = false;
    this.showWeightsDiv = false;
    this.weight_color = "transparent";   
    this.showColorsDiv = false; 
    this.onPen = false;
    this.imageCollaborationService.changePointerClick(this.onPointer);
    this.controlsOpen = true;

  }

  onShapeClick(){
    this.showShapesDiv = !this.showShapesDiv;

    // disable other tools
    this.showWidgetsDiv = false;
    this.showWeightsDiv = false;
    this.weight_color = "transparent";   
    this.showColorsDiv = false; 
  }

  onWidgetClick(){
    this.showWidgetsDiv = !this.showWidgetsDiv;

    // disable other tools
    this.showShapesDiv = false;
    this.showWeightsDiv = false;
    this.weight_color = "transparent";   
    this.showColorsDiv = false; 
  }

  changeWeight(weight: string){
    this.imageCollaborationService.changeWeight(weight);
    this.weight = weight;  

    setTimeout(() => {
      this.weight_color = "transparent";   
      this.showWeightsDiv = false;
    },300);


  }

  changeColor(color: string){
    this.imageCollaborationService.changeColor(color);
    this.color = color;
    if(this.color === "red")
      this.borderColor = this.red;
    else if(this.color === "green")
      this.borderColor = this.green;
    else if(this.color === "yellow")
      this.borderColor = this.yellow;
    else if(this.color === "white")
      this.borderColor = this.white;

    setTimeout(() => {
      this.showColorsDiv = false; 
    },300);

    this.renderer.setStyle(this.frame.nativeElement, 'border', '2px solid '+this.borderColor);
   
  }

  changeShape(shape: string){
    // disable other tools
    this.controlsOpen = false;
    this.showShapesDiv = false;
    this.onPen = false;
    this.onWidget = false;
    this.onText = false;
    this.onMouse = false;
    this.onPointer = false;


    this.imageCollaborationService.changeShape(shape);  
    this.onShape = true;
  }

  
  changeWidget(widget: string){

    // disable other tools
    this.controlsOpen = false;
    this.showWidgetsDiv = false;
    this.onPen = false;
    this.onShape = false;
    this.onText = false;
    this.onMouse = false;
    this.onPointer = false;

    this.imageCollaborationService.changeShape(widget);
    this.onWidget = true;
  }

  onMouseClick(){
    this.onMouse = !this.onMouse;
    this.onText = false;
    this.showWidgetsDiv = false;
    this.onWidget = false;
    this.showShapesDiv = false;
    this.onShape = false;
    this.showWeightsDiv = false;
    this.weight_color = "transparent";   
    this.showColorsDiv = false; 
    this.onPen = false;
    this.imageCollaborationService.changeMouseClick(this.onMouse);
    this.controlsOpen = true;
    this.onPointer = false;

  }

  onDocumentMouseUp(e){
    this.isClicked = false;
    this.isReleased = true;
    
  }
 
  onDocumentMouseDown(e){
    this.isClicked = true;
    this.isReleased = false;
  }

  onDocumentMouseMove(e){

    if(this.onShape || this.onText || this.onWidget){
      this.hide = true;
      this.controlsOpen = false;
      //this.navbar.nativeElement.style["margin-top"]="-40px";
      return;
    }
  
    if(this.isClicked && !this.isReleased && !this.isOnButton && this.annotating){

      this.navbar.nativeElement.style.transition="margin 0s";
      if (this.controlsOpen) {
        this.scale.nativeElement.style.transition="margin 0s";
        this.direction.nativeElement.style.transition="margin 0s";
      }

      this.hide = true;
    }
    else{
      if(!this.hideForever){
        this.navbar.nativeElement.style.transition="margin 0.5s ";
        if (this.controlsOpen) {
          this.scale.nativeElement.style.transition="margin 0.5s ease-out";
          this.direction.nativeElement.style.transition="margin 0.5s";
        }

        this.hide = false;
      }
      else{
        this.navbar.nativeElement.style.transition="margin 0s";
        if (this.controlsOpen) {
          this.scale.nativeElement.style.transition="margin 0s";
          this.direction.nativeElement.style.transition="margin 0s";
        }
        this.hide = true;
      }
    }    
  }

  onDocumentMouseLeave(e){
    this.isClicked = false;
    this.isReleased = true;
    this.hide = true;
  }
  onDocumentMouseEnter(e){
    if (this.size !== 'small')
      this.hide = false;
  }
  onDocumentMouseLeaveControl(e){
    this.isOnButton = false;
  }
  onDocumentMouseEnterControl(e){
    this.isOnButton = true;
  }
   
  annotate() {
    this.toggleAnnotatingColor(!this.annotating);
    this.collaborationService.toggleAnnotate(!this.annotating);
    this.imageCollaborationService.toggleAnnotate(!this.annotating);
    setTimeout(() => {
      this.imageCollaborationService.triggerResize();
    },100);
    if(!this.annotating){
      this.showColorsDiv = false;  
      this.showWeightsDiv = false; 
      this.showWidgetsDiv = false;
      this.showShapesDiv = false;
      this.onShape = false;
      this.onWidget = false;
      this.onPen = true;  
      this.onMouse = false;
      this.onPointer = false;
      this.weight_color = "transparent";  
      this.onText = false;
      this.imageCollaborationService.changePenClick(this.onPen);
    }  
   
  }

  toggleAnnotatingColor(annotating: boolean) {
    if (annotating) {
      if(this.color === "red")
        this.borderColor = this.red;
      else if(this.color === "green")
        this.borderColor = this.green;
      else if(this.color === "yellow")
        this.borderColor = this.yellow;
      else if(this.color === "white"){
        this.borderColor = this.white;
      }
      this.renderer.setStyle(this.frame.nativeElement, 'border', '2px solid '+this.borderColor);


    } else {
      this.renderer.setStyle(this.frame.nativeElement, 'border', '2px solid white');
      this.borderColor = "white";
    }
  }

  onToggleFullscreen() {

    // exis full screen if the current is full screen. Vice versa.
    if (this.isFullscreen) {
      this.fullscreenService.exitFullscreen();
    } else {
      this.fullscreenService.requestFullscreen(this.frame.nativeElement);
    }
  }

  undo(){
    
    this.dismissTools();

    // trigger undo function.
    this.imageCollaborationService.undo();


  }
  redo(){
   
    this.dismissTools();

    // trigger redo function.
    this.imageCollaborationService.redo();

 
  }
  zoomIn(){

    this.dismissTools();

    // trigger zoom In function.
    this.imageCollaborationService.zoomIn();


  }
  zoomOut(){

    this.dismissTools();
    
    // trigger zoom Out function.
    this.imageCollaborationService.zoomOut();
   
  }
  moveUp(){

   this.dismissTools();
    
    // trigger moveUp function.
    this.imageCollaborationService.moveUp("mouse");

  }
  moveRight(){

    this.dismissTools();
    
    // trigger moveRight function.
    this.imageCollaborationService.moveRight("mouse");

  }
  moveDown(){
   
    this.dismissTools();
    
    // trigger moveDown function.
    this.imageCollaborationService.moveDown("mouse");

  }
  moveLeft(){
    
    this.dismissTools();
    
    // trigger moveLeft function.     
    this.imageCollaborationService.moveLeft("mouse");
    this.showShapesDiv = false;
    this.showWidgetsDiv = false;
  }
  refresh(){
     
    this.dismissTools();    
    this.onText = false;
    this.onShape = false;
    this.onPen = true;
    this.onMouse = false;
    this.onPointer = false;
    this.imageCollaborationService.revert();

  }

  dismissTools(){

    // when a control button is clicked, dissmiss the tools div.
    this.showColorsDiv = false; 
    this.showWeightsDiv = false;  
    this.showShapesDiv = false;
    this.showWidgetsDiv = false;
    this.weight_color = "transparent";       
    this.hide = false;

  }
  onDocumentTouchStart(e){
  }
  onDocumentTouchMove(e){
  }
  onDocumentTouchEnd(e){
  }
}
