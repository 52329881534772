import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked, OnDestroy, ElementRef, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription, merge } from 'rxjs';
import { UserService } from '@services/user.service';
import { RoomService } from '@services/room.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { DetailPageService } from '@services/support/detail-page.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@services/support/loader.service';
import { ModalService } from '@services/support/modal.service';
import { AccountService } from '@services/account.service';

@Component({
  selector: 'app-room-details',
  templateUrl: './room-details.component.html',
  styleUrls: ['./room-details.component.scss']
})
export class RoomDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {

  static ACTION_DETAILS: string = "details";
  static ACTION_EDIT: string = "edit";
  static ACTION_NEW: string = "new";
  /**** Access in template ****/
  get ACTION_DETAILS() { return RoomDetailsComponent.ACTION_DETAILS; }
  get ACTION_EDIT() { return RoomDetailsComponent.ACTION_EDIT; }
  get ACTION_NEW() { return RoomDetailsComponent.ACTION_NEW; }

  @ViewChild("delRoomTemplate", { static: true }) private delRoomTemplate: TemplateRef<any>;
  @ViewChild("toggleTemplate", { static: true }) private toggleTemplate: TemplateRef<any>;
  @ViewChild("archiveChangeTemplate", { static: true }) private archiveChangeTemplate: TemplateRef<any>;
  @ViewChild("peerToPeerTemplate", { static: true }) private peerToPeerTemplate: TemplateRef<any>;

  @ViewChild('roomForm', { static: true }) roomForm: NgForm;
  @ViewChild('iconName', { static: true }) iconName: ElementRef;
  @ViewChild('iconFile', { static: true }) iconFile: ElementRef;
  @ViewChild('icon', { static: true }) icon: ElementRef;

  action: string;
  actionTitle: string = "";

  roomId: string;

  formPristine: boolean = true;
  formLoading: boolean = true;
  formValueSub: Subscription = null;

  users = [];
  usersLoading: boolean = true;

  trainingLicenses = [];

  orjRoom: any;
  room: any = {
    room_data: {
      name: "",
      disabled: false,
      auto_archive: false,
      peer_to_peer: false,
      training_room: false,
      full_hd: false,
      archive_perm_needed: false,
      icon: this.roomService.defaultRoomIcon,
      icon_default: true,
      description: "",
      users: [],
      training_master: null,
      training_license: null
    }
  };

  private userSub: Subscription = null;
  private roomSub: Subscription = null;

  licenseType: string = "expert_user_based";
  accountDataSub: Subscription = null;
  accountArchiveAllowed: boolean = false;
  accountAlwaysArchive: boolean = false;
  peerToPeerAllowed: boolean = false;
  accountFullHdAllowed: boolean = false;
  accountAlwaysFullHd: boolean = false;
  trainingRoomAllowed: boolean = false;

  constructor(
    private userService: UserService,
    private roomService: RoomService,
    private accountService: AccountService,
    private detailPageService: DetailPageService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private flashMessageService: FlashMessageService,
    private modalService: ModalService,
    private loaderService: LoaderService
  ) {
    this.userSub = this.userService.users.subscribe(users => {
      this.usersLoading = false;
      this.users = users
        .map(user => this.userService.generateUserNameWithRole(user, this.licenseType))
        .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    });
  }

  ngAfterViewChecked() {
    if (this.action === this.ACTION_DETAILS) {
      this.roomForm.control.disable();
      this.changeDetector.detectChanges();
      this.iconName.nativeElement.disabled = true;
    }
  }

  ngOnInit() {
    if (this.action === this.ACTION_NEW) {
      this.orjRoom = {
        room_data: {
          name: "",
          disabled: false,
          auto_archive: false,
          peer_to_peer: false,
          training_room: false,
          full_hd: false,
          archive_perm_needed: false,
          icon: this.roomService.defaultRoomIcon,
          icon_default: true,
          description: "",
          users: [],
          training_master: null,
          training_license: null
        }
      };
      this.formLoading = false;
      this.translateService.get('APP.MAIN.ROOMS.ROOM_DETAILS.TITLE_NEW').toPromise()
      .then(result => { this.actionTitle = result; });
    } else {
      if (this.action === this.ACTION_DETAILS) {
        this.translateService.get('APP.MAIN.ROOMS.ROOM_DETAILS.TITLE_DETAILS').toPromise()
        .then(result => { this.actionTitle = result; });
      } else if (this.action === this.ACTION_EDIT) {
        this.translateService.get('APP.MAIN.ROOMS.ROOM_DETAILS.TITLE_EDIT').toPromise()
        .then(result => { this.actionTitle = result; });
      }
      this.loadRoomData();
    }

    this.accountDataSub = this.accountService.accountData
    .subscribe(accountData => {
      this.licenseType = accountData.license.type;
      this.users = this.users.map(user => this.userService.generateUserNameWithRole(user, this.licenseType));
      this.trainingLicenses = accountData.training_licenses ? Object.keys(accountData.training_licenses).map(lId => {
        const l = accountData.training_licenses[lId];
        l.id = lId;
        return l;
      }) : [];

      this.accountArchiveAllowed = accountData.features.archiving;
      this.peerToPeerAllowed = accountData.features.peertopeer;
      this.accountFullHdAllowed = accountData.add_ons.fullhd;
      this.accountAlwaysFullHd = accountData.always_full_hd;
      this.trainingRoomAllowed = accountData.add_ons.trainingroom;
      this.accountAlwaysArchive = accountData.always_archive;

      if (this.action === this.ACTION_NEW && this.accountAlwaysArchive && !this.room.room_data.peer_to_peer) {
        this.room.room_data.auto_archive = true;
        this.orjRoom.room_data.auto_archive = true;
      }
      if (this.action === this.ACTION_NEW && this.accountAlwaysFullHd) {
        this.room.room_data.full_hd = true;
        this.orjRoom.room_data.full_hd = true;
      }
    });

    this.formValueSub = this.roomForm.valueChanges.subscribe(value => {
      const rawValue = this.roomForm.control.getRawValue();

      let pristine = true;
      if (this.orjRoom) {
        Object.keys(rawValue).filter(x => x !== 'users').forEach(property => {
          if (rawValue[property] !== this.orjRoom.room_data[property]) {
            pristine = false;
          }
        });
        if (rawValue.users && (this.orjRoom.room_data.users.filter(x => !rawValue.users.includes(x)).length > 0 ||
          rawValue.users.filter(x => !this.orjRoom.room_data.users.includes(x)).length > 0)) {
          pristine = false;
        }
      }
      this.formPristine = pristine;
    });
  }

  roomUserListChanged(event) {
    const master = event.find(i => i.id === this.room.room_data.training_master);
    if (!master) {
      if (event.length > 0) {
        this.room.room_data.training_master = event[0].id;
      } else {
        this.room.room_data.training_master = null;
      }
    }
  }

  loadRoomData() {
    this.roomService.getRoom(this.roomId).then(room => {
      this.orjRoom = {
        id: room.id,
        room_data: {
          name: room.room_data.name,
          disabled: room.room_data.disabled,
          auto_archive: room.room_data.auto_archive,
          peer_to_peer: room.room_data.peer_to_peer,
          training_room: room.room_data.training_room,
          full_hd: room.room_data.full_hd ? true : false,
          archive_perm_needed: room.room_data.archive_perm_needed ? true : false,
          icon: room.room_data.icon,
          icon_default: room.room_data.icon_default ? true : false,
          description: room.room_data.description,
          users: Object.keys(room.room_data.users).filter(u => !room.room_data.users[u].guest).sort((a, b) => room.room_data.users[a].name.toLocaleLowerCase().localeCompare(room.room_data.users[b].name.toLocaleLowerCase()))
        }
      };

      let us = Object.keys(room.room_data.users).filter(u => !room.room_data.users[u].guest).sort((a, b) => room.room_data.users[a].name.toLocaleLowerCase().localeCompare(room.room_data.users[b].name.toLocaleLowerCase()));

      this.room = {
        id: room.id,
        room_data: {
          name: room.room_data.name,
          disabled: room.room_data.disabled,
          auto_archive: room.room_data.auto_archive,
          peer_to_peer: room.room_data.peer_to_peer,
          training_room: room.room_data.training_room,
          full_hd: room.room_data.full_hd ? true : false,
          archive_perm_needed: room.room_data.archive_perm_needed ? true : false,
          icon: room.room_data.icon,
          icon_default: room.room_data.icon_default ? true : false,
          description: room.room_data.description,
          users: us
        }
      };

      if (this.room.room_data.training_room) {
        this.room.room_data.training_master = us.find(i => room.room_data.users[i].status === "master");
        this.orjRoom.room_data.training_master = this.room.room_data.training_master;

        this.room.room_data.training_license = room.room_data.training_license;
        this.orjRoom.room_data.training_license = room.room_data.training_license;
      }
      this.formLoading = false;
    });
  }

  resetForm() {
    this.iconFile.nativeElement.value = "";
    this.iconName.nativeElement.value = "";

    Object.keys(this.room.room_data).forEach(prop => {
      this.room.room_data[prop] = this.orjRoom.room_data[prop];
    });

    Object.keys(this.roomForm.controls).forEach(c => {
      this.roomForm.controls[c].markAsUntouched();
    });
  }

  onPeerToPeerChange(event) {
    if (this.room.room_data.peer_to_peer) {
      this.room.room_data.auto_archive = false;
      this.room.room_data.archive_perm_needed = false;
    } else {
      if (this.accountAlwaysArchive || (this.orjRoom && this.orjRoom.room_data.auto_archive)) {
        this.room.room_data.auto_archive = true;
      }
      if (this.orjRoom && this.orjRoom.room_data.archive_perm_needed) {
        this.room.room_data.archive_perm_needed = true;
      }
    }
  }

  onTrainingRoomChange(event) {
    if (this.room.room_data.training_room) {
      this.room.room_data.peer_to_peer = false;
    } else if (this.orjRoom && this.orjRoom.room_data.peer_to_peer) {
      this.room.room_data.peer_to_peer = true;
    }
  }

  onIconChange(event) {
    if (event.target.files[0]) {
      this.iconName.nativeElement.value = event.target.files[0].name;

      const img: HTMLImageElement = new Image();
      img.src = URL.createObjectURL(event.target.files[0]);
      
      img.onload = () => {
        const w = Math.round( img.naturalHeight > img.naturalWidth ? 64 * (img.naturalWidth / img.naturalHeight) : 64 );
        const h = Math.round( img.naturalWidth > img.naturalHeight ? 64 * (img.naturalHeight / img.naturalWidth) : 64 );

        const cnv: HTMLCanvasElement = document.createElement("canvas");
        cnv.width = w;
        cnv.height = h;
        const ctx: CanvasRenderingContext2D = cnv.getContext('2d');
        ctx.drawImage(img, 0, 0, w, h);
        this.room.room_data.icon = cnv.toDataURL("image/png", 1);
        this.room.room_data.icon_default = false;
      }
    } else {
      this.iconName.nativeElement.value = '';
      this.room.room_data.icon = this.roomService.defaultRoomIcon;
      this.room.room_data.icon_default = true;
    }
  }

  ngOnDestroy() {
    if (this.userSub) { this.userSub.unsubscribe() }
    if (this.roomSub) { this.roomSub.unsubscribe() }
    if (this.formValueSub) { this.formValueSub.unsubscribe() }
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
  }

  detectRoomChanges(orjRoom, rawValue) {
    let changes: any = {
      room_id: orjRoom.id,
      room_name: rawValue.name,
      room_data: {}
    };
    Object.keys(rawValue).filter(x => x !== 'users').forEach(property => {
      if (rawValue[property] !== orjRoom.room_data[property]) {
        changes.room_data[property] = rawValue[property];
      }
    });

    const deleted_users = orjRoom.room_data.users.filter(x => !rawValue.users.includes(x));
    const inserted_users = rawValue.users.filter(x => !orjRoom.room_data.users.includes(x));
    if (deleted_users.length > 0) {
      changes['deleted_users'] = deleted_users;
    } else {
      changes['deleted_users'] = [];
    }
    if (inserted_users.length > 0) {
      changes['inserted_users'] = inserted_users;
    } else {
      changes['inserted_users'] = [];
    }

    return changes;
  }

  onSubmit(form: NgForm) {
    const rawValue = form.control.getRawValue();

    if (this.action === this.ACTION_EDIT) {
      const changes =  this.detectRoomChanges(this.orjRoom, rawValue);

      const auto_archive_changed = changes.room_data.auto_archive === true || changes.room_data.auto_archive === false;
      const p2p_changed = changes.room_data.peer_to_peer === true || changes.room_data.peer_to_peer === false;
      const training_room_changed = changes.room_data.training_room === true || changes.room_data.training_room === false;

      if (auto_archive_changed || p2p_changed || training_room_changed) {
        const dataModel = {
          auto_archive_changed: auto_archive_changed,
          p2p_changed: p2p_changed,
          p2p_on: (p2p_changed && changes.room_data.peer_to_peer) ? true : false,
          training_room_changed: training_room_changed
        };
        const modalId = this.modalService.show({
          template: this.archiveChangeTemplate,
          context: {
            dataModel: dataModel,
            callbacks: {
              no: () => {
                this.modalService.hide(modalId);
              },
              yes: () => {
                this.editRoom(changes)
                .finally(() => {
                  this.modalService.hide(modalId);
                });
              }
            }
          }
        });
      } else {
        this.editRoom(changes);
      }
    } else if (this.action === this.ACTION_NEW) {

      const newRoom: any = {
        room_data: {
          name: rawValue.name,
          icon: rawValue.icon,
          icon_default: this.room.room_data.icon_default,
          auto_archive: rawValue.auto_archive,
          peer_to_peer: rawValue.peer_to_peer,
          training_room: rawValue.training_room,
          full_hd: rawValue.full_hd ? true : false,
          archive_perm_needed: rawValue.archive_perm_needed ? true : false,
          description: rawValue.description,
          users: rawValue.users
        }
      }
      if (newRoom.room_data.training_room) {
        newRoom.room_data.training_master = rawValue.training_master;
        newRoom.room_data.training_license = rawValue.training_license;
      }
      this.createRoom(newRoom);
    }
  }

  editRoom(changes: any) {
    const disabledControls = (this.roomForm.controls ? Object.values(this.roomForm.controls) : []).filter(c => c.disabled);
    this.iconName.nativeElement.disabled = true;
    this.roomForm.control.disable();
    this.loaderService.show();
    return this.roomService.updateRoom(changes)
    .then(() => {
      this.flashMessageService.showTranslated('APP.MAIN.ROOMS.ROOM_DETAILS.ROOM_UPDATED', { cssClass: "alert-success" });
      const detail = this.detailPageService.loadComponent(RoomDetailsComponent).instance;
      detail.action = RoomDetailsComponent.ACTION_DETAILS;
      detail.roomId = changes.room_id;
    })
    .catch(error => {
      this.flashMessageService.showTranslated('APP.MAIN.ROOMS.ROOM_DETAILS.CANNOT_UPDATE');
      this.iconName.nativeElement.disabled = false;
      this.roomForm.control.enable();

      disabledControls.forEach(c => c.disable());
    })
    .finally(() => {
      this.loaderService.hide();
    });
  }

  createRoom(room: any) {
    const disabledControls = (this.roomForm.controls ? Object.values(this.roomForm.controls) : []).filter(c => c.disabled);
    this.iconName.nativeElement.disabled = true;
    this.roomForm.control.disable();
    this.loaderService.show();
    this.roomService.createRoom(room)
    .then(roomId => {
      this.flashMessageService.showTranslated('APP.MAIN.ROOMS.ROOM_DETAILS.ROOM_CREATED', { cssClass: "alert-success" });
      this.detailPageService.removeComponent();
    })
    .catch(error => {
      this.flashMessageService.showTranslated('APP.MAIN.ROOMS.ROOM_DETAILS.CANNOT_CREATE');
      this.iconName.nativeElement.disabled = false;
      this.roomForm.control.enable();
      
      disabledControls.forEach(c => c.disable());
    })
    .finally(() => {
      this.loaderService.hide();
    });
  }

  onBack() {
    this.detailPageService.removeComponent();
  }

  onEdit() {
    const detail = this.detailPageService.loadComponent(RoomDetailsComponent).instance;
    detail.action = RoomDetailsComponent.ACTION_EDIT;
    detail.roomId = this.roomId;
  }

  cancelEdit() {
    const detail = this.detailPageService.loadComponent(RoomDetailsComponent).instance;
    detail.action = RoomDetailsComponent.ACTION_DETAILS;
    detail.roomId = this.roomId;
  }

  onToggle() {
    const modalId = this.modalService.show({
      template: this.toggleTemplate,
      context: {
        dataModel: this.room.room_data,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.loaderService.show();
            this.roomService.toggleRoomStatus(this.roomId)
            .then(disabled => {
              if (disabled) {
                this.flashMessageService.showTranslated('APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.DISABLED', { cssClass: "alert-success" });
              } else {
                this.flashMessageService.showTranslated('APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.ENABLED', { cssClass: "alert-success" });
              }

              const detail = this.detailPageService.loadComponent(RoomDetailsComponent).instance;
              detail.action = RoomDetailsComponent.ACTION_DETAILS;
              detail.roomId = this.roomId;
            })
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.ROOMS.ROOM_DETAILS.TOGGLE_ROOM_MODAL.ERROR'))
            .finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
            });
          }
        }
      }
    });
  }

  onDelete() {
    const modalId = this.modalService.show({
      template: this.delRoomTemplate,
      context: {
        dataModel: this.room,
        callbacks: {
          cancel: () => {
            this.modalService.hide(modalId);
          },
          delete: () => {
            this.loaderService.show();
            this.roomService.deleteRoom(this.roomId)
            .then(roomId => {
              this.flashMessageService.showTranslated('APP.MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM_MODAL.DELETED', { cssClass: "alert-success" });
              this.detailPageService.removeComponent();
            })
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.ROOMS.ROOM_DETAILS.DELETE_ROOM_MODAL.ERROR'))
            .finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
            })
          }
        }
      }
    });
  }

  onPeerToPeerInfo() {
    const modalId = this.modalService.show({
      template: this.peerToPeerTemplate,
      context: {
        dataModel: null,
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }
}
