import { Component, OnInit } from '@angular/core';
import { RoomService } from '@services/room.service';
import { UserService } from '@services/user.service';
import { Subscription } from 'rxjs';
import { SessionService } from '@services/session.service';
import { AccountService } from '@services/account.service';

@Component({
  selector: 'app-info-boxes',
  templateUrl: './info-boxes.component.html',
  styleUrls: ['./info-boxes.component.scss']
})
export class InfoBoxesComponent implements OnInit {

  roomCount: number = 0;
  private roomsSub: Subscription = null;

  userCount: number = 0;
  private usersSub: Subscription = null;

  sessionCount: number = 0;
  private sessionSub: Subscription = null;

  durationHours: number = 0;
  durationMinutes: number = 0;
  private durationSub: Subscription = null;

  constructor(
    private userService: UserService,
    private roomService: RoomService,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.usersSub = this.userService.users.subscribe(users => {
      this.userCount = users.length;
    });
    this.roomsSub = this.roomService.rooms.subscribe(rooms => {
      this.roomCount = rooms.length;
    });
    this.accountService.sessionCount.subscribe(count => {
      this.sessionCount = count;
    });
    this.accountService.sessionDuration.subscribe(duration => {
      this.durationHours = Math.floor(duration / 3600000);
      this.durationMinutes = Math.floor((duration - (this.durationHours * 3600000)) / 60000);
    });
  }

  ngOnDestroy() {
    if (this.usersSub) { this.usersSub.unsubscribe() }
    if (this.roomsSub) { this.roomsSub.unsubscribe() }
    if (this.sessionSub) { this.sessionSub.unsubscribe() }
    if (this.durationSub) { this.durationSub.unsubscribe() }
  }
}
