import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import firebase from 'firebase/app';
import 'firebase/auth';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { User } from '../models/User';
import { switchMap, map, filter, throwIfEmpty } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser: User = null;

  // Current user observable -> can subscribable for real time updates
  user: Observable<User>;
  private currentUserSource: BehaviorSubject<User> = new BehaviorSubject(null);

  private isLoggedIn: boolean = false;
  private listeners: { login: any[], logout: any[] };

  constructor(
    private afAuth: AngularFireAuth,
    private afdb: AngularFireDatabase,
    private http: HttpClient
  ) {
    afAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    this.user = this.currentUserSource.asObservable().pipe(filter(users => !!users));

    this.listeners = { login: [], logout: [] };

    this.afAuth.authState.pipe(
      switchMap(auth => {
        if (auth) {
          return this.afAuth.idTokenResult;
        } else {
          return of(null);
        }
      }),
      switchMap(t => {
        if (t) {
          if (!this.isLoggedIn) {
            this.isLoggedIn = true;
            this.listeners.login.forEach(callback => {
              callback(t.claims.account_id, t.claims.account_name);
            });
          }

          return this.afdb.object<User>(`accounts/${t.claims.account_id}/users/${t.claims.uid}`)
          .valueChanges().pipe(
            map(user => {
              user.id = t.claims.uid;
              user.token = t.token;
              return user;
            })
          );
        } else {
          this.isLoggedIn = false;
          return of(null);
        }
      })
    ).subscribe(user => {
      this.currentUser = user;
      this.currentUserSource.next(user);
    });
  }

  on( event: "login" | "logout" | "externalLogin" | "multipleLogin", callback) {
    this.listeners[event].push(callback);
  }

  off(event: "login" | "logout", callback?) {
    if (callback == undefined) {
      this.listeners[event] = [];
    } else {
      const index = this.listeners[event].indexOf(callback);
      this.listeners[event].splice(index, 1);
    }
  }

  getAuth(): Observable<firebase.User> {
    return this.afAuth.authState.pipe( map(auth => auth) );
  }

  login(account_name: string, username: string, password: string): Promise<any> {
    const url = environment.endPoints.login;
    return this.http.post<any>(url, { account_name: account_name, username: username, password: password, for_admin: true }).toPromise();
  }

  verifyLoginCode(account_name: string, username: string, password: string, code: string) {
    const url = environment.endPoints.login;
    return this.http.post<any>(url, { account_name: account_name, username: username, password: password, for_admin: true, verification: code }).toPromise();
  }

  loginToFirebase(token: string) {
    return this.afAuth.signInWithCustomToken(token).then(userCredential => userCredential.user);
  }

  switchToWorkflow(workflowBaseUrl: string) {
    const url = environment.endPoints.switchToAdmin;
    return this.http.post<any>(url, { token: this.currentUser.token }).toPromise()
    .then(result => {
      const encodedToken = encodeURIComponent(btoa(result.token));
      const navUrl = `${workflowBaseUrl}/login-from-admin?token=${encodedToken}`;
      window.open(navUrl, '_blank');
    });
  }

  logout() {
    return this.afAuth.signOut().then(() => {
      this.listeners.logout.forEach(callback => {
        callback();
      });
      return;
    });
  }

  twoFactorSendCode(action: string) {
    return this.http.post<any>(environment.endPoints.twofactorauth, {
      token: this.currentUser.token, action: action
    }).toPromise();
  }

  twoFactorProceed(action: string, code: string) {
    return this.http.post<any>(environment.endPoints.twofactorauth, {
      token: this.currentUser.token, action: action, verification: code.toString()
    }).toPromise();
  }

  authenticateSSO(id: string) {
    return this.http.post<any>(environment.endPoints.admin_sso, { sso_id: id }).toPromise();
  }
}
