import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';

import { undesiredPasswordListValidator } from '../validators/undesired-password-list.validator';

@Directive({
  selector: '[undesiredPasswordListValidator]',
  providers: [
    {
        provide: NG_VALIDATORS, 
        useExisting: forwardRef(() => UndesiredPasswordValidatorDirective), 
        multi: true 
    }
  ]
})

export class UndesiredPasswordValidatorDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    return undesiredPasswordListValidator(control);
  }
}