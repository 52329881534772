import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Station } from '@models/Station';
import { StationService } from '@services/station.service';
import { Subscription } from 'rxjs';
import { DetailPageService } from '@services/support/detail-page.service';
import { StationDetailsComponent } from '../station-details/station-details.component';
import { ModalService } from '@services/support/modal.service';
import { LoaderService } from '@services/support/loader.service';

@Component({
  selector: 'app-station-list',
  templateUrl: './station-list.component.html',
  styleUrls: ['./station-list.component.scss']
})
export class StationListComponent implements OnInit, OnDestroy {

  @ViewChild("deleteStationTemplate", { static: true }) private deleteStationTemplate: TemplateRef<any>;

  stations: Station[] = [];
  stationsSub: Subscription = null;

  constructor(
    private stationService: StationService,
    private detailPageService: DetailPageService,
    private modalService: ModalService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.stationsSub = this.stationService.stations.subscribe(sts => {
      this.stations = sts;
    });
  }

  ngOnDestroy() {
    if (this.stationsSub) { this.stationsSub.unsubscribe() }
  }

  onNewStation() {
    const detail = this.detailPageService.loadComponent(StationDetailsComponent).instance;
    detail.action = StationDetailsComponent.ACTION_NEW;
  }

  onStationDetails(e: MouseEvent, station: Station) {
    e.stopPropagation();

    const detail = this.detailPageService.loadComponent(StationDetailsComponent).instance;
    detail.action = StationDetailsComponent.ACTION_DETAILS;
    detail.stationId = station.id;
  }

  onStationEdit(e: MouseEvent, station: Station) {
    e.stopPropagation();

    const detail = this.detailPageService.loadComponent(StationDetailsComponent).instance;
    detail.action = StationDetailsComponent.ACTION_EDIT;
    detail.stationId = station.id;
  }

  onStationDelete(e: MouseEvent, station: Station) {
    e.stopPropagation();

    const modalId = this.modalService.show({
      template: this.deleteStationTemplate,
      context: {
        dataModel: station,
        callbacks: {
          cancel: () => this.modalService.hide(modalId),
          delete: () => {
            this.loaderService.show();
            this.stationService.deleteStation(station.id)
            .finally(() => {
              this.loaderService.hide();
              this.modalService.hide(modalId);
            });
          }
        }
      }
    });
  }
}
