import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { FlashMessageService } from '@services/support/flash-message.service';

@Component({
  selector: 'app-flash-message',
  templateUrl: './flash-message.component.html',
  styleUrls: ['./flash-message.component.scss'],
  animations: [
    trigger('flash', [
      state('in', style({marginRight: 0, marginTop: '1rem', transform: 'scale(1.0)'})),
      transition(':enter', [ style({marginRight: '-100%', marginTop: '0', transform: 'scale(0.1)'}), animate(200) ]),
      transition(':leave', animate(200, style({marginRight: '-100%', marginTop: '0', transform: 'scale(0.1)'})) )
    ])
  ]
})
export class FlashMessageComponent {

  constructor(public flashMessageService: FlashMessageService) {
  }

}
