import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Renderer2} from '@angular/core';
import { Chart } from 'chart.js';
import { SessionService } from '@services/session.service';
import { Session } from '@models/Session';
import { NgbDate, NgbCalendar, NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@models/User';
import { UserService } from '@services/user.service';
import { RoomService } from '@services/room.service';
import { Subscription } from 'rxjs';
import { AccountService } from '@services/account.service';

@Component({
  selector: 'app-reports-chart',
  templateUrl: './reports-chart.component.html',
  styleUrls: ['./reports-chart.component.scss']
})
export class ReportsChartComponent implements OnInit, OnDestroy {

  @ViewChild('sessionCountContainer', { static: true }) sessionCountContainer: ElementRef;
  @ViewChild('sessionDurationByRoomContainer', { static: true }) sessionDurationByRoomContainer: ElementRef;
  @ViewChild('sessionDurationContainer', { static: true }) sessionDurationContainer: ElementRef;

  sessionCountCanvas: HTMLCanvasElement
  sessionDurationCanvas: HTMLCanvasElement
  sessionDurationByRoomCanvas: HTMLCanvasElement
selectedLabelFilter
  sessionCountChart: any;
  sessionDurationChart: any;
  rooms: any;
  sessionsByRoom: any = {};
  timezone: string = 'UTC';
  startDate: string;
  endDate: string;
  selectedYears: number[] = [];
  selectedRangeLabel: string = this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.LAST7DAYS');
  hoveredDate: NgbDate | null = null;
  isDatepickerOpen: boolean = false;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  sessionDurationByRoomChart: any;
  labels: string[] = [];
  selectedRoomFilter
  filterUsers = [];
  users: User[] = [];
  roomNames: string[] = [];
  userNames: string[] = [];
  roomData = {};
  allRooms = []
  roomsDropdown
  allSessions: Session[] = null;

  subscriptions: Subscription[] = []
  allMeetingsRoom = {
    id: 'allMeetings',
    name: 'All Meetings',
    room_data: {
      name: 'All Meetings',
      meeting_room: true 
    }
  };

  roomLabels

  constructor(
    private translateService: TranslateService,
    private sessionService: SessionService,
    private userService: UserService,
    private renderer: Renderer2,
    private accountService: AccountService,
    calendar: NgbCalendar
    ){ 
      this.fromDate = calendar.getToday();
      this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  ngOnInit(): void {
    this.setDefaultDateRange(true);
    this.getAllUsersToSetDropdown();
    this.getAllRoomsToSetDropdown();
    this.accountService.getAccountLabels().subscribe(labels => {
      this.labels = labels;
    });
  }

  getAllUsersToSetDropdown() {
    this.subscriptions.push(this.userService.allUsers.subscribe(u => {
      this.users = u.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
      this.userNames = this.users.map(user => user.name);
    }))
  }

  getAllRoomsToSetDropdown() {
    this.sessionService.getRooms().then((rooms) => {
      this.allRooms.push(this.allMeetingsRoom);

      Object.keys(rooms).forEach(roomKey => {
        const room = rooms[roomKey];
        if (!room.room_data.meeting_room && !room.room_data.personal_room && !room.room_data.deleted) {
          let arrayObject = {id:roomKey,name:room.room_data.name, ...room };
          this.allRooms.push(arrayObject);
        }
      });
      this.rooms = this.allRooms;
      this.roomNames = this.rooms.map(r => r.name);
      this.roomsDropdown = this.rooms;
      this.rooms.forEach(r => this.roomData[r.name] = r.id);
    });
  }
  
  onFilterChanged(res?) {
    this.updateChart()

  }

  resetFilters(): void {
    this.updateChart();
  }
  

  setDefaultDateRange(isInitial?): void {
    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6); 
    this.startDate = lastWeek.toISOString().substring(0, 10);
    this.endDate = today.toISOString().substring(0, 10);
    this.updateChart(isInitial);
  }
  
  updateChart(isInitial?): void {
    const startDate = new Date(this.startDate);
    const endDate = new Date(this.endDate);
    this.getSessionsByDateRange(startDate, endDate,isInitial);
   
  }

  updateChartManually(): void {
    if (this.fromDate && this.toDate) {
      const startDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      const endDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
      this.getSessionsByDateRange(startDate, endDate);
    }
  }

  getSessionsByDateRange(startDate: Date, endDate: Date, isInitial?): void {
    Promise.all([
      this.sessionService.getSessionsAtDateRange(startDate, endDate),
      this.sessionService.getRooms()
    ]).then(([sessions, rooms]) => {
      const sessionCounts = {};
      const sessionDurations = {};
      const allMeetingsCounts = {};
      const allMeetingsDurations = {};

      if (this.selectedLabelFilter && this.selectedLabelFilter.length > 0) {
        sessions = sessions.filter((session) => {
          const sessionLabels = session.label || []; 
          return this.selectedLabelFilter.some(label => sessionLabels.includes(label));
        });
      }

      for (const sessionId in sessions) {
        const session = sessions[sessionId];
        const roomId = session.room_id;

        if (!rooms[roomId] || !rooms[roomId].room_data) {
          continue;
        }
  
        const sessionYear = session.start_time ? new Date(session.start_time).getFullYear() : null;
        const isMeetingRoom = rooms[roomId].room_data.meeting_room;

        if(sessionYear) {
          if (!(sessionYear in sessionCounts)) {
            sessionCounts[sessionYear] = {};
            allMeetingsCounts[sessionYear] = 0;
          }
          if (!(sessionYear in sessionDurations)) {
            sessionDurations[sessionYear] = {};
            allMeetingsDurations[sessionYear] = 0;
          }

          if (isMeetingRoom) {
            allMeetingsCounts[sessionYear]++;
            allMeetingsDurations[sessionYear] += this.getSessionDuration(session);
          } else {
            if (roomId in sessionCounts[sessionYear]) {
              sessionCounts[sessionYear][roomId]++;
            } else {
              sessionCounts[sessionYear][roomId] = 1;
            }
  
            if (roomId in sessionDurations[sessionYear]) {
              sessionDurations[sessionYear][roomId] += this.getSessionDuration(session);
            } else {
              sessionDurations[sessionYear][roomId] = this.getSessionDuration(session);
            }
         }
       }
      }

      let roomLabels = []
      let users = []
      let userSelected = []

      if(!this.filterUsers?.length) {
        this.users.forEach(res => 
          this.filterUsers.push(res.id))
      }

      if(this.selectedRoomFilter?.length || this.filterUsers?.length ) {
        let ids = []
        this.selectedRoomFilter?.forEach(res => {
          ids.push(res.id)
        })
        if(ids.length) {
          roomLabels = this.allRooms.filter(
            (room) => ids.includes(room.id)
          );
        }else{
          roomLabels = this.allRooms.filter(
            (room) => !room.room_data.meeting_room && !room.room_data.personal_room && !room.room_data.deleted
          );
          roomLabels.push(this.allMeetingsRoom);
        }

        if (sessions) {
          let roomSelected
          let filterRoomIds = []
          if(this.selectedRoomFilter?.length) {
            filterRoomIds = this.selectedRoomFilter.map(res => res.id)
            roomSelected = sessions.filter(session => filterRoomIds.includes(session.room_id))
          }else{
            roomSelected = sessions
          }


          if(this.filterUsers?.length > 0) {
            this.filterUsers.forEach(userId => {
              sessions.forEach(session => {
                if(session.users) {
                  if(Object.keys(session.users).includes(userId)) {
                    if(!userSelected.some(ses =>ses.id == session.id)){
                      userSelected.push(session)
                    }
                  }
                }
              })
            })
          }
        }
      }else{
        roomLabels = this.allRooms.filter(
          (room) => !room.room_data.meeting_room && !room.room_data.personal_room && !room.room_data.deleted
        );
        roomLabels.push(this.allMeetingsRoom);
      }

      if(this.filterUsers?.length) {
        this.allRooms.forEach(room => {
          this.filterUsers.forEach(ids => {
            if(room.room_data?.users){
              Object.keys(room.room_data.users).includes(ids) ? users.push(room) : null
              Object.keys(room.room_data.users).includes(ids)
            }
          })
        })
      }else{
        this.allRooms.forEach(room => {
          if(room.room_data?.users){
            users.push(room)
          }
        })
      }

      const yearLabels = this.getSelectedYears(startDate, endDate);

      for (const year of yearLabels) {
        if (!sessionCounts[year]) {
          sessionCounts[year] = {};
        }
        if (!sessionDurations[year]) {
          sessionDurations[year] = {};
        }

        const roomLabelIds = []
        for (const room of roomLabels) {
          if (!sessionCounts[year][room.id]) {
            sessionCounts[year][room.id] = 0;
          }
          if (!sessionDurations[year][room.id]) {
            sessionDurations[year][room.id] = 0;
          }
          roomLabelIds.push(room.id)
        }

        if(this.selectedRoomFilter?.length) {
          let filteredsessionCounts = {
            [year]: {}
          }
          let filteredSessionDuration = {
            [year]: {}
          }

          roomLabelIds.forEach(id => {
            if(sessionCounts[year][id]) {
              if(roomLabels.length && this.filterUsers?.length &&  !users.length) {
                filteredsessionCounts[year][id] = 0
                filteredSessionDuration[year][id] = 0
              }else{
              filteredsessionCounts[year][id] = sessionCounts[year][id]
              }
            }else{
              filteredsessionCounts[year][id] = 0
            }

            if(sessionDurations[year][id]) {
              filteredSessionDuration[year][id] = sessionDurations[year][id]
            }else{
              filteredSessionDuration[year][id] = 0
            }
          })
          sessionCounts[year] =filteredsessionCounts[year]
          sessionDurations[year] =filteredSessionDuration[year]
        }

        if(this.filterUsers?.length) {
          let filteredsessionCounts = {
            [year]: {}
          }
          let filteredSessionDuration = {
            [year]: {}
          }
          sessionCounts
          roomLabelIds.forEach(id => {
            if(sessionCounts[year][id]) {
              if(roomLabels.length && this.filterUsers?.length &&  !users.length) {
                filteredsessionCounts[year][id] = 0
                filteredSessionDuration[year][id] = 0

              }else{
                let sessionCountsArray = []
                let sessionDurationsFilteredData = {}

                if(userSelected?.length > 0) {
                  sessionCountsArray = userSelected.filter(s => s.room_id == id)
                }
                sessionCountsArray.forEach(sessionFiltered => {
                  if(!sessionDurationsFilteredData[year]) {
                    sessionDurationsFilteredData[year] = {}
                  }
                  if (sessionDurationsFilteredData[year][id]) {
                    sessionDurationsFilteredData[year][id] += this.getSessionDuration(sessionFiltered);
                  } else {
                    sessionDurationsFilteredData[year][id] = this.getSessionDuration(sessionFiltered);
                  }
                })
                filteredsessionCounts[year][id] = sessionCountsArray.length
                filteredSessionDuration[year][id] = sessionDurationsFilteredData?.[year] ? sessionDurationsFilteredData[year][id] : 0
              }
            }else{
              filteredsessionCounts[year][id] = 0
              filteredSessionDuration[year][id] = 0
            }
          })
          sessionCounts[year] = filteredsessionCounts[year]
          sessionDurations[year] = filteredSessionDuration[year]

        }
      }


      yearLabels.forEach(year => {
        if (!(year in sessionCounts)) {
          sessionCounts[year] = {};
        }
        if (!(year in sessionDurations)) {
          sessionDurations[year] = {};
        }
        sessionCounts[year]['allMeetings'] = allMeetingsCounts[year];
        sessionDurations[year]['allMeetings'] = allMeetingsDurations[year];
      });

      const sessionCountsByYear = yearLabels.map((year) =>
        Object.values(sessionCounts[year]).reduce((acc, count) => Number(acc) + Number(count), 0)
      );
      const sessionDurationsByYear = yearLabels.map((year) => {
        return Object.values(sessionDurations[year]).reduce((acc, duration) => Number(acc) + Number(duration), 0)
      }
    );

      const sessionCountsByRoom = roomLabels.map((room) => {
        const countsByYear = yearLabels.map((year) => sessionCounts[year]?.[room.id] || 0);
        return countsByYear.reduce((acc, count) => acc + (count as number), 0);
      });

      const sessionDurationsByRoom = roomLabels.map((room) => {
        const durationsByYear = yearLabels.map((year) => sessionDurations[year][room.id] || 0);
        return durationsByYear.reduce((acc, duration) => acc + Number(duration), 0);
      });

      if (this.sessionCountCanvas) {
        this.sessionCountChart.destroy()
        this.sessionCountChart = null
        this.renderer.removeChild(this.sessionCountContainer.nativeElement, this.sessionCountCanvas);
      }
      this.sessionCountCanvas = this.renderer.createElement('canvas') as HTMLCanvasElement
      this.renderer.appendChild(this.sessionCountContainer.nativeElement, this.sessionCountCanvas)

      this.sessionCountCanvas.height = 60 + (roomLabels.length * 8);
      const sessionCountCtx = this.sessionCountCanvas.getContext('2d');

      if (!this.sessionCountChart) {
        const labels = []
        roomLabels.forEach(res => {
          labels.push(res.room_data.name)
        })
        this.sessionCountChart = new Chart(sessionCountCtx, {
          type: 'horizontalBar',
          data: {
            labels: labels,
            datasets: [
              {
                label:  this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.SESSIONS'),
                data: sessionCountsByRoom,
                backgroundColor: roomLabels.map(room => room.id === 'allMeetings' ? '#FFA500' : '#003DA6'),   
              }
            ]
          },
          options: {
              title: {
                  display: true,
                  text:  this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.SESSIONS'),
                  position: 'top',
                  fontColor: '#000000'
              },
              legend: {
                position: 'bottom'
              },
              layout: {
                padding: {
                  left: 50,
                  right: 20,
                  top: 15,
                  bottom: 15
                }
              },
            scales: {
                yAxes: [{
                    gridLines: { 
                      display: false,
                    },
                    ticks: {
                      minor: {
                        padding: 10,
                        fontSize: 12,
                        fontColor: 'black'
                        
                      },
                    }
                  }],
                  xAxes: [{
                    ticks: {
                      minor: {
                        fontColor: 'black',
                      },
                      beginAtZero: true,
                      callback: function(value) {
                        if (Math.floor(value) === value) {
                            return value;
                        }
                    }
                    }
                  }],
                },
                    }
                    });
      } else {
        this.sessionCountChart.data.labels = roomLabels.map((room) => rooms[room.id].room_data.name);
        this.sessionCountChart.data.datasets[0].data = sessionCountsByRoom;
        this.sessionCountChart.update();
      }

      // const sessionDurationCanvas = document.getElementById('sessionDurationChart') as HTMLCanvasElement;
      if (this.sessionDurationCanvas) {
        this.sessionDurationChart.destroy()
        this.sessionDurationChart = null
        this.renderer.removeChild(this.sessionDurationContainer.nativeElement, this.sessionDurationCanvas)
      }
      this.sessionDurationCanvas = this.renderer.createElement('canvas') as HTMLCanvasElement
      this.renderer.appendChild(this.sessionDurationContainer.nativeElement, this.sessionDurationCanvas)

      const sessionDurationCtx = this.sessionDurationCanvas.getContext('2d');

      if (!this.sessionDurationChart) {
        this.sessionDurationChart = new Chart(sessionDurationCtx, {
          type: 'bar',
          data: {
            labels: yearLabels.map((year) => year.toString()),
            datasets: [
              {
                label:  this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.MINUTES'),
                data: sessionDurationsByYear,
                backgroundColor: '#66D8E5',
                yAxisID: 'y-axis-1', 
              },
              {
                label:  this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.SESSIONS'),
                data: sessionCountsByYear,
                backgroundColor: '#003DA6',
                yAxisID: 'y-axis-2',
              },
         ],
          },
          options: {
            title: {
              display: true,
              text:  this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.DURATIONS_SESSIONS'),
              position: 'top',
              fontColor: '#000000',
            },
            legend: {
              position: 'bottom',
            },
            layout: {
              padding: {
                left: 50,
                right: 20,
                top: 15,
                bottom: 15,
              },
            },
            scales: {
              yAxes: [
                {
                  gridLines: { 
                    display: false,
                  },
                  id: 'y-axis-1',
                  position: 'left',
                  ticks: {
                    fontColor: 'black',
                    
                    beginAtZero: true,
                    callback: function(value) {
                      if (Math.floor(value) === value) {
                          return value;
                      }
                  },
                  },
                  scaleLabel: {
                    display: true,
                    labelString:  this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.MINUTES'),
                    fontColor: 'black',
                  },
                },
                { gridLines: { 
                  display: false,
                },
                  id: 'y-axis-2',
                  position: 'right',
                  ticks: {
                    fontColor: 'black',
                    beginAtZero: true,
                    callback: function(value) {
                      if (Math.floor(value) === value) {
                          return value;
                      }
                  }
                  },
                  scaleLabel: {
                    display: true,
                    labelString:  this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.SESSIONS'),
                    fontColor: 'black',
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    minor: {
                      fontColor: 'black',
                    },
                  },
                },
              ],
            },
          },
        });
      } else {
        this.sessionDurationChart.data.labels = yearLabels;
        this.sessionDurationChart.data.datasets[0].data = sessionDurationsByYear;
        this.sessionDurationChart.data.datasets[1].data = sessionCountsByYear;
        this.sessionDurationChart.update();
      }

      // const sessionDurationByRoomCanvas = document.getElementById('sessionDurationByRoomChart') as HTMLCanvasElement;
      if (this.sessionDurationByRoomCanvas) {
        this.sessionDurationByRoomChart.destroy()
        this.sessionDurationByRoomChart = null
        this.renderer.removeChild(this.sessionDurationByRoomContainer.nativeElement, this.sessionDurationByRoomCanvas)
      }
      this.sessionDurationByRoomCanvas = this.renderer.createElement('canvas') as HTMLCanvasElement
      this.renderer.appendChild(this.sessionDurationByRoomContainer.nativeElement, this.sessionDurationByRoomCanvas)

      this.sessionDurationByRoomCanvas.height = 60 + (roomLabels.length * 8);
      const sessionDurationByRoomCtx = this.sessionDurationByRoomCanvas.getContext('2d');

      if (!this.sessionDurationByRoomChart) {
        const labels = []
        roomLabels.forEach(res => {
          labels.push(res.room_data.name)
        })
        this.sessionDurationByRoomChart = new Chart(sessionDurationByRoomCtx, {
          type: 'horizontalBar',
          data: {
            labels: labels,
            datasets: [
              {
                label:  this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.SESSIONDURATIONS_MIN'),
                data: sessionDurationsByRoom,
                backgroundColor: roomLabels.map(room => room.id === 'allMeetings' ? '#FFA500' : '#66D8E5'),   
              },
            ],
          },
          options: {
            title: {
              display: true,
              text:  this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.SESSION_DURATIONS'),
              position: 'top',
              fontColor: '#000000',
            },
            legend: {
              position: 'bottom',
            },
            layout: {
              padding: {
                left: 50,
                right: 20,
                top: 15,
                bottom: 15,
              },
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    minor: {
                      fontColor: 'black',
                      padding: 40
                    }
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    minor: {
                      fontColor: 'black',
                    },
                    beginAtZero: true,
                  //   callback: function(value) {
                  //     if (Math.floor(value) === value) {
                  //         return value;
                  //     }
                  // }
                  },
                },
              ],
            },
          },
        });
      } else {
        this.sessionDurationByRoomChart.data.labels = roomLabels.map((room) => rooms[room.id].room_data.name);
        this.sessionDurationByRoomChart.data.datasets[0].data = sessionDurationsByRoom;
        this.sessionDurationByRoomChart.update();
      }
    });
  }

  getSelectedYears(startDate: Date, endDate: Date): number[] {
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const years = [];

    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  }

  getSessionDuration(value: Session): number {
    return value.start_time && value.end_time ? Math.ceil((value.end_time - value.start_time) / 60000) : 0;
  }

  predefinedDateRanges = [
    { label: this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.TODAY'), start: 0, end: 0 },
    { label: this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.YESTERDAY'), start: 1, end: 1 },
    { label: this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.LAST7DAYS'), start: 7, end: 0 },
    { label: this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.LAST30DAYS'), start: 30, end: 0 },
    { label: this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.LAST90DAYS'), start: 90, end: 0 },
    { label: this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.THISYEAR'), start: 0, end: 0, year: true },
    { label: this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.LASTYEAR'), start: 365, end: 1, year: true },
    { label: this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.LAST2YEARS'), start: 730, end: 1, year: true }
  ];

  selectDateRange(range: {
    label: string; start: number; end: number; year?: boolean 
}): void {
    const today = new Date();
    this.startDate = new Date(today.getTime() - range.start * 24 * 60 * 60 * 1000).toISOString().substring(0, 10);
    if (range.end === 0) {
      this.endDate = today.toISOString().substring(0, 10);
    } else {
      this.endDate = new Date(today.getTime() - range.end * 24 * 60 * 60 * 1000).toISOString().substring(0, 10);
    }
    if (range.year) {
      const currentYear = today.getFullYear();
      if (range.start === 0) {
        this.startDate = `${currentYear}-01-01`;
        this.endDate = today.toISOString().substring(0, 10);
      } else if (range.start === 365) {
        this.startDate = `${currentYear - 1}-01-01`;
        this.endDate = `${currentYear - 1}-12-31`;
      } else if (range.start === 730) {
        this.startDate = `${currentYear - 1}-01-01`;
        this.endDate = today.toISOString().substring(0, 10);
      }
    }
    this.selectedRangeLabel = range.label;
    this.updateChart();
    this.isDatepickerOpen = !this.isDatepickerOpen;
  }
  
  selectCustomDateRange() {
    this.selectedRangeLabel =  this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.CUSTOM')
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.selectedRangeLabel =  this.translateService.instant('APP.MAIN.SIDEBAR.REPORTS.CUSTOM');
  }


  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

 toggleDatepicker() {
    this.isDatepickerOpen = !this.isDatepickerOpen;
  }

Cancel() {
  this.isDatepickerOpen = false;
}

Apply() {
  this.updateChartManually();
  this.isDatepickerOpen = false;
}
}