import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Archive } from '@models/Archive';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';

// import * as Azure from '@azure/storage-blob';
// import { saveAs } from "file-saver";

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {

  constructor(
    private authService: AuthService,
    private afdb: AngularFireDatabase,
    private http: HttpClient
  ) { }

  getSessionArchives(room_id: string, session_id: string): Observable<Archive[]> {
    return this.afdb.list<Archive>(`accounts/${this.authService.currentUser.account_id}/sessions/${room_id}/${session_id}/archives`)
    .snapshotChanges(["child_added", "child_changed"])
    .pipe(
      map(archiveSnapList => archiveSnapList.map(
        archiveSnap => {
          const archive = archiveSnap.payload.val();
          archive.id = archiveSnap.key;
          return archive;
        })
      )
    );
  }

  checkSessionArchives(roomId: string, sessionId: string) {
    return this.http.post<any>(environment.endPoints.changearchive, { token: this.authService.currentUser.token, action: "check", room: roomId, session: sessionId }).toPromise();
  }

  downloadArchive(roomId: string, sessionId: string, archive: Archive) {
    return this.http.post<any>(environment.endPoints.changearchive, { token: this.authService.currentUser.token, action: "get", room: roomId, session: sessionId, archive: archive.id }).toPromise()
    .then(response => response.url);
  }

  deleteArchive(roomId: string, sessionId: string, archive: Archive) {
    return this.http.post<any>(environment.endPoints.changearchive, { token: this.authService.currentUser.token, action: "delete", room: roomId, session: sessionId, archive: archive.id  }).toPromise();
  }
}
