import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, Output, EventEmitter, HostListener } from '@angular/core';

import * as p5 from 'p5';

import { ImageCollaborationService } from '@services/remote/image-collaboration.service';

import { Subscription, Observable, fromEvent } from 'rxjs';

import { Annotation } from '@models/Annotation';
import { DrawObject } from '@models/DrawObject';
import { DataSnapshot } from '@angular/fire/database/interfaces';
import { AuthService } from '@services/auth.service';
import { auditTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-image-annotation-canvas',
  templateUrl: './image-annotation-canvas.component.html',
  styleUrls: ['./image-annotation-canvas.component.scss']
})
export class ImageAnnotationCanvasComponent implements OnInit, OnDestroy {

  @Input('controlInput') controlInput: Observable<{ type: string, value: any }>;

  @Output() canvas = new EventEmitter<{ canvas: HTMLCanvasElement, container: HTMLDivElement }>();

  @ViewChild('container', { static: true }) container:ElementRef;
  @ViewChild('viewer', { static: true }) viewer:ElementRef;
  @ViewChild('textContainer', { static: true }) textContainer:ElementRef;
  @ViewChild('textField', { static: true }) textField:ElementRef;
  @ViewChild('shapeContainer', { static: true }) shapeContainer:ElementRef;
  @ViewChild('tick', { static: true }) tickIcon:ElementRef;
  @ViewChild('tempWidget', { static: true }) tempWidget:ElementRef;


  annotation: Annotation = null;
  annotationSub: Subscription = null;
  arraysSub: Subscription = null;

  controlSub: Subscription = null;
  initializeSub: Subscription = null;
  resizeSub: Subscription = null;

  private p5; 
 
  canvas_width: number;
  canvas_height: number;
  default_image_width: number;
  default_image_height: number;
  container_width: number;
  container_height: number;
  drawObject_id: string;

  isReleased: boolean;
  old_zoom_ratio: number;
  isTouch: boolean = false;

  deleted_drawObjects: {
    id?: any,
    color?: any,
    weight?: any,
    type?: any
  }[] = []; 

  existing_drawObjects: {
    id?: any,
    color?: any,
    weight?: any,
    type?: any
  }[] = []; 

  drawObjects: DrawObject[] = [];

  isClicked: boolean;

  isOnViewer : boolean;
  counter: number;
  default_left : number;
  default_top : number;
  image_left: number;
  image_top: number;
  image_width: number;
  image_height: number;
  old_xratio: number;
  old_yratio: number;

  firstX: number;
  firstY: number;
  preX: number;
  preY: number;

  font: any;
  font_size: string;
  showText: boolean = false;
  dragButtonClicked: boolean = false;
  dragButtonReleased: boolean = true;

  resizeButtonClicked: boolean = false;
  resizeButtonReleased: boolean = true;

  dragPreX: number;
  dragPreY: number;
  fontSizeNum: number;
  onRect: boolean = false;
  onArrow: boolean = false;
  onWidget: string = null;
  onEllipse: boolean = false;
  onPen: boolean = true;
  onMouse: boolean = false;
  onPointer: boolean = false;
  isFullScreen: boolean = false;
  widgets: {
    style: {
      "left": string,
      "top": string,
      "width": string,
      "height": string
    },
    class : any,
    x: number,
    y: number,
    length: number,
    src: string,
    id: string
   
  }[] = [];

  tempWidgetClass : {
    class: any,
    src: string
  };

  red: string = "rgb(255, 0, 10)";
  green: string = "rgb(0, 206, 136)";
  yellow: string = "rgb(255, 195, 0)";
  white: string = "rgb(255, 255, 255)";
  color: string;
  strokeWeight: number;
  stroke_weight_constant: number = 0.005;
  TYPE_LINE: string = "line";
  TYPE_TEXT: string = "text";
  TYPE_RECTANGLE: string = "rectangle";
  TYPE_ELLIPSE: string = "ellipse";
  TYPE_ARROW: string = "arrow";
  TYPE_LEFT_ARROW: string = "leftArrow";
  TYPE_RIGHT_ARROW: string = "rightArrow";
  TYPE_WARNING: string = "warning";
  color_normal: string = "yellow";
  weight_normal: string = "middle";
  size: string = "";

  textContainerLeft: number;
  textContainerTop: number;
  textContainer_defaultWidth: number = 80;
  textContainer_defaultHeight: number = 40;

  shapeContainer_width: number;
  shapeContainer_height: number;
  shapeContainer_left: number;
  shapeContainer_top: number;
  shapeContainer_rotation: number = 0;
  arrowAngle: number = 45*Math.PI/180;
  arrowHeadLength: number = 20;
  mouseHold: boolean = false;
  temp_left: number;
  temp_top: number;
  pre_x: number;
  pre_y: number;
  first_x: number;
  first_y: number;

  pointerCollaborationActive: boolean = false;
  pointerCollMouseSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private imageCollaborationService: ImageCollaborationService
  ) {
    this.isReleased = true;
    this.isClicked = false;
    this.isOnViewer = false;
    this.old_zoom_ratio = 1.0;
    this.counter = 0;
    this.color = this.yellow;
    this.font_size ="50px";
    this.fontSizeNum = 50;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
         
    // redo ctrl+shift+z
    if (event.keyCode === 90 && event.ctrlKey && event.shiftKey) {
      this.imageCollaborationService.redo();
    }

    // undo ctrl+z
    else if (event.keyCode === 90 && event.ctrlKey) {
      this.imageCollaborationService.undo();
    }

    // REMOTE_CHANGED
    // undo backspace
    //if(event.keyCode === 8 && !this.showText){
    //  this.imageCollaborationService.undo();
    //}
  }

  ngOnInit() {

    this.drawObject_id = this.imageCollaborationService.createID();

    this.initializeSub = this.imageCollaborationService.initializeImage.subscribe(size => {
      if (size) {
        this.initialize(size);
      }
    });

    this.resizeSub = this.imageCollaborationService.resizeImage.subscribe(size => {
      if (size) {
        this.resize(size);
      }
    });
 
    this.controlSub = this.controlInput.subscribe(control => {
      let method = control.type;
      let val = control.value;
      if(method=="zoomIn"){
        this.dragPreX = null;
        this.dragPreY = null;
        this.zoomIn(val);
      }
      else if(method=="zoomOut"){
        this.zoomOut(val);
      }
      else if(method=="scrollX"){
        this.scrollX(val);
      }
      else if(method=="scrollY"){
        this.scrollY(val);
      }
      else if(method=="undo"){
        this.toggleUndo();
      }
      else if(method=="redo"){
        this.toggleRedo();
      }
      else if(method=="refresh"){
        this.refresh();
      }
      else if(method == "changeSmall"){
        this.size = val;
        if(this.size=="small"){
          this.refreshShapeRelatedValues();
          this.clearAndReDrawCanvas(true);
          this.showText = false;
        }
      }
      else if(method=="changeColor"){
        this.changeColor(val);
      }
      else if(method=="changeWeight"){
        this.changeWeight(val);
      }
      else if(method=="changeTextClick"){
        this.onPen = false;
        this.onMouse = false;
        this.changePointerClick(false);
        this.clearAndReDrawCanvas(true);
        this.refreshShapeRelatedValues();
        this.changeTextClick(val);
      }
      else if(method == "changePenClick"){
        this.showText = false;
        this.onMouse = false;
        this.changePointerClick(false);
        this.clearAndReDrawCanvas(true);
        this.refreshShapeRelatedValues();
        this.changePenClick(val);
      }
      else if(method == "changeMouseClick"){
        this.showText = false;
        this.onPen = false;
        this.changePointerClick(false);
        this.clearAndReDrawCanvas(true);
        this.refreshShapeRelatedValues();
        this.changeMouseClick(val);
      }
      else if(method == "changePointerClick"){
        this.showText = false;
        this.onPen = false;
        this.onMouse = false;
        this.clearAndReDrawCanvas(true);
        this.refreshShapeRelatedValues();
        this.changePointerClick(val);
      }
      else if(method=="changeShape"){
        this.onPen = false;
        this.onMouse = false;
        this.changePointerClick(false);
        this.clearAndReDrawCanvas(true);
        this.refreshShapeRelatedValues();
        this.showText = false;

        if(val == "rectangle")
          this.changeRect();
        else if(val == "ellipse")
          this.changeEllipse();
        else if(val == "arrow")
          this.changeArrow();
        else if(val != "none")
          this.changeWidget(val);
        
      }
      else if(method=="remove"){
        this.onPen = true; 
        this.onMouse = false;
        this.changePointerClick(false);
        this.refresh();
        this.remove();
      }
      else if(method=="toggleFullScreen")
        this.isFullScreen = val;
    });

    this.imageCollaborationService.getDrawObjects().on("child_added", this.onDrawObjects);

    this.arraysSub = this.imageCollaborationService.getArrays().subscribe(arrays => {
      if(arrays){
        let isWidgetsChanged = false;
        this.existing_drawObjects = arrays.existing_drawObjects;
        this.deleted_drawObjects = arrays.deleted_drawObjects;
        if(!this.existing_drawObjects)
          this.existing_drawObjects = [];
        if(!this.deleted_drawObjects)
          this.deleted_drawObjects = [];

        let counter = 0;
        for(let d of this.existing_drawObjects){
          let found = false;
          if(d.type!="line" && d.type!="rectangle" && d.type!="ellipse" && d.type!="arrow" && d.type!="text"){
            for(let w of this.widgets){
              if(d.id == w.id){
                found = true;
                counter++;
                break;
              }  
            }
            if(!found){
              isWidgetsChanged = true;
              break;
            }
          }
        }
        if(counter!=this.widgets.length)
          isWidgetsChanged = true;

        if(isWidgetsChanged)
          this.widgets = [];
            
        if(this.p5){
          this.p5.clear();
          for(let existing of this.existing_drawObjects){
            this.updateCanvasPaint(existing.color, existing.weight, existing.type);
            for(let drawObject of this.drawObjects){
              if(drawObject.id == existing.id){
                if(existing.type==this.TYPE_LINE)
                  this.drawLine(drawObject.x2*this.canvas_width,drawObject.y2*this.canvas_height,drawObject.x1*this.canvas_width,drawObject.y1*this.canvas_height);
                else if(existing.type==this.TYPE_TEXT)
                  this.drawText(drawObject.x1,drawObject.y1,drawObject.text);
                else if(existing.type==this.TYPE_RECTANGLE)
                  this.drawRectangle(drawObject.x1,drawObject.y1,drawObject.width, drawObject.height);
                else if(drawObject.type==this.TYPE_ELLIPSE)
                  this.drawEllipse(drawObject.x1,drawObject.y1,drawObject.width, drawObject.height);
                else if(existing.type==this.TYPE_ARROW)
                  this.drawArrow(drawObject.x1,drawObject.x2,drawObject.x3, drawObject.x4,drawObject.y1,drawObject.y2,drawObject.y3, drawObject.y4);
                else if(isWidgetsChanged)
                  this.drawWidget(drawObject.x1, drawObject.y1, drawObject.type, drawObject.width, drawObject.id);
              }
            }
          }
        }
      }
    });

    this.shapeContainer.nativeElement.style['transform-origin']="left center";
  }

  onContainerMouseDown(e){
    if (this.onPointer) {
      e.stopPropagation();
      this.pointerCollaborationActive = true;
      this.sendPointer(e);
    }
  }

  onContainerMouseUp(e){
    if (this.onPointer) {
      e.stopPropagation();
      this.pointerCollaborationActive = false;
      this.imageCollaborationService.setDot("");
    }
  }

  onContainerMouseLeave(e){
    if (this.onPointer) {
      e.stopPropagation();
      this.pointerCollaborationActive = false;
      this.imageCollaborationService.setDot("");
    }
  }

  sendPointer(e) {

    let c;
    if(this.color_normal=="yellow")
      c = "ff9800";
    else if(this.color_normal=="green")
      c = "28a745";
    else if(this.color_normal=="red")
      c = "dc3545";
    else if(this.color_normal=="white")
      c = "ffffff";

  
    this.imageCollaborationService.setDot(c+'!'+
      ((e.layerX+this.image_left) / this.container_width * 100).toFixed(2)+'!'+
      ((e.layerY+this.image_top) / this.container_height * 100).toFixed(2)+'!'+
      this.authService.currentUser.name+'!disc');
  }

  onDrawObjects = (_drawObject: DataSnapshot) => {
    let drawObject = _drawObject.val();
    this.drawObjects.push(drawObject);
    this.updateCanvasPaint(drawObject.color,drawObject.weight,drawObject.type);
    if(this.p5){
      if(drawObject.type == this.TYPE_LINE)
        this.drawLine(drawObject.x2*this.canvas_width, drawObject.y2*this.canvas_height, drawObject.x1*this.canvas_width, drawObject.y1*this.canvas_height);
      else if(drawObject.type == this.TYPE_TEXT)
        this.drawText(drawObject.x1, drawObject.y1, drawObject.text);
      else if(drawObject.type==this.TYPE_RECTANGLE)
        this.drawRectangle(drawObject.x1,drawObject.y1,drawObject.width, drawObject.height);
      else if(drawObject.type==this.TYPE_ELLIPSE)
        this.drawEllipse(drawObject.x1,drawObject.y1,drawObject.width, drawObject.height);
      else if(drawObject.type==this.TYPE_ARROW)
        this.drawArrow(drawObject.x1,drawObject.x2,drawObject.x3, drawObject.x4,drawObject.y1,drawObject.y2,drawObject.y3, drawObject.y4);
    }
  }

  updateCanvasPaint(color: string, weight: number, type: string){
    if(this.p5){
      this.p5.fill(0, 0, 0, 0); 
      if(type==this.TYPE_LINE || type == this.TYPE_ELLIPSE || type == this.TYPE_ARROW || type == this.TYPE_RECTANGLE ){
        if(color === this.red)
          this.p5.stroke(255, 0, 10); 
        else if(color === this.green)
          this.p5.stroke(0, 206, 136);
        else if(color === this.white)
        this.p5.stroke(255, 255, 255);
        else if(color === this.yellow)
          this.p5.stroke(255, 195, 0);

        if(weight)
          this.p5.strokeWeight(weight*this.canvas_width)

      }
      else if(type == this.TYPE_TEXT){
        this.p5.strokeWeight(0);
        if(color === this.red)
          this.p5.fill(255, 0, 10); 
        else if(color === this.green)
          this.p5.fill(0, 206, 136);
        else if(color === this.white)
          this.p5.fill(255, 255, 255);
        else if(color === this.yellow)
          this.p5.fill(255, 195, 0);
        if(weight)
        this.p5.textSize(weight*this.canvas_height);

      }  
    }  
  }

  drawWidget(x: number, y: number, widget_type: string, length: number, id: string){

    const widget: any = { 
      style : {
        "left": x*this.image_width-length*this.image_width/2+"px",
        "top": y*this.image_height-length*this.image_width/2+"px",
        "width": length*this.image_width+"px",
        "height": length*this.image_width+"px"
      },
      class:{},
      x: (x*this.image_width-length*this.image_width/2)/this.image_width,
      y: (y*this.image_height-length*this.image_width/2)/this.image_height,
      length: length,
      id: id
    };

    if(widget_type == this.TYPE_RIGHT_ARROW){
      widget.class["spinRight"] = true;
    }
    else if(widget_type == this.TYPE_LEFT_ARROW){
      widget.class["spinLeft"] = true;
    }
    else if(widget_type == this.TYPE_WARNING){
      widget.class["scaleAnim"] = true;
    }

    widget.src="assets/widgets/"+widget_type+".png";
    

    this.widgets.push(widget);
  }

  drawLine(x: number, y: number, px: number, py: number){
    this.p5.line(x, y, px, py);
  }

  drawText(x: number, y: number, text: string){
    
    this.p5.text(text,x*this.canvas_width,y*this.canvas_height);
    this.p5.fill(0,0,0,0);
  }

  drawRectangle(x: number, y: number, width: number, height: number){
    
    this.p5.rect(x*this.canvas_width,y*this.canvas_height, width*this.canvas_width, height*this.canvas_height);
  }

  drawEllipse(x: number, y: number, width: number, height: number){
    this.p5.ellipse(x*this.canvas_width,y*this.canvas_height, width*this.canvas_width, height*this.canvas_height);
  }

  drawArrow(x1: number, x2: number, x3: number, x4: number, y1: number, y2: number, y3: number, y4: number){
    this.p5.line(x2*this.canvas_width,y2*this.canvas_height,x1*this.canvas_width,y1*this.canvas_height);
    this.p5.line(x3*this.canvas_width,y3*this.canvas_height,x2*this.canvas_width,y2*this.canvas_height);
    this.p5.line(x4*this.canvas_width,y4*this.canvas_height,x2*this.canvas_width,y2*this.canvas_height);
  }

  resize(size){

    let left = size.left;
    let top = size.top;

    this.container_height = this.container.nativeElement.clientHeight;
    this.container_width = this.container_height*4/3;

    this.container.nativeElement.style.width=this.container_width+"px";
    this.container.nativeElement.style.margin="0 auto";

    this.default_image_height = size.height/size.zoom_ratio;
    this.default_image_width = size.width/size.zoom_ratio;

    this.image_width = size.width;
    this.image_height = size.height;

    this.viewer.nativeElement.style.width = ""+size.width+"px";
    this.viewer.nativeElement.style.height = ""+size.height+"px";

    this.viewer.nativeElement.style.left = ""+left+"px";
    this.viewer.nativeElement.style.top = ""+top+"px";
    this.image_left = left;
    this.image_top = top;

    if(this.p5 && this.p5._curElement){
      this.p5._curElement.canvas.style.width = size.width+"px";
      this.p5._curElement.canvas.style.height = size.height+"px";
      this.p5.fill(0,0,0,0);

    }

    // shape container resize
    this.dragPreX = null;
    this.dragPreY = null;
    this.shapeContainer_rotation = 0;
    this.font_size = this.fontSizeNum*this.image_height/this.canvas_height/this.old_zoom_ratio+"px";

    if(this.p5){
      if(this.onRect){
        this.clearAndReDrawCanvas(true);
        setTimeout(() => {
          this.createShapeDiv();
          this.drawTemporaryRectangle();
        },100);
      }
      if(this.onEllipse){
        this.clearAndReDrawCanvas(true);
        setTimeout(() => {
          this.createShapeDiv();
          this.drawTemporaryEllipse();
        },100);
      }
      if(this.onArrow){
        this.clearAndReDrawCanvas(true);
        setTimeout(() => {
          this.createShapeDiv();
          this.drawTemporaryArrow();
        },100);
      }
      if(this.onWidget != null ){
        this.clearAndReDrawCanvas(true);
        setTimeout(() => {
          this.createShapeDiv();
          this.drawTemporaryWidget(this.onWidget);
        },100);
      }
    }

    this.updateWidgets();
    
  }

  changeColor(color: string){

    this.justChangeColor(color);
    this.updateAfterPhysicalChange();
  }
    
  changeWeight(weight: string){

    this.justChangeWeight(weight);
    this.updateAfterPhysicalChange();
  }


  justChangeColor(color: string){

    this.color_normal = color;
    if(color === "red"){
      this.color = this.red;
      this.p5.stroke(255, 0, 10);
    }
    else if(color === "green"){
      this.color = this.green;
      this.p5.stroke(0, 206, 136);
    }
    else if(color === "white"){
      this.color = this.white;
      this.p5.stroke(255, 255, 255);
    }
    else if(color === "yellow"){
      this.color = this.yellow;
      this.p5.stroke(255, 195, 0);
    }
  }

  justChangeWeight(weight: string){
    
    this.weight_normal = weight;
    if(weight === "thin"){
      this.stroke_weight_constant = 0.0025;
      this.fontSizeNum = 35;
    }
    else if(weight === "middle"){
     this.stroke_weight_constant = 0.005;
     this.fontSizeNum = 50;
    }
    else if(weight === "thick"){
     this.stroke_weight_constant = 0.01;
     this.fontSizeNum = 75;
    }
    else if(weight === "verythick"){
     this.stroke_weight_constant = 0.02;
     this.fontSizeNum = 100;
    }

    this.font_size = this.fontSizeNum*this.image_height/this.canvas_height/this.old_zoom_ratio+"px";
    this.p5.textSize(this.fontSizeNum);
    this.strokeWeight =  this.stroke_weight_constant*this.canvas_width;
    this.p5.strokeWeight(this.strokeWeight/this.old_zoom_ratio);
  }

  updateAfterPhysicalChange(){

    if(this.p5){
      if(this.onRect){
        this.clearAndReDrawCanvas(true);
        setTimeout(() => {
          this.drawTemporaryRectangle();
        },10);
      }
      if(this.onEllipse){
        this.clearAndReDrawCanvas(true);
        setTimeout(() => {
          this.drawTemporaryEllipse();
        },10);
      }
      if(this.onArrow){
        this.clearAndReDrawCanvas(true);
        setTimeout(() => {
          this.drawTemporaryArrow();
        },10);
      }
    }

  }

  changePenClick(onPen: boolean){
    this.onPen = onPen;
  }
  changeMouseClick(onMouse: boolean){
    this.onMouse = onMouse;
  }
  changePointerClick(onPointer: boolean){
    // If its status changed to open
    if (!this.onPointer && onPointer) {
      this.pointerCollMouseSub = fromEvent(this.container.nativeElement, "mousemove").pipe(
        auditTime(50),
        filter(event => this.pointerCollaborationActive)
      )
      .subscribe((e: any) => {
        e.stopPropagation();
        this.sendPointer(e);
      });
    // If its status changed to closed
    } else if (this.onPointer && !onPointer) {
      if (this.pointerCollMouseSub) { this.pointerCollMouseSub.unsubscribe() }
    }

    this.onPointer = onPointer;
  }

  changeTextClick(showText: boolean){
    this.showText = showText;

    if(showText){
      setTimeout(() =>{
        this.textField.nativeElement.focus();
      },100);
      
      let width = this.textContainer_defaultWidth;

      if(!this.textContainerLeft || !this.textContainerTop){
        let left = ( this.container_width - width ) / 2;
        let height = this.textContainer_defaultHeight;
        let top = (this.container_height - height) / 2
        this.textContainer.nativeElement.style.width = width + "px";
        this.textContainer.nativeElement.style.height = height + "px";
        this.textContainer.nativeElement.style.left = left + "px";
        this.textContainer.nativeElement.style.top = top + "px";
        this.textContainerLeft = left;
        this.textContainerTop = top;
        this.textField.nativeElement.style.width = "100%";
      } 

    }
    else{
      this.refreshShapeRelatedValues();
    }
  }

  changeRect(){

    this.onRect = true;
    this.createShapeDiv();
    this.drawTemporaryRectangle();
  }

  changeArrow(){

    this.onArrow = true;
    this.createShapeDiv();
    this.drawTemporaryArrow();
  }

  changeWidget(val: string){

    this.onWidget = val;
    this.createShapeDiv();
    this.drawTemporaryWidget(val);
    
  }

  changeEllipse(){
    this.onEllipse = true;
    this.createShapeDiv();
    this.drawTemporaryEllipse();
  }

  // this method draw a widget on the viewer div. But it is temporary. 
  // It means that it is not written to database and other users cannot see it on their canvas.
  // It is used to drag and resize.
  drawTemporaryWidget(val: string){

    let length;
    if(this.shapeContainer_width <= this.shapeContainer_height)
      length = this.shapeContainer_width;
    else
      length = this.shapeContainer_height;
    
    this.tempWidget.nativeElement.style.width = length+"px";
    this.tempWidget.nativeElement.style.height = length+"px";
    this.tempWidgetClass = {
      class: {},
      src: "assets/widgets/"+val+".png"
    };

    // LEFT ROTATING ARROW
    if(val == "leftArrow"){
      this.tempWidgetClass.class={"spinLeft":true}
    }

    // RIGHT ROTATING ARROW
    else if(val == "rightArrow"){
      this.tempWidgetClass.class={"spinRight":true}

    }

    // WARNING
    else if(val == "warning"){
      this.tempWidgetClass.class={"scaleAnim":true}
    }
  }

  // this method draw an arrow on the canvas. But it is temporary. 
  // It means that it is not written to database and other users cannot see it on their canvas.
  // It is used to drag and resize.
  drawTemporaryArrow(){

    let image_left = parseFloat(this.viewer.nativeElement.style.left);
    let image_top = parseFloat(this.viewer.nativeElement.style.top);
    const x1 = (this.shapeContainer_left-image_left)/this.image_width;
    const y1 = (this.shapeContainer_top-image_top+20)/this.image_height;
    const x2 = (x1*this.image_width + Math.cos(this.shapeContainer_rotation)*(this.shapeContainer_width))/this.image_width;
    const y2 = (y1*this.image_height + Math.sin(this.shapeContainer_rotation)*(this.shapeContainer_width))/this.image_height;
    let points = this.calculateArrowEndPoints(x1*this.image_width, x2*this.image_width, y1*this.image_height, y2*this.image_height);
    let leftX = points.leftX/this.image_width;
    let leftY = points.leftY/this.image_height;
    let rightX = points.rightX/this.image_width;
    let rightY = points.rightY/this.image_height;
    this.justChangeColor(this.color_normal);
    this.justChangeWeight(this.weight_normal);
    this.tempWidgetClass = {
      class: {"d-none":true},
      src: ""
    };
    this.p5.line(x2*this.canvas_width, y2*this.canvas_height, x1*this.canvas_width, y1*this.canvas_height); // arrow body
    this.p5.line(leftX*this.canvas_width, leftY*this.canvas_height, x2*this.canvas_width, y2*this.canvas_height); // arrow head left
    this.p5.line(rightX*this.canvas_width, rightY*this.canvas_height, x2*this.canvas_width, y2*this.canvas_height); // arrow head right
  }

  // this function calculates the coordinates of the two lines on the array head.
  calculateArrowEndPoints(x1: number, x2: number, y1: number, y2: number){

    let angle = Math.atan((y2-y1)/(x2-x1));
    if(x2<x1 && y2<y1)
      angle = angle - Math.PI;
    else if(x2<x1 && y2>y1)
      angle = angle + Math.PI;

    let dx = this.arrowHeadLength*Math.cos(this.arrowAngle + angle);
    let dy = this.arrowHeadLength*Math.sin(this.arrowAngle + angle);

    let leftX = x2 - dx;
    let leftY = y2 - dy;
    let rightX = x2 - dy;
    let rightY = y2 + dx;

    return {leftX: leftX, leftY: leftY, rightX: rightX, rightY: rightY};

  }

  // this method draw a rectangle on the canvas. But it is temporary. 
  // It means that it is not written to database and other users cannot see it on their canvas.
  // It is used to drag and resize.
  drawTemporaryRectangle(){

    let image_left = parseFloat(this.viewer.nativeElement.style.left);
    let image_top = parseFloat(this.viewer.nativeElement.style.top);
    const x1 = (this.shapeContainer_left-image_left)/this.image_width;
    const y1 = (this.shapeContainer_top-image_top)/this.image_height;
    this.justChangeColor(this.color_normal);
    this.justChangeWeight(this.weight_normal);
    this.tempWidgetClass = {
      class: {"d-none":true},
      src: ""
    };
    this.p5.rect(x1*this.canvas_width,y1*this.canvas_height,this.shapeContainer_width*this.canvas_width/this.image_width,this.shapeContainer_height*this.canvas_height/this.image_height);
  }

  // this method draw a ellipse on the canvas. But it is temporary. 
  // It means that it is not written to database and other users cannot see it on their canvas.
  // It is used to drag and resize.
  drawTemporaryEllipse(){

    let image_left = parseFloat(this.viewer.nativeElement.style.left);
    let image_top = parseFloat(this.viewer.nativeElement.style.top);
    const x1 = (this.shapeContainer_left-image_left)/this.image_width;
    const y1 = (this.shapeContainer_top-image_top)/this.image_height;
    const widthForCanvas = this.shapeContainer_width*this.canvas_width/this.image_width;
    const heightForCanvas = this.shapeContainer_height*this.canvas_height/this.image_height;
    this.justChangeColor(this.color_normal);
    this.justChangeWeight(this.weight_normal);
    this.p5.ellipse(x1*this.canvas_width+widthForCanvas/2, y1*this.canvas_height+heightForCanvas/2, widthForCanvas, heightForCanvas);
    this.tempWidgetClass = {
      class: {"d-none":true},
      src: ""
    };
  }

  // this method creates the div of the shape and put it into the center of the container frame
  createShapeDiv(){

    this.shapeContainer.nativeElement.style["pointer-events"] = "all";
    let temp = 10;
    if(this.onEllipse || this.onRect || this.onArrow)
      temp = 6;
    this.shapeContainer_width = this.container_width/temp;
    this.shapeContainer_height = this.shapeContainer_width;
    if(this.onArrow)
      this.shapeContainer_height = 40;

    this.shapeContainer_left = ( this.container_width - this.shapeContainer_width ) / 2;
    this.shapeContainer_top = (this.container_height - this.shapeContainer_height) / 2
    this.shapeContainer.nativeElement.style.width = this.shapeContainer_width + "px";
    this.shapeContainer.nativeElement.style.height = this.shapeContainer_height + "px";
    this.shapeContainer.nativeElement.style.left =  this.shapeContainer_left + "px";
    this.shapeContainer.nativeElement.style.top =  this.shapeContainer_top + "px";
    this.p5.fill(0,0,0,0);
  }

  resizeShapeDiv(x_coordinate: number, y_coordinate: number){

    this.viewer.nativeElement.style.cursor = "nw-resize";
    let image_left = parseFloat(this.viewer.nativeElement.style.left);
    let image_top = parseFloat(this.viewer.nativeElement.style.top);  
    let newX = x_coordinate+image_left;
    let newY = y_coordinate+image_top;
   
    this.shapeContainer_width = newX - this.shapeContainer_left;
    this.shapeContainer_height = newY - this.shapeContainer_top;
    if(this.onArrow){
      this.shapeContainer_height = 40;
      this.shapeContainer_width = Math.sqrt(Math.pow(newX - this.shapeContainer_left,2)+Math.pow(newY-this.shapeContainer_top-20,2));
      let angle = Math.atan((newY-this.shapeContainer_top-20)/(newX-this.shapeContainer_left));
      
      if(newX<this.shapeContainer_left && newY<this.shapeContainer_top+20)
        angle = angle - Math.PI;
      else if(newX<this.shapeContainer_left && newY>this.shapeContainer_top+20)
        angle = angle + Math.PI;

      this.shapeContainer_rotation = angle;
      this.tickIcon.nativeElement.style.transform = "rotate("+(-this.shapeContainer_rotation)+"rad)";
      this.shapeContainer.nativeElement.style.transform = "rotate("+this.shapeContainer_rotation+"rad)";
    }

    if(this.shapeContainer_width<20)
      this.shapeContainer_width = 20;
    if(this.shapeContainer_height<30)
      this.shapeContainer_height = 30;

    this.shapeContainer.nativeElement.style.width = this.shapeContainer_width+"px";
    this.shapeContainer.nativeElement.style.height = this.shapeContainer_height+"px";
    if(this.onWidget==null)
      this.clearAndReDrawCanvas(true);
  }

  dragShapeDiv(x_coordinate: number, y_coordinate: number){

    this.viewer.nativeElement.style.cursor = "grabbing";
    let newX = x_coordinate;
    let newY = y_coordinate;
    let dx = newX - this.dragPreX;
    let dy = newY - this.dragPreY;
     
    this.shapeContainer_left += dx;
    this.shapeContainer_top += dy;

    if(!this.onArrow){
      if(this.shapeContainer_left<0)
        this.shapeContainer_left=0;
      else if(this.shapeContainer_left + this.shapeContainer_width>this.container_width)
        this.shapeContainer_left = this.container_width - this.shapeContainer_width;

      if(this.shapeContainer_top<0)
        this.shapeContainer_top=0;
      else if(this.shapeContainer_top + this.shapeContainer_height>this.container_height)
        this.shapeContainer_top = this.container_height - this.shapeContainer_height;
    }
    else{
      /*
      let image_left = parseFloat(this.viewer.nativeElement.style.left);
      let image_top = parseFloat(this.viewer.nativeElement.style.top);
      const x1 = (this.shapeContainer_left-image_left);
      const y1 = (this.shapeContainer_top-image_top+20);
      const x2 = (x1 + Math.cos(this.shapeContainer_rotation)*(this.shapeContainer_width));
      const y2 = (y1 + Math.sin(this.shapeContainer_rotation)*(this.shapeContainer_width));
      let angle = Math.atan((y2-y1)/(x2-x1));
      if(x2<x1 && y2<y1)
        angle = angle - Math.PI;
      else if(x2<x1 && y2>y1)
        angle = angle + Math.PI;

      if(this.shapeContainer_left<0)
        this.shapeContainer_left=0;
      if(this.shapeContainer_left>this.container_width)
        this.shapeContainer_left = this.container_width;
      else if(this.shapeContainer_left + Math.cos(angle)*(this.shapeContainer_width+30)>this.container_width)
        this.shapeContainer_left = this.container_width - (Math.cos(angle)*(this.shapeContainer_width+30));
      else if(this.shapeContainer_left + Math.cos(angle)*(this.shapeContainer_width+30)<0)
        this.shapeContainer_left = -Math.cos(angle)*(this.shapeContainer_width+30);

      if(this.shapeContainer_top<0)
        this.shapeContainer_top=0;
      else if(this.shapeContainer_top>this.container_height)
        this.shapeContainer_top = this.container_height
      else if(this.shapeContainer_top + Math.sin(angle)*(this.shapeContainer_width+30)>this.container_height)
        this.shapeContainer_top = this.container_height - (Math.sin(angle)*(this.shapeContainer_width+30));
      else if(this.shapeContainer_top + Math.sin(angle)*(this.shapeContainer_width+30)<0)
        this.shapeContainer_top = -Math.sin(angle)*(this.shapeContainer_width+30);
        */
    }

    this.shapeContainer.nativeElement.style.left = this.shapeContainer_left+"px";
    this.shapeContainer.nativeElement.style.top = this.shapeContainer_top+"px";
    this.dragPreX = newX;
    this.dragPreY = newY;

    if(this.onWidget==null)
      this.clearAndReDrawCanvas(true);
  }

  dragTextDiv(x_coordinate: number, y_coordinate: number){

    this.viewer.nativeElement.style.cursor = "grabbing";
    let newX = x_coordinate;
    let newY = y_coordinate;

    let dx = newX - this.dragPreX;
    let dy = newY - this.dragPreY;
   
    this.textContainerLeft += dx;
    this.textContainerTop += dy;
    if(this.textContainerLeft+parseFloat(this.textContainer.nativeElement.style.width)<this.container_width && this.textContainerLeft>=0 && this.textContainerTop>=0 && this.textContainerTop+40<=this.container_height){
      this.textContainer.nativeElement.style.left = this.textContainerLeft+"px";
      this.textContainer.nativeElement.style.top = this.textContainerTop+"px";
    }
    else{
      this.textContainerLeft -= dx;
      this.textContainerTop -= dy;
    }
    this.dragPreX = newX;
    this.dragPreY = newY;
  }

  onViewerMouseMove(e: any){
    if(!this.isTouch)
      this.move(e.layerX, e.layerY);
  }

  // when the user has done with the shape and click the tick, this method is called.
  // this method calculates the final coordinates and others like width, height and then update the database
  shapeDone(){
    this.justChangeColor(this.color_normal);
    this.justChangeWeight(this.weight_normal);
    let image_left = parseFloat(this.viewer.nativeElement.style.left);
    let image_top = parseFloat(this.viewer.nativeElement.style.top);

    let ratio;
    if(this.image_height >= this.image_width)
      ratio = this.canvas_height / this.container_height;
    else  
      ratio = this.canvas_width / this.container_width;
      
    const widthRatio = this.shapeContainer_width*ratio/this.canvas_width/this.old_zoom_ratio;
    const heightRatio = this.shapeContainer_height*ratio/this.canvas_height/this.old_zoom_ratio;
   
    let drawObject;
    let new_existingObject;

    if(this.onRect){

      const x1 = (this.shapeContainer_left-image_left)/this.image_width;
      const y1 = (this.shapeContainer_top-image_top)/this.image_height;
  
      drawObject = {color: this.color,  weight:this.stroke_weight_constant/this.old_zoom_ratio, id: this.drawObject_id, type: "rectangle", x1: x1 , y1: y1, width: widthRatio, height: heightRatio};
      new_existingObject = {id:this.drawObject_id, color:this.color, weight:this.stroke_weight_constant/this.old_zoom_ratio, type:"rectangle" };
       
    }
    else if(this.onEllipse){
      const x1 = (this.shapeContainer_left+this.shapeContainer_width/2-image_left)/this.image_width;
      const y1 = ((this.shapeContainer_top-image_top+this.shapeContainer_height/2))/this.image_height;
  
      drawObject = {color: this.color,  weight:this.stroke_weight_constant/this.old_zoom_ratio, id: this.drawObject_id, type: "ellipse", x1: x1 , y1: y1, width: widthRatio, height: heightRatio};
      new_existingObject = {id:this.drawObject_id, color:this.color, weight:this.stroke_weight_constant/this.old_zoom_ratio, type:"ellipse" };
    }

    else if(this.onArrow){

      const x1 = (this.shapeContainer_left-image_left)/this.image_width;
      const y1 = (this.shapeContainer_top-image_top+20)/this.image_height;
      const x2 = (x1*this.image_width + Math.cos(this.shapeContainer_rotation)*(this.shapeContainer_width))/this.image_width;
      const y2 = (y1*this.image_height + Math.sin(this.shapeContainer_rotation)*(this.shapeContainer_width))/this.image_height;
      let points = this.calculateArrowEndPoints(x1*this.image_width, x2*this.image_width, y1*this.image_height, y2*this.image_height);
      const x3 = points.leftX/this.image_width;
      const y3 =  points.leftY/this.image_height;
      const x4 = points.rightX/this.image_width;
      const y4 = points.rightY/this.image_height;
  
      drawObject = {color: this.color,  weight:this.stroke_weight_constant/this.old_zoom_ratio, id: this.drawObject_id, type: "arrow", x1: x1 , y1: y1, x2: x2, y2: y2, x3: x3, y3: y3, x4: x4, y4: y4};
      new_existingObject = {id:this.drawObject_id, color:this.color, weight:this.stroke_weight_constant/this.old_zoom_ratio, type:"arrow" };
    }

    else if(this.onWidget!=null){
      const x1 = (this.shapeContainer_left+this.shapeContainer_width/2-image_left)/this.image_width;
      const y1 = ((this.shapeContainer_top-image_top+this.shapeContainer_height/2))/this.image_height;
  
      let w = this.shapeContainer_width*ratio/this.canvas_width/this.old_zoom_ratio;
      let h = this.shapeContainer_height*ratio/this.canvas_width/this.old_zoom_ratio;
      let length;

      if(this.shapeContainer_width <= this.shapeContainer_height){
        length = w;
      }
      else{
        length = h;
      }
      

      drawObject = {color: this.color,  weight:this.stroke_weight_constant, id: this.drawObject_id, type: this.onWidget, x1: x1 , y1: y1, width: length, height: length};
      new_existingObject = {id:this.drawObject_id, color:this.color, type:this.onWidget};
    }

    this.imageCollaborationService.addDrawObject(drawObject);
    this.existing_drawObjects.push(new_existingObject);
    this.imageCollaborationService.updateArrays(this.existing_drawObjects, []);
    this.imageCollaborationService.openControls();
    this.viewer.nativeElement.style.cursor = "default";
    this.refreshShapeRelatedValues();
    this.drawObject_id = this.imageCollaborationService.createID();

  }

  // when we done with the draw Object, the div should be hidden and controls should be open
  refreshShapeRelatedValues(){
    this.shapeContainer_left = null;
    this.shapeContainer_top = null;
    this.dragPreX = null;
    this.dragPreY = null;
    this.onRect = false;
    this.onEllipse = false;
    this.onArrow = false;
    this.onWidget = null;
    this.tempWidgetClass = null;
    this.shapeContainer_rotation = 0;
    this.tickIcon.nativeElement.style.transform = "rotate("+(-this.shapeContainer_rotation)+"rad)";
    this.shapeContainer.nativeElement.style.transform = "rotate("+this.shapeContainer_rotation+"rad)";

  }

  refresTextRelatedValues(){
    
    this.textContainerLeft = null;
    this.textContainerTop = null;
    this.dragPreX = null;
    this.dragPreY = null;
    this.showText = false;
    this.textField.nativeElement.value="";
    this.textContainer.nativeElement.style["pointer-events"] = "all";

  }

  onViewerMouseUp(e){
    if(!this.isTouch)
      this.up(e);
    
    if(this.mouseHold && this.onMouse){
      this.scrollX(this.old_xratio);
      this.scrollY(this.old_yratio);
      this.imageCollaborationService.triggerCompleteMove((this.pre_x-this.first_x),(this.pre_y-this.first_y));
    }

    if(this.onMouse)
      this.viewer.nativeElement.style.cursor="grabbing";
    

    this.mouseHold = false;
    this.pre_x = null;
    this.pre_y = null;
    this.temp_left = null;
    this.temp_top = null;
    this.first_x = null;
    this.first_y = null;
  }

  onViewerMouseDown(e){
    if(!this.isTouch)
      this.down(e);
    
    this.mouseHold = true;
    if(this.onMouse)
      this.viewer.nativeElement.style.cursor="grab";
  }

  onViewerMouseLeave(e){
    this.isOnViewer = false;
    this.mouseHold = false;
    //this.onViewerMouseUp(null);

    this.pre_x = null;
    this.pre_y = null;
    this.temp_left = null;
    this.temp_top = null;
    this.first_x = null;
    this.first_y = null;
    if(this.onMouse)
      this.viewer.nativeElement.style.cursor="grab";
    
  }
  onViewerMouseEnter(e){
    this.isOnViewer = true;
    this.mouseHold = false;

    this.pre_x = null;
    this.pre_y = null;
    this.temp_left = null;
    this.temp_top = null;
    this.first_x = null;
    this.first_y = null;
    if(this.onMouse)
      this.viewer.nativeElement.style.cursor="grab";
  }

  // isMouseEvent enable that if there are widgets on the canvas, they are not deleted and redrawed when mouse events.  
  clearAndReDrawCanvas(isMouseEvent: boolean){
    
    if(!this.p5)
      return;

    this.p5.clear();
    for(let existing of this.existing_drawObjects){
      this.updateCanvasPaint(existing.color, existing.weight, existing.type);
      for(let drawObject of this.drawObjects){
        if(drawObject.id == existing.id){
          this.updateCanvasPaint(drawObject.color,drawObject.weight,drawObject.type);
          if(existing.type==this.TYPE_LINE)
            this.drawLine(drawObject.x2*this.canvas_width,drawObject.y2*this.canvas_height,drawObject.x1*this.canvas_width,drawObject.y1*this.canvas_height);
          else if(existing.type==this.TYPE_TEXT)
            this.drawText(drawObject.x1,drawObject.y1,drawObject.text);
          else if(existing.type==this.TYPE_RECTANGLE)
            this.drawRectangle(drawObject.x1,drawObject.y1,drawObject.width, drawObject.height);
          else if(existing.type==this.TYPE_ELLIPSE)
            this.drawEllipse(drawObject.x1,drawObject.y1,drawObject.width, drawObject.height);
          else if(existing.type==this.TYPE_ARROW)
            this.drawArrow(drawObject.x1,drawObject.x2,drawObject.x3, drawObject.x4,drawObject.y1,drawObject.y2,drawObject.y3, drawObject.y4);
          else if(!isMouseEvent)
            this.drawWidget(drawObject.x1, drawObject.y1, drawObject.type, drawObject.width, drawObject.id);
        }
      }
    }

  }
  
  onTextMouseLeave(event: any){
    //this.dragButtonClicked = false;
    //this.dragButtonReleased = true;
  }

  onTextMouseUp(event: any){
    this.viewer.nativeElement.style.cursor = "default";
    this.dragButtonClicked = false;
    this.dragButtonReleased = true;
  }

  onTextMouseDown(event: any){
    this.textContainer.nativeElement.style["pointer-events"] = "none";
    this.viewer.nativeElement.style.cursor = "grabbing";
    this.dragButtonClicked = true;
    this.dragButtonReleased = false;
  }

  onResizeButtonMouseLeave(event: any){
    //this.resizeButtonClicked = false;
    //this.resizeButtonReleased = true;
    this.viewer.nativeElement.style.cursor = "default";
  }

  onResizeButtonMouseUp(event: any){
    this.resizeButtonClicked = false;
    this.resizeButtonReleased = true;
    this.viewer.nativeElement.style.cursor = "default";
  }

  onResizeButtonMouseDown(event: MouseEvent){
    event.stopPropagation();
    this.viewer.nativeElement.style.cursor = "nw-resize";
    this.resizeButtonClicked = true;
    this.resizeButtonReleased = false;
    this.shapeContainer.nativeElement.style["pointer-events"] = "none";
    this.textContainer.nativeElement.style["pointer-events"] = "none";

  }

  onShapeMouseLeave(event: any){
    //this.dragButtonClicked = false;
    //this.dragButtonReleased = true;
    this.viewer.nativeElement.style.cursor = "default";
  }

  onShapeMouseUp(event: any){
    this.dragButtonClicked = false;
    this.dragButtonReleased = true;
    this.shapeContainer.nativeElement.style.cursor = "grab";
    this.viewer.nativeElement.style.cursor = "default";
  }

  onShapeMouseDown(event: any){
    this.shapeContainer.nativeElement.style["pointer-events"] = "none";
    this.viewer.nativeElement.style.cursor = "grabbing";
    this.dragButtonClicked = true;
    this.dragButtonReleased = false;
  }

  textDone(){
    if(this.textField.nativeElement.value.length>0){
      let el = {id:this.drawObject_id, color:this.color, weight:this.fontSizeNum/this.canvas_height/this.old_zoom_ratio, type:"text" };
      let image_left = parseFloat(this.viewer.nativeElement.style.left);
      let image_top = parseFloat(this.viewer.nativeElement.style.top);

      const x1 = (this.textContainerLeft-image_left+20)/this.image_width;
      const y1 = (this.textContainerTop-image_top+20+((this.fontSizeNum*this.image_height/this.canvas_height/this.old_zoom_ratio)/2)-this.fontSizeNum/10)/this.image_height;

      let textObject = {color: this.color,  weight:this.fontSizeNum/this.canvas_height/this.old_zoom_ratio, id: this.drawObject_id, type: "text", x1: x1 , y1: y1, text:this.textField.nativeElement.value};
      this.imageCollaborationService.addDrawObject(textObject);
      this.existing_drawObjects.push(el);
      this.drawObject_id = this.imageCollaborationService.createID();
      this.imageCollaborationService.updateArrays(this.existing_drawObjects, []);
    }
    this.imageCollaborationService.openControls();
    this.refresTextRelatedValues();
    this.viewer.nativeElement.style.cursor = "default";

  }

  onEnterPressed(event: any){
    if(event.keyCode == 13){
      this.textDone();
      return;
    }
    
    if (!this.textField.nativeElement.startW) { 
      this.textField.nativeElement.startW = this.textField.nativeElement.offsetWidth; 
    }

    //Force complete recalculation of width
    //in case characters are deleted and not added:
    let old = this.textField.nativeElement.style.width;
    this.textField.nativeElement.style.width = 0;

    let desiredW = this.textField.nativeElement.scrollWidth;
    //Optional padding to reduce "jerkyness" when typing:
    desiredW += this.fontSizeNum/3;

    let width = Math.max(desiredW, this.textField.nativeElement.startW);

    if(this.textField.nativeElement.value.length==0){
      this.textField.nativeElement.style.width = this.textContainer_defaultWidth - 40 + 'px';
      this.textContainer.nativeElement.style.width = this.textContainer_defaultWidth + 'px';
    }
    else if(this.container_width*0.75 > width && this.textContainer_defaultWidth-40 < width && this.textContainerLeft+width+40<this.container_width){
      this.textField.nativeElement.style.width = width + 'px';
      this.textContainer.nativeElement.style.width = width + 40 + 'px';
    }
    else
      this.textField.nativeElement.style.width = old;
  }

  private createCanvas() {
    new p5(p5 => {
      this.p5 = p5;

      p5.setup = this.setup;
      p5.draw = this.draw;
    });
  }

  setup = () => {
  
    // create canvas 
    this.p5.createCanvas(this.canvas_width, this.canvas_height);

    // append the canvas to div with id "viewer"
    this.viewer.nativeElement.appendChild(this.p5._curElement.canvas);

    // set background transparent
    this.p5.background(0,0,0,0);
    this.p5._curElement.canvas.style.background="transparent"; 

    // calculate the initial line width and set
    this.strokeWeight = this.canvas_width*this.stroke_weight_constant;
    this.p5.strokeWeight(this.strokeWeight/this.old_zoom_ratio);

    // set the initial color of the lines
    this.p5.stroke(255, 195, 0);

    // we don't want to fill the shapes. So, set as transparent
    this.p5.fill(0,0,0,0);

    // set text's features
    this.font = this.p5.loadFont("assets/arialBold.ttf");
    this.p5.textFont(this.font);
    this.p5.textSize(this.fontSizeNum);
    this.p5.textAlign(this.p5.LEFT);

    // call  this function to get last status of the annotation.
    this.clearAndReDrawCanvas(false);

    // trigger resize one time to make synchron the canvas with the image
    this.imageCollaborationService.triggerResize();

    this.canvas.emit({ canvas: this.p5._curElement.canvas, container: this.viewer.nativeElement });
  }

  draw = () => {


    if (this.p5 && this.onPen && this.p5.mouseIsPressed === true  && this.isOnViewer === true){

      // first line case
      if(this.counter==0){

        // Check for X
        if(Math.abs(this.p5.mouseX-this.p5.pmouseX)>20){
          if(this.p5.mouseX>this.p5.pmouseX){
            this.p5.pmouseX = this.p5.mouseX-1;
          }
          else
            this.p5.pmouseX = this.p5.mouseX+1;
        }
        else{
          this.p5.pmouseX = this.p5.mouseX;
        }

        // Check for Y
        if(Math.abs(this.p5.mouseY-this.p5.pmouseY)>10){
          if(this.p5.mouseY>this.p5.pmouseY){
            this.p5.pmouseY = this.p5.mouseY-1;
          }
          else
            this.p5.pmouseY = this.p5.mouseY+1;
        }
        else{
          this.p5.pmouseY = this.p5.mouseY;
        }

        this.counter++;
      }
      
      // take the position of the mouse and derive the ratios.
      let mx = this.p5.mouseX/this.canvas_width;
      let my = this.p5.mouseY/this.canvas_height;
      let pmx = this.p5.pmouseX/this.canvas_width;
      let pmy = this.p5.pmouseY/this.canvas_height;

      // if the values are calculated correctly with no errors, then create a drawObject with type line and add it to database.
      if(mx>=0 && pmx>=0 && my>=0 && pmy>=0 &&
        !isNaN(mx) && !isNaN(my) && !isNaN(pmx) && !isNaN(pmy) && 
        isFinite(mx) && isFinite(my) && isFinite(pmx) && isFinite(pmy)){

        const line: DrawObject = {
          id: this.drawObject_id,
          type: "line",
          x2: mx,
          y2: my,
          x1: pmx,
          y1: pmy,
          color: this.color,
          weight: this.stroke_weight_constant/this.old_zoom_ratio
        }

        this.imageCollaborationService.addDrawObject(line);
      }

    }
  }

  toggleUndo(){

    if(this.existing_drawObjects.length!=0){
      let el = this.existing_drawObjects.pop();
      this.deleted_drawObjects.push(el);
      this.imageCollaborationService.updateArrays(this.existing_drawObjects, this.deleted_drawObjects);
    }
  }

  toggleRedo(){
    if(this.deleted_drawObjects.length!=0){
      let id = this.deleted_drawObjects.pop();
      this.existing_drawObjects.push(id);
      this.imageCollaborationService.updateArrays(this.existing_drawObjects, this.deleted_drawObjects);
    }
  }

  zoomIn(value: number){

    this.old_zoom_ratio = value;

    // calculate the current ratios. We need them after width and height are changed.
    const curr_x_ratio = this.calculateCurrentX();
    const curr_y_ratio = this.calculateCurrentY();
    
    // change the width and height of the canvas.
    this.image_width = this.default_image_width * value;
    this.image_height = this.default_image_height * value;

    this.viewer.nativeElement.style.width = this.image_width+"px";
    this.viewer.nativeElement.style.height = this.image_height+"px";

    if(this.p5){
      this.p5._curElement.canvas.style.width = this.image_width+"px";
      this.p5._curElement.canvas.style.height = this.image_height+"px";
    }

    // focus the canvas to the point before the zoom. This point is the center of the container.
    this.scrollX(curr_x_ratio);
    this.scrollY(curr_y_ratio);
   }
 
  zoomOut(value: number){

    this.old_zoom_ratio = value;

    // calculate the current ratios.
    let curr_x_ratio = this.calculateCurrentX();
    let curr_y_ratio = this.calculateCurrentY();
    curr_x_ratio  = curr_x_ratio + (0.5 - curr_x_ratio)/value;
    curr_y_ratio  = curr_y_ratio + (0.5 - curr_y_ratio)/value;

    // change the width and height of the image.
    this.image_width = this.default_image_width * value;
    this.image_height = this.default_image_height * value;

    this.viewer.nativeElement.style.width = this.image_width+"px";
    this.viewer.nativeElement.style.height = this.image_height+"px";

    if(this.p5){
      this.p5._curElement.canvas.style.width = this.image_width+"px";
      this.p5._curElement.canvas.style.height = this.image_height+"px";
    }

    // scroll the image to the new point
    this.scrollX(curr_x_ratio);
    this.scrollY(curr_y_ratio);

  }

  refresh(){
       
    // set the dafault values
    this.viewer.nativeElement.style.width = this.default_image_width+"px";
    this.viewer.nativeElement.style.height = this.default_image_height+"px";
    if(this.p5){
      this.p5._curElement.canvas.style.width = this.default_image_width+"px";
      this.p5._curElement.canvas.style.height = this.default_image_height+"px";
    }
    this.old_zoom_ratio = 1.0;
    this.old_xratio = 0.5;
    this.old_yratio = 0.5;

  }

  remove(){
    if(this.p5)
      this.p5.clear();
    this.drawObjects = [];
    this.existing_drawObjects = [];
    this.deleted_drawObjects = [];
    this.widgets = [];
  }

  scrollX(x_ratio: number){

    let width = this.image_width;
    let left = x_ratio*width-(this.container_width/2);
    this.viewer.nativeElement.style.left = -left+"px";
    this.image_left = -left;
    this.old_xratio = x_ratio;
    this.updateWidgets();
  }

  scrollY(y_ratio: number){

    let height = this.image_height;
    let top = y_ratio*height-(this.container_height/2);
    this.viewer.nativeElement.style.top = -top+"px";
    this.image_top = -top;
    this.old_yratio = y_ratio;
    this.updateWidgets();
  }

  calculateCurrentX(){

    let left = parseInt(this.viewer.nativeElement.style.left);
    let width = this.image_width;
    let x_ratio = (-left+this.container_width/2)/width;
    return x_ratio;

  }
  calculateCurrentY(){

    let top = parseInt(this.viewer.nativeElement.style.top);
    let height = this.image_height;
    let y_ratio = (-top+this.container_height/2)/height;
    return y_ratio;

  }

  
  ngOnDestroy() {
    if (this.annotationSub) { this.annotationSub.unsubscribe(); }
    if (this.controlSub) { this.controlSub.unsubscribe(); }
    if (this.initializeSub) { this.initializeSub.unsubscribe();}
    if (this.resizeSub) { this.initializeSub.unsubscribe(); }
    if (this.arraysSub) {this.arraysSub.unsubscribe();}
    if (this.pointerCollMouseSub) {this.pointerCollMouseSub.unsubscribe();}

    this.imageCollaborationService.getDrawObjects().off("child_added", this.onDrawObjects);
    this.p5 = null;
  }

  initialize(size){

    // take the width and height of the container
    this.container_height = size.container_height;
    this.container_width = size.container_width;

    // take the width and height of the image
    this.default_image_height = size.height;
    this.default_image_width = size.width;
    
    // calculate the width and height of the canvas
    if(this.default_image_width>this.default_image_height){
      this.canvas_width = 1600;
      this.canvas_height = 1600 * this.default_image_height / this.default_image_width;
    }
    else{
      this.canvas_height = 1200;
      this.canvas_width = 1200 * this.default_image_width / this.default_image_height;
    }

    this.image_width = this.default_image_width;
    this.image_height = this.default_image_height;

    this.viewer.nativeElement.style.left="0px";
    this.viewer.nativeElement.style.top="0px";
    this.image_left = 0;
    this.image_top = 0;

    this.createCanvas();
  }

  updateWidgets(){
    for(let widget of this.widgets){
      widget.style.left = widget.x*this.image_width+"px";
      widget.style.top = widget.y*this.image_height+"px";
      widget.style.width = widget.length*this.image_width+"px";
      widget.style.height = widget.length*this.image_width+"px";
      widget.style["font-size"] = widget.length*this.image_width+"px";
    }
  }

  onDocumentTouchStart(e){
    
    e.stopPropagation();
    e.preventDefault();
    this.isTouch = true;
    this.down(e);

    let mouseX;
    let mouseY;
    if(!this.isFullScreen){
      mouseX = e.touches[0].clientX - parseFloat(e.path[6].style.left) - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - parseFloat(e.path[6].style.top) - parseFloat(this.viewer.nativeElement.style.top) - 56; 
    }
    else{
      mouseX =  mouseX = e.touches[0].clientX - (window.innerWidth-this.container_width)/2 - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - (window.innerHeight-this.container_height)/2 - parseFloat(this.viewer.nativeElement.style.top);
    }

    
    mouseX *= this.canvas_width/this.image_width;
    mouseY *= this.canvas_height/this.image_height;   
 
    if(!this.preX && !this.preY){
      this.preX = mouseX;
      this.preY = mouseY;
    }
    
    this.drawWithTouch(mouseX, mouseY, this.preX, this.preY)

    this.preX = mouseX;
    this.preY = mouseY;

  }
  onDocumentTouchMove(e){
    
    e.stopPropagation();
    e.preventDefault();

    let mouseX;
    let mouseY;
    if(!this.isFullScreen){
      mouseX = e.touches[0].clientX - parseFloat(e.path[6].style.left) - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - parseFloat(e.path[6].style.top) - parseFloat(this.viewer.nativeElement.style.top) - 56; 
    }
    else{
      mouseX =  mouseX = e.touches[0].clientX - (window.innerWidth-this.container_width)/2 - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - (window.innerHeight-this.container_height)/2 - parseFloat(this.viewer.nativeElement.style.top);
    }

    mouseX *= this.canvas_width/this.image_width;
    mouseY *= this.canvas_height/this.image_height;

    if(!this.preX && !this.preY){
      this.preX = mouseX;
      this.preY = mouseY;
    }
    
    this.drawWithTouch(mouseX, mouseY, this.preX, this.preY)

    this.preX = mouseX;
    this.preY = mouseY;

  }
  onDocumentTouchEnd(e){
    
    e.stopPropagation();
    e.preventDefault();

    this.isTouch = false;
    this.up(e);
    this.preX = null;
    this.preY = null;

  }

  onDocumentTouchCancel(e){
    this.isTouch = false;
    this.preX = null;
    this.preY = null;
  }

  drawWithTouch(x: number, y:number, px: number, py: number){
    if(!this.onPen)
      return;

    let mx = x/this.canvas_width;
    let my = y/this.canvas_height;
    let pmx = px/this.canvas_width;
    let pmy = py/this.canvas_height;

      if(mx>=0 && pmx>=0 && my>=0 && pmy>=0 &&
        !isNaN(mx) && !isNaN(my) && !isNaN(pmx) && !isNaN(pmy) && 
        isFinite(mx) && isFinite(my) && isFinite(pmx) && isFinite(pmy)){

        const line: DrawObject = {
          id: this.drawObject_id,
          type: "line",
          x2: mx,
          y2: my,
          x1: pmx,
          y1: pmy,
          color: this.color,
          weight: this.stroke_weight_constant/this.old_zoom_ratio
        }

        this.imageCollaborationService.addDrawObject(line);
      }
  }

  onTextTouchStart(e: any){
    e.stopPropagation();
    e.preventDefault();
    this.dragButtonClicked = true;
    this.dragButtonReleased = false;
    let mouseX;
    let mouseY;
    if(!this.isFullScreen){
      mouseX = e.touches[0].clientX - parseFloat(e.path[5].style.left) - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - parseFloat(e.path[5].style.top) - parseFloat(this.viewer.nativeElement.style.top) - 56; 
    }
    else{
      mouseX =  mouseX = e.touches[0].clientX - (window.innerWidth-this.container_width)/2 - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - (window.innerHeight-this.container_height)/2 - parseFloat(this.viewer.nativeElement.style.top);
    }

    this.move(mouseX, mouseY);

    
  }
  onTextTouchMove(e: any){
    e.stopPropagation();
    e.preventDefault();
    let mouseX;
    let mouseY;
    if(!this.isFullScreen){
      mouseX = e.touches[0].clientX - parseFloat(e.path[5].style.left) - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - parseFloat(e.path[5].style.top) - parseFloat(this.viewer.nativeElement.style.top) - 56; 
    }
    else{
      mouseX =  mouseX = e.touches[0].clientX - (window.innerWidth-this.container_width)/2 - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - (window.innerHeight-this.container_height)/2 - parseFloat(this.viewer.nativeElement.style.top);
    }

    this.move(mouseX, mouseY);

  }
  onTextTouchEnd(e: any){
    e.stopPropagation();
    e.preventDefault();
    this.dragButtonClicked = false;
    this.dragButtonReleased = true;
  }

  onShapeTouchStart(e: any){
    e.stopPropagation();
    e.preventDefault();
    this.dragButtonClicked = true;
    this.dragButtonReleased = false;
    let mouseX;
    let mouseY;
    if(!this.isFullScreen){
      mouseX = e.touches[0].clientX - parseFloat(e.path[5].style.left) - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - parseFloat(e.path[5].style.top) - parseFloat(this.viewer.nativeElement.style.top) - 56; 
    }
    else{
      mouseX =  mouseX = e.touches[0].clientX - (window.innerWidth-this.container_width)/2 - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - (window.innerHeight-this.container_height)/2 - parseFloat(this.viewer.nativeElement.style.top);
    }

    this.move(mouseX, mouseY);


  }
  onShapeTouchMove(e: any){
    e.stopPropagation();
    e.preventDefault();
    let mouseX;
    let mouseY;

    if(!this.isFullScreen){
      mouseX = e.touches[0].clientX - parseFloat(e.path[5].style.left) - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - parseFloat(e.path[5].style.top) - parseFloat(this.viewer.nativeElement.style.top) - 56; 
    }
    else{
      mouseX =  mouseX = e.touches[0].clientX - (window.innerWidth-this.container_width)/2 - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - (window.innerHeight-this.container_height)/2 - parseFloat(this.viewer.nativeElement.style.top);
    }

    this.move(mouseX, mouseY);

  }
  onShapeTouchEnd(e: any){
    e.stopPropagation();
    e.preventDefault();
    this.dragButtonClicked = false;
    this.dragButtonReleased = true;
    this.up(e);
  }

  onResizeButtonTouchStart(e: any){
    e.stopPropagation();
    e.preventDefault();

    let mouseX;
    let mouseY;

    if(!this.isFullScreen){
      mouseX = e.touches[0].clientX - parseFloat(e.path[6].style.left) - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - parseFloat(e.path[6].style.top) - parseFloat(this.viewer.nativeElement.style.top) - 56; 
    }
    else{
      mouseX =  mouseX = e.touches[0].clientX - (window.innerWidth-this.container_width)/2 - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - (window.innerHeight-this.container_height)/2 - parseFloat(this.viewer.nativeElement.style.top);
    }

    this.move(mouseX, mouseY);

    this.resizeButtonClicked = true;
    this.resizeButtonReleased = false;

  }
  onResizeButtonTouchMove(e: any){
    e.stopPropagation();
    e.preventDefault();

    let mouseX;
    let mouseY;

    if(!this.isFullScreen){
      mouseX = e.touches[0].clientX - parseFloat(e.path[6].style.left) - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - parseFloat(e.path[6].style.top) - parseFloat(this.viewer.nativeElement.style.top) - 56; 
    }
    else{
      mouseX =  mouseX = e.touches[0].clientX - (window.innerWidth-this.container_width)/2 - parseFloat(this.viewer.nativeElement.style.left);
      mouseY = e.touches[0].clientY - (window.innerHeight-this.container_height)/2 - parseFloat(this.viewer.nativeElement.style.top);
    }

    this.move(mouseX, mouseY);

  }
  onResizeButtonTouchEnd(e: any){
    e.stopPropagation();
    e.preventDefault();
    this.up(e);
    this.resizeButtonClicked = false;
    this.resizeButtonReleased = true;

  }

  onTickButtonTouchEnd(e: any){
    e.stopPropagation();
    e.preventDefault();
    this.shapeDone();
  }

  onTextTickButtonTouchEnd(e: any){
    e.stopPropagation();
    e.preventDefault();
    this.textDone();
  }

  down(e: any){
    if(e.layerX>=this.shapeContainer_left-30 && e.layerY >= this.shapeContainer_top-30 && e.layerX <= this.shapeContainer_left+this.shapeContainer_width+30 && e.layerY <= this.shapeContainer_top+this.shapeContainer_height+30){
      return;
    }
    this.imageCollaborationService.openControls();
    this.clearAndReDrawCanvas(true);
    this.onRect = false;
    this.onEllipse = false;
    this.onArrow = false;
    this.onWidget = null;
    this.showText = false; 
    
    this.refresTextRelatedValues();
    this.refreshShapeRelatedValues();
  }
  
  move(layerX: number, layerY: number){
    
    if(this.onMouse)
      this.viewer.nativeElement.style.cursor="grab";
    else
      this.viewer.nativeElement.style.cursor = "default";
    // TEXT DRAG
    if(this.dragButtonClicked && !this.dragButtonReleased && this.showText){
      
      if(this.dragPreX && this.dragPreY){
        this.dragTextDiv(layerX, layerY);
      }
      else{
        this.dragPreX = layerX;
        this.dragPreY = layerY;
      }
    }

    // RECTANGLE RESIZE
    else if(this.resizeButtonClicked && !this.resizeButtonReleased && this.onRect){
      this.resizeShapeDiv(layerX, layerY);
      this.drawTemporaryRectangle();
    }

    // RECTANGLE DRAG
    else if(this.dragButtonClicked && !this.dragButtonReleased && this.onRect){
      if(this.dragPreX && this.dragPreY){
        this.dragShapeDiv(layerX, layerY);
        this.drawTemporaryRectangle();
      }
      else{
        this.dragPreX = layerX;
        this.dragPreY = layerY;
      }
    }

    // ELLIPSE RESIZE
    if(this.resizeButtonClicked && !this.resizeButtonReleased && this.onEllipse){
      this.resizeShapeDiv(layerX, layerY);
      this.drawTemporaryEllipse();
    }

    // ELLIPSE DRAG
    else if(this.dragButtonClicked && !this.dragButtonReleased && this.onEllipse){
      if(this.dragPreX && this.dragPreY){
        this.dragShapeDiv(layerX, layerY);
        this.drawTemporaryEllipse();
      }
      else{
        this.dragPreX = layerX;
        this.dragPreY = layerY;
      }
    }

    // ARROW RESIZE
    if(this.resizeButtonClicked && !this.resizeButtonReleased && this.onArrow){
      this.resizeShapeDiv(layerX, layerY);
      this.drawTemporaryArrow();
    }

    // ARROW DRAG
    else if(this.dragButtonClicked && !this.dragButtonReleased && this.onArrow){
      if(this.dragPreX && this.dragPreY){
        this.dragShapeDiv(layerX, layerY);
        this.drawTemporaryArrow();
      }
      else{
        this.dragPreX = layerX;
        this.dragPreY = layerY;
      }
    }

    // WIDGET RESIZE
    if(this.resizeButtonClicked && !this.resizeButtonReleased && this.onWidget!=null){
      this.resizeShapeDiv(layerX, layerY);
      this.drawTemporaryWidget(this.onWidget);
    }

    // WIDGET DRAG
    else if(this.dragButtonClicked && !this.dragButtonReleased && this.onWidget!=null){
      if(this.dragPreX && this.dragPreY){
        this.dragShapeDiv(layerX, layerY);
        this.drawTemporaryWidget(this.onWidget);
      }
      else{
        this.dragPreX = layerX;
        this.dragPreY = layerY;
      }
    }
    
    if(this.onMouse && this.mouseHold){

      this.viewer.nativeElement.style.cursor="grabbing";

      if(!this.first_x || !this.first_y){
        this.first_x = layerX;
        this.first_y = layerY;
  
        this.pre_x = layerX;
        this.pre_y = layerY;
      }
  

      if(!this.temp_left || !this.temp_top){
        this.temp_left = parseFloat(this.viewer.nativeElement.style.left);
        this.temp_top = parseFloat(this.viewer.nativeElement.style.top);
  
      }
      this.temp_left += (layerX - this.pre_x);
      this.temp_top += (layerY - this.pre_y);
      this.viewer.nativeElement.style.left = this.temp_left+"px";
      this.viewer.nativeElement.style.top = this.temp_top+"px";
      this.imageCollaborationService.triggerImageScroll(this.temp_left,this.temp_top);
        
      this.pre_x = layerX;
      this.pre_y = layerY;
      
    }
  }
  up(e: any){
    if(this.onArrow || this.onEllipse || this.onRect ||this.onWidget || this.showText){
      this.viewer.nativeElement.style.cursor = "grab";
      this.shapeContainer.nativeElement.style.cursor = "grab";
    }

    if(this.onPen){
      this.viewer.nativeElement.style.cursor = "default";
      let el = {id:this.drawObject_id, color:this.color, weight:this.stroke_weight_constant/this.old_zoom_ratio,type:"line" };
      this.existing_drawObjects.push(el);
      this.drawObject_id = this.imageCollaborationService.createID();
      this.imageCollaborationService.updateArrays(this.existing_drawObjects, []);
    }
    else{
      this.textContainer.nativeElement.style["pointer-events"] = "all";
      this.shapeContainer.nativeElement.style["pointer-events"] = "all";
      this.dragPreX = null;
      this.dragPreY = null;
      this.dragButtonClicked = false;
      this.dragButtonReleased = true;
      this.resizeButtonClicked = false;
      this.resizeButtonReleased = true;
      if(this.onRect){
        this.drawTemporaryRectangle();
      }
      else if(this.onEllipse){
        setTimeout(()=>{
          this.drawTemporaryEllipse();
        },10);
      }
      else if(this.onArrow){
        setTimeout(()=>{
          this.drawTemporaryArrow();
        },10);
      }
      else if(this.onWidget!=null){
        setTimeout(()=>{
          this.drawTemporaryWidget(this.onWidget);
        },10);
      }
    }
  }

 

}
