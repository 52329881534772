import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input } from '@angular/core';

import { User } from '@models/User';
import { Room } from '@models/Room';
import { Subscription } from 'rxjs';
import { map, distinctUntilChanged, startWith } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UserService } from '@services/user.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { ModalService } from '@services/support/modal.service';
import { RoomService } from '@services/room.service';
import { LoaderService } from '@services/support/loader.service';
import { AccountService } from '@services/account.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { DetailPageService } from '@services/support/detail-page.service';

import { UserDetailsComponent } from '../user-details/user-details.component';
import { TranslateService } from '@ngx-translate/core';

interface Users {  
  role: any;  
  name: any;  
  username: any;  
  email: any;
  password: any;
  allow_archiving: any;  
  allow_calling: any; 
  allow_join_link: any;
  call_archive_perm_needed?: any;
  err?: string;
  success?: string;
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {

  @ViewChild("toggleTemplate", { static: true }) private toggleTemplate: TemplateRef<any>;
  @ViewChild("uploadExcelTemplate", { static: true }) private uploadExcelTemplate: TemplateRef<any>;
  @ViewChild("resultsExcelTemplate", { static: true }) private resultsExcelTemplate: TemplateRef<any>;
  @ViewChild("successfullyDeployedTemplate", { static: true }) private successfullyDeployedTemplate: TemplateRef<any>;
  @ViewChild("failureDeployedTemplate", { static: true }) private failureDeployedTemplate: TemplateRef<any>;
  @Input() detailed: boolean = true;

  users: User[] = [];
  usersSub: Subscription = null;

  enabledUsers: User[] = [];
  disabledUsers: User[] = [];

  rooms: Room[] = [];
  roomNames: string[] = [];
  roomData = {};
  
  roomsSub: Subscription = null;

  names = [];
  nameUserName: string = '';

  excelUploadUsers: Users[] = [];
  isAnyError: boolean = false;
  fileUploaded: boolean = false;
  repeatedUsers = [];
  excelUserUploadError: string = '';
  uploadedFile: File = null;
  uploadExcelFileTemplateId: number = 0;

  licenseType: string = "concurrent_user_based";
  accountDataSub: Subscription = null;
  
  roles: any[] = [];
  roleNames: any[] = [];
  selectedRoomFilter: string[] = [];
  selectedRoleFilter: string[] = [];
  
  allUsers: any[] = null;

  searchTextChangedSource: Subject<string> = new Subject<string>();
  searchTextChangedSub: Subscription = null;

  langSub: Subscription = null;
  textfieldInput: any = false;

  roomIds: any[] = [];

  subscriber: any = "";
  user: any = "";
  admin: any = "";
  coadmin: any = "";
  expert: any = "";

  constructor(
    private userService: UserService,
    private roomService: RoomService,
    private accountService: AccountService,
    private modalService: ModalService,
    private detailPageService: DetailPageService,
    private loaderService: LoaderService,
    private flashMessageService: FlashMessageService,
    private multilanguageService: MultilanguageService,
    private translateService: TranslateService
  ) {
    // Code piece runs whenever user touches the textfield search.
    this.searchTextChangedSub = this.searchTextChangedSource.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(text => {
      this.textfieldInput = text;
      var rooms = [...this.selectedRoomFilter];
      var roles = [...this.selectedRoleFilter];
      
      roles = roles.map(item => 
        item === this.subscriber   ? "user"    : 
        item === this.user         ? "user"    :
        item === this.admin        ? "admin"   :
        item === this.coadmin      ? "coadmin" :
        item === this.expert       ? "expert"  : item
      )

      if (this.licenseType === "concurrent_user_based" && roles.includes("user")) { roles.push("expert") }

      this.users = this.allUsers
        .filter(user => { 
          let nameSelected      = text ? user.name.toLowerCase().match(text.toLowerCase()) : true;
          let userNameSelected  = text ? (user.ad_user ? user.user_principal_name.toLowerCase().match(text.toLowerCase()) : 
                                          user.auth.username.toLowerCase().match(text.toLowerCase())) : true;
          let roomIds           = rooms.map(room => room ? this.roomData[room.toString()] : "");
          let roomSelected      = rooms.length > 0 ? roomIds.some(id => user.rooms ? Object.keys(user.rooms).includes(id.toString()) : false) : true;
          let roleSelected      = roles.length > 0 ? roles.includes(user.role) : true;
          return roleSelected && roomSelected && (nameSelected || userNameSelected);
        })
        .sort((a, b) => {
          if (!a.auth.disabled  && b.auth.disabled === true) {
            return -1;
          } else if (a.auth.disabled === true && !b.auth.disabled) {
            return 1;
          } else {
            return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
          }
        })

        this.roles = this.users
        .map(user => this.licenseType === "expert_user_based"     && user.role === "user"    ? this.subscriber  : user)
        .map(user => this.licenseType === "concurrent_user_based" && user.role === "user"    ? this.user        : user)
        .map(user => this.licenseType === "concurrent_user_based" && user.role === "expert"  ? this.user        : user)
        .map(user => this.licenseType === "expert_user_based"     && user.role === "expert"  ? this.expert      : user)
        .map(user =>
          user.role === "admin"   ? this.admin   :
          user.role === "coadmin" ? this.coadmin : user
        )
        this.roles = this.roles.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);
    })
  }

  ngOnInit() {
    this.langSub =this.multilanguageService.onLangChange.pipe(startWith(this.multilanguageService.currentLang)).subscribe(change => {
      this.usersSub = this.userService.users.subscribe(users => {
        if (users) {
          var roles = [...this.selectedRoleFilter];
        
          roles = roles.map(item => 
            item === this.subscriber   ? "user"    : 
            item === this.user         ? "user"    :
            item === this.admin        ? "admin"   :
            item === this.coadmin      ? "coadmin" :
            item === this.expert       ? "expert"  : item
          )
    
          if (this.licenseType === "concurrent_user_based" && roles.includes("user")) { roles.push("expert") }
  
          let disableFilteredUserList = users
          .filter(user => { 
            let nameSelected      = this.textfieldInput ? user.name.toLowerCase().match(this.textfieldInput.toLowerCase()) : true;
            let userNameSelected  = this.textfieldInput ? (user.ad_user ? user.user_principal_name.toLowerCase().match(this.textfieldInput.toLowerCase()) : 
                                            user.auth.username.toLowerCase().match(this.textfieldInput.toLowerCase())) : true;
            let roomIds           = this.selectedRoomFilter.map(room => room ? this.roomData[room.toString()] : "");
            let roomSelected      = this.selectedRoomFilter.length > 0 ? roomIds.some(id => user.rooms ? Object.keys(user.rooms).includes(id.toString()) : false) : true;
            let roleSelected      = roles.length > 0 ? roles.includes(user.role) : true;

            return roleSelected && roomSelected && (nameSelected || userNameSelected);
          })
          .sort((a, b) => {
            if (!a.auth.disabled && b.auth.disabled === true) {
              return -1;
            } else if (a.auth.disabled === true && !b.auth.disabled) {
              return 1;
            } else {
              return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
            }
          });
          this.users = [...disableFilteredUserList];
  
            
            this.subscriber = this.translateService.instant('APP.MAIN.LOBBY.CONTACTS.SUBSCRIBER')
            this.user = this.translateService.instant('APP.MAIN.LOBBY.CONTACTS.USER')
            this.admin = this.translateService.instant('APP.MAIN.LOBBY.CONTACTS.ADMIN')
            this.coadmin = this.translateService.instant('APP.MAIN.LOBBY.CONTACTS.COADMIN')
            this.expert = this.translateService.instant('APP.MAIN.LOBBY.CONTACTS.EXPERT')
  
            this.roles = this.users
              .map(user => this.licenseType === "expert_user_based"     && user.role === "user"    ? this.subscriber  : user)
              .map(user => this.licenseType === "concurrent_user_based" && user.role === "user"    ? this.user        : user)
              .map(user => this.licenseType === "concurrent_user_based" && user.role === "expert"  ? this.user        : user)
              .map(user => this.licenseType === "expert_user_based"     && user.role === "expert"  ? this.expert      : user)
              .map(user =>
                user.role === "admin"   ? this.admin   :
                user.role === "coadmin" ? this.coadmin : user
              )
            this.roles = this.roles.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);
          
          this.allUsers = users.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
        } else {
          this.users = [];
          this.roles = [];
        }
      }); 
    });
    this.accountDataSub = this.accountService.accountData.pipe(map(d => d.license.type), distinctUntilChanged())
    .subscribe(type => {
      this.licenseType = type;
    });
    
   

    this.roomsSub = this.roomService.rooms.subscribe(r => {
      this.rooms = r
        .map(room => {
          room.name = room.room_data.name;
          return room;
        })
        .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
      this.roomNames = this.rooms.map(r => r.name);
      this.rooms.map(r => this.roomData[r.name] = r.id)
    });
    this.userStatusUpdate(this.users);
  }

  ngOnDestroy() {
    if (this.usersSub) { this.usersSub.unsubscribe() }
    if (this.roomsSub) { this.roomsSub.unsubscribe() }
    if (this.accountDataSub) { this.accountDataSub.unsubscribe() }
    if (this.searchTextChangedSub) { this.searchTextChangedSub.unsubscribe() }
    if (this.langSub) { this.langSub.unsubscribe() }
  }

  toggleItems(event: Event, user) {
    event.stopPropagation();
    user['isExpanded'] = !!!user['isExpanded'];
  }

  replaceSpecialCharactersWithSpace(text: string): string {
    return text.replace(/([.?*+^$[\]\\(){}|-])/g, ' ');
  }

  onSearchTextChange(text: string): void {
    this.searchTextChangedSource.next(this.replaceSpecialCharactersWithSpace(text));
  }

  userStatusUpdate(users: User[]) {
    this.disabledUsers = [];
    this.enabledUsers = [];
    for (let i = 0; i < users.length; i++ ) {
      if (users[i].auth.disabled) {
        this.disabledUsers.push(users[i]);
      } else {
        this.enabledUsers.push(users[i]);
      }
    }
  }

  async uploadFiles(event) {
    const upFile: FileList = event.target.files;
    this.fileUploaded = false;

    const uploadedFile = upFile.item(0);
    const xlsxFileType = uploadedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (xlsxFileType) {  
      this.fileUploaded = true;
      this.uploadedFile = uploadedFile;
    } else {
      this.fileUploaded = false;
      this.uploadedFile = null;
    }  
  }

  onUploadExcel() {
    let sameUsernameError: string = '';
    let sameUsernamePreRegistered: string = '';
    let fillNecessaryFields: string = '';
    let expertLimitExceeded: string = '';
    let dontUseDotsForUsername: string = '';
    let wrongBooleanFormat: string = '';

    if (this.langSub) { this.langSub.unsubscribe() }
    this.langSub = this.multilanguageService.onLangChange.subscribe(change => {
      sameUsernameError = change.translations.APP.MAIN.USERS.USER_LIST.SAME_USERNAME;
      sameUsernamePreRegistered = change.translations.APP.MAIN.USERS.USER_LIST.SAME_USERNAME_PRE_REGISTERED;
      fillNecessaryFields = change.translations.APP.MAIN.USERS.USER_LIST.FILL_ALL_NECESSARY;
      expertLimitExceeded = change.translations.APP.MAIN.USERS.USER_LIST.EXPERT_LIMIT_EXCEEDED;
      dontUseDotsForUsername = change.translations.APP.MAIN.USERS.USER_LIST.PLEASE_DONT_USE_DOTS_FOR_USERNAME;
      wrongBooleanFormat = change.translations.APP.MAIN.USERS.USER_LIST.WRONG_BOOLEAN_FORMAT;
    });
    
    const modalId = this.modalService.show({
      template: this.uploadExcelTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => {
            this.fileUploaded = false;
            this.modalService.hide(modalId);
            this.uploadExcelFileTemplateId = modalId;
          },
          importFile: async () => {
            this.uploadExcelFileTemplateId = modalId;

            this.excelUploadUsers = [];
            this.repeatedUsers = [];

            this.isAnyError = false;

            this.loaderService.show();
            this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_LIST.PLEASE_WAIT', { cssClass: 'alert-info' });

            const formData = new FormData();
            await formData.append("file", this.uploadedFile);
            
            this.userService.importUsersConfirm(formData)
              .then(response => {
                const checkResult = response?.check_result;
                const sameUsernamesInExcelList = response?.duplicated_usernames_in_excel_table;
                const sameUsernamesPreregistered = response?.repeated_users_registered_before;

                if (checkResult) {
                  this.setExcelUserUploadArray(response); 
                  if (sameUsernamesInExcelList?.length > 0) {
                    this.setErrorMessage(sameUsernameError, sameUsernamesInExcelList);
                    this.closeModal(this.uploadExcelFileTemplateId);
                    this.openFailureDeploymentModal();
                  } else if (sameUsernamesPreregistered?.length > 0) {
                    this.setErrorMessage(sameUsernamePreRegistered, sameUsernamesPreregistered);       
                    this.closeModal(this.uploadExcelFileTemplateId);  
                    this.openFailureDeploymentModal();
                  } else {
                    this.closeModal(modalId);
                    this.openUploadConfirmationModal();
                  }  
                }
              })
              .catch(error => {
                this.isAnyError = true;

                if (error.error === 'wrong-username-format') {
                  this.setErrorMessage(dontUseDotsForUsername, null);  
                  this.closeModal(modalId);
                  this.openFailureDeploymentModal();
                } else if (error.error === 'wrong-boolean-format') {
                  this.setErrorMessage(wrongBooleanFormat, null);
                  this.closeModal(modalId);  
                  this.openFailureDeploymentModal();
                } else if (error.error === 'some-fields-missing') {
                  this.setErrorMessage(fillNecessaryFields, null);         
                  this.closeModal(modalId);  
                  this.openFailureDeploymentModal();
                } else if (error.error === 'expert-limit-reached') {-
                  this.setErrorMessage(expertLimitExceeded, null);    
                  this.closeModal(modalId);
                  this.openFailureDeploymentModal();
                } else {
                  this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_LIST.USER_COULD_NOT_BE_ADDED');
                } 
              }) 
          }
        }
      }
    });
    this.uploadedFile = null;
  }

  setExcelUserUploadArray(response: any) {
    const userListSize = Object.keys(response.check_result).length;
    for (let i=0; i<userListSize; i++) {
      const excelUploadUser = response.check_result[i];
      this.excelUploadUsers.push(excelUploadUser);
      
      Object.values(excelUploadUser).every(val => {
        const user: any = val;
        if (user.hasError === true) {
          this.isAnyError = true;
          return false;
        }
        return true;
      });
    }
  }

  setErrorMessage(error: string, messageToModal: any) {
    this.excelUserUploadError = error;
    this.repeatedUsers = messageToModal;
  }

  closeModal(modalId: number) {
    this.loaderService.hide();
    this.modalService.hide(modalId);
  }

  openFailureDeploymentModal() {
    this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_LIST.PROBLEMS_OCCURRED');

    const modalId = this.modalService.show({
      template: this.failureDeployedTemplate,
      context: {
        dataModel: null,
        callbacks: {
          close: () => {
            this.fileUploaded = false;
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }

  openUploadConfirmationModal() {
    let successfullyAdded: string = '';
    let userCouldNotBeAdded: string = '';
    
    if (this.langSub) { this.langSub.unsubscribe() }
    this.langSub = this.multilanguageService.onLangChange.subscribe(change => {
      successfullyAdded = change.translations.APP.MAIN.USERS.USER_LIST.SUCCESSFULLY_ADDED;
      userCouldNotBeAdded = change.translations.APP.MAIN.USERS.USER_LIST.USER_COULD_NOT_BE_ADDED;
    });

    const modalId = this.modalService.show({
      template: this.resultsExcelTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => {
            this.excelUploadUsers = [];
            this.fileUploaded = false;
            this.modalService.hide(modalId);

            this.onUploadExcel();
          },
          importUsers: async () => {
            this.modalService.hide(this.uploadExcelFileTemplateId);
            this.isAnyError = false;
            this.loaderService.show();
            this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_LIST.PLEASE_WAIT', { cssClass: 'alert-info' });
            this.modalService.hide(modalId);
            this.userService.importUsers(this.excelUploadUsers)
              .then(response => {
                const failed_users_indices_length = response.failed_users_indices?.length; 
                if (failed_users_indices_length > 0) {
                  this.isAnyError = true;
                  this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_LIST.PROBLEMS_OCCURRED');

                  for (let i=0; i<failed_users_indices_length; i++) { 
                    this.excelUploadUsers[response.failed_users_indices[i]].err = userCouldNotBeAdded;
                  }
                  const succeeded_users_indices_length = response.succeeded_users_indices?.length;
                  for (let i=0; i<succeeded_users_indices_length; i++) { 
                    this.excelUploadUsers[response.succeeded_users_indices[i]].success = successfullyAdded;
                  }
  
                  this.loaderService.hide();
                  this.openUploadConfirmationModal();
                } else {
                  this.loaderService.hide();
                  this.successfullyDeployedModal();
                }
              })
              .catch(error => {
                this.loaderService.hide();
              })
              .finally(() => {
                this.isAnyError = true;
                this.fileUploaded = false;
              })
          }
        }
      }
    });
  }

  successfullyDeployedModal() {
    this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_LIST.SUCCESSFULLY_ADDED', { cssClass: "alert-success" });

    const modalId = this.modalService.show({
      template: this.successfullyDeployedTemplate,
      context: {
        dataModel: null,
        callbacks: {
          close: () => {
            this.fileUploaded = false;

            this.modalService.hide(modalId);
          }
        }
      }
    });
  }

  getUserRooms(userRooms: any) {
    Object.keys(userRooms).map(roomId => this.roomIds.includes(roomId) === false ? this.roomIds.push(roomId) : "")
    return (userRooms ? Object.keys(userRooms).filter(r => !userRooms[r].personal_room && !userRooms[r].meeting_room).map(r => userRooms[r].name) : [])
    .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
  }

  onNewUser() {
    const detail = this.detailPageService.loadComponent(UserDetailsComponent).instance;
    detail.action = UserDetailsComponent.ACTION_NEW;
  }

  onUserDetails(e: MouseEvent, user: User) {
    e.stopPropagation();

    const detail = this.detailPageService.loadComponent(UserDetailsComponent).instance;
    detail.action = UserDetailsComponent.ACTION_DETAILS;
    detail.userId = user.id;
  }

  onUserEdit(e: MouseEvent, user: User) {
    e.stopPropagation();
    
    if (!user.auth.disabled) {
      const detail = this.detailPageService.loadComponent(UserDetailsComponent).instance;
      detail.action = UserDetailsComponent.ACTION_EDIT;
      detail.userId = user.id;
    }
  }

  onFilterChanged() {
    var roles = [...this.selectedRoleFilter];
    var rooms = [...this.selectedRoomFilter];
    
    roles = roles.map(item => 
      item === this.subscriber   ? "user"    : 
      item === this.user         ? "user"    :
      item === this.admin        ? "admin"   :
      item === this.coadmin      ? "coadmin" :
      item === this.expert       ? "expert"  : item
    );
    
    if (this.licenseType === "concurrent_user_based" && roles.includes("user")) { 
      roles.push("expert");
    }
    rooms = rooms.map(r => this.roomData[r.toString()]);
    
    this.users = this.allUsers
      .filter(user => {
        let nameSelected      = this.textfieldInput ? user.name.toLowerCase().match(this.textfieldInput.toLowerCase())          : true;
        let userNameSelected  = this.textfieldInput ? user.auth.username.toLowerCase().match(this.textfieldInput.toLowerCase()) : true;
        let roomSelected      = rooms.length > 0 ? rooms.some(room => user.rooms ? Object.keys(user.rooms).includes(room.toString()) : false) : true;
        let roleSelected      = roles.length > 0 ? roles.includes(user.role)                                                    : true;
        return roomSelected && roleSelected && (nameSelected || userNameSelected);
      })
      .sort((a, b) => {
        if (!a.auth.disabled && b.auth.disabled === true) {
          return -1;
        } else if (a.auth.disabled === true && !b.auth.disabled) {
          return 1;
        } else {
          return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
        }
      })
      this.roles = this.users
      .map(user => this.licenseType === "expert_user_based"     && user.role === "user"    ? this.subscriber  : user)
      .map(user => this.licenseType === "concurrent_user_based" && user.role === "user"    ? this.user        : user)
      .map(user => this.licenseType === "concurrent_user_based" && user.role === "expert"  ? this.user        : user)
      .map(user => this.licenseType === "expert_user_based"     && user.role === "expert"  ? this.expert      : user)
      .map(user =>
        user.role === "admin"   ? this.admin   :
        user.role === "coadmin" ? this.coadmin : user
      )
      this.roles = this.roles.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);
  }

  onUserToggle(e: MouseEvent, user: User) {
    e.stopPropagation();

    if (user.role !== 'admin') {
      const modalId = this.modalService.show({
        template: this.toggleTemplate,
        context: {
          dataModel: user,
          callbacks: {
            no: () => {
              this.modalService.hide(modalId);
            },
            yes: () => {
              this.loaderService.show();
              this.userService.toggleUserStatus(user.id)
              .then(disabled => {
                this.userStatusUpdate(this.users);
                if (disabled) {
                  this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.DISABLED', { cssClass: 'alert-success' });
                } else {
                  this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.ENABLED', { cssClass: 'alert-success' });
                }
              })
              .catch(error => this.flashMessageService.showTranslated('APP.MAIN.USERS.USER_DETAILS.TOGGLE_USER_MODAL.ERROR'))
              .finally(() => {
                this.loaderService.hide();
                this.modalService.hide(modalId);
              });
          }
        }
      }
    });
    }
  }
}