<div class="card">
  <div class="card-header d-flex justify-content-between align-items-center">
    <span class="card-header-title">{{'APP.MAIN.SIDEBAR.REPORTS.REPORTS' | translate }}</span>
  </div>
  <div class="card-body">
    <div class="datepicker-container">
      <div class="dropdown-datepicker" [ngClass]="{'dropdown-open': isDatepickerOpen}">
        <div class="dropdown" (click)="toggleDatepicker()">
          <button class="btn btn-sm date-custom-dropdown" type="button" id="dropdownMenuButton">
            {{ selectedRangeLabel }} 
            <i class="arrow-icon"></i>
          </button>
        </div>
        <div class="datepicker-container-open" [ngClass]="{'dropdown-open': isDatepickerOpen}">
          <div class="padding-right-15">
            <div class="dropdown-datepicker">
              <div class="dropdown-menu-container">
                <ul class="dropdown-menu" aria-labelledby="dateRangeDropdown" [ngClass]="{ 'show': isDatepickerOpen }">
                  <li class="dropdown-item" (click)="selectDateRange(range)" *ngFor="let range of predefinedDateRanges">{{ range.label }}</li>
                  <li class="dropdown-item" (click)="selectCustomDateRange()">{{'APP.MAIN.SIDEBAR.REPORTS.CUSTOM' | translate }}</li>
                </ul>
              </div>
            </div>
            <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden" *ngIf="isDatepickerOpen" ></ngb-datepicker>
          </div>
          <hr *ngIf="isDatepickerOpen" style="border-top: 1px solid #e1e1e1;margin-top:10px">
          <div class="d-flex justify-content-end align-items-center mr-3" *ngIf="isDatepickerOpen">
            <button (click)="Cancel()" class="btn btn-secondary cancel-button">{{'APP.MAIN.SIDEBAR.REPORTS.CANCEL' | translate }}</button>
            <button (click)="Apply()" class="btn btn-primary apply-button ml-2 pt-1">{{'APP.MAIN.SIDEBAR.REPORTS.APPLY' | translate }}</button>
          </div>
        </div>
      </div>
    </div>

  <div class="row">
    <div class="col-sm-8">
      <div class="chart-container" #sessionCountContainer>
            <!--<canvas id="sessionCountChart"></canvas>-->
      </div>
      <div class="session-duration-container" #sessionDurationByRoomContainer>
         <!--<canvas id="sessionDurationByRoomChart"></canvas>-->
      </div>
      <div class="duration-container" #sessionDurationContainer>
           <!--<canvas id="sessionDurationChart"></canvas>-->
      </div>
    </div>

    <div class="col-sm-4 filter-side">
      <div class="row">
        <div class="col-sm mb-2 mt-4 mr-3" >
          <ng-select
            (clear)="resetFilters()"
            [items]="roomsDropdown"
            name="roomsDropdown"
            [clearSearchOnAdd]="true"
            [closeOnSelect]="false"
            [placeholder]="'APP.MAIN.USERS.ALL_ROOMS' | translate"
            [multiple]="true"
            [(ngModel)]="selectedRoomFilter"
            (change)="onFilterChanged(selectedRoomFilter)"
            style="font-size: 13px;"
            bindLabel="name"
            class="placeholder"
            dropdownPosition="bottom">
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm mb-2 mt-2 mr-3">
          <ng-select [items]="users" name="users" bindLabel="name" bindValue="id" [clearSearchOnAdd]="true" [closeOnSelect]="false"
          placeholder="{{ 'APP.MAIN.SESSIONS.SESSION_LIST.ALL_USERS' | translate }}" [multiple]="true" [(ngModel)]="filterUsers" (change)="onFilterChanged(filterUsers)" (clear)="resetFilters()"   class="placeholder" dropdownPosition="bottom">
          </ng-select>
        </div>
      </div>
      <div class="row" *ngIf="('design' | env).showLabels">
        <div class="col-sm mb-2 mt-2 mr-3">
          <ng-select
            class="placeholder"
            name="labels"
            [items]="labels"
            [multiple]="true"
            [clearSearchOnAdd]="true"
            [closeOnSelect]="false"
            placeholder="{{ 'APP.MAIN.SESSIONS.SESSION_LIST.ALL_LABELS' | translate }}"
            bindLabel="name"
            dropdownPosition="bottom"
            [(ngModel)]="selectedLabelFilter"
            (change)="onFilterChanged(selectedLabelFilter)"
            (clear)="resetFilters()"
          >
          </ng-select>
        </div>
      </div>
      

    </div>
  </div>

<ng-template #t let-date let-focused="focused">
	<span
		class="custom-day"
		[class.focused]="focused"
		[class.range]="isRange(date)"
		[class.faded]="isHovered(date) || isInside(date)"
		(mouseenter)="hoveredDate = date"
		(mouseleave)="hoveredDate = null"
    
	>
		{{ date.day }}
	</span>
</ng-template>