import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from "@services/user.service";

@Pipe({name: 'userName'})
export class UserNamePipe implements PipeTransform {

    constructor(private userService: UserService) {}

    transform(value: string, args?: any): Observable<string> {
        return this.userService.allUsers.pipe(map(users => {
            const user = users.find(u => u.id === value);
            return user ? user.name : "Unknown";
        }));
    }
}