import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { AddNativeElementDirective } from './others/directives/add-native-element.directive';
import { MustMatchDirective } from './others/directives/must-match.directive';
import { AlreadyTakenDirective } from './others/directives/already-taken.directive';
import { DynamicTooltipDirective } from './others/directives/dynamic-tooltip.directive';
import { TutorialDirective } from './others/directives/tutorial.directive';
import { CopyClipboardDirective } from './others/directives/copy-clipboard.directive';
import { FakeDisabledDirective } from './others/directives/fake-disabled.directive';
import { MinlengthDirective } from './others/directives/minlength.directive';
import { CustomRegexValidatorDirective } from './others/directives/custom-regex-validator.directive';
import { WhitespaceValidatorDirective } from './others/directives/whitespace-validator.directive';
import { UndesiredPasswordValidatorDirective } from './others/directives/undesired-password.directive';
import { MatTableResponsiveDirective } from './others/directives/mat-table-responsive.directive';

import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { EnvPipe } from './others/pipes/env.pipe';
import { FileSizePipe } from './others/pipes/file-size.pipe';
import { UserNamesPipe } from './others/pipes/user-names.pipe';
import { UserNamePipe } from './others/pipes/user-name.pipe';
import { RoomNamePipe } from './others/pipes/room-name.pipe';
import { SessionDurationPipe } from './others/pipes/session-duration.pipe';
import { GetIdsPipe } from './others/pipes/get-ids.pipe';
import { UtcCalcPipe } from './others/pipes/utc-calc.pipe';
import { DurationPipe } from './others/pipes/duration.pipe';
import { ObjectToArrayPipe } from './others/pipes/object-to-array.pipe';
import { FilterGuestsPipe } from './others/pipes/filter-guests.pipe';
import { DurationFormatPipe } from './others/pipes/duration-format.pipe';
import { UserUsernameRolePipe } from './others/pipes/user-username-role.pipe';
import { DatePipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagInputModule } from 'ngx-chips';

import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { NavbarComponent } from './components/main/navbar/navbar.component';
import { SidebarComponent } from './components/main/sidebar/sidebar.component';
import { DashboardComponent } from './components/main/content/dashboard/dashboard.component';
import { NotFoundComponent } from './components/others/not-found/not-found.component';
import { UserListComponent } from './components/main/content/users/user-list/user-list.component';
import { UserDetailsComponent } from './components/main/content/users/user-details/user-details.component';
import { RoomDetailsComponent } from './components/main/content/rooms/room-details/room-details.component';
import { RoomListComponent } from './components/main/content/rooms/room-list/room-list.component';
import { ModalComponent } from './components/others/modal/modal.component';
import { InfoBoxesComponent } from './components/main/content/dashboard/info-boxes/info-boxes.component';
import { SessionChartsComponent } from './components/main/content/dashboard/session-charts/session-charts.component';
import { PrivacyComponent } from './components/others/privacy/privacy.component';
import { TermsComponent } from './components/others/terms/terms.component';
import { SystemRequirementsComponent } from './components/main/content/settings/system-requirements/system-requirements.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SessionDetailsComponent } from './components/main/content/sessions/session-details/session-details.component';
import { StorageComponent } from './components/main/content/storage/storage.component';
import { SettingsComponent } from './components/main/content/settings/settings.component';
import { ArchiveListComponent } from './components/main/content/archives/archive-list/archive-list.component';
import { StationListComponent } from './components/main/content/stations/station-list/station-list.component';
import { StationDetailsComponent } from './components/main/content/stations/station-details/station-details.component';
import { StepDetailsComponent } from './components/main/content/stations/step-details/step-details.component';
import { StepListComponent } from './components/main/content/stations/step-list/step-list.component';
import { ImageCollaborationComponent } from './components/main/remote/image-collaboration/image-collaboration.component';
import { ImageAnnotationCanvasComponent } from './components/main/remote/image-collaboration/image-annotation-canvas/image-annotation-canvas.component';
import { ImageViewerComponent } from './components/main/remote/image-collaboration/image-viewer/image-viewer.component';
import { FlashMessageComponent } from './components/others/flash-message/flash-message.component';
import { SessionListLastComponent } from './components/main/content/sessions/session-list/session-list.component';
import { SessionsComponent } from './components/main/content/sessions/sessions.component';
import { AuthenticateComponent } from './components/authenticate/authenticate.component';
import { TicketsComponent } from './components/main/content/tickets/tickets.component';
import { AdLoginComponent } from './components/externals/ad-login/ad-login.component';
import { AdErrorComponent } from './components/externals/ad-error/ad-error.component';
import { SessionChatComponent } from './components/main/content/sessions/session-chat/session-chat.component';
import { SessionFileComponent } from './components/main/content/sessions/session-file/session-file.component';
import { LoginFromRemoteComponent } from './components/login-from-remote/login-from-remote.component';
import { ReportsChartComponent } from './components/reports-chart/reports-chart.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AddNativeElementDirective,
    MustMatchDirective,
    AlreadyTakenDirective,
    DynamicTooltipDirective,
    TutorialDirective,
    CopyClipboardDirective,
    FakeDisabledDirective,
    CustomRegexValidatorDirective,
    WhitespaceValidatorDirective,
    UndesiredPasswordValidatorDirective,
    MinlengthDirective,
    MatTableResponsiveDirective,
    EnvPipe,
    FileSizePipe,
    UserNamesPipe,
    UserNamePipe,
    RoomNamePipe,
    GetIdsPipe,
    SessionDurationPipe,
    UtcCalcPipe,
    DurationPipe,
    ObjectToArrayPipe,
    FilterGuestsPipe,
    DurationFormatPipe,
    UserUsernameRolePipe,
    AppComponent,
    LoginComponent,
    NavbarComponent,
    MainComponent,
    SidebarComponent,
    DashboardComponent,
    NotFoundComponent,
    UserListComponent,
    UserDetailsComponent,
    RoomDetailsComponent,
    RoomListComponent,
    ModalComponent,
    InfoBoxesComponent,
    SessionChartsComponent,
    PrivacyComponent,
    TermsComponent,
    SystemRequirementsComponent,
    SessionDetailsComponent,
    StorageComponent,
    SettingsComponent,
    ArchiveListComponent,
    StationListComponent,
    StationDetailsComponent,
    StepDetailsComponent,
    StepListComponent,
    ImageCollaborationComponent,
    ImageAnnotationCanvasComponent,
    ImageViewerComponent,
    FlashMessageComponent,
    SessionListLastComponent,
    SessionsComponent,
    AuthenticateComponent,
    TicketsComponent,
    AdLoginComponent,
    AdErrorComponent,
    SessionChatComponent,
    SessionFileComponent,
    LoginFromRemoteComponent,
    ReportsChartComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatTableModule,
    MatSortModule,
    TagInputModule,
    DragDropModule,
    HttpClientModule,
    ProgressbarModule.forRoot(),
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule.forRoot(),
    QRCodeModule,
    AngularFireModule.initializeApp(environment.firebase, environment.firebase.projectId),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgbModule,

  ],
  entryComponents: [
    UserDetailsComponent,
    RoomDetailsComponent,
    SessionDetailsComponent,
    StationDetailsComponent,
    StepDetailsComponent
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }