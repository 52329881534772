<div class="station-list-container">
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <span class="card-header-title">{{ 'APP.MAIN.STATIONS.STATION_LIST.TITLE' | translate }}</span>
            <button class="btn btn-outline-success" (click)="onNewStation()">{{ 'APP.MAIN.STATIONS.STATION_LIST.NEW_STATION' | translate }}</button>
        </div>
        <div class="card-body px-0 py-1">
            <div class="text-center my-2" *ngIf="stations.length === 0">{{ 'APP.MAIN.STATIONS.STATION_LIST.NO_STATION_MESSAGE' | translate }}</div>
            <div class="t-container container-fluid" *ngIf="stations.length !== 0">
                <div class="t-head row sm-hide pl-3 pr-4 button-pad">
                    <div class="t-cell col-1">#</div>
                    <div class="t-cell col-4">{{ 'APP.MAIN.STATIONS.STATION_LIST.STATION_NAME' | translate }}</div>
                    <div class="t-cell col-6">{{ 'APP.MAIN.STATIONS.STATION_LIST.STATION_DESCRIPTION' | translate }}</div>
                    <div class="t-cell col-1"></div>
                </div>

                <div class="t-row row pl-3 pr-4 button-pad" *ngFor="let station of stations; index as i;" (click)="onStationDetails($event, station)">
                    <div class="t-cell col-1 sm-hide">{{ i+1 }}</div>
                    <div class="t-cell sm-show col-6">{{ 'APP.MAIN.STATIONS.STATION_LIST.STATION_NAME' | translate }}</div>
                    <div class="t-cell col-6 col-lg-4">{{ station.name }}</div>
                    <div class="t-cell sm-show col-6">{{ 'APP.MAIN.STATIONS.STATION_LIST.STATION_DESCRIPTION' | translate }}</div>
                    <div class="t-cell col-6 col-lg-6">{{ station.description }}</div>
                    <div class="t-cell col-12 col-lg-1">
                        <div class="btn-group d-flex">
                            <button type="button" class="btn btn-sm btn-warning" (click)="onStationDetails($event, station)">
                                <i class="mi mi-remove-red-eye"></i><span class="d-inline d-lg-none">{{ 'APP.MAIN.STATIONS.STATION_LIST.DETAILS' | translate }}</span>
                            </button>
                            <button type="button" class="btn btn-sm btn-info" (click)="onStationEdit($event, station)">
                                <i class="mi mi-edit"></i><span class="d-inline d-lg-none">{{ 'APP.MAIN.STATIONS.STATION_LIST.EDIT' | translate }}</span>
                            </button>
                            <button type="button" class="btn btn-sm btn-danger" (click)="onStationDelete($event, station)">
                                <i class="mi mi-delete"></i><span class="d-inline d-lg-none">{{ 'APP.MAIN.STATIONS.STATION_LIST.DELETE' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #deleteStationTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="card-header py-3">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.DELETE_MODAL.TITLE' | translate }}</div>
                <div class="card-body">
                    <div class="m-body padded" [innerHTML]="'APP.MAIN.STATIONS.STATION_DETAILS.DELETE_MODAL.MESSAGE' | translate:dataModel"></div>
                    <div class="m-footer">
                        <a class="text-info" (click)="callbacks.cancel()">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.DELETE_MODAL.CANCEL' | translate }}</a>
                        <a class="text-danger" (click)="callbacks.delete()">{{ 'APP.MAIN.STATIONS.STATION_DETAILS.DELETE_MODAL.DELETE' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>