import { Injectable } from '@angular/core';

import { FlashMessageOptions } from '@models/FlashMessageOptions';

import { Observable, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FlashMessageService {

  messages: Observable<any[]>;
  private messagesSource = new BehaviorSubject<any[]>([]);
  private nextMessageId: number = 1;

  constructor( private translateService: TranslateService ) {
    this.messages = this.messagesSource.asObservable();
  }

  show(message: string, options: FlashMessageOptions = null): number {
    if (!options) {
      options = {
        cssClass: "alert-danger",
        timeout: 4000,
        noTimeout: false,
        closeOnClick: true,
        showCloseBtn: false
      }
    } else {
      if (!options.hasOwnProperty('cssClass')) { options.cssClass = "alert-danger" }
      if (!options.hasOwnProperty('noTimeout')) {
        options.noTimeout = false;
        if (!options.hasOwnProperty('timeout')) { options.timeout = 4000 }
      } else if (options.noTimeout) {
        if (!options.hasOwnProperty('timeout')) { options.timeout = 4000 }
      }
      if (!options.hasOwnProperty('closeOnClick')) { options.closeOnClick = true }
      if (!options.hasOwnProperty('showCloseBtn')) { options.showCloseBtn = false }
    }

    const flash: any = {
      id: this.nextMessageId,
      text: message,
      cssClass: options.cssClass,
      closeOnClick: options.closeOnClick,
      showCloseBtn: options.showCloseBtn,
      close: this.close
    }

    if (!options.noTimeout) {
      flash.timer = setTimeout(() => flash.close(flash), options.timeout);
    }

    this.nextMessageId++;
    const messages = this.messagesSource.getValue();
    messages.push(flash);
    this.messagesSource.next(messages);

    return flash.id;
  }

  private close = (message) => {
    if (message.noTimeout && message.timer) { clearTimeout(message.timer) }
    this.messagesSource.next(this.messagesSource.getValue().filter(m => m.id !== message.id));
  }

  hide(id: number) {
    const messages = this.messagesSource.getValue();
    const message = messages.find(m => m.id === id);
    if (message) {
      if (message.noTimeout && message.timer) { clearTimeout(message.timer) }
      this.messagesSource.next(this.messagesSource.getValue().filter(m => m.id !== message.id));
    }
  }

  showTranslated(key: string, options: FlashMessageOptions = null): Promise<number> {
    return this.translateService.get(key).toPromise().then(result => this.show(result, options));
  }

  showTranslatedWithData(key: string, params: any, options: FlashMessageOptions = null): Promise<number> {
    return this.translateService.get(key, params).toPromise().then(result => this.show(result, options));
  }
}