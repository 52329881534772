import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from './auth.service';
import { filter, map, first, distinctUntilChanged } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { Station } from '@models/Station';
import { Step } from '@models/Step';

@Injectable({
  providedIn: 'root'
})
export class StationService {

  stations: Observable<Station[]>;
  private stationsSource = new BehaviorSubject<Station[]>(null);
  private stationsSub: Subscription = null;

  constructor(
    private authService: AuthService,
    private afdb: AngularFireDatabase
  ) {
    this.stations = this.stationsSource.asObservable().pipe(filter(rooms => !!rooms));

    this.authService.on("login", this.onLogin);
    this.authService.on("logout", this.onLogout);
  }

  private onLogin = (account_id: string) => {
    this.startListennigAllStations(account_id);
  }

  private onLogout = () => {
    this.stopListenningAllStations();
  }

  startListennigAllStations(account_id: string) {
    if (this.stationsSub) { this.stationsSub.unsubscribe() }

    this.stationsSub = this.afdb.list<Station>(`accounts/${account_id}/workflow/stations`).snapshotChanges()
    .pipe(
      map(stationsSnaps => stationsSnaps.map(snap => {
        const station = snap.payload.val();
        station.id = snap.key;
        return station;
      }))
    ).subscribe(stations => {
      this.stationsSource.next(stations);
    });
  }

  stopListenningAllStations() {
    if (this.stationsSub) { this.stationsSub.unsubscribe() }
    this.stationsSource.next(null);
  }

  getStation(stationId: string): Promise<Station> {
    return this.stations.pipe(first(), map(stations => stations.find(station => station.id === stationId))).toPromise();
  }

  getStepsStream(stationId: string): Observable<Step[]> {
    return this.stations.pipe(
      map(stations => {
        const s = stations.find(station => station.id === stationId);
        return s ? s.steps : {};
      }),
      distinctUntilChanged(),
      map(stepsObj => {
        return (stepsObj ? Object.keys(stepsObj).map(s => {
          const st = stepsObj[s];
          st.id = s;
          return st;
        }) : []);
      })
    );
  }

  getStep(stationId: string, stepId: string): Promise<Step> {
    return this.stations.pipe(first(), map(stations => stations.find(station => station.id === stationId).steps[stepId])).toPromise();
  }

  editStation(changes: {id: string, station: any}) {
    return new Promise<void>((resolve, reject) => {
      this.afdb.object(`accounts/${this.authService.currentUser.account_id}/workflow/stations/${changes.id}`).update(changes.station)
      .then(() => setTimeout(() => resolve(), 1000))
      .catch(error => reject(error));
    });
  }

  createStation(changes: {id: string, station: any}) {
    return new Promise<string>((resolve, reject) => {
      const account_id = this.authService.currentUser.account_id;
      const qr_id = this.afdb.createPushId();
      changes.station.qrCode = `${account_id}!!${qr_id}`;
      this.afdb.object(`accounts/${account_id}/workflow/stations/${changes.id}`).set(changes.station)
      .then(() => setTimeout(() => resolve(changes.id), 1000))
      .catch(error => reject(error));
    });
  }

  deleteStation(stationId: string) {
    const workflow: any = {};
    workflow[`stations/${stationId}`] = null;
    workflow[`collaborations/${stationId}`] = null;

    return this.afdb.object(`accounts/${this.authService.currentUser.account_id}/workflow`).update(workflow);
  }

  editStep(changes: {stationId: string, stepId: string, step: any}) {
    return new Promise<void>((resolve, reject) => {
      this.afdb.object(`accounts/${this.authService.currentUser.account_id}/workflow/stations/${changes.stationId}/steps/${changes.stepId}`).update(changes.step)
      .then(() => setTimeout(() => resolve(), 1000))
      .catch(error => reject(error));
    });
  }

  stepUp(stationId: string, stepId: string) {
    return this.afdb.database.ref(`accounts/${this.authService.currentUser.account_id}/workflow/stations/${stationId}/steps`).transaction(steps => {
      if (steps) {
        const stepList = Object.keys(steps).map(s => {
          const step = steps[s];
          step.id = s;
          return step;
        });
        let previousStep: any = null;
        for (const step of stepList.sort((a, b) => a.order - b.order)) {
          if (previousStep && step.id === stepId) {
            const order = previousStep.order;
            previousStep.order = step.order;
            step.order = order;
          }
          step.id = null;
          previousStep = step;
        }
      }
      return steps;
    }, () => {}, false);
  }

  stepDown(stationId: string, stepId: string) {
    return this.afdb.database.ref(`accounts/${this.authService.currentUser.account_id}/workflow/stations/${stationId}/steps`).transaction(steps => {
      if (steps) {
        const stepList = Object.keys(steps).map(s => {
          const step = steps[s];
          step.id = s;
          return step;
        });
        let currentStep: any = null;
        for (const step of stepList.sort((a, b) => a.order - b.order)) {
          if (currentStep) {
            const order = step.order;
            step.order = currentStep.order;
            currentStep.order = order;
            currentStep = null;
          }
          if (step.id === stepId) {
            currentStep = step;
          }
          step.id = null;
        }
      }
      return steps;
    }, () => {}, false);
  }

  stepChange(stationId: string, stepId: string, newOrder: number) {
    return this.afdb.database.ref(`accounts/${this.authService.currentUser.account_id}/workflow/stations/${stationId}/steps`).transaction(steps => {
      if (steps) {
        // Convert steps object to array
        const stepList = Object.keys(steps).map(s => {
          const step = steps[s];
          step.id = s;
          return step;
        })
        .filter(s => s.id !== stepId)
        .sort((a, b) => a.order - b.order);

        stepList.splice(newOrder, 0, steps[stepId]);
        stepList.forEach((step, index) => {
          step.order = index;
          step.id = null;
        });
      }
      return steps;
    }, () => {}, false);
  }

  async createStep(changes: {stationId: string, stepId: string, step: any}) {
    //const steps = await this.stations.pipe(first(), map(stations => stations.find(station => station.id === changes.stationId).steps)).toPromise()
    //const maxOrder = steps ? Object.values(steps).reduce((max, step) => ((max > step.order) ? max : step.order), 0) : -1;
    //changes.step.order = maxOrder + 1;
    //const result = await this.afdb.list(`accounts/${this.authService.currentUser.account_id}/stations/${changes.stationId}/steps`).push(changes.step);

    const stepId = this.afdb.createPushId();
    const result = await this.afdb.database.ref(`accounts/${this.authService.currentUser.account_id}/workflow/stations/${changes.stationId}/steps`).transaction(steps => {
      if (steps) {
        const stepList = Object.keys(steps).map(s => {
          const step = steps[s];
          step.id = s;
          return step;
        });
        let count: number = 0;
        stepList.sort((a, b) => a.order - b.order).forEach(step => {
          steps[step.id].order = count;
          steps[step.id].id = null;
          count = count + 1;
        });
        changes.step.order = count;
        steps[stepId] = changes.step;
      } else {
        changes.step.order = 0;
        return { [stepId]: changes.step }
      }
      return steps;
    }, () => {}, false);
    if (!result.committed) {
      throw new Error('create-step-failed');
    }
    return (new Promise<string>((resolve) => setTimeout(() => resolve(stepId), 1000)));
  }

  deleteStep(stationId: string, stepId: string) {
    return (this.afdb.database.ref(`accounts/${this.authService.currentUser.account_id}/workflow/stations/${stationId}/steps`).transaction(steps => {
      if (steps) {
        const stepList = Object.keys(steps).filter(k => k !== stepId).map(s => {
          const step = steps[s];
          step.id = s;
          return step;
        });
        let count: number = 0;
        stepList.sort((a, b) => a.order - b.order).forEach(step => {
          steps[step.id].order = count;
          steps[step.id].id = null;
          count = count + 1;
        });
        steps[stepId] = null;
      }
      return steps;
    }, () => {}, false)
    .then(result => {
      return this.afdb.database.ref(`accounts/${this.authService.currentUser.account_id}/workflow/collaborations/${stationId}/steps/${stepId}`)
      .remove().then(() => result);
    }));
  }
}
